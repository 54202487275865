import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
  getPO,
  get_store,
  getInternalCompany,
  get_poWithLineItems_node,
  save_purchase_inward_node,
  get_material_code,
} from "../../../api";
import {
  convertDateYYMMDD,
  getLabels,
  convertDate,
  incrementMaterialCode,
} from "../../../common/Functions/CommonFunctions";
import { useNavigate } from "react-router-dom";
import CreateRMI from "./RMI/CreateRMI";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
const imageFileTypes = ["image/jpeg", "image/jpg", "image/png"];

const schema = yup
  .object()
  .shape({
    // store: yup
    //   .array()
    //   .min(1, "Please select store !")
    //   .required("Please select store !"),
    location: yup
      .array()
      .min(1, "Please select location !")
      .required("Please select location !"),
    // tally_voucher_no: yup
    //   .string()
    //   .required("Enter tally voucher no / requisition no !"),
    // tally_voucher_date: yup
    //   .date()
    //   .typeError("Please select tally voucher date !")
    //   .required("Please select tally voucher date !"),
    invoice_no: yup.string().required("Invoice no is required !"),
    invoice_date: yup
      .date()
      .typeError("Please select invoice date !")
      .required("Please select invoice date !"),
    pi_image: yup
      .mixed()
      .test("fileSize", "Image can be uploaded upto 10 MB", (value) => {
        if (value.length == 0) return true;
        return value && value[0]?.size <= 10 * 1024 * 1024;
      })
      .test("fileType", "Only image files are allowed", (value) => {
        if (value.length == 0) return true; // If no file is selected, it's considered valid.
        // return value[0]?.type.startsWith('image/');
        return imageFileTypes.includes(value[0]?.type);
      }),
    items: yup.array().of(
      yup.object().shape({
        product: yup
          .array()
          .min(1, "Please select product !")
          .required("Please select product !"),
        // material_code: yup.string().required("Batch code is required !"),
        material_code: yup.string().when(
          ['batch_tracking'],
          (batchTracking, schema) => {
            return batchTracking === true
              ? schema.required("Batch code is required!")
              : schema;
          }
        ).nullable(),
        // po_order_qty: yup
        //   .string()
        //   .required("Po order qty is required")
        //   .matches(/^\d+(\.\d+)?$/, "Enter valid po order qty"),
        // received_qty: yup
        //   .string()
        //   .required("Received qty is required !")
        //   .matches(/^\d+(\.\d+)?$/, "Enter valid received qty !")
        //   .test(
        //     "minValueLessThanValue",
        //     "Received qty must be greater than or equal to order qty !",
        //     function (value) {
        //       const val = this.resolve(yup.ref("po_order_qty"));
        //       return parseFloat(value) <= parseFloat(val);
        //     }
        //   ),
        // invoice_qty: yup
        //   .string()
        //   .required("Invoice qty is required !")
        //   .matches(/^\d+(\.\d+)?$/, "Max value must be a number !")
        //   .test(
        //     "maxValueGreaterThanValue",
        //     "Invoice qty must be greater than or equal to order qty !",
        //     function (value) {
        //       const val = this.resolve(yup.ref("po_order_qty"));
        //       return parseFloat(value) <= parseFloat(val);
        //     }
        //   ),
        // remark: yup.string().required("Remark is required !"),
      })
    ),
  })
  .required();

function CreatePurchaseInv() {
  let navigate = useNavigate();
  const rmi_config = JSON.parse(localStorage.getItem("configuration"))
  // const configuration = JSON.parse(localStorage.getItem("configuration"));

  const [poList, setPOList] = useState({ data: [], loading: false });
  const [poWithItem, setPOWithItem] = useState({ data: {}, loading: false });
  const [selPO, setSelPO] = useState([]);
  const [dataStore, setDataStore] = useState([]);
  const [storeList, setStoreList] = useState({ data: [], loading: false });
  const [loc_name, setLoc_Name] = useState({ data: [], loading: false });
  const [bar_obj, setBarobj] = useState({});

  const [dummyRec, setDummyRec] = useState({}); // for calculation purpose
  const [lock, setLock] = useState(false); // state used for save button disabled if store_type is not "Purchase"
  const [constLabel, setConstLabel] = useState({});
  const [lockBtn, setLockBtn] = useState(false);

  const [rmiModal, setRMIModal] = useState(false);
  const [currIndex, setCurrIndex] = useState(0);
  const [rmi_data, setRMI_Data] = useState([]);

  const [mat_code, setMat_Code] = useState();

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    getValues,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    // defaultValues: {
    //   items: [
    //     {
    //       product: [],
    //       po_order_qty: 0,
    //       received_qty: 0,
    //       invoice_qty: 0,
    //       remain_qty: 0,
    //       amount: 0,
    //       remark: "",
    //     },
    //   ],
    // },
  }); // initialise the hook

  let { errors } = formState;
  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  // when component load then run below useEffect and call the fuctions inside useEffeact
  useEffect(() => {
    setConstLabel(getLabels("Purchase", "Purchase Inward"));
    fetchPOList();
    fetchMaterialCode();
    // fetchStore();
    // fetchLocation();
  }, []);

  // when user select PO number then set data in dummyRec useState then call the fuction inside useEffect
  useEffect(() => {
    if (dummyRec !== undefined && Object.keys(dummyRec).length > 0) {
      fetchInternal_company(); // Collect location list form this function
    }
  }, [dummyRec]);

  useEffect(() => {
    var poLineItems = [];
    if (
      dummyRec?.PO_line_item !== undefined &&
      dummyRec?.PO_line_item.length > 0
    ) {
      for (let i = 0; i < dummyRec.PO_line_item.length; i++) {
        poLineItems.push({
          product: [
            {
              uuid: dummyRec?.PO_line_item?.[i]?.productid_id,
              productname: dummyRec?.PO_line_item?.[i]?.productname,
              poproductid: dummyRec?.PO_line_item?.[i]?.poproductid,
              producttype: dummyRec?.PO_line_item?.[i]?.producttype,
              product_id: dummyRec?.PO_line_item?.[i]?.product_id,
              materialType: dummyRec?.PO_line_item?.[i]?.material_type,
            },
          ],
          batch_tracking: dummyRec?.PO_line_item?.[i]?.batch_tracking,
          material_code:
            dummyRec?.PO_line_item?.[i]?.producttype == "Raw material" &&
            dummyRec?.PO_line_item?.[i]?.material_type &&
            ["BAR", "CASTING", "FORGING"].includes(
              dummyRec?.PO_line_item?.[i]?.material_type
            )
              ? dummyRec?.PO_line_item?.[i]?.material_code
              : null,
          po_order_qty: dummyRec?.PO_line_item?.[i]?.quantity,
          // received_qty:
          //   dummyRec?.PO_line_item?.[i]?.quantity -
          //   dummyRec?.PO_line_item?.[i]?.inward,
          received_qty: Math.max(
              0,
              (
                dummyRec?.PO_line_item?.[i]?.inward !== undefined ?
                  dummyRec?.PO_line_item?.[i]?.quantity - dummyRec?.PO_line_item?.[i]?.inward :
                // dummyRec?.PO_line_item?.[i]?.inward :
                dummyRec?.PO_line_item?.[i]?.quantity
              ).toFixed(2)
          ),
          invoice_qty: Math.max(
            0,
            (
              dummyRec?.PO_line_item?.[i]?.inward !== undefined ?
              dummyRec?.PO_line_item?.[i]?.quantity - dummyRec?.PO_line_item?.[i]?.inward :
              // dummyRec?.PO_line_item?.[i]?.inward :
              dummyRec?.PO_line_item?.[i]?.quantity
            ).toFixed(2)
          ),
          // invoice_qty:
          //   dummyRec?.PO_line_item?.[i]?.quantity -
          //   dummyRec?.PO_line_item?.[i]?.inward,
          // remain_qty:
          //   dummyRec?.PO_line_item?.[i]?.inward !== 0
          //     ? dummyRec?.PO_line_item?.[i]?.quantity -
          //       dummyRec?.PO_line_item?.[i]?.inward
          //     : 0,
          remain_qty: Math.max(
            0,
            (
              dummyRec?.PO_line_item?.[i]?.inward !== undefined ?
              // dummyRec?.PO_line_item?.[i]?.quantity - (dummyRec?.PO_line_item?.[i]?.quantity - dummyRec?.PO_line_item?.[i]?.inward) :
              dummyRec?.PO_line_item?.[i]?.quantity - dummyRec?.PO_line_item?.[i]?.inward : 0
              // dummyRec?.PO_line_item?.[i]?.quantity
            ).toFixed(2)
        ),
          unit: dummyRec?.PO_line_item?.[i]?.unit,
          // amount: parseFloat(
          //   dummyRec?.PO_line_item?.[i]?.rate *
          //     (dummyRec?.PO_line_item?.[i]?.quantity -
          //       dummyRec?.PO_line_item?.[i]?.inward)
          // ).toFixed(2),
          amount: parseFloat(
            dummyRec?.PO_line_item?.[i]?.rate *
            Math.max(
                0,
                dummyRec?.PO_line_item?.[i]?.inward !== undefined ?
                dummyRec?.PO_line_item?.[i]?.quantity - dummyRec?.PO_line_item?.[i]?.inward :
                // dummyRec?.PO_line_item?.[i]?.inward :
                dummyRec?.PO_line_item?.[i]?.quantity
            ).toFixed(2)
        ),
        });
      }
      // setValue("items", poLineItems);
      reset({ items: poLineItems });
      setDataStore(poLineItems);
      setDummyRec(poWithItem.data);
    }
  }, [dummyRec, poWithItem]);

  const selLoc = watch("location");

  useEffect(() => {
    if (selLoc !== undefined && selLoc.length > 0) {
      fetchStore(selLoc[0]?.uuid);
    }
  }, [selLoc]);

  function fetchStore(id) {
    setStoreList({ ...storeList, data: [], loading: true });

    get_store({ location_id: id }).then(
      (res) => {
        const purch_store = res.data.data.filter(
          (store) => store.store_types == "Purchase"
        );

        if (purch_store.length > 0) {
          setStoreList({
            ...storeList,
            data: purch_store,
            loading: false,
          });
        } else {
          toast.error("Purchase store is not present for this location", {
            autoClose: 3000,
          });
          setLock(true);
        }
      },
      (err) => {
        setStoreList({ ...storeList, data: [], loading: false });
      }
    );
  }

  // function fetchLocation() {
  //   setLoc_Name({ ...loc_name, state: true, data: [] });
  //   getLocation().then(
  //     (res) => {

  //       setLoc_Name({ ...loc_name, state: false, data: res.data.data });
  //     },
  //     (err) => {
  //       //
  //       setLoc_Name({ ...loc_name, state: false, data: [] });
  //       toast.error(err.response.data.message, {
  //         autoClose: 3000,
  //       });
  //     }
  //   );
  // }

  //fetching the company where uuid match and set the location list from the company object to useState variable
  function fetchInternal_company() {
    setLoc_Name({ ...loc_name, loading: true, data: [] });
    getInternalCompany({
      uuid: dummyRec?.invoice_to_id,
      with_location: 1,
    }).then(
      (res) => {
        setLoc_Name({
          ...loc_name,
          loading: false,
          data: res.data.data[0]?.location_list,
        });
      },
      (err) => {
        //
        setLoc_Name({ ...loc_name, loading: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }

  function fetchPOList() {
    setPOList({ ...poList, data: [], loading: true });

    getPO({ po_status: "Approved"}).then(
      (res) => {
        //

        const resPOList = res.data.data.filter((val) => {
          if (val.client_poid == null) {
            val.client_poid = "";
          }
        });

        setPOList({ ...poList, data: res.data.data, loading: false });
      },
      (err) => {
        setPOList({ ...poList, data: [], loading: false });
        toast.error("Failed in fetching purchase order list !", {
          autoClose: 3000,
        });
      }
    );
  }

  function fetchpoInwardItemsList(poId) {
    setPOWithItem({ ...poWithItem, data: {}, loading: true });

    const payload = {
      po_id: poId[0]?.uuid,
    };

    get_poWithLineItems_node(payload).then(
      (res) => {
        const foundRecord = poList.data.find(
          (record) => record.uuid === poId[0]?.uuid
        );
        const updatedArr2 = res.data?.data?.PO_line_item?.map((obj2, ind) => {
          const matchingObj = foundRecord?.PO_line_item?.find(
            (obj1) => obj1.productid_id?.uuid === obj2.productid_id
          );
          if (matchingObj) {
            return {
              ...obj2,
              material_type:
                matchingObj.productid_id?.materialTypeDetail?.material_type,
              material_code:
                ind == 0 ? mat_code : incrementMaterialCode(mat_code, ind),
              batch_tracking: matchingObj?.productid_id?.batch_tracking,
            };
          } else {
            return {
              ...obj2,
              material_type: null,
              material_code:
                ind == 0 ? mat_code : incrementMaterialCode(mat_code, ind),
              batch_tracking: matchingObj?.productid_id?.batch_tracking,
            };
          }
        });

        // console.log("updatedArr2", updatedArr2);

        setPOWithItem({
          ...poWithItem,
          // data: { ...res.data.data },
          data: { ...res.data.data, PO_line_item: updatedArr2 },
          loading: false,
        });
        setDummyRec({ ...res.data.data, PO_line_item: updatedArr2 });
      },
      (err) => {
        setPOWithItem({ ...poWithItem, data: {}, loading: false });
        toast.error("Failed in fetching purchase order list !", {
          autoClose: 3000,
        });
      }
    );
  }

  async function calInwardQty(para1, para2, index) {
    const invoiceQuantity = getValues(`items[${index}].invoice_qty`);

    // const rate = parseFloat(poWithItem.data.PO_line_item[index].rate);
    const rate = parseFloat(dummyRec?.PO_line_item[index].rate);
    const poOrderQty = getValues(`items[${index}].po_order_qty`);
    // const recvQty = poWithItem.data.PO_line_item[index].inward;
    const recvQty = dummyRec?.PO_line_item[index].inward !== undefined ? dummyRec?.PO_line_item[index].inward : 0;

    switch (para2) {
      case "orderQty":
        // items[${index}].price

        // if(poOrderQty >= parseFloat(para1)){
        //   const result = poOrderQty - parseFloat(para1);
        //   const amt = parseFloat(para1) * rate;
        //   setValue(`items[${index}].remain_qty`, result.toFixed(1));
        //   setValue(`items[${index}].invoice_qty`, parseFloat(para1));
        //   setValue(`items[${index}].amount`, amt.toFixed(2));
        // }

        if (recvQty >= parseFloat(para1)) {
          const result = poOrderQty - parseFloat(para1);
          const amt = parseFloat(para1) * rate;
          setValue(`items[${index}].remain_qty`, result.toFixed(1));
          setValue(`items[${index}].invoice_qty`, parseFloat(para1));
          setValue(`items[${index}].amount`, amt.toFixed(2));
        }

        break;
      case "recvQty":
        // if(Number.isInteger(para1)){

        //   setValue(`items[${index}].received_qty`, parseFloat(para1));
        // }else{
        //   setValue(`items[${index}].received_qty`, parseFloat(0));

        // }

        if (rmi_data && rmi_data.length > 0) {
          const updatedRmiData = rmi_data?.map((item) => {
            if (currIndex === item.ind) {
              return { ...item, NCDetails: [] };
            }
            return item;
          });
          setRMI_Data(updatedRmiData);
        }

        setValue(`items[${index}].received_qty`, para1);

        const regex = /^[0-9]+(\.[0-9]{1,2})?$/;
        if (!regex.test(para1)) {
          setError(`items[${index}].received_qty`, {
            type: "custom",
            message: "Enter valid order quantity !",
          });
          return;
        }

        let z = Number(recvQty) + Number(para1);

        if (z > poOrderQty) {
          setError(`items[${index}].received_qty`, {
            type: "custom",
            message:
              "The received is should not be greater than order quantity !",
          });
        } else {
          clearErrors(`items[${index}].received_qty`);
          const result = poOrderQty - (Number(recvQty) + Number(para1));
          const amt = para1 * rate;

          setValue(`items[${index}].remain_qty`, result.toFixed(1));
          setValue(`items[${index}].invoice_qty`, para1);
          setValue(`items[${index}].amount`, amt.toFixed(2));
        }

        break;

      case "invQty":
        if (poOrderQty >= para1) {
          const result = poOrderQty - parseFloat(para1);
          const amt = parseFloat(para1) * rate;
          setValue(`items[${index}].remain_qty`, result.toFixed(1));
          setValue(`items[${index}].amount`, amt.toFixed(2));
        }

        break;
    }
  }

  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      if (file) {
        reader.readAsDataURL(file);
      } else {
        reject(new Error("File is empty"));
      }
    });
  }

  const onSubmit = async (data) => {
    // console.log("rmi_data", rmi_data);
    const rmiInwardCount = data?.items?.filter(
      (item) =>
        item.product?.[0]?.producttype === "Raw material" &&
        item.product?.[0]?.materialType &&
        ["BAR", "CASTING", "FORGING"].includes(item.product?.[0]?.materialType)
    );

    if (rmiInwardCount.length !== rmi_data.length) {
      toast.error("Please fill raw material inward details first !", {
        autoClose: 2000,
      });
      return;
    }

    let reqPayload = [];

    if (lock) {
      return;
    }

    setLockBtn(true);

    for (let i = 0; i < data.items.length; i++) {
      //if po_order_qty and inward qty both are same then not create lineItems payload
      let rmi_obj = rmi_data.find((rmi) => rmi.ind === i);

      if (
        parseFloat(data.items[i].po_order_qty) ==
        parseFloat(dummyRec?.PO_line_item[i].inward)
      ) {
        continue;
      }

      const lineItems = {
        // poinward_id: "res.data.uuid", // po inward uuid
        productid_id: data.items[i].product[0]?.uuid,
        productname: data.items[i].product[0]?.productname.trim(),
        po_order_qty: parseFloat(data.items[i].po_order_qty),
        received_qty: parseFloat(data.items[i].received_qty),
        prev_inward: dummyRec?.PO_line_item[i].inward !== undefined ? parseFloat(dummyRec?.PO_line_item[i].inward) : 0, // As per backend request added 01-11-2023
        remain_qty: parseFloat(data.items[i].remain_qty),
        amount: parseFloat(data.items[i].amount),
        unit: data.items[i].unit.trim(), // As per discussio backend devloper key added 30-11-2023
        remark: data.items[i].remark.trim(),
        invoice_no: data.invoice_no,
        invoice_qty: parseFloat(data.items[i].invoice_qty),
        invoice_date: convertDateYYMMDD(data.invoice_date, "-"), //"2023-01-01",

        poproductid: data.items[i].product[0]?.poproductid,
        prodType: data.items[i].product[0]?.producttype,
        prodId: data.items[i].product[0]?.product_id,
        materialType: dummyRec?.PO_line_item?.[i]?.material_type,
        // diameter: dummyRec?.PO_line_item?.[i]?.diameter,
        diameter: selPO?.[0]?.PO_line_item?.[i]?.productid_id?.diameter,
        // material_code: dummyRec?.PO_line_item?.[i]?.material_code,
        material_code: data.items?.[i]?.material_code,
      };

      if (rmi_obj) {
        delete rmi_obj.ind;
        lineItems.RMI = rmi_obj;
      }

      reqPayload.push(lineItems);
    }

    // If product items order qty and inwarded qty both are same then below block run
    if (reqPayload.length == 0) {
      SweetAlert.fire({
        title: "Product items already inwarded !",
        // text: "Once deleted, you will not be able to recover this record !",
        icon: "warning",
        confirmButtonText: "Ok",
        reverseButtons: true,
      });
      setLockBtn(false);
      return;
    }

    if (storeList.data.length == 0) {
      toast.error("Stores not found", {
        autoClose: 3000,
      });
    }
    const purchase_store = storeList.data.filter(val => val.name.trim().toUpperCase() == "PURCHASE");
    if (purchase_store.length == 0) {
      toast.error("Purchase store not found", {
        autoClose: 3000,
      });
    }

    const payload = {
      client_poid: selPO[0]?.client_poid,
      po_id: selPO[0]?.uuid,
      invoice_to_name: selPO[0]?.invoice_toname,
      dispatch_to_name: selPO[0]?.despatch_toname,
      invoice_to_id: selPO[0]?.invoice_to_id.uuid,
      dispatch_to_id: selPO[0]?.despatch_to_id.uuid,
      supplier_id: selPO[0]?.supplier_id.uuid,
      supplier_name: selPO[0]?.suppliername,
      po_remark: selPO[0]?.remark,
      invoice_no: data.invoice_no,
      invoice_date: convertDateYYMMDD(data.invoice_date, "-"),
      location_id:
        selLoc !== undefined && selLoc.length > 0 ? selLoc?.[0]?.uuid : null,
      location_name:
        selLoc !== undefined && selLoc.length > 0
          ? selLoc?.[0]?.locationname
          : null,
          store_id:
          storeList.data !== undefined && storeList.data.length > 0
            ? purchase_store[0]?.uuid
            : null,
        store_name:
          storeList.data !== undefined && storeList.data.length > 0
            ? purchase_store[0]?.name
            : null,
      tally_voucher_no:
        data.tally_voucher_no !== undefined && data.tally_voucher_no !== ""
          ? data.tally_voucher_no
          : "0",
      tally_voucher_date:
        data.tally_voucher_date !== undefined &&
        data.tally_voucher_date !== null
          ? convertDateYYMMDD(data.tally_voucher_date, "-")
          : convertDateYYMMDD(new Date(), "-"),
      po_date: selPO[0]?.po_date,
      voucher_no: selPO[0]?.voucher_no,
      // po_inward_line_item: JSON.stringify(reqPayload),
      po_inward_line_item: reqPayload,
    };

    if (data.pi_image?.length > 0) {
      payload.upload_file = data.pi_image[0];
    } else {
      payload.upload_file = null;
    }

    if (data.pi_image?.length > 0) {
      const getExt = data.pi_image?.[0]?.name?.split(".").pop();

      let dataimage = await convertBase64(data.pi_image[0]);
      let newImage = dataimage.replace(
        /data:image\/[a-zA-Z]+;/,
        "data:image/" + getExt + ";"
      );

      payload.upload_file = newImage;
      payload.file_name = data.pi_image?.[0]?.name;
    } else {
      payload.upload_file = null;
    }

    Object.keys(payload).forEach((key) => {
      // Check if the value is null and delete the key
      if (payload[key] === null) {
        delete payload[key];
      }
    });

    // console.log("payload", payload);

    // const formDataToSend = new FormData();

    // for (const [key, value] of Object.entries(payload)) {
    //   formDataToSend.append(key, value);
    // }

    save_purchase_inward_node(payload).then(
      (res) => {
        //
        toast.success("Purchase inward created successfully", {
          autoClose: 2000,
        });

        setTimeout(() => {
          navigate("/pcms/purchaseInward");
        }, [2300]);
        setLockBtn(false);
      },
      (err) => {
        if (err.response.status === 401) {
          toast.error(err.response.statusText, {
            autoClose: 3000,
          });
        }
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
        setRMI_Data([]);
        setLockBtn(false);
      }
    );
  };

  const resetLineItems = () => {
    let poLineItems1 = [];
    for (let i = 0; i < dummyRec?.PO_line_item.length; i++) {
      poLineItems1.push({
        product: [
          {
            uuid: dummyRec?.PO_line_item?.[i]?.productid_id,
            productname: dummyRec?.PO_line_item?.[i]?.productname,
            poproductid: dummyRec?.PO_line_item?.[i]?.poproductid,
            producttype: dummyRec?.PO_line_item?.[i]?.producttype,
            product_id: dummyRec?.PO_line_item?.[i]?.product_id,
            materialType: dummyRec?.PO_line_item?.[i]?.material_type,
          },
        ],
        batch_tracking: dummyRec?.PO_line_item?.[i]?.batch_tracking,
        material_code:
            dummyRec?.PO_line_item?.[i]?.producttype == "Raw material" &&
            dummyRec?.PO_line_item?.[i]?.material_type &&
            ["BAR", "CASTING", "FORGING"].includes(
              dummyRec?.PO_line_item?.[i]?.material_type
            )
              ? dummyRec?.PO_line_item?.[i]?.material_code
              : null,
        po_order_qty: dummyRec?.PO_line_item?.[i]?.quantity,
        // received_qty:
          //   dummyRec?.PO_line_item?.[i]?.quantity -
          //   dummyRec?.PO_line_item?.[i]?.inward,
          received_qty: Math.max(
            0,
            (
              dummyRec?.PO_line_item?.[i]?.inward !== undefined ?
                dummyRec?.PO_line_item?.[i]?.quantity - dummyRec?.PO_line_item?.[i]?.inward :
              // dummyRec?.PO_line_item?.[i]?.inward :
              dummyRec?.PO_line_item?.[i]?.quantity
            ).toFixed(2)
        ),
        invoice_qty: Math.max(
          0,
          (
            dummyRec?.PO_line_item?.[i]?.inward !== undefined ?
            dummyRec?.PO_line_item?.[i]?.quantity - dummyRec?.PO_line_item?.[i]?.inward :
            // dummyRec?.PO_line_item?.[i]?.inward :
            dummyRec?.PO_line_item?.[i]?.quantity
          ).toFixed(2)
        ),
        // invoice_qty:
        //   dummyRec?.PO_line_item?.[i]?.quantity -
        //   dummyRec?.PO_line_item?.[i]?.inward,
        // remain_qty:
        //   dummyRec?.PO_line_item?.[i]?.inward !== 0
        //     ? dummyRec?.PO_line_item?.[i]?.quantity -
        //       dummyRec?.PO_line_item?.[i]?.inward
        //     : 0,
        remain_qty: Math.max(
          0,
          (
            dummyRec?.PO_line_item?.[i]?.inward !== undefined ?
            // dummyRec?.PO_line_item?.[i]?.quantity - (dummyRec?.PO_line_item?.[i]?.quantity - dummyRec?.PO_line_item?.[i]?.inward) :
            dummyRec?.PO_line_item?.[i]?.quantity - dummyRec?.PO_line_item?.[i]?.inward : 0
            // dummyRec?.PO_line_item?.[i]?.quantity
          ).toFixed(2)
      ),
        unit: dummyRec?.PO_line_item?.[i]?.unit,
        // amount: parseFloat(
        //   dummyRec?.PO_line_item?.[i]?.rate *
        //     (dummyRec?.PO_line_item?.[i]?.quantity -
        //       dummyRec?.PO_line_item?.[i]?.inward)
        // ).toFixed(2),
        amount: parseFloat(
          dummyRec?.PO_line_item?.[i]?.rate *
          Math.max(
              0,
              dummyRec?.PO_line_item?.[i]?.inward !== undefined ?
              dummyRec?.PO_line_item?.[i]?.quantity - dummyRec?.PO_line_item?.[i]?.inward :
              // dummyRec?.PO_line_item?.[i]?.inward :
              dummyRec?.PO_line_item?.[i]?.quantity
          ).toFixed(2)
      ),
      });
    }

    reset({ items: poLineItems1 });
    // setDummyRec(poWithItem.data);
  };

  const handleRemove = (index) => {
    // Remove the item from the field array
    remove(index);

    // Update your PO_line_item state here, removing the item at the same index
    // Assuming you have a state variable for PO_line_item, you can do something like this
    const updatedPOLineItem = JSON.parse(JSON.stringify({ ...dummyRec }));
    updatedPOLineItem.PO_line_item.splice(index, 1);
    // setDummyRec(updatedPOLineItem);
  };

  function form_data(index) {
    const invoiceNo = getValues("invoice_no");
    const invoiceDate = getValues("invoice_date");
    const received_qty = getValues(`items[${index}].received_qty`);

    // if(rmi_data && rmi_data.length > 0){
    //   const updatedRmiData = rmi_data?.map(item => {
    //     if (currIndex === item.ind) {
    //       return { ...item, NCDetails: [] };
    //     }
    //     return item;
    //   });
    //   setRMI_Data(updatedRmiData);
    // }

    if (
      invoiceNo !== undefined &&
      invoiceNo !== null &&
      invoiceDate !== undefined &&
      invoiceDate !== null &&
      received_qty !== undefined &&
      received_qty !== null
    ) {
      const data = {
        invoiceNo: invoiceNo,
        invoiceDate: invoiceDate,
        received_qty: received_qty,
      };
      localStorage.setItem("form-data", JSON.stringify(data));
      setRMIModal(true);
      clearErrors("invoice_no");
      clearErrors("invoice_date");
    } else {
      // Set error message for both invoice no and invoice date
      setError("invoice_no", {
        type: "manual",
        message: "Please enter invoice no!",
      });
      setError("invoice_date", {
        type: "manual",
        message: "Please enter invoice date!",
      });
    }
  }

  function fetchMaterialCode() {
    get_material_code().then(
      (res) => {
        setMat_Code(res.data.data);
      },
      (err) => {
        setMat_Code();
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <CreateRMI
        rmiModal={rmiModal}
        setRMIModal={setRMIModal}
        poList={poList}
        poWithItem={poWithItem}
        currIndex={currIndex}
        rmi_data={rmi_data}
        setRMI_Data={setRMI_Data}
      />

      <div className="purchase_inward">
        <div className="row">
          <div className="d-flex justify-content-between mb-5">
            <h1 className="bold">
              {constLabel?.lbl_create_purchase_inward
                ? constLabel.lbl_create_purchase_inward
                : "Create Purchase Inward"}
            </h1>
          </div>
          <div className="d-flex justify-content-start">
            <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
              <label
                style={{ fontSize: "14px" }}
                className="form-label roboto-bold col-12 col-md-3"
              >
                {constLabel?.lbl_purchase_order_number
                  ? constLabel.lbl_purchase_order_number
                  : "Purchase Order Number"}
              </label>

              <div className="col-12 col-md-6">
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="poid"
                  multiple={false}
                  options={poList.data}
                  isLoading={poList.loading}
                  placeholder="Select purchase order"
                  selected={selPO}
                  onChange={(e) => {
                    setSelPO(e);
                    if (e !== undefined && e.length > 0) {
                      fetchpoInwardItemsList(e);
                    } else {
                      reset();
                    }
                  }}
                />
              </div>
            </div>
          </div>

          {selPO.length > 0 ? (
            <div className="row">
              <div className="col-12">
                <div className="card border-0">
                  <div className="card-body p-4">
                    <div className="row ">
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 col-md-4">
                            <p className="lbl-style roboto-bold mb-0">
                              {constLabel?.lbl_invoice_to
                                ? constLabel.lbl_invoice_to
                                : "Invoice To"}
                            </p>
                            <p className="pi_data mt-2 mb-0 roboto-bold">
                              {dummyRec?.invoice?.comName}
                            </p>
                            <p className="pi_data mt-2 mb-0">
                              {dummyRec?.invoice?.comaddress}
                            </p>
                          </div>
                          <div className="col-12 col-md-4">
                            <p className="lbl-style roboto-bold mb-0">
                              {constLabel?.lbl_dispatch_to
                                ? constLabel.lbl_dispatch_to
                                : "Dispatch To"}
                            </p>
                            <p className="pi_data mt-2 mb-0 roboto-bold">
                              {dummyRec?.despatch?.comName}
                            </p>
                            <p className="pi_data mt-2 mb-0">
                              {dummyRec?.despatch?.comaddress}
                            </p>
                          </div>
                          <div className="col-12 col-md-4">
                            <p className="lbl-style roboto-bold mb-0">
                              {constLabel?.lbl_supplier_name
                                ? constLabel.lbl_supplier_name
                                : "Supplier Name"}
                            </p>
                            <p className="pi_data mt-2 mb-0 roboto-bold">
                              {dummyRec?.supp?.name}
                            </p>

                            <p className="pi_data mt-2 mb-0">
                              {dummyRec?.supp?.address}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* <hr className="my-3" /> */}

                      <div className="col-12 mt-5">
                        <div className="row">
                          {/* <div className="col-12 col-md-4">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_client_po_no
                                ? constLabel.lbl_client_po_no
                                : "Client PO No"}
                            </label>

                            <p className="mb-0 pi_data mt-2">
                              {dummyRec?.client_poid}
                            </p>
                          </div> */}
                          <div className=" col-12 col-md-4">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_po_date
                                ? constLabel.lbl_po_date
                                : "PO Date"}
                            </label>
                            <p className="mb-0 pi_data mt-2">
                              {convertDate(dummyRec?.po_date, "-")}
                            </p>
                          </div>
                          <div className="col-12 col-md-4">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_voucher_no
                                ? constLabel.lbl_voucher_no
                                : "Voucher No"}
                            </label>
                            <p className="mb-0 pi_data mt-2">
                              {dummyRec?.voucher_no}
                            </p>
                          </div>
                          <div className="col-12 col-md-4">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_credit_period
                                ? constLabel.lbl_credit_period
                                : "Credit Period"}
                            </label>
                            <p className="mb-0 pi_data mt-2">
                              {dummyRec?.credit_period}
                            </p>
                          </div>
                          {/* <div className="col-12 col-md-4 mt-3">
                            <label className="lbl-style roboto-bold">Date</label>
                            <p className="mb-0 pi_data mt-2">{}</p>
                          </div> */}
                          <div className="col-12 col-md-4 mt-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_quote_ref
                                ? constLabel.lbl_quote_ref
                                : "Quote Ref"}
                            </label>
                            <p className="mb-0 pi_data mt-2">
                              {dummyRec?.quote_ref}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-4">
                <div className="card border-0">
                  <div className="card-body p-4">
                    <div className="row ">
                      {/* <div className="col-12 col-sm-4">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold">
                            {"Store"}
                            <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="store"
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                className={"mt-2"}
                                id="basic-typeahead-single"
                                labelKey="name"
                                multiple={false}
                                options={
                                  storeList.data !== undefined
                                    ? storeList.data
                                    : []
                                }
                                isLoading={storeList.state}
                                placeholder="Select store "
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={errors.store ? true : false}
                              />
                            )}
                          />
                          <span className="text-danger err-msg">
                            {errors.store?.message}
                          </span>
                        </div>
                      </div> */}

                      <div className="col-12 col-sm-4">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_location_name
                              ? constLabel.lbl_location_name
                              : "Location Name"}
                            <span className="text-danger"> *</span>
                          </label>
                          <Controller
                            name="location"
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                className={"mt-2"}
                                id="basic-typeahead-single"
                                labelKey="locationname"
                                multiple={false}
                                options={
                                  loc_name.data !== undefined
                                    ? loc_name.data
                                    : []
                                }
                                isLoading={loc_name.loading}
                                placeholder="Select location "
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={errors.location ? true : false}
                              />
                            )}
                          />
                          <span className="text-danger err-msg">
                            {errors.location?.message}
                          </span>
                        </div>
                      </div>

                      <div className="col-12 col-sm-4">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_tally_voucher_no
                              ? constLabel.lbl_tally_voucher_no
                              : "Tally Voucher No"}
                            {/* <span className="text-danger"> *</span> */}
                          </label>
                          <input
                            className={
                              errors.tally_voucher_no
                                ? "form-control mt-2 is-invalid"
                                : "form-control mt-2"
                            }
                            type="text"
                            id="tally_voucher_no"
                            name="tally_voucher_no"
                            defaultValue={"0"}
                            placeholder="Enter tally voucher no / requisition no"
                            {...register("tally_voucher_no")}
                          />
                          {/* <span className="text-danger err-msg">
                            {errors.tally_voucher_no?.message}
                          </span> */}
                        </div>
                      </div>

                      <div className="col-12 col-sm-4">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_tally_voucher_date
                              ? constLabel.lbl_tally_voucher_date
                              : "Tally Voucher Date"}
                            {/* <span className="text-danger"> *</span> */}
                          </label>
                          <Controller
                            name="tally_voucher_date"
                            control={control}
                            render={({ field }) => (
                              <DatePicker
                              className={
                                errors.tally_voucher_date
                                  ? "form-control mt-2 is-invalid"
                                  : "form-control mt-2"
                              }
                                {...field}
                                showYearDropdown
                                scrollableMonthYearDropdown
                                dateFormat="dd/MM/yyyy"
                                selected={field.value || null}
                                defaultValue={new Date()}
                                // minDate={new Date(dummyRec?.po_date)}
                                placeholderText="Select tally voucher date"
                                autoComplete="off"
                              />
                            )}
                          />
                          {/* {errors.tally_voucher_date && (
                            <span className="text-danger err-msg">
                              {errors.tally_voucher_date.message}
                            </span>
                          )} */}
                        </div>
                      </div>

                      <div className="col-12 col-sm-4">
                        <div className="form-group mt-3">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_invoice_no
                              ? constLabel.lbl_invoice_no
                              : "Invoice No"}
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className={
                              errors.invoice_no
                                ? "form-control mt-2 is-invalid"
                                : "form-control mt-2"
                            }
                            type="text"
                            id="invoice_no"
                            name="invoice_no"
                            placeholder="Enter invoice no"
                            {...register("invoice_no")}
                          />
                          <span className="text-danger err-msg">
                            {errors.invoice_no?.message}
                          </span>
                        </div>
                      </div>

                      <div className="col-12 col-sm-4">
                        <div className="form-group mt-3">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_invoice_date
                              ? constLabel.lbl_invoice_date
                              : "Invoice Date"}
                            <span className="text-danger">*</span>
                          </label>
                          <Controller
                            name="invoice_date"
                            control={control}
                            render={({ field }) => (
                              <DatePicker
                                className={
                                  errors.invoice_date
                                    ? "form-control mt-2 is-invalid"
                                    : "form-control mt-2"
                                }
                                {...field}
                                showYearDropdown
                                scrollableMonthYearDropdown
                                dateFormat="dd/MM/yyyy"
                                selected={field.value || null}
                                // minDate={new Date(dummyRec?.po_date)}
                                placeholderText="Select invoice date"
                                autoComplete="off"
                              />
                            )}
                          />
                          {errors.invoice_date && (
                            <span className="text-danger err-msg">
                              {errors.invoice_date.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-12 col-sm-4">
                        <div className="form-group mt-3">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_upload_image
                              ? constLabel.lbl_upload_image
                              : "Upload Image"}
                          </label>
                          <input
                            className={
                              errors.pi_image
                                ? "form-control is-invalid mt-2"
                                : "form-control mt-2"
                            }
                            type="file"
                            name="pi_image"
                            {...register("pi_image")}
                            accept="image/*"
                          />
                          {errors.pi_image && (
                            <span className="text-danger err-msg">
                              {errors.pi_image.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12 mt-4">
                <div
                  className="row "
                  style={{ paddingTop: "10px", paddingBottom: "25px" }}
                >
                  <div className="col-12 mt-3 ">
                    <div className="card">
                      <div className="card-body">
                        <div className="my-3 d-flex justify-content-between">
                          <i>
                            {" "}
                            <span className="text-danger roboto-bold">
                              Note:{" "}
                            </span>
                            <FontAwesomeIcon
                              style={{
                                color: "#c7c7c7",
                              }}
                              icon={faCircle}
                              size="2xs"
                              className="mx-2"
                            />
                            {
                              "RMIInward link will be visible only for BAR, FORGING, CASTING"
                            }
                          </i>
                          <button
                            className="btn btn-sm btn-yellow"
                            onClick={() => resetLineItems()}
                          >
                            {constLabel?.lbl_reset
                              ? constLabel.lbl_reset
                              : "Reset"}
                          </button>
                        </div>
                        <div
                          className=""
                          // style={{ minHeight: "60vh" }}
                        >
                          <table className="table table-responsive table-borderless">
                            <thead>
                              <tr className="">
                                <th width={"20%"}>
                                  {constLabel?.lbl_product_name
                                    ? constLabel.lbl_product_name
                                    : "Product Name"}
                                </th>
                                <th>
                                  {constLabel?.lbl_batch_code
                                    ? constLabel.lbl_batch_code
                                    : "Batch Code"}
                                </th>
                                <th>
                                  {constLabel?.lbl_unit
                                    ? constLabel.lbl_unit
                                    : "Unit"}
                                </th>
                                <th>
                                  {constLabel?.lbl_order_qty
                                    ? constLabel.lbl_order_qty
                                    : "Order Qty"}
                                </th>
                                <th>
                                  {constLabel?.lbl_received_qty
                                    ? constLabel.lbl_received_qty
                                    : "Received Qty"}
                                </th>
                                <th>
                                  {constLabel?.lbl_invoice_qty
                                    ? constLabel.lbl_invoice_qty
                                    : "Invoice Qty"}
                                </th>
                                <th>
                                  {constLabel?.lbl_remain_qty
                                    ? constLabel.lbl_remain_qty
                                    : "Remain Qty"}
                                </th>
                                <th>
                                  {constLabel?.lbl_amount
                                    ? constLabel.lbl_amount
                                    : "Amount"}
                                </th>
                                <th>
                                  {constLabel?.lbl_remarks
                                    ? constLabel.lbl_remarks
                                    : "Remarks"}
                                  {/* <span className="text-danger"> *</span> */}
                                </th>
                                <th>{"Action"}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {fields.map(({ id }, index) => (
                                <tr key={id} className="">
                                  <td style={{ verticalAlign: "top" }}>
                                    <Controller
                                      name={`items[${index}].product`}
                                      control={control}
                                      render={({
                                        field: { onChange, onBlur, value, ref },
                                      }) => (
                                        <Typeahead
                                          id="basic-typeahead-single"
                                          labelKey="productname"
                                          size="sm"
                                          multiple={false}
                                          // options={selPO[0]?.PO_line_item}
                                          options={[]}
                                          // isLoading={prodType.loading}
                                          placeholder="Select product"
                                          disabled
                                          onChange={onChange}
                                          // onChange={(selected) => {
                                          //   handleProdType(selected, index)
                                          // }}
                                          onBlur={onBlur}
                                          selected={value}
                                          isInvalid={
                                            errors.items?.[index]?.product
                                              ? true
                                              : false
                                          }
                                        />
                                      )}
                                    />

                                    {(dummyRec && rmi_config.isBarConfigure) &&
                                    dummyRec?.PO_line_item?.[index]
                                      ?.producttype == "Raw material" &&
                                    dummyRec?.PO_line_item?.[index]
                                      ?.material_type &&
                                    ["BAR", "CASTING", "FORGING"].includes(
                                      dummyRec?.PO_line_item?.[index]
                                        ?.material_type
                                    ) ? (
                                      <a
                                        className="text-primary cursor_pointer mt-2"
                                        onClick={() => {
                                          setCurrIndex(index);
                                          form_data(index);
                                        }}
                                      >
                                        {" "}
                                        {"RMI Inward"}
                                      </a>
                                    ) : null}
                                    {errors.items?.[index]?.product && (
                                      <span className="text-danger err-msg">
                                        {errors.items?.[index]?.product.message}
                                      </span>
                                    )}

                                    {/* {index + 1 == fields.length ? (
                                      <div>
                                        <button
                                          style={{ fontSize: "16px" }}
                                          className="btn btn-sm button-primary px-2 mt-3"
                                          onClick={() => {
                                            if (
                                              fields.length >=
                                              selPO[0]?.PO_line_item.length
                                            ) {
                                              toast.error(
                                                "Can not add more than purchase order items",
                                                {
                                                  autoClose: 3000,
                                                }
                                              );
                                              return;
                                            }

                                            append({
                                              product: [],
                                              po_order_qty: 0,
                                              received_qty: 0,
                                              invoice_qty: 0,
                                              remain_qty: 0,
                                              amount: 0,
                                              remark: "",
                                            });
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            style={{
                                              color: "#ffffff",
                                              marginRight: "5px",
                                            }}
                                            icon={faAdd}
                                            size="sm"
                                          />{" "}
                                          Add
                                        </button>
                                      </div>
                                    ) : null} */}
                                  </td>

                                  <td style={{ verticalAlign: "top", width: "15%" }}>
                                    <input
                                      className={
                                        errors.items?.[index]?.material_code
                                          ? "form-control form-control-sm is-invalid"
                                          : "form-control form-control-sm"
                                      }
                                      type="text"
                                      name={`items[${index}].material_code`}
                                      {...register(
                                        `items[${index}].material_code`
                                      )}
                                      disabled={
                                        dummyRec &&
                                        dummyRec?.PO_line_item?.[index]
                                          ?.producttype == "Raw material" &&
                                        dummyRec?.PO_line_item?.[index]
                                          ?.material_type &&
                                        ["BAR", "CASTING", "FORGING"].includes(
                                          dummyRec?.PO_line_item?.[index]
                                            ?.material_type
                                        )
                                          ? true
                                          : false
                                      }
                                    />
                                    {errors.items?.[index]?.material_code && (
                                      <span className="text-danger err-msg">
                                        {
                                          errors.items?.[index]?.material_code
                                            .message
                                        }
                                      </span>
                                    )}
                                  </td>

                                  <td style={{ verticalAlign: "top" }}>
                                    <input
                                      className={"form-control form-control-sm"}
                                      type="text"
                                      disabled
                                      name={`items[${index}].unit`}
                                      {...register(`items[${index}].unit`)}
                                    />
                                  </td>

                                  <td style={{ verticalAlign: "top" }}>
                                    <input
                                      className={
                                        errors.items?.[index]?.po_order_qty
                                          ? "form-control form-control-sm is-invalid"
                                          : "form-control form-control-sm"
                                      }
                                      type="text"
                                      // defaultValue={0}
                                      disabled
                                      name={`items[${index}].po_order_qty`}
                                      {...register(
                                        `items[${index}].po_order_qty`
                                      )}
                                    />

                                    {errors.items?.[index]?.po_order_qty && (
                                      <span className="text-danger err-msg">
                                        {
                                          errors.items?.[index]?.po_order_qty
                                            .message
                                        }
                                      </span>
                                    )}
                                  </td>

                                  <td style={{ verticalAlign: "top" }}>
                                    <input
                                      className={
                                        errors.items?.[index]?.received_qty
                                          ? "form-control form-control-sm is-invalid"
                                          : "form-control form-control-sm"
                                      }
                                      type="text"
                                      // defaultValue={0}
                                      name={`items[${index}].received_qty`}
                                      disabled={
                                        dummyRec?.PO_line_item?.[index]
                                          ?.inward ===
                                        dummyRec?.PO_line_item?.[index]
                                          ?.quantity
                                          ? true
                                          : false
                                      }
                                      {...register(
                                        `items[${index}].received_qty`
                                      )}
                                      onChange={(e) =>
                                        calInwardQty(
                                          e.target.value,
                                          "recvQty",
                                          index
                                        )
                                      }
                                    />

                                    {errors.items?.[index]?.received_qty && (
                                      <>
                                        <span className="text-danger err-msg">
                                          {
                                            errors.items?.[index]?.received_qty
                                              .message
                                          }
                                        </span>
                                        <br />
                                      </>
                                    )}
                                    <span className="text-primary err-msg">
                                      {"Prev Received: "}
                                      {dummyRec?.PO_line_item?.[
                                        index
                                      ]?.inward?.toFixed(2)}
                                    </span>
                                  </td>

                                  <td style={{ verticalAlign: "top" }}>
                                    <input
                                      className={
                                        errors.items?.[index]?.invoice_qty
                                          ? "form-control form-control-sm is-invalid"
                                          : "form-control form-control-sm"
                                      }
                                      type="text"
                                      disabled
                                      name={`items[${index}].invoice_qty`}
                                      {...register(
                                        `items[${index}].invoice_qty`,
                                        {
                                          onChange: (e) =>
                                            calInwardQty(
                                              e.target.value,
                                              "invQty",
                                              index
                                            ),
                                        }
                                      )}
                                    />

                                    {errors.items?.[index]?.invoice_qty && (
                                      <span className="text-danger err-msg">
                                        {
                                          errors.items?.[index]?.invoice_qty
                                            .message
                                        }
                                      </span>
                                    )}
                                  </td>

                                  <td style={{ verticalAlign: "top" }}>
                                    <input
                                      className={
                                        errors.items?.[index]?.remain_qty
                                          ? "form-control form-control-sm is-invalid"
                                          : "form-control form-control-sm"
                                      }
                                      type="text"
                                      name={`items[${index}].remain_qty`}
                                      {...register(
                                        `items[${index}].remain_qty`
                                      )}
                                      disabled
                                    />

                                    {errors.items?.[index]?.remain_qty && (
                                      <span className="text-danger err-msg">
                                        {
                                          errors.items?.[index]?.remain_qty
                                            .message
                                        }
                                      </span>
                                    )}
                                  </td>

                                  <td style={{ verticalAlign: "top" }}>
                                    <input
                                      className={
                                        errors.items?.[index]?.amount
                                          ? "form-control form-control-sm is-invalid"
                                          : "form-control form-control-sm"
                                      }
                                      type="text"
                                      name={`items[${index}].amount`}
                                      {...register(`items[${index}].amount`)}
                                      disabled
                                    />

                                    {errors.items?.[index]?.amount && (
                                      <span className="text-danger err-msg">
                                        {errors.items?.[index]?.amount.message}
                                      </span>
                                    )}
                                  </td>

                                  <td style={{ verticalAlign: "top" }}>
                                    <input
                                      className={
                                        errors.items?.[index]?.remark
                                          ? "form-control form-control-sm is-invalid"
                                          : "form-control form-control-sm"
                                      }
                                      type="text"
                                      name={`items[${index}].remark`}
                                      placeholder="Enter remark"
                                      {...register(`items[${index}].remark`)}
                                    />

                                    {/* {errors.items?.[index]?.remark && (
                                      <span className="text-danger err-msg">
                                        {errors.items?.[index]?.remark.message}
                                      </span>
                                    )} */}
                                  </td>

                                  <td
                                    style={{
                                      // textAlign: "center",
                                      verticalAlign: "top",
                                      width: "5%",
                                    }}
                                  >
                                    <OverlayTrigger
                                      placement="bottom"
                                      overlay={
                                        <Tooltip id="tooltip">
                                          {constLabel?.lbl_delete_item
                                            ? constLabel.lbl_delete_item
                                            : "Delete Item"}
                                        </Tooltip>
                                      }
                                    >
                                      <div
                                        className="deleteIcon cursor_pointer"
                                        onClick={() => {
                                          handleRemove(index);
                                        }}
                                      >
                                        <Trash2 color="#FFFFFF" size={18} />
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end mt-2">
                <button
                  className="btn btn-grey px-4 ms-0 ms-md-2"
                  onClick={handleSubmit(onSubmit)}
                  disabled={lockBtn}
                >
                  {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}{" "}
                  {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
                </button>
              </div>
            </div>
          ) : (
            <div
              className="alert alert-danger mb-0 text-center my-4"
              role="alert"
            >
              {"Please select a purchase order !"}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default CreatePurchaseInv;
