import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faBan,
  faFilter,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Modal } from "react-bootstrap";
import { Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { Tooltip } from "react-tooltip";
import {
  getLocation,
  saveLocation,
  deleteLocation,
  getLoctionType,
  getCustomer,
  getInternalCompany,
  getShift,
  createShift,
  deleteShift
} from "../../../api";
import {
  calMaxPage_new,
  getLabels,
  setAccessPrivilage,
} from "../../../common/Functions/CommonFunctions";
import { Location_Col_Name, Shift_Col_Name } from "../../../common/Labels/labelConstant";
import { useSelector } from "react-redux";
import List from "../../../assets/images/icons/project_menu/checklist.png";
import EditShiftMaster from "./EditShiftMaster";
import DatePicker from "react-datepicker";

const schema = yup
  .object()
  .shape({
    shiftName: yup
      .string()
      .required("Shift name is required!"),
    startTime: yup
      .date()
      .typeError("Please select start time!")
      .required("Please select start time!"),
    endTime: yup
      .date()
      .typeError("Please select end time!")
      .required("Please select end time!"),
  })
  .required();

function ShiftMaster() {
  const [showLabel, setShowLabel] = useState(Shift_Col_Name);
  const [visible, setVisible] = useState(false);

  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [sea_val, setSea_val] = useState(false);

  const [filterConfig, setFilterConfig] = useState({ searchVal: "" });
  const [constLabel, setConstLabel] = useState({});

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const [privilage, setPrivilage] = useState(
    setAccessPrivilage(loginData, "Shift")
  );

  const { register, handleSubmit, formState, control, reset, watch, setValue, clearErrors, setError } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    setConstLabel(getLabels("Master", "Shift"));
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchShift(page, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  useEffect(() => {
    // Reset both fields and show an error if endTime is smaller than startTime
    if (startTime && endTime && endTime < startTime) {
      setStartTime(null); // Reset startTime
      setEndTime(null); // Reset endTime
      setValue("startTime", null); // Reset startTime in form
      setValue("endTime", null); // Reset endTime in form
      setError("endTime", {
        type: "manual",
        message: "End time cannot be before start time",
      });
    } else if (!startTime && endTime) {
      // Show error if endTime is selected before startTime
      setError("endTime", {
        type: "manual",
        message: "Please select start time first",
      });
    } else {
      clearErrors("endTime"); // Clear errors when validation passes
    }
  }, [startTime, endTime, setValue, setError, clearErrors]);

  function clearFilter() {
    setFilterConfig({ ...filterConfig, searchVal: "" });
    setSrNo(0);
    setPage(1);
  }

  function fetchShift(pg, epp) {
    setDataList({ ...dataList, loading: true, data: [] });

    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
    };

    if (filterConfig.searchVal !== "") {
      payload.searchVal = filterConfig.searchVal;

      if (
        filterConfig.searchVal?.length > 0 &&
        page !== 1 &&
        sea_val == false
      ) {
        payload.pageNo = 1;
        payload.entriesPerPage = entriesPerPage;

        setPage(1);
        setSrNo(0);
      }
    } else {
      delete payload.searchVal;
    }

    getShift(payload).then(
      (res) => {
        // console.log("res shift", res);
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });
        if (
          filterConfig.search !== "" &&
          filterConfig.searchVal?.length > 0
        ) {
          setSea_val(true);
        } else {
          setSea_val(false);
        }
        setMaxPage(calMaxPage_new(res.data.total, epp));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, loading: false, data: [] });
      }
    );
  }

  const onSubmit = (data) => {
    // console.log("data", data); 

    if (data !== "") {
      const reqPayload = {
        name: data.shiftName,
        start_time: formatTime(new Date(data.startTime)),
        end_time: formatTime(new Date(data.endTime)),
      };
    // console.log("reqPayload", reqPayload);
      createShift(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Shift created Successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            fetchShift(page, entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  // Deleting shift
  const delShift = (val) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          uuid: val.uuid,
        };
        deleteShift(payload).then(
          (res) => {
            toast.success("Shift deleted successfully !", {
              autoClose: 3000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchShift(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchShift(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", { autoClose: 3000 });
          }
        );
      }
    });
  };

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function formatTime(date) {
    let hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12; // Convert 0 or 12 to 12, 13-23 to 1-11
    hours = String(hours).padStart(2, '0'); // Ensure hours are 2 digits
  
    return `${hours}:${minutes}:${seconds} ${period}`;
  }

  function convertTo12Hour(timeStr) {
    let [hours, minutes, seconds] = timeStr.split(':');
    let period = hours >= 12 ? 'PM' : 'AM';
  
    hours = hours % 12 || 12; // Convert 0 or 12 to 12, 13-23 to 1-11
  
    return `${hours}:${minutes}:${seconds} ${period}`;
  }

  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}
      <div className="min-vh-100" id="root_div_main">
        <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>
              {constLabel?.lbl_add_shift
                ? constLabel.lbl_add_shift
                : "Add Shift"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form action="">

              <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 roboto-bold"
                >
                  {constLabel?.lbl_shift_name
                    ? constLabel.lbl_shift_name
                    : "Shift Name"}
                  <span>
                    <i style={{ color: "red" }}> *</i>
                  </span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text"
                    className={
                      errors.shiftName
                        ? "form-control  is-invalid"
                        : "form-control "
                    }
                    id="shiftName"
                    name="shiftName"
                    placeholder="Enter shift name"
                    {...register("shiftName")}
                  />
                  <span className="text-danger err-msg">
                    {errors.shiftName?.message}
                  </span>
                </div>
              </div>

              <div className="form-group mt-md-0 mt-3">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_start_time
                      ? constLabel?.lbl_start_time
                      : "Start Time"}
                    <span className="text-danger"> *</span>
                  </label>

                  {/* DatePicker for startTime */}
                  <Controller
                    control={control}
                    name="startTime"
                    render={({ field }) => (
                      <DatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={5}
                        timeCaption="Time"
                        className={
                          errors.startTime ? "form-control is-invalid" : "form-control"
                        }
                        placeholderText="--:--:--"
                        selected={startTime}
                        onChange={(time) => {
                          setStartTime(time);
                          field.onChange(time);
                        }}
                        dateFormat="hh:mm:ss aa"
                        autoComplete="false"
                      />
                    )}
                  />

                  {errors.startTime && (
                    <span className="text-danger err-msg">
                      {errors.startTime.message}
                    </span>
                  )}
              </div>

              <div className="form-group mt-md-0 mt-3">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_end_time
                      ? constLabel?.lbl_end_time
                      : "End Time"}
                    <span className="text-danger"> *</span>
                  </label>

                  {/* DatePicker for endTime */}
                  <Controller
                    control={control}
                    name="endTime"
                    render={({ field }) => (
                      <DatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={5}
                        timeCaption="Time"
                        className={errors.endTime ? "form-control is-invalid" : "form-control"}
                        placeholderText="--:--:--"
                        selected={endTime}
                        onChange={(time) => {
                          setEndTime(time);
                          field.onChange(time);
                        }}
                        dateFormat="hh:mm:ss aa"
                        autoComplete="false"
                      />
                    )}
                  />

                  {errors.endTime && (
                    <span className="text-danger err-msg">
                      {errors.endTime.message}
                    </span>
                  )}
              </div>

            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleSubmit(onSubmit)}
              className="f-16 btn btn-yellow "
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
            </button>
          </Modal.Footer>
        </Modal>
      
        <div className="content-wrapper">
          <div className="card border-0">
            <div className="card-body">
            <div className="row align-items-center">
                <div className="d-md-flex justify-content-start align-items-center col-12 col-md-6">
                  {/* <FontAwesomeIcon
                      onClick={() => toggleNav()}
                      className="cursor_pointer mb-2"
                      style={{
                        color: "Black",
                        marginRight: "10px",
                      }}
                      icon={faBars}
                      size="lg"
                    /> */}
                  <h1 className="bold">
                    {constLabel?.lbl_shift_master
                      ? constLabel?.lbl_shift_master
                      : "Shift Master"}
                  </h1>
                </div>
                <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                  <div className="col-12 col-md-6 me-md-2 me-md-0">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search by name"
                      value={filterConfig.searchVal}
                      onChange={(val) => {
                        const inputValue = val.target.value;
                        if (!inputValue.trim()) {
                          // Input contains only whitespace, don't trigger the search.
                          if (filterConfig.searchVal.length == 1) {
                            setFilterConfig({ ...filterConfig, searchVal: "" });
                          }
                          
                          if(inputValue.length == 0){
                            setFilterConfig({ ...filterConfig, searchVal: "" });
                          }
                        } else {
                          setFilterConfig({
                            ...filterConfig,
                            searchVal: inputValue,
                          });
                        }
                      }}
                    />
                  </div>
                  {privilage.write && (
                    <button
                      className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                      onClick={() => {
                        setVisible(true);
                        setStartTime(null);
                        setEndTime(null);
                        reset();
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faPlus}
                        size="sm"
                      />{" "}
                      {constLabel?.lbl_add_shift
                    ? constLabel.lbl_add_shift
                    : "Add Shift"}
                    </button>
                  )}

                  <Tooltip id={"show-tooltip"} place="top" />
                  <div
                    className="dropdown"
                    data-tooltip-id={"show-tooltip"}
                    data-tooltip-content={"Show / Hide Columns"}
                  >
                    <div
                      className="d-md-flex justify-content-start align-items-center"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {/* <FontAwesomeIcon icon={faCog} size="lg" /> */}
                      {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                      <img src={List} height='32' width='32' className="cursor_pointer" />
                    </div>

                    <ul className="dropdown-menu px-2">
                      {showLabel !== undefined && showLabel.length > 0
                        ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                {dataList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : dataList && dataList.data?.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                      >
                        <thead className="table-grey roboto">
                          <tr className="f-14 ">
                            <th scope="col" width="15%">
                              {"Sr.No"}
                            </th>
                            {showLabel.map((v, i) =>
                              v.label === "Shift Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_shift_name
                                    ? constLabel.lbl_shift_name
                                    : "Shift Name"}
                                </th>
                              ) : v.label === "Start Time" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_start_time
                                    ? constLabel.lbl_start_time
                                    : "Start Time"}
                                </th>
                              ) : v.label === "End Time" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_end_time
                                    ? constLabel.lbl_end_time
                                    : "End Time"}
                                </th>
                              ) : null
                            )}
                            <th scope="col" width="15%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="roboto">
                          {dataList.data?.map((val, i) => (
                            <tr key={i} className="">
                              <td>{i + parseInt(srNo) + 1}</td>
                              {showLabel.map((item, idx) =>
                                item.label === "Shift Name" && item.flag ? (
                                  <td key={idx}>
                                    {val.name != "" &&
                                    val.name !== null
                                      ? val.name
                                      : "NA"}
                                  </td>
                                ) : item.label === "Start Time" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.start_time != "" &&
                                    val.start_time !== null
                                      ? convertTo12Hour(val.start_time)
                                      : "NA"}
                                  </td>
                                ) : item.label === "End Time" && item.flag ? (
                                  <td key={idx}>
                                    {val.end_time != "" &&
                                    val.end_time !== null
                                      ? convertTo12Hour(val.end_time)
                                      : "NA"}
                                  </td>
                                ) : null
                              )}
                              <td>
                                {privilage.write && (
                                  <EditShiftMaster
                                    data={val}
                                    constLabel={constLabel}
                                    fetchShift={fetchShift}
                                    page={page}
                                    entriesPerPage={entriesPerPage}
                                  />
                                )}

                                {privilage.delete && (
                                  <>
                                    <Tooltip id={"delete-tooltip" + i} place="top" />
                                      <Trash2
                                         data-tooltip-id={"delete-tooltip" + i}
                                         data-tooltip-content={
                                          constLabel?.lbl_delete_shift
                                            ? constLabel.lbl_delete_shift
                                            : "Delete Shift"
                                          }
                                        className="menu_icon_red cursor_pointer"
                                        size={20} 
                                        onClick={() => delShift(val)}
                                      />
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="mb-0">
                        Show
                        <select
                          className="mx-1"
                          defaultValue={"10"}
                          onChange={(e) => {
                            setEntriesPerPage(e.target.value);
                            fetchShift(1, e.target.value);
                          }}
                        >
                          <option value={"10"}>10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        Entries
                      </p>
                      <p className="mb-0">{`Showing ${
                        parseInt(srNo) + 1
                      } to ${Math.min(
                        parseInt(entriesPerPage) + parseInt(srNo),
                        dataList.totalRecords
                      )} of ${dataList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchShift(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1
                                ? 0
                                : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                            maxPage === 0 ||
                            entriesPerPage > dataList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            fetchShift(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  </>
                ) : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"Shift not Found !"}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ShiftMaster;
