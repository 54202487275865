import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faSearch,
  faFilter,
  faBan,
  faPlus,
  faCircle,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { Pagination, Spinner, Modal } from "react-bootstrap";
import { Edit2, Eye, Trash2, Image, FileText, Edit } from "react-feather";
import {
  calMaxPage,
  convertDate,
  convertDateYYMMDD,
  getFinancialYearStartDate,
  getLabels,
  setAccessPrivilage,
} from "../../../../common/Functions/CommonFunctions";
import SweetAlert from "sweetalert2";
import {
  getMachineList,
  getLotNumList,
  getProdList,
  getOperationList,
  get_dispatch,
  delete_dispatch,
  getCustomer,
  get_WorkOrder,
} from "../../../../api";
import { Dispatch_Col_Name } from "../../../../common/Labels/labelConstant";
// import Sidebar from "../../Sidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import List from "../../../../assets/images/icons/project_menu/checklist.png"
import Slide from "../../../../assets/images/icons/project_menu/collapse.png"

function Dispatch() {
  let navigate = useNavigate();
  const [startDate, setStartDate] = useState(getFinancialYearStartDate(new Date()));
  const [endDate, setEndDate] = useState(new Date());

  const [lockBtn, setLockBtn] = useState(false);

  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const [showLabel, setShowLabel] = useState(Dispatch_Col_Name);
  const [constLabel, setConstLabel] = useState({});

  const [show, setShow] = useState(false);
  const [selItem, setSelItem] = useState([]);

  const [dispatchList, setDispatchList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });

  const [dataList, setDataList] = useState({ data: [], loading: true });
  const [partyList, setPartyList] = useState({ data: [], loading: false });
  const [workOrders, setWorkOrders] = useState({ data: [], loading: false });

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage, setPrivilage] = useState(
    setAccessPrivilage(loginData, "Dispatch")
  );

  const [filterConfig, setFilterConfig] = useState({
    party: [],
    product: [],
    work_order: [],
  });

  useEffect(() => {
    // console.table("filterConfig", filterConfig);
    setConstLabel(getLabels("Dispatch", "Dispatch"));
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchDispatchData(page, entriesPerPage);
      // fetchLotList(startDate, endDate);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig, startDate, endDate]);

  function getPrvMonthDateFromToday() {
    const today = new Date();
    const endDate = new Date(today);
    endDate.setMonth(today.getMonth() - 1);
    return endDate;
  }

  function clearFilter() {
    let obj = {
      ...filterConfig,
      party: [],
      product: [],
      work_order: [],
    };
    setFilterConfig({ ...obj });
    setStartDate(getFinancialYearStartDate(new Date()));
    setEndDate(new Date());
  }

  function fetchProdList() {
    setDataList({ ...dataList, loading: true, data: [] });

    getProdList().then(
      (res) => {
        // console.log("res", res);
        const filteredData = res.data.data.filter(
          (item) => item.id !== "" && item.productName !== null
        );
        setDataList({ ...dataList, loading: false, data: filteredData });
      },
      (err) => {
        // console.log("err", err);
        setDataList({ ...dataList, data: [], loading: false });
      }
    );
  }

  function fetchParties() {
    setPartyList({ ...partyList, data: [], loading: true });

    getCustomer({ partyType: "Customer" }).then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.organization !== "" && item.organization !== null
        );
        setPartyList({ ...partyList, data: result, loading: false });
      },
      (err) => {
        setPartyList({ ...partyList, data: [], loading: false });
      }
    );
  }

  function fetchWorkOrder() {
    setWorkOrders({ ...workOrders, data: [], loading: true });
    get_WorkOrder().then(
      (res) => {
        setWorkOrders({ ...workOrders, data: res.data.data, loading: false });
      },
      (err) => {
        setWorkOrders({ ...workOrders, data: [], loading: false });
      }
    );
  }

  function fetchDispatchData(pg, epp) {
    setDispatchList({ ...dispatchList, loading: true, data: [] });

    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
      startDate: convertDateYYMMDD(new Date(startDate), "-"),
      endDate: convertDateYYMMDD(new Date(endDate), "-"),
    };

    if (filterConfig.party.length > 0) {
      payload.party_id = filterConfig.party[0].uuid; // set party uuid to the search field
    } else {
      delete payload.party_id;
    }

    if (filterConfig.product.length > 0) {
      payload.product_id = filterConfig.product[0].productId; // set product readable id to the search field
    } else {
      delete payload.product_id;
    }

    if (filterConfig.work_order.length > 0) {
      payload.work_order_id = filterConfig.work_order[0].uuid; // set work order uuid to the search field
    } else {
      delete payload.work_order_id;
    }

    get_dispatch(payload).then(
      (res) => {
        // console.log("res", res);
        setDispatchList({
          ...dispatchList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });
        // console.log("ma", calMaxPage(parseInt(res.data.total)));
        setMaxPage(calMaxPage(res.data.total));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        // console.log("err", err);
        // toast.error(err.response.data.message, {
        //   autoClose: 2000,
        // });
        setDispatchList({ ...dispatchList, loading: false, data: [] });
      }
    );
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);

    setTimeout(() => {
      fetchProdList();
      fetchParties();
      fetchWorkOrder();
    }, [2000]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  const delDispatch = (val) => {
    // console.log("val", val);
    SweetAlert.fire({
      // title: "Are you sure to delete \n" + val.camshaft_type + " ?",
      title: "Are you sure to delete ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        delete_dispatch({ uuid: val.uuid }).then(
          (res) => {
            // console.log("res", res);
            toast.success("Dispatch data deleted successfully", {
              autoClose: 2000,
            });

            if (dispatchList.data.length == 1 && page > 1) {
              fetchDispatchData(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchDispatchData(page, entriesPerPage);
            }
          },
          (err) => {
            console.log("err", err);
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  function handleClick(ind) {
    // console.log("ind", ind);

    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  const handleFromDateChange = (date) => {
    if (endDate && date > endDate) {
      return;
    }
    setStartDate(date);
  };

  const handleToDateChange = (date) => {
    if (startDate && date < startDate) {
      return;
    }
    setEndDate(date);
  };

  function createChallan(data) {
    console.log("challan Data", data);

    let obj={...data.party_details.locationList[0]}; 
    obj.locationList={gst:data.party_details.gst}; 
    let to_loc_arr=[obj]
    let arr_store = data?.location_info?.storemaster;
    let pck_store = [];
    if (arr_store && arr_store.length > 0) {
      pck_store = arr_store.filter(
        (item) => item.name == "Packaging And Dispatch"
      );
    }

    let reqPayload = [];
    if (data.dispatch_line_items.length > 0) {
      for (let i = 0; i < data.dispatch_line_items.length; i++) {
        reqPayload.push({
          qty: data.dispatch_line_items[i].qty,
          uom: data.dispatch_line_items[i].work_order_details?.unit,
          product_id: data.dispatch_line_items[i]?.prod_info.uuid,
          productId: data.dispatch_line_items[i]?.prod_info.productId,
          partNo: data.dispatch_line_items[i]?.prod_info.partNo,
          product_name: data.dispatch_line_items[i]?.prod_info.productName,
          hsn_model:data.dispatch_line_items[i]?.prod_info.hsn_model,
          salesPrice:data.dispatch_line_items[i]?.prod_info.salesPrice,
          work_order_id: data.dispatch_line_items[i].work_order_details?.uuid,
          work_order_no:
            data.dispatch_line_items[i].work_order_details?.work_order_no,
          work_order_status:
            data.dispatch_line_items[i].work_order_details?.work_order_status,
          work_order_quantity:
            data.dispatch_line_items[i].work_order_details?.quantity,
        });
      }
    } else {
      reqPayload = [];
    }
    const payload = {
      challantype: "Dispatch Challan",
      challandate: data.dispatch_line_items?.[0]?.date,
      to_store_data: [
        {
          uuid: data.party_details.locationList?.[0]?.storemaster?.[0]?.uuid,
          name: data.party_details.locationList?.[0]?.storemaster?.[0]?.name,
        },
      ],
      to_locationdata: to_loc_arr,
      from_locationdata: data?.location_info ? [data.location_info] : [],
      from_store_data: pck_store,
      Productdetails: reqPayload,
      party_id: data.party_id,
      dispatch_id: data.uuid,
    };

    console.log("payload", payload);

    navigate("/pcms/createGoodsChallan", {
      state: payload,
    });
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");
  
    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
      fetchProdList();
      fetchParties();
      fetchWorkOrder();
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="min-vh-100" id="root_div_main">
        <div id="mySidebar" className="customsidebar ">
          <a className="closebtn" onClick={() => toggleNav()}>
            ×
          </a>

          <div className=" content">
            <div className="filter row">
              <label
                className="filterLabel mb-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_date_range
                  ? constLabel.lbl_date_range
                  : "Date Range"}
              </label>
              <div className="fromDate col-12 col-md-6">
                <DatePicker
                  id="fromDate"
                  className="form-control"
                  selected={startDate}
                  showYearDropdown
                  scrollableMonthYearDropdown
                  dateFormat="dd/MM/yyyy"
                  placeholderText="From Date"
                  // onChange={(date) => setStartDate(date)}
                  onChange={(date) => handleFromDateChange(date)}
                  maxDate={new Date()}
                />
              </div>

              <div className="toDate col-12 col-md-6">
                <DatePicker
                  id="toDate"
                  className="form-control"
                  showYearDropdown
                  scrollableMonthYearDropdown
                  selected={endDate}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="To Date"
                  // onChange={(date) => setEndDate(date)}
                  onChange={(date) => handleToDateChange(date)}
                  maxDate={new Date()}
                />
              </div>
            </div>

            <div className="mt-2">
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_party
                  ? constLabel.lbl_party
                  : "Party"}
              </label>

              <Typeahead
                id="basic-typeahead-single"
                labelKey="organization"
                multiple={false}
                options={partyList.data}
                isLoading={partyList.loading}
                selected={filterConfig.party}
                // size="sm"
                onChange={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, party: val });
                  } else {
                    setFilterConfig({ ...filterConfig, party: [] });
                  }
                }}
                placeholder="Search by party"
              />
            </div>

            <div className="mt-2">
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_product
                  ? constLabel.lbl_product
                  : "Product"}
              </label>

              <Typeahead
                id="basic-typeahead-single"
                labelKey="productName"
                multiple={false}
                options={
                  dataList.data !== undefined
                    ? dataList.data
                    : []
                }
                isLoading={dataList.loading}
                selected={filterConfig.product}
                // size="sm"
                onChange={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, product: val });
                  } else {
                    setFilterConfig({ ...filterConfig, product: [] });
                  }
                }}
                placeholder="Search by product"
              />
            </div>

            <div className="mt-2">
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_work_order
                  ? constLabel.lbl_work_order
                  : "Work Order"}
              </label>

              <Typeahead
                id="basic-typeahead-single"
                labelKey="work_order_no"
                multiple={false}
                options={
                  workOrders.data !== undefined
                    ? workOrders.data
                    : []
                }
                isLoading={workOrders.loading}
                selected={filterConfig.work_order}
                // size="sm"
                onChange={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, work_order: val });
                  } else {
                    setFilterConfig({ ...filterConfig, work_order: [] });
                  }
                }}
                placeholder="Search by work order no"
              />
            </div>

            <div className="my-3 d-flex justify-content-end">
              <button
                onClick={() => clearFilter()}
                className="btn btn-sm btn-yellow "
              >
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {constLabel?.lbl_clear_filter
                  ? constLabel.lbl_clear_filter
                  : "Clear Filter"}
              </button>
            </div>
          </div>
        </div>
        <div className="nc">
            <div className="content-wrapper">
              <div className="card border-0">
                <div className="card-body">
                  <div className="row align-items-center">
                  <div className="d-md-flex justify-content-start col-12 col-md-6">
                    <Tooltip id={"open-tooltip"} place="top" />
                    <img
                      src={Slide}
                      height="32"
                      width="32"
                      className="cursor_pointer my-1 me-2"
                      onClick={() => toggleNav()}
                      data-tooltip-id={"open-tooltip"}
                      data-tooltip-content={"Sidebar"}
                    />
                    <h1 className="bold">
                      {constLabel?.lbl_Dispatch ? constLabel.lbl_Dispatch : "Dispatch"}
                    </h1>
                  </div>

                  <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">

                    {privilage.write && (
                      <button
                        className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                        onClick={() => {
                          navigate("/pcms/create-dispatch");
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_create_dispatch
                          ? constLabel.lbl_create_dispatch
                          : "Create Dispatch"}
                      </button>
                    )}

                    <Tooltip id={"show-tooltip"} place="top" />
                    <div
                      className="dropdown"
                      data-tooltip-id={"show-tooltip"}
                      data-tooltip-content={"Show / Hide Columns"}
                    >
                      <div
                        className="d-md-flex justify-content-start align-items-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <FontAwesomeIcon icon={faListCheck} size="lg" /> */}
                        {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                        <img
                          src={List}
                          height="32"
                          width="32"
                          className="cursor_pointer"
                        />
                      </div>

                      <ul className="dropdown-menu px-2">
                        {showLabel !== undefined && showLabel.length > 0
                          ? showLabel.map((val, ind, arr) => (
                              <li key={ind}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    style={{ width: "20px", height: "20px" }}
                                    onChange={() => handleClick(ind)}
                                    value=""
                                    checked={val.flag}
                                  />
                                  <label
                                    className=""
                                    style={{ fontSize: "13px" }}
                                    htmlFor="flexCheckDefault"
                                  >
                                    {val.label}
                                  </label>
                                </div>
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  </div>
                  </div>
                  <div className="mt-3">
                  {dispatchList.loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : dispatchList.data?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead className="table-grey roboto-bold">
                          <tr className="">
                            <th scope="col">{"SrNo"}</th>
                            {/* <th scope="col">{"Tag No"}</th> */}
                            {showLabel.map((v, i) =>
                              v.label === "Dispatch Id" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Dispatch Id"}
                                </th>
                              ) : v.label === "Dispatch Date" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Dispatch Date"}
                                </th>
                              ) : v.label === "Customer Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Customer Name"}
                                </th>
                              ) : v.label === "Transporter" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Transporter"}
                                </th>
                              ) : v.label === "Status" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Status"}
                                </th>
                              ) : null
                            )}

                            <th scope="col" width="15%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="roboto">
                          {dispatchList.data.map((val, i) => (
                            <tr key={i}>
                              <td style={{ verticalAlign: "middle" }}>
                                {parseInt(i) + 1 + parseInt(srNo)}
                              </td>
                              {showLabel.map((item, idx) =>
                                item.label === "Dispatch Id" && item.flag ? (
                                  <td key={idx}>
                                    {val?.dispatchId !== "" &&
                                    val?.dispatchId !== null
                                      ? val?.dispatchId
                                      : "NA"}
                                  </td>
                                ) : item.label === "Dispatch Date" && item.flag ? (
                                  <td key={idx}>
                                    {val?.dispatch_line_items?.[0]?.date !== "" &&
                                    val?.dispatch_line_items?.[0]?.date !== null
                                      ? convertDate(val?.dispatch_line_items?.[0]?.date, '-')
                                      : "NA"}
                                  </td>
                                ) : item.label === "Customer Name" && item.flag ? (
                                  <td key={idx}>
                                    {val?.party_details?.organization !== "" &&
                                    val?.party_details?.organization !== null
                                      ? val?.party_details?.organization
                                      : "NA"}
                                  </td>
                                ) : item.label === "Transporter" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val?.transporter !== "" &&
                                    val?.transporter !== null
                                      ? val?.transporter
                                      : "NA"}
                                  </td>
                                ) : item.label === "Status" && item.flag ? (
                                  <td key={idx}>
                                    {val.status !== "" && val.status !== null
                                      ? val.status
                                      : "NA"}
                                  </td>
                                ) : null
                              )}

                              <td className="">
                                {loginData.loginData?.data?.response
                                  .role_name !== "client_admin" &&
                                loginData.loginData?.data?.response
                                  .role_name !== "admin" ? (
                                  privilage.write == true ? (
                                    <>
                                      <Tooltip
                                        id={"edit-tooltip" + i}
                                        place="top"
                                      />
                                      <Edit
                                        data-tooltip-id={"edit-tooltip" + i}
                                        data-tooltip-content={
                                          constLabel?.lbl_edit_dispatch
                                            ? constLabel.lbl_edit_dispatch
                                            : "Edit Dispatch"
                                        }
                                        className="menu_icon_grey cursor_pointer me-2"
                                        size={20}
                                        onClick={() => {
                                          navigate("/pcms/update-dispatch", {
                                            state: val,
                                          });
                                        }}
                                      />
                                    </>
                                  ) : null
                                ) : (
                                  <>
                                    <Tooltip
                                      id={"edit-tooltip" + i}
                                      place="top"
                                    />
                                    <Edit
                                      data-tooltip-id={"edit-tooltip" + i}
                                      data-tooltip-content={
                                        constLabel?.lbl_edit_dispatch
                                          ? constLabel.lbl_edit_dispatch
                                          : "Edit Dispatch"
                                      }
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20}
                                      onClick={() => {
                                        navigate("/pcms/update-dispatch", {
                                          state: val,
                                        });
                                      }}
                                    />
                                  </>
                                )}

                                {loginData.loginData?.data?.response
                                  .role_name !== "client_admin" &&
                                loginData.loginData?.data?.response
                                  .role_name !== "admin" ? (
                                  privilage.view == true ? (
                                    <>
                                      <Tooltip
                                        id={"view-tooltip" + i}
                                        place="top"
                                      />
                                      <Eye
                                        data-tooltip-id={"view-tooltip" + i}
                                        data-tooltip-content={
                                          constLabel?.lbl_dispatch_items
                                            ? constLabel.lbl_dispatch_items
                                            : "Dispatch Items"
                                        }
                                        className="menu_icon_grey cursor_pointer me-2"
                                        size={20}
                                        onClick={() => {
                                          setSelItem(val.dispatch_line_items);
                                          setShow(true);
                                        }}
                                      />
                                    </>
                                  ) : null
                                ) : (
                                  <>
                                    <Tooltip
                                      id={"view-tooltip" + i}
                                      place="top"
                                    />
                                    <div
                                      data-tooltip-id={"view-tooltip" + i}
                                      data-tooltip-content={
                                        constLabel?.lbl_dispatch_items
                                          ? constLabel.lbl_dispatch_items
                                          : "Dispatch Items"
                                      }
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20}
                                      onClick={() => {
                                        setSelItem(val.dispatch_line_items);
                                        setShow(true);
                                      }}
                                    />
                                  </>
                                )}

                                {loginData.loginData?.data?.response
                                  .role_name !== "client_admin" &&
                                loginData.loginData?.data?.response
                                  .role_name !== "admin" ? (
                                  privilage.delete == true ? (
                                    <>
                                      <Tooltip
                                        id={"delete-tooltip" + i}
                                        place="top"
                                      />
                                      <Trash2
                                        data-tooltip-id={"delete-tooltip" + i}
                                        data-tooltip-content={
                                          constLabel?.lbl_delete_dispatch
                                            ? constLabel.lbl_delete_dispatch
                                            : "Delete Dispatch"
                                        }
                                        className="menu_icon_red cursor_pointer me-2"
                                        size={20}
                                        onClick={() => delDispatch(val)}
                                      />
                                    </>
                                  ) : null
                                ) : (
                                  <>
                                    <Tooltip
                                      id={"delete-tooltip" + i}
                                      place="top"
                                    />
                                    <div
                                      data-tooltip-id={"delete-tooltip" + i}
                                      data-tooltip-content={
                                        constLabel?.lbl_delete_dispatch
                                          ? constLabel.lbl_delete_dispatch
                                          : "Delete Dispatch"
                                      }
                                      className="menu_icon_red cursor_pointer me-2"
                                      size={20}
                                      onClick={() => delDispatch(val)}
                                    />
                                  </>
                                )}

                                {loginData.loginData?.data?.response
                                  .role_name !== "client_admin" &&
                                loginData.loginData?.data?.response
                                  .role_name !== "admin" ? (
                                  // privilage.view == true && val.invoice_attachment !== null ? (
                                  privilage.view == true ? (
                                    <>
                                      <Tooltip
                                        id={"create-tooltip" + i}
                                        place="top"
                                      />
                                      <FileText
                                        data-tooltip-id={"create-tooltip" + i}
                                        data-tooltip-content={
                                          constLabel?.lbl_create_challan
                                            ? constLabel.lbl_create_challan
                                            : "Create Challan"
                                        }
                                        size={20}
                                        className={"menu_icon_grey cursor_pointer"}
                                        // className={
                                        //   val.invoice_attachment == null
                                        //     ? "button-blur"
                                        //     : "menu_icon_grey cursor_pointer"
                                        // }
                                        // disabled={
                                        //   val.invoice_attachment == null
                                        //     ? true
                                        //     : false
                                        // }
                                        onClick={() => {
                                          // if (val.invoice_attachment !== null) {
                                            createChallan(val);
                                          // }
                                        }}
                                      />
                                    </>
                                  ) : null
                                ) : null }
                                {/* val.invoice_attachment !== null ? (
                                  <>
                                    <Tooltip
                                      id={"create-tooltip" + i}
                                      place="top"
                                    />
                                    <FileText
                                      data-tooltip-id={"create-tooltip" + i}
                                      data-tooltip-content={
                                        constLabel?.lbl_create_challan
                                          ? constLabel.lbl_create_challan
                                          : "Create Challan"
                                      }
                                      className={"menu_icon_grey cursor_pointer"}
                                      // className={
                                      //   val.invoice_attachment == null
                                      //     ? "button-blur"
                                      //     : "menu_icon_grey cursor_pointer"
                                      // }
                                      size={20}
                                      // disabled={
                                      //   val.invoice_attachment == null
                                      //     ? true
                                      //     : false
                                      // }
                                      onClick={() => {
                                        // if (val.invoice_attachment !== null) {
                                          createChallan(val);
                                        // }
                                      }}
                                    />
                                  </>
                                ) : null} */}

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p className="text-center text-danger mb-0">
                      {"No dispatch data found !"}
                    </p>
                  )}

                  {dispatchList.loading ? null : dispatchList.data?.length >
                    0 ? (
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="mb-0">
                        Show
                        <select
                          className="mx-1"
                          defaultValue={entriesPerPage}
                          onChange={(e) => {
                            setEntriesPerPage(e.target.value);
                            fetchDispatchData(1, e.target.value);
                          }}
                        >
                          <option value={"10"}>10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        Entries
                      </p>
                      <p className="mb-0">{`Showing ${
                        parseInt(srNo) + 1
                      } to ${Math.min(
                        parseInt(entriesPerPage) + parseInt(srNo),
                        dispatchList.totalRecords
                      )} of ${dispatchList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchDispatchData(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1
                                ? 0
                                : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                            maxPage === 0 ||
                            entriesPerPage > dispatchList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            // console.log("parseInt(srNo) + parseInt(entriesPerPage)", parseInt(srNo) + parseInt(entriesPerPage));
                            fetchDispatchData(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  ) : null}
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
      <Modal show={show} onHide={() => setShow(false)} size="xl">
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_dispatch_items
              ? constLabel.lbl_dispatch_items
              : "Dispatch Items"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selItem?.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered mt-2">
                <thead className="table-grey roboto-bold">
                  <tr className="">
                    <th scope="col">{"Sr No"}</th>
                    <th scope="col">{"Date"}</th>
                    <th scope="col">{"Work Order No"}</th>
                    <th scope="col">{"Work Order Qty"}</th>
                    <th scope="col">{"Product Name"}</th>
                    <th scope="col">{"HT Inward Qty"}</th>
                    <th scope="col">{"Dispatch Qty"}</th>
                    <th scope="col">{"Scrap Qty"}</th>
                    <th scope="col">{"Remark"}</th>
                  </tr>
                </thead>
                <tbody className="roboto">
                  {selItem.map((val, i) => (
                    <tr key={i}>
                      <td style={{ verticalAlign: "middle" }}>
                        {parseInt(i) + 1}
                      </td>
                      <td>
                        {val?.date !== "" && val?.date !== null
                          ? convertDate(val?.date, "-")
                          : "NA"}
                      </td>
                      <td>
                        {val?.work_order_details?.work_order_no !== "" && val?.work_order_details?.work_order_no !== null
                          ? val?.work_order_details?.work_order_no
                          : "NA"}
                      </td>
                      <td>
                        {val?.work_order_details?.quantity !== "" && val?.work_order_details?.quantity !== null
                          ? val?.work_order_details?.quantity
                          : "NA"}
                      </td>
                      <td>
                        {val?.prod_info?.productName !== "" && val?.prod_info?.productName !== null
                          ? val?.prod_info?.productName
                          : "NA"}
                      </td>
                      <td>
                        {val?.ht_qty !== "" && val?.ht_qty !== null
                          ? val?.ht_qty
                          : "NA"}
                      </td>
                      <td>
                        {val?.qty !== "" && val?.qty !== null ? val?.qty : "NA"}
                      </td>
                      <td>
                        {val?.scrapqty !== "" && val?.scrapqty !== null
                          ? val?.scrapqty
                          : "NA"}
                      </td>
                      <td>
                        {val?.remark !== "" && val?.remark !== null
                          ? val?.remark
                          : "NA"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-center text-danger mb-0">
              {"No dispatch data found !"}
            </p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-grey px-4" onClick={() => setShow(false)}>
            {constLabel?.lbl_close ? constLabel.lbl_close : "Close"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default Dispatch;
