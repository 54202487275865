import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { UploadCloud } from "react-feather";
import { faEye, faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useNavigate } from "react-router-dom";
import { Modal, Pagination } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import {
  calMaxPage,
  convertUrl,
  getLabels,
} from "../../../common/Functions/CommonFunctions";
import {
  getProductImage,
  getProductAttribute,
  getProdConfig,
  getProdImages,
} from "../../../api";

function PreviewProduct({ nextStep, previousStep, main_obj, setMain_obj }) {
  const [visible, setVisible] = useState(false);
  const [galVisible, setGalVisible] = useState(false);
  const [docList, setDocList] = useState({ data: [], loading: true });
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [prod_img, setProd_Img] = useState({ data: [], loading: true });
  const [prod_attr, setProd_Attr] = useState({ data: [], loading: false });
  const [prodConfig, setProdConfig] = useState({
    data: [],
    loading: false,
    totalRecords: "",
  });
  const [typeList, setTypeList] = useState({
    data: [{ name: "type1" }, { name: "type2" }],
    loading: false,
  });

  const [constLabel, setConstLabel] = useState({});

  const [attrb, setAttrb] = useState(false);
  const [oprt, setOprt] = useState(false);
  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    setValue,
    getValues,
  } = useForm({
    // resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook
  let { errors } = formState;
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    //   
    
    setConstLabel(getLabels("Master", "Product"));
    fetchProductImage();
    fetchProductSpecifications();
    fetchProdConfig(1, 10);
    if (galVisible) {
    }
  }, []);

  function toggleDiv(ind) {
    const selRow = document.getElementById("collapse_row" + ind);
    
    selRow.classList.toggle("expanded");
  }

  function fetchProductImage() {
    setProd_Img({ ...prod_img, data: [], loading: true });
    const payload = {
      product_uuid: main_obj && main_obj?.prod_overview?.uuid,
    };
    getProductImage(payload).then(
      (res) => {
        
        setProd_Img({ ...prod_img, data: res.data.data, loading: false });
      },
      (err) => {
        
        setProd_Img({ ...prod_img, data: [], loading: false });
      }
    );
  }

  function fetchProductSpecifications() {
    setProd_Attr({ ...prod_attr, data: [], loading: true });
    const payload = {
      pageNo: 1,
      entriesPerPage: 1000,
      product_id: main_obj && main_obj?.prod_overview?.uuid,
    };
    getProductAttribute(payload).then(
      (res) => {
        
        setProd_Attr({ ...prod_attr, data: res.data.data, loading: false });
      },
      (err) => {
        
        setProd_Attr({ ...prod_attr, data: [], loading: false });
      }
    );
  }

  function fetchProdConfig(pg, epp) {
    setProdConfig({ ...prodConfig, data: [], loading: true });
    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
      product_id: main_obj && main_obj?.prod_overview?.uuid,
    };
    getProdConfig(payload).then(
      (res) => {
        
        setProdConfig({
          ...prodConfig,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });
        setMaxPage(calMaxPage(res.data.total));
      },
      (err) => {
        
        setProdConfig({ ...prodConfig, data: [], loading: false });
      }
    );
  }

  function fetchDocuments(val) {
    setDocList({ ...docList, data: [], loading: true });
    const payload = {
      product_operation_id: val.uuid, //"de38cb18-1408-467a-839f-24636a2e9806"
    };

    getProdImages(payload).then(
      (res) => {
        
        setGalVisible(true);
        setDocList({ ...docList, data: res.data.data, loading: false });
        // const filData = res.data.data.filter((val) => val.name == "Operation Drawing");

        // setValue('docType', filData);
      },
      (err) => {
        
        setDocList({ ...docList, data: [], loading: false });
      }
    );
  }

  const onSubmit = (data) => {
    
  };

  return (
    <React.Fragment>
      <div className="mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <p className="mb-0" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_product_preview
                ? constLabel?.lbl_product_preview
                : "Product Preview"}
            </p>
          </div>
          <div className="card-body">
            <div className="">
              <div className="d-flex justify-content-between align-items-center">
                <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                  {constLabel?.lbl_product_information
                    ? constLabel?.lbl_product_information
                    : "Product Information"}
                </p>
                <div>
                  <button className="btn btn-sm btn-grey" onClick={() => { previousStep(1) }}>
                    {constLabel?.lbl_edit ? constLabel?.lbl_edit : "Edit"}
                  </button>
                </div>
              </div>
              <hr className="mt-2" />

              <div className="">
                <div
                  className="row"
                  style={{ paddingTop: "10px", paddingBottom: "25px" }}
                >
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 col-sm-4">
                        <div className="card shadow-sm rounded-0 p-2">
                          <div className="card-body">
                            <p className="pc_card_title roboto-bold">
                              {constLabel?.lbl_product_summary
                                ? constLabel?.lbl_product_summary
                                : "Product Summary"}
                            </p>
                            <div className="form-group">
                              <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_product_type
                                  ? constLabel?.lbl_product_type
                                  : "Product Type"}
                              </label>
                              <input
                                className="form-control mt-2 "
                                disabled
                                type="text"
                                defaultValue={
                                  main_obj?.prod_overview?.productTypename !== ""
                                    ? main_obj?.prod_overview?.productTypename
                                    : "NA"
                                }
                              />
                            </div>
                            <div className="form-group mt-3">
                              <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_product_category
                                  ? constLabel?.lbl_product_category
                                  : "Product Category"}
                              </label>
                              <input
                                className="form-control mt-2"
                                disabled
                                type="text"
                                defaultValue={
                                  main_obj?.prod_overview?.category !== ""
                                    ? main_obj?.prod_overview?.category
                                    : "NA"
                                }
                              />
                            </div>
                            <div className="form-group mt-3">
                              <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_product_name
                                  ? constLabel?.lbl_product_name
                                  : "Product Name"}
                              </label>
                              <input
                                className="form-control mt-2"
                                disabled
                                type="text"
                                defaultValue={
                                  main_obj?.prod_overview?.productName !== ""
                                    ? main_obj?.prod_overview?.productName
                                    : "NA"
                                }
                              />
                            </div>
                            <div className="form-group mt-3">
                              <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_hsn
                                  ? constLabel?.lbl_hsn
                                  : "HSN"}
                              </label>
                              <input
                                className="form-control mt-2"
                                disabled
                                type="text"
                                defaultValue={
                                  main_obj?.prod_overview?.hsn !== ""
                                    ? main_obj?.prod_overview?.hsn
                                    : "NA"
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-sm-4">
                        <div className="card shadow-sm rounded-0 p-2">
                          <div className="card-body">
                            <p className="pc_card_title roboto-bold">
                              {constLabel?.lbl_product_detail
                                ? constLabel?.lbl_product_detail
                                : "Product Detail"}
                            </p>
                            {/* <div className="form-group">
                        <label className="lbl-style roboto-bold">
                            {"Drawing No."}
                        </label>
                        <input
                            className="form-control mt-2"
                            disabled
                            type="text"
                            defaultValue={main_obj?.prod_overview?.drawingno}
                        />
                        </div> */}
                            <div className="mt-2">
                              <label className="lbl-style roboto-bold invisible">
                                {constLabel?.lbl_can_be_sold
                                  ? constLabel?.lbl_can_be_sold
                                  : "Can be Sold"}
                              </label>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultChecked={
                                    main_obj?.prod_overview?.can_be_sold
                                  }
                                  disabled
                                />
                                <label className="form-check-label lbl-style roboto-bold">
                                  {constLabel?.lbl_can_be_sold
                                    ? constLabel?.lbl_can_be_sold
                                    : "Can be Sold"}
                                </label>
                              </div>
                            </div>

                            <div className="mt-2">
                              <label className="lbl-style roboto-bold invisible">
                                {constLabel?.lbl_can_be_pruchase
                                  ? constLabel?.lbl_can_be_pruchase
                                  : "Can be Purchased"}
                              </label>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultChecked={
                                    main_obj?.prod_overview?.can_be_purchased
                                  }
                                  disabled
                                />
                                <label className="form-check-label lbl-style roboto-bold">
                                  {constLabel?.lbl_can_be_pruchase
                                    ? constLabel?.lbl_can_be_pruchase
                                    : "Can be Purchased"}
                                </label>
                              </div>
                            </div>

                            <div className="mt-2">
                              <label className="lbl-style roboto-bold invisible">
                                {constLabel?.lbl_can_be_expense
                                  ? constLabel?.lbl_can_be_expense
                                  : "Can be Expense"}
                              </label>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultChecked={
                                    main_obj?.prod_overview?.can_be_expensed
                                  }
                                  disabled
                                />
                                <label className="form-check-label lbl-style roboto-bold">
                                  {constLabel?.lbl_can_be_expense
                                    ? constLabel?.lbl_can_be_expense
                                    : "Can be Expense"}
                                </label>
                              </div>
                            </div>

                            {/* <div className="form-group mt-3">
                        <label className="lbl-style roboto-bold">
                            {"OEM Part No."}
                        </label>
                        <input
                            className="form-control mt-2"
                            disabled
                            type="text"
                            defaultValue={main_obj?.prod_overview?.OEMnumber}
                        />
                        </div> */}

                            {/* <div className="form-group mt-3">
                        <label className="lbl-style roboto-bold">
                            {"Material"}
                        </label>
                        <input
                            className="form-control mt-2"
                            disabled
                            type="text"
                            defaultValue={main_obj?.prod_overview?.material}
                        />
                        </div> */}

                            {/* <div className="form-group mt-3">
                        <label className="lbl-style roboto-bold">
                            {"Ø in mm"}
                        </label>
                        <input
                            className="form-control mt-2"
                            disabled
                            type="text"
                            defaultValue={main_obj?.prod_overview?.diameter}
                        />
                        </div> */}

                            <div className="form-group mt-3">
                              <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_beacon_id
                                  ? constLabel?.lbl_beacon_id
                                  : "BeaconId"}
                              </label>
                              <input
                                className="form-control mt-2"
                                disabled
                                type="text"
                                defaultValue={
                                  main_obj?.prod_overview?.beacon_id !== ""
                                    ? main_obj?.prod_overview?.beacon_id
                                    : "NA"
                                }
                              />
                            </div>

                            <div className="form-group mt-3">
                              <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_qr_code_id
                                  ? constLabel?.lbl_qr_code_id
                                  : "QR Code Id"}
                              </label>
                              <input
                                className="form-control mt-2"
                                disabled
                                type="text"
                                defaultValue={
                                  main_obj?.prod_overview?.qr_code_id !== ""
                                    ? main_obj?.prod_overview?.qr_code_id
                                    : "NA"
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-sm-4">
                        <div className="card shadow-sm rounded-0 p-2">
                          <div className="card-body">
                            <p className="pc_card_title roboto-bold">
                              {constLabel?.lbl_product_price
                                ? constLabel?.lbl_product_price
                                : "Product Price"}
                            </p>
                            <div className="form-group">
                              <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_cost
                                  ? constLabel?.lbl_cost
                                  : "Cost"}
                              </label>
                              <input
                                className="form-control mt-2"
                                disabled
                                type="text"
                                defaultValue={
                                  main_obj?.prod_overview?.machineCost
                                }
                              />
                            </div>

                            <div className="form-group mt-3">
                              <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_sales_price
                                  ? constLabel?.lbl_sales_price
                                  : "Sales Price"}
                              </label>
                              <input
                                className="form-control mt-2 "
                                disabled
                                type="text"
                                defaultValue={main_obj?.prod_overview?.salesPrice}
                              />
                            </div>

                            <div className="form-group mt-3">
                              <label className="lbl-style roboto-bold">
                                {constLabel?.lbl_remark
                                  ? constLabel?.lbl_remark
                                  : "Remark"}
                              </label>
                              <input
                                className="form-control mt-2"
                                disabled
                                type="text"
                                defaultValue={
                                  main_obj?.prod_overview?.remark !== ""
                                    ? main_obj?.prod_overview?.remark
                                    : "NA"
                                }
                              />
                            </div>

                            <div className="form-group mt-3">
                              <div>
                                <label className="lbl-style roboto-bold">
                                  {constLabel?.lbl_check_process
                                    ? constLabel?.lbl_check_process
                                    : "Check Process"}
                                </label>
                              </div>
                              <button className="btn button-primary px-3 mt-2">
                                {constLabel?.lbl_process_flow
                                  ? constLabel?.lbl_process_flow
                                  : "Process Flow"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="mb-2" />
                <div className="d-flex justify-content-between align-items-center">
                  <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                    {constLabel?.lbl_product_images
                      ? constLabel?.lbl_product_images
                      : "Product Images"}
                  </p>
                  <div>
                    <button className="btn btn-sm btn-grey" onClick={() => { previousStep(2) }}>
                      {constLabel?.lbl_edit ? constLabel?.lbl_edit : "Edit"}
                    </button>
                  </div>
                </div>
                <hr className="mt-2" />

                <div
                  className="row image_gallery2 "
                  style={{ paddingTop: "10px", paddingBottom: "25px" }}
                >
                  <div className="col-12">
                    <div className="card shadow-sm rounded-0">
                      <div className="card-body mx-4">
                        {/* <Slider {...settings}> */}
                        <div className="row">
                          {prod_img.data !== undefined &&
                            prod_img.data.length > 0 ? (
                            prod_img.data.map((imgSrc, index) => (
                              <div className="col-12 col-md-3" key={index}>
                                <div className="image-container">
                                  <img
                                    src={process.env.REACT_APP_IMAGE_URL + imgSrc.attachment}
                                    className="img-fluid"
                                    alt="Image 1"
                                  />

                                  <div className="image-overlay">
                                    <FontAwesomeIcon
                                      onClick={() =>
                                        window.open(process.env.REACT_APP_IMAGE_URL + imgSrc.attachment, "_blank")
                                      }
                                      className="icon"
                                      style={{ color: "#fff" }}
                                      icon={faEye}
                                      size="xl"
                                    />

                                    {/* <FontAwesomeIcon
                                className="icon-close"
                                style={{ color: "#fff" }}
                                icon={faClose}
                                size="lg"
                                /> */}

                                    <span className="image-name cursor_pointer">
                                      {imgSrc.name == null ? "NA" : imgSrc.name}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <div
                              className="alert alert-danger mb-0 text-center my-4"
                              role="alert"
                            >
                              {"No product images found !"}
                            </div>
                          )}
                        </div>

                        {/* </Slider> */}
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="mb-2" />
                <div className="d-flex justify-content-between align-items-center">
                  <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                    {constLabel?.lbl_product_attribute
                      ? constLabel?.lbl_product_attribute
                      : "Product Attribute"}
                  </p>
                  <div>
                    <button className="btn btn-sm btn-grey" onClick={() => { previousStep(4) }}>
                      {constLabel?.lbl_edit ? constLabel?.lbl_edit : "Edit"}
                    </button>
                  </div>
                </div>
                <hr className="mt-2" />

                <div
                  className="row "
                  style={{ paddingTop: "10px", paddingBottom: "25px" }}
                >
                  <div className="col-12">
                    {prod_attr.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : prod_attr.data !== undefined &&
                      prod_attr.data.length > 0 ? (
                      <div className="table-responsive pc">
                        <table className="table my-2 table-bordered">
                          <thead className="table-grey roboto">
                            <tr>
                              <th>{"Sr.No"}</th>
                              <th>
                                {constLabel?.lbl_name
                                  ? constLabel?.lbl_name
                                  : "Name"}
                              </th>
                              <th>
                                {constLabel?.lbl_attribute
                                  ? constLabel?.lbl_attribute
                                  : "Attribute"}
                              </th>
                              <th>
                                {constLabel?.lbl_value
                                  ? constLabel?.lbl_value
                                  : "Value"}
                              </th>
                              <th>
                                {constLabel?.lbl_min_value
                                  ? constLabel?.lbl_min_value
                                  : "Min Value"}
                              </th>
                              <th>
                                {constLabel?.lbl_max_value
                                  ? constLabel?.lbl_max_value
                                  : "Max Value"}
                              </th>
                              <th>
                                {constLabel?.lbl_unit
                                  ? constLabel?.lbl_unit
                                  : "Unit"}
                              </th>
                              <th>
                                {constLabel?.lbl_operation_name
                                  ? constLabel.lbl_operation_name
                                  : "Operation"}
                              </th>
                              <th>
                                {constLabel?.lbl_remark
                                  ? constLabel?.lbl_remark
                                  : "Remark"}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {prod_attr.data.map((val, index) => (
                              <tr key={index}>
                                <td width="5%">{index + 1}</td>
                                <td>{val.name}</td>
                                <td>{val.attname}</td>
                                <td>{val.value}</td>
                                <td>{val.minValue}</td>
                                <td>{val.maxValue}</td>
                                <td>{val.unitname}</td>
                                <td>{val.product_operation_name == null ? "NA" : val.product_operation_name}</td>
                                <td>{val.remark}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    ) : (
                      <div
                        className="alert alert-danger mb-0 text-center my-4"
                        role="alert"
                      >
                        {"No product attributes found !"}
                      </div>
                    )}
                  </div>
                </div>

                <hr className="mb-2" />
                <div className="d-flex justify-content-between align-items-center">
                  <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                    {constLabel?.lbl_product_specification
                      ? constLabel?.lbl_product_specification
                      : "Operation Sequence"}
                  </p>
                  <div>
                    <button className="btn btn-sm btn-grey" onClick={() => { previousStep(3) }}>
                      {constLabel?.lbl_edit ? constLabel?.lbl_edit : "Edit"}
                    </button>
                  </div>
                </div>
                <hr className="mt-2" />
                {prodConfig.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : prodConfig.data?.length > 0 ? (
                  <div
                    className="row"
                    style={{ paddingTop: "10px", paddingBottom: "25px" }}
                  >
                    <div className="col-12">
                      <div className="table-responsive pc">
                        <table className="table my-3 table-bordered">
                          <thead className="table-grey roboto">
                            <tr>
                              <th>{"Sr.No"}</th>
                              <th>
                                {constLabel?.lbl_sequence_no
                                  ? constLabel?.lbl_sequence_no
                                  : "Sequence No"}
                              </th>
                              <th>
                                {constLabel?.lbl_operation_name
                                  ? constLabel?.lbl_operation_name
                                  : "Operation Name"}
                              </th>
                              <th>
                                {constLabel?.lbl_operation_cost
                                  ? constLabel?.lbl_operation_cost
                                  : "Operation Cost"}{" "}(<span>&#8377;</span>)
                              </th>
                              <th>
                                {constLabel?.lbl_route
                                  ? constLabel?.lbl_route
                                  : "Route"}
                              </th>
                              <th>{constLabel?.lbl_man_hour_cost ? constLabel?.lbl_man_hour_cost : "Man Hour Cost"}{" "}(<span>&#8377;</span>)</th>
                              <th>{constLabel?.lbl_job_weight ? constLabel?.lbl_job_weight : "Job Weight"}{" "}(<span>&#13199;</span>)</th>
                              <th>{constLabel?.lbl_job_length ? constLabel?.lbl_job_length : "Job Length"}{" "}(<span>m;</span>)</th>
                              <th>
                                {constLabel?.lbl_drawing
                                  ? constLabel?.lbl_drawing
                                  : "Drawing"}
                              </th>
                              <th>
                                {constLabel?.lbl_serial_no_tracking
                                  ? constLabel?.lbl_serial_no_tracking
                                  : "Serial Number Tracking"}
                              </th>
                              <th>
                                {constLabel?.lbl_machine
                                  ? constLabel?.lbl_machine
                                  : "Machine"}
                              </th>
                              {/* <th>Actions</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {prodConfig.data.map((val, ind) => (
                              <React.Fragment key={ind}>
                                <tr>
                                  <td>{parseInt(ind) + 1 + parseInt(srNo)}</td>
                                  <td>{val.sequenceNo}</td>
                                  <td>{val.operationname}</td>
                                  <td>{val.oprcost}</td>
                                  <td>{val.routesname}</td>
                                  <td>{val.man_hour_cost !== null ? val.man_hour_cost : 0}</td>
                                  <td>{val.job_weight !== null ? val.job_weight : 0}</td>
                                  <td>{val.job_length !== null ? val.job_length : 0}</td>
                                  <td>
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="tooltip">
                                          {constLabel?.lbl_view_documents
                                            ? constLabel?.lbl_view_documents
                                            : "View Documents"}
                                        </Tooltip>
                                      }
                                    >
                                      <div
                                        className="downloadIcon m-1 cursor_pointer"
                                        onClick={() => fetchDocuments(val)}
                                      >
                                        <UploadCloud color="#FFFFFF" size={18} />
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                  <td>
                                    <input type="checkbox" checked={val.serial_number_tracking} readOnly />
                                  </td>
                                  <td>
                                    <button
                                      className="btn btn-sm btn-darkblue px-3"
                                      onClick={() => toggleDiv(ind, val)}
                                    >
                                      {constLabel?.lbl_view_machine
                                        ? constLabel.lbl_view_machine
                                        : "View Machine"}
                                    </button>
                                  </td>
                                  {/* <td>
                            <button
                                className="btn btn-sm btn-darkblue px-3"
                                onClick={() => toggleDiv(ind)}
                            >
                                Add / Edit Parameters
                            </button>
                            </td> */}
                                  {/* <td>
                            <button
                                className="btn btn-sm btn-lightblue px-3"
                                onClick={() => toggleDiv(ind)}
                            >
                                Add / Edit Parameters
                            </button>
                            </td> */}
                                  {/* <td>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip id="tooltip">{"Edit"}</Tooltip>}
                            >
                                <div className="editIcon m-1 cursor_pointer">
                                <Edit2 color="#FFFFFF" size={18} />
                                </div>
                            </OverlayTrigger>

                            <OverlayTrigger
                                placement="top"
                                overlay={
                                <Tooltip id="tooltip">{"Delete"}</Tooltip>
                                }
                            >
                                <div className="deleteIcon m-1 cursor_pointer">
                                <Trash2 color="#FFFFFF" size={18} />
                                </div>
                            </OverlayTrigger>
                            </td> */}
                                </tr>

                                <tr
                                  className={"collapsible-row"}
                                  id={"collapse_row" + ind}
                                >
                                  <td colSpan="12">
                                    <div className="collapse-content">
                                      <div className="row">
                                        <div className="col-12 col-md-1">
                                          <label className="">{"Sr.No"}</label>
                                        </div>

                                        <div className="col-12 col-md-2">
                                          <label className="">
                                            {constLabel?.lbl_machine
                                              ? constLabel.lbl_machine
                                              : "Machine"}
                                          </label>
                                        </div>
                                        <div className="col-12 col-md-2">
                                          <label className="">
                                            {constLabel?.lbl_cycle_time
                                              ? constLabel.lbl_cycle_time
                                              : "Cycle Time"}
                                          </label>
                                        </div>
                                        <div className="col-12 col-md-2">
                                          <label className="">
                                            {constLabel?.lbl_setting_time
                                              ? constLabel.lbl_setting_time
                                              : "Setting Time"}
                                          </label>
                                        </div>
                                        <div className="col-12 col-md-2">
                                          <label className="">
                                            {constLabel?.lbl_tool_change_time
                                              ? constLabel.lbl_tool_change_time
                                              : "Tool Change Time"}
                                          </label>
                                        </div>
                                        <div className="col-12 col-md-2">
                                          <label className="">
                                            {constLabel?.lbl_machine_rate
                                              ? constLabel.lbl_machine_rate
                                              : "Machine Rate"}
                                          </label>
                                        </div>
                                      </div>
                                      {val.machine.map((vv, index) => (
                                        <div className="row" key={index}>
                                          <div className="col-12 col-md-1">
                                            <label className="roboto-bold">
                                              {index + 1}
                                            </label>
                                          </div>

                                          <div className="col-12 col-md-2">
                                            <label className="roboto-bold">
                                            {vv.name}{" - "}{vv.serialNo}
                                            </label>
                                          </div>
                                          <div className="col-12 col-md-2">
                                            <label className="roboto-bold">
                                              {vv.cycle_time}
                                            </label>
                                          </div>
                                          <div className="col-12 col-md-2">
                                            <label className="roboto-bold">
                                              {vv.settings_time
                                                ? vv.settings_time
                                                : "NA"}
                                            </label>
                                          </div>
                                          <div className="col-12 col-md-2">
                                            <label className="roboto-bold">
                                              {vv.tool_change_time
                                                ? vv.tool_change_time
                                                : "NA"}
                                            </label>
                                          </div>
                                          <div className="col-12 col-md-2">
                                            <label className="roboto-bold">
                                              {vv.machine_rate
                                                ? vv.machine_rate
                                                : "NA"}
                                            </label>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </td>
                                </tr>
                              </React.Fragment>
                            ))}
                          </tbody>
                        </table>

                        {/* {prodConfig.loading ? null : prodConfig.data?.length > 0 ? ( */}
                        <div className="mt-4 d-flex justify-content-between">
                          <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                            } to ${prodConfig.data.length +
                            entriesPerPage * page -
                            entriesPerPage
                            } of ${prodConfig.totalRecords} entries`}</p>
                          <Pagination className="my-2">
                            <Pagination.Prev
                              disabled={page === 1 ? true : false}
                              onClick={() => {
                                fetchProdConfig(
                                  page - 1,
                                  entriesPerPage,
                                  parseInt(srNo) - parseInt(entriesPerPage)
                                );
                                setSrNo((prevC) =>
                                  page - 1 == 1
                                    ? 0
                                    : prevC - parseInt(entriesPerPage)
                                );
                                setPage(page - 1);
                              }}
                            >
                              {"Prev"}
                            </Pagination.Prev>

                            <Pagination.Item active>{page}</Pagination.Item>

                            <Pagination.Next
                              disabled={
                                page === maxPage ||
                                  maxPage === 0 ||
                                  entriesPerPage > prodConfig.data.length
                                  ? true
                                  : false
                              }
                              onClick={() => {
                                
                                fetchProdConfig(
                                  page + 1,
                                  entriesPerPage,
                                  parseInt(srNo) + parseInt(entriesPerPage)
                                );
                                setSrNo(
                                  (prevC) => prevC + parseInt(entriesPerPage)
                                );
                                setPage(page + 1);
                              }}
                            >
                              {"Next"}
                            </Pagination.Next>
                          </Pagination>
                        </div>
                        {/* ) : null} */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    className="alert alert-danger mb-0 text-center my-4"
                    role="alert"
                  >
                    {"No product operation sequence found !"}
                  </div>
                )}
              </div>

              <hr className="my-2" />
              <div className="d-flex justify-content-end">
                {/* <button
                    className="btn btn-grey px-4 me-2"
                    onClick={() => previousStep()}
                >
                    {constLabel?.lbl_previous ? constLabel?.lbl_previous : "Previous"}
                </button> */}
                <button
                  className="btn btn-lightblue px-4 me-2"
                  onClick={() => navigate("/pcms/product")}
                >
                  {constLabel?.lbl_close ? constLabel?.lbl_close : "Close"}
                </button>
              </div>
            </div>
          </div>

          <Modal
            show={visible}
            onHide={() => setVisible(false)}
            size={"lg"}
            className="modal-dialog-centered"
          >
            <Modal.Header
              style={{ background: "#2A3643", color: "white" }}
              closeButton
            >
              <Modal.Title>
                {constLabel?.lbl_process_flow
                  ? constLabel.lbl_process_flow
                  : "Process Flow"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form action="">
                <div className="table-responsive">
                  <table
                    className="table table-bordered mt-2 table-bordered"
                    width={"100%"}
                  >
                    <thead className="thead-light text-ceter">
                      <tr className="">
                        <th scope="col">{"Sr. No"}</th>
                        <th scope="col">
                          {constLabel?.lbl_sequence_no
                            ? constLabel.lbl_sequence_no
                            : "Sequence No"}
                        </th>
                        <th scope="col">
                          {constLabel?.lbl_operation_name
                            ? constLabel.lbl_operation_name
                            : "Operation Name"}
                        </th>
                        <th scope="col">
                          {constLabel?.lbl_drawing
                            ? constLabel.lbl_drawing
                            : "Drawing"}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {/* {props.data.map((val, i) => (
                    <tr key={i} className="f-14">
                      <td>{val.bankId}</td>
                      <td>{val.bankAccNo}</td>
                      <td>{val.bankIfscCode}</td>
                      <td>{val.bankMicrCode}</td>
                    </tr>
                  ))} */}
                      <tr className="f-14">
                        <td>{1}</td>
                        <td>{1}</td>
                        <td>{"FUEL-ECC CAM TURNING"}</td>
                        <td>{""}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </form>
            </Modal.Body>
          </Modal>

          <Modal
            show={galVisible}
            onHide={() => setGalVisible(false)}
            size="lg"
            backdrop="static"
          >
            <Modal.Header
              style={{ background: "#2A3643", color: "white" }}
              closeButton
            >
              <Modal.Title>
                {constLabel?.lbl_drawing ? constLabel.lbl_drawing : "Drawing"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {docList.data.length > 0 ? (
                <div className="row mt-4 drawing_gallery row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
                  {docList.data.map((val, i) =>
                    val.attachment !== null ? (
                      val.attachment.includes(".jpg") == true ? (
                        <div className="col text-center" key={i}>
                          <div className="image-container cursor_pointer">
                            <img
                              src={process.env.REACT_APP_IMAGE_URL + val.attachment}
                              onClick={() =>
                                window.open(process.env.REACT_APP_IMAGE_URL + val.attachment, "_blank")
                              }
                              className="img-fluid"
                              alt="Image 1"
                            />
                          </div>

                          <span className="image-name cursor_pointer">
                            {val.name}
                          </span>
                        </div>
                      ) : val.attachment.includes(".png") ==
                        true ? (
                        <div className="col text-center" key={i}>
                          <div className="image-container cursor_pointer">
                            <img
                              src={process.env.REACT_APP_IMAGE_URL + val.attachment}
                              onClick={() =>
                                window.open(process.env.REACT_APP_IMAGE_URL + val.attachment, "_blank")
                              }
                              className="img-fluid"
                              alt="Image 1"
                            />
                          </div>

                          <span className="image-name cursor_pointer">
                            {val.name}
                          </span>
                        </div>
                      ) : (
                        <div className="col text-center" key={i}>
                          <div className="image-container ">
                            <div className="other_doc d-flex justify-content-center align-items-center">
                              <FontAwesomeIcon
                                onClick={() =>
                                  window.open(
                                    process.env.REACT_APP_IMAGE_URL + val.attachment,
                                    "_blank"
                                  )
                                }
                                className="icon-file-pdf cursor_pointer"
                                style={{ color: "#fff" }}
                                icon={faFileAlt}
                                size="3x"
                              />
                            </div>
                          </div>

                          <span className="image-name cursor_pointer">
                            {val.name}
                          </span>
                        </div>
                      )
                    ) : null
                  )}
                </div>
              ) : (
                <div
                  className="alert alert-danger mb-0 text-center my-4"
                  role="alert"
                >
                  No drawing files found!
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-grey px-4"
                onClick={() => setGalVisible(false)}
              >
                {constLabel?.lbl_close ? constLabel.lbl_close : "Close"}
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PreviewProduct;
