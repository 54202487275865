import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import {
  createCustomer_Contact,
  delete_party_contact,
} from "../../../../../api/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { getLabels } from "../../../../../common/Functions/CommonFunctions";

const schema = yup.object().shape({
  items: yup.array().of(
    yup.object().shape({
      firstName: yup
        .string()
        .required("First name is required !")
        .matches(/^[a-zA-Z ]*$/, "Enter valid name"),
      lastName: yup
        .string()
        .required("Last name is required !")
        .matches(/^[a-zA-Z ]*$/, "Enter valid name"),
      mobileNo: yup
        .string()
        .matches(/^[0-9]*$/, "Enter valid mobile number")
        .required("Mobile number is required !"),
      alternateMobileNo: yup
        .string()
        .matches(/^[0-9]*$/, "Enter valid mobile number")
        .notRequired(),
      landlineExtentionNo: yup
        .string()
        .matches(/^[0-9]*$/, "Enter valid landline extension number")
        .notRequired(),
      landlineNo: yup
        .string()
        .matches(/^[0-9]*$/, "Enter valid landline number")
        .notRequired(),
      alternateLandlineExtentionNo: yup
        .string()
        .matches(/^[0-9]*$/, "Enter valid alternate landline extension number")
        .notRequired(),
      alternateLandlineNo: yup
        .string()
        .matches(/^[0-9]*$/, "Enter valid alternate landline number")
        .notRequired(),
      emailid: yup.string().email("Enter valid email id").notRequired(),
    })
  ),
});

function NewCreateContactDetails(props) {
  let navigate = useNavigate();
  const location = useLocation();

  let arr = [{}];
  if (props.main_obj.party_contact && props.main_obj.party_contact.length > 0) {
    arr = new Array(props.main_obj.party_contact.length)
      .fill(null)
      .map(() => ({}));
  }
  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: { items: arr },
  }); // initialise the hook

  const [constLabel, setConstLabel] = useState({});

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  useEffect(() => {
    setConstLabel(getLabels("Party Master", "Customer"));
  }, []);

  const onSubmit = (data) => {
    let arr = data.items;
    console.log("arr", arr);
    if (location.pathname == "/pcms/neweditCustomer") {
      for (let i = 0; i < arr.length; i++) {
        if (props.main_obj.party_contact?.[i]?.uuid !== undefined) {
          arr[i].uuid = props.main_obj.party_contact?.[i]?.uuid;
        }
      }
    }

    if (data !== "" || data.items?.length !== 0) {
      // localStorage.setItem("contacts", JSON.stringify(contacts));
      props.setMain_obj({ ...props.main_obj, party_contact: arr });
      props.nextStep();
    } else {
      errors.showMessages();
    }
  };

  return (
    <>
      <h4 className="mb-4 roboto-bold">
        {constLabel?.lbl_contact_detail
          ? constLabel?.lbl_contact_detail
          : "Contact Detail"}
      </h4>
      {fields.map(({ id }, index) => (
        <div className="row mt-3" key={id}>
          <div className="col-12 col-md-3">
            <div className="form-group mt-md-0 mt-3">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {constLabel?.lbl_first_name
                  ? constLabel.lbl_first_name
                  : "First Name"}
                <span>
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <input
                type="text"
                className={
                  errors.items?.[index]?.firstName
                    ? "form-control  mt-2 is-invalid"
                    : "form-control  mt-2"
                }
                id="firstName"
                name={`items[${index}].firstName`}
                placeholder="Enter first name"
                defaultValue={
                  props.main_obj.party_contact &&
                  props.main_obj.party_contact.length > index
                    ? props.main_obj.party_contact[index].firstName.length > 0
                      ? props.main_obj.party_contact[index].firstName
                      : ""
                    : ""
                }
                {...register(`items[${index}].firstName`)}
              />
              <span className="text-danger err-msg">
                {errors.items?.[index]?.firstName?.message}
              </span>
            </div>
          </div>

          <div className="col-12 col-md-3">
            <div className="form-group mt-md-0 mt-3">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label roboto-bold"
              >
                {constLabel?.lbl_last_name
                  ? constLabel.lbl_last_name
                  : "Last Name"}
                <span>
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <input
                type="text"
                className={
                  errors.items?.[index]?.lastName
                    ? "form-control  mt-2 is-invalid"
                    : "form-control  mt-2"
                }
                id="lastName"
                name={`items[${index}].lastName`}
                placeholder="Enter last name"
                defaultValue={
                  props.main_obj.party_contact &&
                  props.main_obj.party_contact.length > index &&
                  props.main_obj.party_contact[index].lastName.length > 0
                    ? props.main_obj.party_contact[index].lastName
                    : ""
                }
                {...register(`items[${index}].lastName`)}
              />
              <span className="text-danger err-msg">
                {errors.items?.[index]?.lastName?.message}
              </span>
            </div>
          </div>

          <div className="col-12 col-md-3">
            <div className="form-group mt-md-0 mt-3">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label roboto-bold"
              >
                {constLabel?.lbl_mobile_number
                  ? constLabel.lbl_mobile_number
                  : "Mobile Number"}{" "}
                <span>
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <input
                type="text"
                className={
                  errors.items?.[index]?.mobileNo
                    ? "form-control  mt-2 is-invalid"
                    : "form-control  mt-2"
                }
                id="mobileNo"
                maxLength={10}
                name={`items[${index}].mobileNo`}
                placeholder="Enter contact number"
                defaultValue={
                  props.main_obj.party_contact &&
                  props.main_obj.party_contact.length > index
                    ? props.main_obj.party_contact[index].mobileNo.length > 0
                      ? props.main_obj.party_contact[index].mobileNo
                      : props.props.main_obj.primaryContactNo &&
                        props.main_obj.primaryContactNo
                    : ""
                }
                {...register(`items[${index}].mobileNo`)}
              />
              <span className="text-danger err-msg">
                {errors.items?.[index]?.mobileNo?.message}
              </span>
            </div>
          </div>

          <div className="col-12 col-md-3">
            <div className="form-group mt-md-0 mt-3">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label roboto-bold"
              >
                {constLabel?.lbl_alt_mobile_no
                  ? constLabel.lbl_alt_mobile_no
                  : "Alt. Mobile No"}
                {/* <span>
                                <i style={{ color: "red" }}>*</i>
                            </span> */}
              </label>
              <input
                type="text"
                className="form-control mt-2"
                id="alternateMobileNo"
                maxLength={10}
                name={`items[${index}].alternateMobileNo`}
                placeholder="Enter alternate mobile"
                defaultValue={
                  props.main_obj.party_contact &&
                  props.main_obj.party_contact.length > index &&
                  props.main_obj.party_contact[index].alternateMobileNo !==
                    null &&
                  props.main_obj.party_contact[index].alternateMobileNo.length >
                    0
                    ? props.main_obj.party_contact[index].alternateMobileNo
                    : ""
                }
                {...register(`items[${index}].alternateMobileNo`)}
              />
              <span className="text-danger err-msg">
                {errors.items?.[index]?.alternateMobileNo?.message}
              </span>
            </div>
          </div>

          <div className="col-12 col-md-3 mt-3">
            <div className="form-group mt-md-0 mt-3">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label roboto-bold"
              >
                {constLabel?.lbl_landline_number
                  ? constLabel.lbl_landline_number
                  : "Landline Number"}
              </label>
              <div className="row">
                <div className="col-md-4">
                  <input
                    type="text"
                    className="form-control "
                    id="landlineExtentionNo"
                    maxLength={5}
                    name={`items[${index}].landlineExtentionNo`}
                    placeholder="Enter extension"
                    defaultValue={
                      props.main_obj.party_contact &&
                      props.main_obj.party_contact.length > index &&
                      props.main_obj.party_contact[index]
                        .landlineExtentionNo !== null &&
                      props.main_obj.party_contact[index].landlineExtentionNo
                        .length > 0
                        ? props.main_obj.party_contact[index]
                            .landlineExtentionNo
                        : ""
                    }
                    {...register(`items[${index}].landlineExtentionNo`)}
                  />
                  <span className="text-danger err-msg">
                    {errors.items?.[index]?.landlineExtentionNo?.message}
                  </span>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control "
                    id="landlineNo"
                    maxLength={8}
                    name={`items[${index}].landlineNo`}
                    placeholder="Enter landline"
                    defaultValue={
                      props.main_obj.party_contact &&
                      props.main_obj.party_contact.length > index &&
                      props.main_obj.party_contact[index].landlineNo !== null &&
                      props.main_obj.party_contact[index].landlineNo.length > 0
                        ? props.main_obj.party_contact[index].landlineNo
                        : ""
                    }
                    {...register(`items[${index}].landlineNo`)}
                  />
                  <span className="text-danger err-msg">
                    {errors.items?.[index]?.landlineNo?.message}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-3 mt-3">
            <div className="form-group mt-md-0 mt-3">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label roboto-bold"
              >
                {constLabel?.lbl_alt_landline_no
                  ? constLabel.lbl_alt_landline_no
                  : "Alt. Landline No"}
                {/* <span>
                                <i style={{ color: "red" }}>*</i>
                            </span> */}
              </label>
              <div className="row">
                <div className="col-md-4">
                  <input
                    type="text"
                    className="form-control "
                    id="alternateLandlineExtentionNo"
                    maxLength={5}
                    name={`items[${index}].alternateLandlineExtentionNo`}
                    placeholder="Enter extension"
                    defaultValue={
                      props.main_obj.party_contact &&
                      props.main_obj.party_contact.length > index &&
                      props.main_obj.party_contact[index]
                        .alternateLandlineExtentionNo !== null &&
                      props.main_obj.party_contact[index]
                        .alternateLandlineExtentionNo.length > 0
                        ? props.main_obj.party_contact[index]
                            .alternateLandlineExtentionNo
                        : ""
                    }
                    {...register(
                      `items[${index}].alternateLandlineExtentionNo`
                    )}
                  />
                  <span className="text-danger err-msg">
                    {
                      errors.items?.[index]?.alternateLandlineExtentionNo
                        ?.message
                    }
                  </span>
                </div>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control "
                    id="alternateLandlineNo"
                    maxLength={8}
                    name={`items[${index}].alternateLandlineNo`}
                    placeholder="Enter alternate landline"
                    defaultValue={
                      props.main_obj.party_contact &&
                      props.main_obj.party_contact.length > index &&
                      props.main_obj.party_contact[index]
                        .alternateLandlineNo !== null &&
                      props.main_obj.party_contact[index].alternateLandlineNo
                        .length > 0
                        ? props.main_obj.party_contact[index]
                            .alternateLandlineNo
                        : ""
                    }
                    {...register(`items[${index}].alternateLandlineNo`)}
                  />
                  <span className="text-danger err-msg">
                    {errors.items?.[index]?.alternateLandlineNo?.message}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-3 mt-3">
            <div className="form-group mt-md-0 mt-3">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label roboto-bold"
              >
                {constLabel?.lbl_email_id
                  ? constLabel?.lbl_email_id
                  : "Email Id"}
              </label>
              <input
                type="email"
                className="form-control "
                id="emailid"
                name={`items[${index}].emailid`}
                placeholder="Enter email id"
                defaultValue={
                  props.main_obj.party_contact &&
                  props.main_obj.party_contact.length > index &&
                  props.main_obj.party_contact[index].emailid !== null &&
                  props.main_obj.party_contact[index].emailid.length > 0
                    ? props.main_obj.party_contact[index].emailid
                    : ""
                }
                {...register(`items[${index}].emailid`)}
              />
              <span className="text-danger err-msg">
                {errors.items?.[index]?.emailid?.message}
              </span>
            </div>
          </div>

          <div className="col-12 col-md-3 mt-3">
            <div className="form-group mt-md-0 mt-3">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label roboto-bold"
              >
                {constLabel?.lbl_designation
                  ? constLabel.lbl_designation
                  : "Designation"}
              </label>
              <input
                type="text"
                className="form-control "
                id="designation"
                name={`items[${index}].designation`}
                defaultValue={
                  props.main_obj.party_contact &&
                  props.main_obj.party_contact.length > index &&
                  props.main_obj.party_contact[index].designation !== null &&
                  props.main_obj.party_contact[index].designation.length > 0
                    ? props.main_obj.party_contact[index].designation
                    : ""
                }
                placeholder="Enter designation"
                {...register(`items[${index}].designation`)}
              />
            </div>
          </div>

          <div className="col-12 col-md-3 mt-3">
            <div className="form-group mt-md-0 mt-3">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label roboto-bold"
              >
                {constLabel?.lbl_additional_information
                  ? constLabel?.lbl_additional_information
                  : "Additional Information"}
              </label>
              <textarea
                type="text"
                className="form-control "
                id="remark"
                name={`items[${index}].remark`}
                placeholder="Enter additional information"
                defaultValue={
                  props.main_obj.party_contact &&
                  props.main_obj.party_contact.length > index &&
                  props.main_obj.party_contact[index].remark !== null &&
                  props.main_obj.party_contact[index].remark.length > 0
                    ? props.main_obj.party_contact[index].remark
                    : ""
                }
                {...register(`items[${index}].remark`)}
              ></textarea>
            </div>
          </div>

          {index > 0 ? (
            <div className="mt-3">
              <a
                href="#"
                className={
                  index > 0
                    ? "btn btn-default border roboto-bold"
                    : "cursor_banned btn btn-default border roboto-bold"
                }
                onClick={
                  index > 0
                    ? () => {
                        remove(index);

                        if (
                          props.main_obj.party_contact[index] &&
                          props.main_obj.party_contact[index].uuid
                        ) {
                          delete_party_contact({
                            uuid: props.main_obj.party_contact[index].uuid,
                          }).then(
                            (res) => {
                              toast.success(
                                "Customer contact deleted successfully !",
                                {
                                  autoClose: 1000,
                                }
                              );
                            },
                            (err) => {}
                          );
                        }
                      }
                    : null
                }
              >
                <FontAwesomeIcon
                  style={{ color: "#000", border: "1px" }}
                  icon={faMinus}
                  size="sm"
                />{" "}
                {constLabel?.lbl_remove ? constLabel.lbl_remove : "Remove"}
              </a>
            </div>
          ) : null}
        </div>
      ))}
      {/* <div className="row justify-content-between mt-2">
        <div className="col-md-12 roboto-bold">
          <span>
            <i style={{ color: "red" }}>*</i>
            <u> indicate Mandatory fields</u>
          </span>
        </div>
      </div> */}
      <hr className="my-3 mx-2" style={{ width: "98%" }} />
      <a
        href="#"
        className="btn btn-yellow roboto-bold"
        onClick={() => append({})}
      >
        <FontAwesomeIcon style={{ color: "#000" }} icon={faPlus} size="sm" />
        {constLabel?.lbl_contact ? constLabel?.lbl_contact : "Contact"}
      </a>

      <hr className="my-3 mx-2" style={{ width: "98%" }} />
      <div className="d-md-flex justify-content-end">
        <button
          className="btn btn-grey px-4 me-2"
          onClick={() => props.previousStep()}
        >
          {constLabel?.lbl_previous ? constLabel?.lbl_previous : "Previous"}
        </button>
        <button
          className="btn button-primary px-4 me-2"
          onClick={handleSubmit(onSubmit)}
        >
          {constLabel?.lbl_save_continue
            ? constLabel?.lbl_save_continue
            : "Save & Continue"}
        </button>
        <button
          className="btn btn-grey px-4 me-2"
          onClick={() => props.nextStep()}
        >
          {constLabel?.lbl_next ? constLabel?.lbl_next : "Next"}
        </button>
      </div>
    </>
  );
}

export default NewCreateContactDetails;
