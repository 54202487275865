import React, { useState, useEffect } from "react";
import CreateProduct from "./CreateProduct";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert2";
import {
  faTable,
  faDownload,
  faSearch,
  faFilter,
  faPlus,
  faRefresh,
  faBan,
  faCog,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import { getProdList, deleteProduct, getProductType, productExcel } from "../../../api/index";
import RawMaterialList from "./RawMaterialList";
import ToolList from "./ToolList";
import Guage from "./Guage";
import SFG from "./SFG";
import ForginList from "./ForginList";
import SemiFinishList from "./SemiFinishList";
import {
  calMaxPage,
  calMaxPage_new,
  getLabels,
} from "../../../common/Functions/CommonFunctions";
import { Pagination, Spinner } from "react-bootstrap";
import { Eye, Trash2, Edit2, Copy, Edit } from "react-feather";
import { Modal } from "react-bootstrap";
import NavbarComp2 from "../../../Navbar/NavbarComp2";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import { Product_Col_Name } from "../../../common/Labels/labelConstant";
import ExcelJS from "exceljs";
import List from "../../../assets/images/icons/project_menu/checklist.png"
import Slide from "../../../assets/images/icons/project_menu/collapse.png"

function Product() {
  const [selOption, setSelOption] = useState("Product");
  const [selProd, setSelProd] = useState({});
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [privilage, setPrivilage] = useState({});

  const [lockBtn, setLockBtn] = useState(false);

  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState({ value: "", status: false });
  const [filterConfig, setFilterConfig] = useState({
    productTypename: [],
    // productName: [],
    // partNo: [],
    search_val: "",
  });
  const [reset, setReset] = useState(false);
  const [prodType, setProdType] = useState({ data: [], loading: false });
  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const product_types = ["Manufactured Goods", "Raw material", "Semi Finish Goods", "Tools", "Fixtures", "Gauges"];
  const [showLabel, setShowLabel] = useState(Product_Col_Name);
  const [constLabel, setConstLabel] = useState({});

  let navigate = useNavigate();

  useEffect(() => {
    // fetchProdList(page, entriesPerPage);

    localStorage.removeItem("prod_res");

    setConstLabel(getLabels("Quality", "Product"));
    fetchProductType();
    let isUserAdmin;

    // storing modules access got in login in state

    if (
      loginData.role_name.trim() == "admin" ||
      loginData.role_name.trim() == "client_admin"
    ) {
      isUserAdmin = true;
    } else {
      isUserAdmin = false;
    }

    if (isUserAdmin) {
      setPrivilage({
        read: true,
        write: true,
        view: true,
        delete: true,
        report: true,
      });
    
    } else {
      for (let i = 0; i < loginData.module_list.length; i++) {
        if (loginData.module_list[i].module == "Product") {
          setPrivilage(loginData.module_list[i]);
        } else {
          for (let i = 0; i < loginData.module_list.length; i++) {
            if (loginData.module_list[i].module == "Product") {
              setPrivilage(loginData.module_list[i]);
            } else {
              if (loginData.module_list[i].screens !== undefined) {
                for (
                  let j = 0;
                  j < loginData.module_list[i].screens.length;
                  j++
                ) {
                  if (
                    loginData.module_list[i].screens[j].screen_name == "Product"
                  ) {
                    setPrivilage(loginData.module_list[i]);
                  }
                }
              }
            }
          }
        }
      }
    }
  }, []);

  function fetchProductType() {
    setProdType({ ...prodType, data: [], loading: true });
    getProductType().then(
      (res) => {
        const result = res.data.data.map((item) => {
          if (item.productTypename !== null && item.productTypename !== "") {
            return item;
          }
        });

        setProdType({ ...prodType, data: result, loading: false });
      },
      (err) => {
        setProdType({ ...prodType, data: [], loading: false });
      }
    );
  }

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchProdList(1, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function fetchProdList(pg, epp) {
    setDataList({ ...dataList, loading: true, data: [] });

    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
    };

    if (filterConfig.search_val != "") {
      // payload.searchVal = filterConfig.search_val;
      payload.searchVal = filterConfig.search_val.trim(); //Search by product name / part no

      // setSrNo(0)
    } else {
      delete payload.searchVal;
    }

    if (filterConfig.productTypename.length > 0) {
      payload.product_type_id_list = JSON.stringify(
        filterConfig.productTypename.map((item) => item.uuid)
      );
      // setSrNo(0)
    } else {
      delete payload.product_type_id_list;
    }

    getProdList(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });
        setMaxPage(calMaxPage_new(res.data.total, epp));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({
          ...dataList,
          loading: false,
          data: [],
        });
      }
    );
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function delProduct(val) {
    SweetAlert.fire({
      title: "Are you sure to delete product ? \n",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = { uuid: val.uuid };
        deleteProduct(payload).then(
          (res) => {
            toast.success("Product deleted successfully", {
              autoClose: 2000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchProdList(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchProdList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  function clearFilter() {
    setFilterConfig({
      ...filterConfig,
      productTypename: [],
      // productName: [],
      // partNo: [],
      search_val: "",
    });
    setSrNo(0);
    setPage(1);
    fetchProductType();
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  async function goToDownload() {
    setLockBtn(true);

    const payload = {};

    if (filterConfig.search_val != "") {
      // payload.searchVal = filterConfig.search_val;
      payload.searchVal = filterConfig.search_val.trim(); //Search by product name / part no

      // setSrNo(0)
    } else {
      delete payload.searchVal;
    }

    if (filterConfig.productTypename.length > 0) {
      payload.product_type_id_list = JSON.stringify(
        filterConfig.productTypename.map((item) => item.uuid)
      );
      // setSrNo(0)
    } else {
      delete payload.product_type_id_list;
    }

    await productExcel(payload).then(
      (res) => {
        //
        if (res) {
          // excel_download(res.data.data);
          base64ToExcel(res.data);

        }
      },
      (err) => {
        toast.error(err.message, {
          autoClose: 2000,
        });
      }
    );
  }

  const excel_download = async (excel_data) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    const keyTitleMap = [
      "Sr No",
      "Product Id",
      "Product Type",
      "Part No",
      "Category",
      "Name",
      "Drawingno",
      "OEMnumber",
      "Party",
      "Remark",
      "Machine Cost",
      "Product Family",
      "Sub Category",
      "Sales Price",
      "Material",
      "Diameter in mm",
      "Cut Length in mm",
      "Cut Weight",
      "Wt. per kg  per Mtr",
      "Wt. Per Piece",
      "Finish Length",
      "CNC Weight",
      "HT Weight",
      "Finish Weight",
      "C/S Non Regular",
    ];

    const flattenedData = excel_data.map((item, index) => {
      return [
        index + 1,
        item.productId == "" || item.productId == null ? "NA" : item.productId,
        item.product_type_id?.productType == "" ||
        item.product_type_id?.productType == null
          ? "NA"
          : item.product_type_id?.productType,
        item.partNo == "" || item.partNo == null ? "NA" : item.partNo,
        item.category == "" || item.category == null ? "NA" : item.category,
        item.productName == "" || item.productName == null
          ? "NA"
          : item.productName,
        item.drawingno == "" || item.drawingno == null ? "NA" : item.drawingno,
        item.OEMnumber == "" || item.OEMnumber == null ? "NA" : item.OEMnumber,
        item.partyName == "" || item.partyName == null ? "NA" : item.partyName,
        item.remark == "" || item.remark == null ? "NA" : item.remark,
        item.machineCost == "" || item.machineCost == null
          ? "NA"
          : item.machineCost,
        item.prod_family_id?.familyname == "" ||
        item.prod_family_id?.familyname == null
          ? "NA"
          : item.prod_family_id?.familyname,
        item.subcategory == "" || item.subcategory == null
          ? "NA"
          : item.subcategory,
        item.salesPrice == "" || item.salesPrice == null
          ? "NA"
          : item.salesPrice,
        item.material == "" || item.material == null ? "NA" : item.material,
        item.diameter == "" || item.diameter == null ? "NA" : item.diameter,
        item.cutlength == "" || item.cutlength == null ? "NA" : item.cutlength,
        item.cutweight == "" || item.cutweight == null ? "NA" : item.cutweight,
        item.perKgWeight == "" || item.perKgWeight == null
          ? "NA"
          : item.perKgWeight,
        item.perPieceWeight == "" || item.perPieceWeight == null
          ? "NA"
          : item.perPieceWeight,
        item.finishLenght == "" || item.finishLenght == null
          ? "NA"
          : item.finishLenght,
        item.cncweight == "" || item.cncweight == null ? "NA" : item.cncweight,
        item.htweight == "" || item.htweight == null ? "NA" : item.htweight,
        item.finishweight == "" || item.finishweight == null
          ? "NA"
          : item.finishweight,
        item.isUsed == "" || item.isUsed == null ? "NA" : item.isUsed,
      ];
    });

    const data = [keyTitleMap, ...flattenedData];
    //

    // Add data to the worksheet
    worksheet.addRows(data);

    // Adjust cell width based on string length
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const length = cell.value ? String(cell.value).length : 10;
        if (length > maxLength) {
          maxLength = length;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2; // Set a minimum width
    });

    // Set background color for the first row
    worksheet.getRow(1).eachCell({ includeEmpty: true }, (cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "3081D0" }, // Yellow background color
      };
      cell.font = {
        color: { argb: "ffffff" },
      };
    });

    // Save the workbook to a file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);

    // Create a link and trigger the download
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toDateString().split(" ").join("_");
    link.download = `ProductReport_${currentDate}.xlsx`;
    document.body.appendChild(link);
    link.click();

    setLockBtn(false);

    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  function base64ToExcel(response) {
    const { statusCode, headers, body, isBase64Encoded } = response;

    // Assume base64String contains the base64 representation of the Excel file
    const base64String = body; // Replace this with your base64 string

    // Convert the base64 string into a Uint8Array
    const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));

    // Create a Blob object from the Uint8Array
    const blob = new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a link element with download attribute
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toDateString().split(" ").join("_");
    link.download = `ProductReport_${currentDate}.xlsx`;

    // Programmatically click on the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Cleanup: remove the link and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    setLockBtn(false);
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="min-vh-100" id="root_div_main">
        {/* <NavbarComp2 /> */}

        <div id="mySidebar" className="customsidebar">
          <a className="closebtn" onClick={() => closeNav()}>
            ×
          </a>

          <div className=" content">
            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selProductType
                  ? constLabel.lbl_selProductType
                  : "Select Product Type"}
              </label>
              <Multiselect
                options={prodType.data}
                onSelect={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, productTypename: val });
                  } else {
                    setFilterConfig({ ...filterConfig, productTypename: [] });
                  }
                }}
                onRemove={(e) => {
                  setFilterConfig({ ...filterConfig, productTypename: e });
                }}
                selectedValues={filterConfig.productTypename}
                displayValue="productType"
                placeholder="Select product type"
                loading={prodType.loading}
                customCloseIcon={
                  <p className="ms-2 mb-0 p-0" style={{ fontStyle: "normal" }}>
                    x
                  </p>
                }
              />
            </div>

            <div className="mt-2">
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_product_name
                  ? constLabel?.lbl_product_name
                  : "Product Name"}{" "}
                /{" "}
                {constLabel?.lbl_part_no ? constLabel?.lbl_part_no : "Part No"}
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by product name / part no"
                // value={filterConfig.search_val != ""  ? filterConfig.search_val : ""}
                value={filterConfig.search_val}
                onChange={(val) => {
                  const inputValue = val.target.value;
                  if (!inputValue.trim()) {
                    // Input contains only whitespace, don't trigger the search.
                    if (filterConfig.search_val.length == 1) {
                      setFilterConfig({ ...filterConfig, search_val: "" });
                    }
                  } else {
                    setFilterConfig({
                      ...filterConfig,
                      search_val: inputValue,
                    });
                  }
                }}
              />
            </div>

            <div className="my-3 d-flex justify-content-end">
              <button
                onClick={() => clearFilter()}
                className="btn btn-sm btn-yellow "
              >
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {constLabel?.lbl_clear_filter
                  ? constLabel?.lbl_clear_filter
                  : "Clear Filter"}
              </button>
            </div>
          </div>
        </div>

          <div className="content-wrapper">
            <div className="card border-0">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="d-md-flex justify-content-start col-12 col-md-6">
                    {/* <FontAwesomeIcon
                      onClick={() => toggleNav()}
                      className="cursor_pointer mb-2"
                      style={{
                        color: "Black",
                        marginRight: "10px",
                      }}
                      icon={faBars}
                      size="lg"
                    /> */}
                    <Tooltip id={"open-tooltip"} place="top" />
                    <img src={Slide} height='32' width='32' className="cursor_pointer my-1 me-2" onClick={() => toggleNav()}
                          data-tooltip-id={"open-tooltip"}
                          data-tooltip-content={"Sidebar"}
                    />
                    <h1 className="bold">{selOption}</h1>
                  </div>
                  <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">

                    {loginData.loginData?.data?.response.role_name !==
                      "client_admin" &&
                    loginData.loginData?.data?.response.role_name !== "admin" ? (
                      privilage.report == true ? (
                        <button
                          className="btn btn-light-grey me-0 me-2 ms-0 ms-md-2"
                          // onClick={() => downloadExcel()}
                          disabled={lockBtn}
                          onClick={() => goToDownload()}
                        >
                          <FontAwesomeIcon
                            style={{ color: "#fff" }}
                            icon={faDownload}
                            size="sm"
                          />{" "}
                          {constLabel?.lbl_product_report
                            ? constLabel.lbl_product_report
                            : "Product Report"}{" "}
                          {lockBtn ? (
                            <Spinner animation="border" size="sm" />
                          ) : null}{" "}
                        </button>
                      ) : null
                    ) : (
                      <button
                        className="btn btn-light-grey me-0 me-2 ms-0 ms-md-2"
                        // onClick={() => downloadExcel()}
                        disabled={lockBtn}
                        onClick={() => goToDownload()}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faDownload}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_product_report
                          ? constLabel.lbl_product_report
                          : "Product Report"}{" "}
                        {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
                      </button>
                    )}
                    
                    {privilage.write ? (
                      <button
                        className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                        onClick={() => navigate(`/pcms/productConfigurator`)}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_add_product
                          ? constLabel?.lbl_add_product
                          : "Add Product"}
                      </button>
                    ) : null}

                    <Tooltip id={"show-tooltip"} place="top" />
                    <div
                      className="dropdown"
                      data-tooltip-id={"show-tooltip"}
                      data-tooltip-content={"Show / Hide Columns"}
                    >
                      <div
                        className="d-md-flex justify-content-start align-items-center mt-md-1"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <FontAwesomeIcon icon={faCog} size="lg" /> */}
                        {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                        <img src={List} height='32' width='32' className="cursor_pointer" />
                      </div>

                      <ul className="dropdown-menu px-2">
                        {showLabel !== undefined && showLabel.length > 0
                          ? showLabel.map((val, ind, arr) => (
                              <li key={ind}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    style={{ width: "20px", height: "20px" }}
                                    onChange={() => handleClick(ind)}
                                    value=""
                                    checked={val.flag}
                                  />
                                  <label
                                    className=""
                                    style={{ fontSize: "13px" }}
                                    htmlFor="flexCheckDefault"
                                  >
                                    {val.label}
                                  </label>
                                </div>
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>

                {privilage.read ? (
                  selOption == "Product" ? (
                    <>
                      {dataList.loading ? (
                        <div className="text-center">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : dataList && dataList.data?.length > 0 ? (
                        <>
                          <div className="table-responsive">
                            <table className="table mt-2 table-bordered">
                              <thead className="table-grey  roboto-bold">
                                <tr className="">
                                  <th scope="col">{"Sr.No"}</th>
                                  {showLabel.map((v, i) =>
                                    v.label === "Product Name" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"Product Name"}
                                      </th>
                                    ) : v.label === "OEM Part No" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"OEM Part No"}
                                      </th>
                                    ) : v.label === "Part No" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"Part No"}
                                      </th>
                                    ) : v.label === "Product Type" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"Product Type"}
                                      </th>
                                    ) : v.label === "Product Family" &&
                                      v.flag ? (
                                      <th key={i} scope="col">
                                        {"Product Family"}
                                      </th>
                                    ) : v.label === "Product Category" &&
                                      v.flag ? (
                                      <th key={i} scope="col">
                                        {"Product Category"}
                                      </th>
                                    ) : v.label === "HSN" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"HSN"}
                                      </th>
                                    ) : v.label === "Has BOM" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"Has BOM"}
                                      </th>
                                    ) : v.label === "Can Be Expensed" &&
                                      v.flag ? (
                                      <th key={i} scope="col">
                                        {"Can Be Expensed"}
                                      </th>
                                    ) : v.label === "Can Be Purchased" &&
                                      v.flag ? (
                                      <th key={i} scope="col">
                                        {"Can Be Purchased"}
                                      </th>
                                    ) : v.label === "Can Be Sold" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"Can Be Sold"}
                                      </th>
                                    ) : v.label === "Material" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"Material"}
                                      </th>
                                    ) : v.label === "Sales Price" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"Sales Price"}
                                      </th>
                                    ) : v.label === "Selling Price" &&
                                      v.flag ? (
                                      <th key={i} scope="col">
                                        {"Selling Price"}
                                      </th>
                                    ) : v.label === "Purchase Price" &&
                                      v.flag ? (
                                      <th key={i} scope="col">
                                        {"Purchase Price"}
                                      </th>
                                    ) : v.label === "Remark" && v.flag ? (
                                      <th key={i} scope="col">
                                        {"Remark"}
                                      </th>
                                    ) : null
                                  )}
                                  <th scope="col" width="15%">
                                    {"Action"}
                                  </th>
                                </tr>
                              </thead>
                              <tbody className=" roboto">
                                {dataList.data.map((val, i) => (
                                  <tr key={i}>
                                    <td>{parseInt(i) + 1 + parseInt(srNo)}</td>
                                    {showLabel.map((item, idx) =>
                                      item.label === "Product Name" &&
                                      item.flag ? (
                                        <td key={idx}>{val.productName}</td>
                                      ) : item.label === "OEM Part No" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.OEMnumber != "" &&
                                          val.OEMnumber != null
                                            ? val.OEMnumber
                                            : "NA"}
                                        </td>
                                      ) : item.label === "Part No" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.partNo != "" &&
                                          val.partNo != null
                                            ? val.partNo
                                            : "NA"}
                                        </td>
                                      ) : item.label === "Product Type" &&
                                        item.flag ? (
                                        <td key={idx}>{val.productTypename}</td>
                                      ) : item.label === "Product Family" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.prod_family_id != null
                                            ? val.prod_family_id.familyname
                                            : "NA"}
                                        </td>
                                      ) : item.label === "Product Category" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.category !== null &&
                                          val.category !== undefined &&
                                          val.category.trim().length > 0
                                            ? val.category
                                            : "NA"}
                                        </td>
                                      ) : item.label === "HSN" && item.flag ? (
                                        <td key={idx}>
                                          {val.hsn != "" && val.hsn != null
                                            ? val.hsn
                                            : "NA"}
                                        </td>
                                      ) : item.label === "Has BOM" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.has_bom ? "True" : "False"}
                                        </td>
                                      ) : item.label === "Can Be Expensed" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.can_be_expensed
                                            ? "True"
                                            : "False"}
                                        </td>
                                      ) : item.label === "Can Be Purchased" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.can_be_purchased
                                            ? "True"
                                            : "False"}
                                        </td>
                                      ) : item.label === "Can Be Sold" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.can_be_sold ? "True" : "False"}
                                        </td>
                                      ) : item.label === "Material" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.material != "" &&
                                          val.material != null
                                            ? val.material
                                            : "NA"}
                                        </td>
                                      ) : item.label === "Sales Price" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.salesPrice != "" &&
                                          val.salesPrice != null
                                            ? val.salesPrice
                                            : "NA"}
                                        </td>
                                      ) : item.label === "Selling Price" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.selling_price != "" &&
                                          val.selling_price != null
                                            ? val.selling_price
                                            : "NA"}
                                        </td>
                                      ) : item.label === "Purchase Price" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.purchase_price != "" &&
                                          val.purchase_price != null
                                            ? val.purchase_price
                                            : "NA"}
                                        </td>
                                      ) : item.label === "Remark" &&
                                        item.flag ? (
                                        <td key={idx}>
                                          {val.remark != "" &&
                                          val.remark != null
                                            ? val.remark
                                            : "NA"}
                                        </td>
                                      ) : null
                                    )}

                                    <td className="">

                                      {privilage.write ? (
                                        <>
                                          <Tooltip id={"edit-tooltip" + i} place="top" />
                                          <Edit 
                                            data-tooltip-id={"edit-tooltip" + i}
                                            data-tooltip-content={
                                              constLabel?.lbl_edit_product
                                                ? constLabel.lbl_edit_product
                                                : "Edit Product"
                                            }
                                            className="menu_icon_grey cursor_pointer me-2"
                                            size={20} 
                                            onClick={() =>
                                              navigate(
                                                // "/pcms/editProductConfigure",
                                                "/pcms/editProductConfigurator",
                                                {
                                                  state: val,
                                                }
                                              )
                                            }
                                          />
                                        </>
                                      ) : null}

                                      {privilage.read ? (
                                        <>
                                          <Tooltip id={"view-tooltip" + i} place="top"/>
                                          <Eye  
                                            data-tooltip-id={"view-tooltip" + i}
                                            data-tooltip-content={
                                              constLabel?.lbl_view_product
                                                ? constLabel.lbl_view_product
                                                : "View Product"
                                            }
                                            className="menu_icon_grey cursor_pointer me-2"
                                            size={20} 
                                            onClick={() => {
                                              if (
                                                val.productTypename ===
                                                "Manufactured Goods"
                                              ) {
                                                navigate("/pcms/viewProduct", {
                                                  state: val,
                                                });
                                              }
  
                                              if (
                                                val.productTypename ===
                                                "Raw material"
                                              ) {
                                                navigate(
                                                  "/pcms/viewRawMaterial",
                                                  {
                                                    state: val,
                                                  }
                                                );
                                              }
  
                                              if (
                                                val.productTypename ===
                                                "Semi Finish Goods"
                                              ) {
                                                navigate(
                                                  "/pcms/viewSemiFinishGoods",
                                                  {
                                                    state: val,
                                                  }
                                                );
                                              }
                                              if (
                                                val.productTypename ===
                                                "Tools" ||val.productTypename==="Fixtures"||
                                                val.productTypename==="Gauges"
                                              ) {
                                                navigate(
                                                  "/pcms/viewToolsFixture",
                                                  {
                                                    state: val,
                                                  }
                                                );
                                              }
  
                                              if (!product_types.includes(val.productTypename)){
                                                navigate("/pcms/viewGeneralItem", { state: val });
                                              }
  
                                            }}
                                          />
                                        </>
                                      ) : null}

                                      {privilage.write && (val.productTypename === "Manufactured Goods" || val.productTypename === "Raw material" || val.productTypename === "Semi Finish Goods") ? (
                                        <>
                                          <Tooltip id={"clone-tooltip" + i} place="top" />
                                            <Copy
                                              data-tooltip-id={"clone-tooltip" + i}
                                              data-tooltip-content={
                                                constLabel?.lbl_clone_product
                                                  ? constLabel.lbl_clone_product
                                                  : "Clone Product"
                                              }
                                              className="menu_icon_grey cursor_pointer me-2"
                                              size={20} 
                                              onClick={() =>
                                                navigate(
                                                  // "/pcms/ProductConfigure/clone",
                                                  "/pcms/cloneProductConfigurator",
                                                  {
                                                    state: val,
                                                  }
                                                )
                                              }
                                            />
                                        </>
                                      ): null}

                                      {privilage.delete && (
                                        <>
                                          <Tooltip id={"delete-tooltip" + i} place="top" />
                                            <Trash2
                                              data-tooltip-id={"delete-tooltip" + i}
                                              data-tooltip-content={
                                                constLabel?.lbl_delete_product
                                                  ? constLabel.lbl_delete_product
                                                  : "Delete Product"
                                              }
                                              className="menu_icon_red cursor_pointer"
                                              size={20} 
                                              onClick={() => delProduct(val)}
                                            />
                                        </>
                                      )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>

                          <div className="d-flex justify-content-between align-items-center mt-2">
                            <p className="mb-0">
                              Show
                              <select
                                className="mx-1"
                                defaultValue={entriesPerPage}
                                onChange={(e) => {
                                  setEntriesPerPage(e.target.value);
                                  fetchProdList(1, e.target.value);
                                }}
                              >
                                <option value={"10"}>10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                              Entries
                            </p>
                            <p className="mb-0">{`Showing ${
                              parseInt(srNo) + 1
                            } to ${Math.min(
                              parseInt(entriesPerPage) + parseInt(srNo),
                              dataList.totalRecords
                            )} of ${dataList.totalRecords} entries`}</p>
                            <Pagination className="my-2">
                              <Pagination.Prev
                                disabled={page === 1 ? true : false}
                                onClick={() => {
                                  fetchProdList(
                                    page - 1,
                                    entriesPerPage,
                                    parseInt(srNo) - parseInt(entriesPerPage)
                                  );
                                  setSrNo((prevC) =>
                                    page - 1 == 1
                                      ? 0
                                      : prevC - parseInt(entriesPerPage)
                                  );
                                  setPage(page - 1);
                                }}
                              >
                                {"Prev"}
                              </Pagination.Prev>

                              <Pagination.Item active>{page}</Pagination.Item>

                              <Pagination.Next
                                disabled={
                                  page === maxPage ||
                                  maxPage === 0 ||
                                  entriesPerPage > dataList.data.length
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  fetchProdList(
                                    page + 1,
                                    entriesPerPage,
                                    parseInt(srNo) + parseInt(entriesPerPage)
                                  );
                                  setSrNo(
                                    (prevC) => prevC + parseInt(entriesPerPage)
                                  );
                                  setPage(page + 1);
                                }}
                              >
                                {"Next"}
                              </Pagination.Next>
                            </Pagination>
                          </div>
                        </>
                      ) : (
                        <div
                          className="alert alert-danger mb-0 text-center my-4"
                          role="alert"
                        >
                          {"No Product Records Found !"}
                        </div>
                      )}
                    </>
                  ) : null
                ) : null}

                {selOption == "Raw Material" ? <RawMaterialList /> : null}
                {selOption == "Tool" ? <ToolList /> : null}
                {selOption == "Gauge" ? (
                  <Guage search={search} reset={reset} />
                ) : null}
                {selOption == "SFG" ? (
                  <SFG search={search} reset={reset} />
                ) : null}
                {selOption == "Forgin" ? (
                  <ForginList search={search} reset={reset} />
                ) : null}
                {selOption == "Semi-Finish" ? (
                  <SemiFinishList search={search} reset={reset} />
                ) : null}
              </div>
            </div>
          </div>

        <Modal
          show={visible}
          onHide={() => setVisible(false)}
          size="lg"
          backdrop="static"
        >
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>Party Name & Part Number Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid">
              <div className="table-responsive">
                <table className="table mt-2 table-bordered table-sm">
                  <thead className="table-light text-center roboto-bold">
                    <tr className="">
                      <th scope="col">{"Name"}</th>
                      <th scope="col">{"Part No"}</th>
                    </tr>
                  </thead>
                  <tbody className=" roboto">
                    <tr>
                      <td>
                        {selProd?.partyList !== undefined
                          ? selProd?.partyList[0]?.organization
                          : null}
                      </td>
                      <td>{selProd?.partNo}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-grey px-4"
              onClick={() => setVisible(false)}
            >
              {constLabel?.lbl_close ? constLabel.lbl_close : "Close"}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default Product;
