import { tool_grade } from "../../constant";

export const screenList = {
    nc_screen : "NC",
    quality_dashbaord_screen : "Quality Dashboard",
    product_screen : "Product",
    generic_screen : "Generic",
    customer_type_screen : "Customer Type",
    customer_screen : "Customer",
    supplier_type_screen : "Supplier Type",
    supplier_screen : "Supplier",
    operation_category_screen : "Operation Category",
    operation_screen : "Operation",
    machine_category_screen :  "Machine Category",
    machine_screen :  "Machine",
    task_category_screen : "Task Category",
    task_screen : "Task",
    location_type_screen : "Location Type",
    location_screen : "Location",
    location_mapping_screen : "Location Mapping",
    store_type_screen : "Store Type",
    store_screen : "Store",
    inventory_screen : "Inventory Management",
    user_screen : "User",
    status_screen : "Status",
    purchase_screen : "Purchase",
    sales_screen : "Sales",
    transaction_screen : "Transaction",
    organization_type_screen : "Organization Type",
    organization_screen : "Organization",
    company_type_screen : "Company Type",
    company_screen : "Company",
    role_screen : "Role",
    supplier_quotation_screen : "Supplier Quotation",
    purchase_order_screen : "Purchase Order",
    purchase_inward_screen : "Purchase Inward",
    sales_order_screen : "Sales Order",
    disposal_action_screen : "Disposal Action",
    nc_reason_screen : "NC Reason",
    modules_screen : "Modules",
    supplier360_screen : "Supplier360",
    customer360_screen : "Customer360",
    movement_screen: "Movement",
    gst_slab_screen: "GST Slab",
    gst_treatment_screen: "GST Registration Type",
    manufacture_screen: "Manufacturing",
    bom_screen: "Bill of Material",
    work_order: "Work Order",
    job_screen:"Job Card",
    production_data_screen: "Production Data",
    document_type: "Document Type",
    employee_master: "Employee Master",
    production_dashboard_screen: "Production Dashboard",
    product_family_screen: "Product Family",
    product_category_screen: "Product Category",
    job_card_board_screen: "Job Card Board",
    tool_type_screen:'Tool Type',
    tool_sub_type_screen:"Tool Sub-Type",
    tool_grade:"Tool Grade",
    rmi_screen: "RMI",
    machine_dashboard_screen: "Machine Dashboard",
    cutlot_screen: "Cutlot",
    challan_type_screen: "Challan Type",
    goods_challan_screen: "Goods Challan",
    goods_challan_work_order_view: "Goods Challan Work Order View",
    inspection_type_screen: "Inspection Type",
    stock_availability_screen: "Stock Availability",
    stock_ledger_screen: "Stock Ledger",
    dispatch_screen: "Dispatch",
    hsn_master_screen: "HSN Master",
    corrective_action_screen: "Corrective Action",
    preventive_action_screen: "Preventive Action",
    material_type_screen: "Material Type",
    setting_screen: "Setting",
    ProductionPlanning: "ProductionPlanning",
    adjustment_reason_screen : "Adjustment Reason",
    material_request_screen : "Material Request",
    routing_master_screen : "Routing Master",
    shift_master_screen : "Shift Master"
}