import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from 'react-router-dom';
import { prod_plan_col_list } from "../../../common/Labels/labelConstant";
import { setAccessPrivilage, formatDate5, getFinancialYearStartDate } from "../../../common/Functions/CommonFunctions";
import { Tooltip } from "react-tooltip";
import Slide from "../../../assets/images/icons/project_menu/collapse.png"
import List from "../../../assets/images/icons/project_menu/checklist.png"
import { Pagination, Spinner, Modal } from "react-bootstrap";
import { Eye, Trash2, Edit } from "react-feather";
import { fetch_production_plan, delete_production_plan } from "../../../api/index"
import { convertDateYYMMDD, calMaxPage, calMaxPage_new, getLabels } from "../../../common/Functions/CommonFunctions";
import SweetAlert from "sweetalert2";
import DatePicker from "react-datepicker";
import { faBan, faPlus, } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { resetState } from '../../../slices/production_planning/production_plan_slice';
import { useSelector, useDispatch } from 'react-redux';

function ProductionPlanning() {
    const [showLabel, setShowLabel] = useState(prod_plan_col_list);
    const [constLabel, setConstLabel] = useState({});
    const dispatch = useDispatch();
    const [dataList, setDataList] = useState({
        data: [],
        loading: true,
        totalRecords: "",
    });
    const [galVisible, setGalVisible] = useState(false);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [srNo, setSrNo] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [startDate, setStartDate] = useState(getFinancialYearStartDate(new Date()));
    const [endDate, setEndDate] = useState(new Date());
    const [filterConfig, setFilterConfig] = useState({
        status: "",
        prod_plan_id: "",
    });

    const loginData = useSelector(
        (state) => state.auth?.loginData?.data?.response
    );
    const [privilage] = useState(setAccessPrivilage(loginData, "Production Planning"));

    let navigate = useNavigate();

    useEffect(() => {
        dispatch(resetState());
        setConstLabel(getLabels('Production', 'Production Planning'))

        const debounceTimeout = setTimeout(() => {
            setPage(1);
            fetchProdPlan(page, entriesPerPage);
        }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

        return () => {
            clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
        };
    }, [filterConfig, startDate, endDate]);


    function getPrvMonthDateFromToday() {
        const today = new Date();
        const endDate = new Date(today);
        endDate.setMonth(today.getMonth() - 1);
        return endDate;
    }

    const handleFromDateChange = (date) => {
        if (endDate && date > endDate) {
            return;
        }
        setStartDate(date);
    };

    const handleToDateChange = (date) => {
        if (startDate && date < startDate) {
            return;
        }
        setEndDate(date);
    };

    function fetchProdPlan(pg, epp) {
        setDataList({ ...dataList, data: [], loading: true });
        const payload = {
            entriesPerPage: epp,
            pageNo: pg,
            startDate: convertDateYYMMDD(new Date(startDate), "-"),
            endDate: convertDateYYMMDD(new Date(endDate), "-"),
            with_line_items: true,
            with_loc: true,
            with_org: true,
        };


        if (filterConfig.status != "") {
            // payload.searchVal = filterConfig.search_val;
            payload.status = filterConfig.status?.trim();

            // setSrNo(0)
        } else {
            delete payload.status;
        }

        if (filterConfig.prod_plan_id != "") {
            payload.prod_plan_id = filterConfig.prod_plan_id?.trim();
        } else {
            delete payload.prod_plan_id;
        }


        console.log("payload", payload);
        fetch_production_plan(payload).then(
            (res) => {

                setDataList({
                    ...dataList,
                    data: res.data.data,
                    loading: false,
                    totalRecords: res.data.total,
                });
                // setMaxPage(calMaxPage_new(parseInt(res.data.total), epp));

                setMaxPage(calMaxPage(res.data.total));
                if (pg == 1) {
                    setSrNo(0);
                    setPage(1);
                }
            },
            (err) => {
                setDataList({ ...dataList, data: [], loading: false });

                // if(err.response.status == 400){
                //   toast.error(err.response.data.message, {
                //     autoClose: 2000,
                //   });
                //   setPriceRange({from: 0, to: 100});
                // }
            }
        );
    }

    function clearFilter() {
        let obj = {
            status: "",
            prod_plan_id: "",
        };
        setFilterConfig({ ...obj });
        setStartDate(getFinancialYearStartDate(new Date()));
        setEndDate(new Date());
    }

    function toggleNav() {
        const sidebar = document.getElementById("mySidebar");
        const mainDiv = document.getElementById("root_div_main");

        if (sidebar.style.width === "320px") {
            // Sidebar is open, so close it
            sidebar.style.width = "0";
            mainDiv.style.marginLeft = "0";
            sidebar.style.overflowX = "hidden";
        } else {
            // Sidebar is closed, so open it
            sidebar.style.width = "320px";
            mainDiv.style.marginLeft = "320px";
            setTimeout(() => {
                sidebar.style.overflowX = "visible";
            }, 200);
        }
    }

    function handleClick(ind) {
        setShowLabel((showLabel) => {
            const updatedCardShow = showLabel.map((val, i) => {
                if (ind === i) {
                    // Toggle the flag for the clicked checkbox
                    return { ...val, flag: !val.flag };
                }
                return val;
            });

            return updatedCardShow;
        });
    }

    const delProdPlan = (val) => {
        // console.log("val",val);
        SweetAlert.fire({
            title: "Are you sure to delete ?",
            // text: "Once deleted, you will not be able to recover this record !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ok",
            cancelButtonText: "Cancel",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                delete_production_plan({ uuid: val.uuid }).then(
                    (res) => {

                        toast.success("Production plan deleted successfully", {
                            autoClose: 2000,
                        });
                        if (dataList.data.length == 1 && page > 1) {
                            fetchProdPlan(page - 1, entriesPerPage);
                        } else {
                            fetchProdPlan(page, entriesPerPage);
                        }
                    },
                    (err) => {

                        toast.error(err.response.data.message, {
                            autoClose: 2000,
                        });
                    }
                );
            }
        });
    };

    return (
        <React.Fragment>
            <ToastContainer />
            {privilage.read && (
                <div id="mySidebar" className="customsidebar ">
                    <a className="closebtn" onClick={() => toggleNav()}>
                        ×
                    </a>

                    <div className=" content">

                        <div className="filter row">
                            <label
                                className="filterLabel mb-2 roboto-bold"
                                style={{ fontSize: "14px" }}
                            >
                                {constLabel?.lbl_selectDateRange
                                    ? constLabel.lbl_selectDateRange
                                    : "Select Date Range"}
                            </label>
                            <div className="fromDate col-12 col-md-6">
                                <DatePicker
                                    id="fromDate"
                                    className="form-control form-control-sm"
                                    selected={startDate}
                                    showYearDropdown
                                    scrollableMonthYearDropdown
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="From Date"
                                    // onChange={(date) => setStartDate(date)}
                                    onChange={(date) =>
                                        handleFromDateChange(date)
                                    }
                                    maxDate={new Date()}
                                />
                            </div>

                            <div className="toDate col-12 col-md-6">
                                <DatePicker
                                    id="toDate"
                                    className="form-control form-control-sm"
                                    showYearDropdown
                                    scrollableMonthYearDropdown
                                    selected={endDate}
                                    dateFormat="dd/MM/yyyy"
                                    placeholderText="To Date"
                                    // onChange={(date) => setEndDate(date)}
                                    onChange={(date) =>
                                        handleToDateChange(date)
                                    }
                                    maxDate={new Date()}
                                />
                            </div>
                        </div>


                        <div className="mt-2">
                            <label
                                className="filterLabel mb-1 roboto-bold"
                                style={{ fontSize: "14px" }}
                            >
                                {constLabel?.lbl_status
                                    ? constLabel?.lbl_status
                                    : "Status"}
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Search by status"
                                // value={filterConfig.work_order_status != ""  ? filterConfig.work_order_status : ""}
                                value={filterConfig.status}
                                onChange={(val) => {
                                    const inputValue = val.target.value;
                                    if (!inputValue.trim()) {

                                        // Input contains only whitespace, don't trigger the search.
                                        if (filterConfig.status.length == 1) {
                                            setFilterConfig({ ...filterConfig, status: "" });
                                        }
                                        if (inputValue.length == 0) {
                                            setFilterConfig({ ...filterConfig, status: "" });
                                        }
                                    } else {

                                        setFilterConfig({
                                            ...filterConfig,
                                            status: inputValue,
                                        });
                                    }
                                }}
                            />
                        </div>

                        <div className="mt-2">
                            <label
                                className="filterLabel mb-1 roboto-bold"
                                style={{ fontSize: "14px" }}
                            >
                                {constLabel?.lbl_prod_plan_id
                                    ? constLabel?.lbl_prod_plan_id
                                    : "Prod Plan ID"}
                            </label>
                            <input
                                className="form-control"
                                type="text"
                                placeholder="Search By ID"
                                value={filterConfig.prod_plan_id}
                                onChange={(val) => {
                                    const inputValue = val.target.value;
                                    if (!inputValue.trim()) {
                                        // Input contains only whitespace, don't trigger the search.
                                        if (filterConfig.prod_plan_id.length == 1) {
                                            setFilterConfig({ ...filterConfig, prod_plan_id: "" });
                                        }

                                        if (inputValue.length == 0) {
                                            setFilterConfig({ ...filterConfig, prod_plan_id: "" });
                                        }
                                    } else {
                                        setFilterConfig({
                                            ...filterConfig, prod_plan_id: inputValue,
                                        });
                                    }
                                }}
                            />
                        </div>


                        <div className="my-3 d-flex justify-content-end">
                            <button
                                onClick={() => clearFilter()}
                                className="btn btn-sm btn-yellow "
                            >
                                <FontAwesomeIcon
                                    style={{ color: "#344454" }}
                                    icon={faBan}
                                    size="sm"
                                />{" "}
                                {constLabel?.lbl_clear_filter
                                    ? constLabel.lbl_clear_filter
                                    : "Clear Filter"}
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div className="min-vh-100" id="root_div_main">
                <div className="content-wrapper">
                    <div className="card border-0">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="d-md-flex justify-content-start col-12 col-md-6">
                                    <Tooltip id={"open-tooltip"} place="top" />
                                    <img
                                        src={Slide}
                                        height="32"
                                        width="32"
                                        className="cursor_pointer my-1 me-2"
                                        onClick={() => toggleNav()}
                                        data-tooltip-id={"open-tooltip"}
                                        data-tooltip-content={"Sidebar"}
                                    />
                                    <h1 className="bold">{constLabel?.lbl_production_planning ?
                                        constLabel.lbl_production_planning : "Production Planning"}</h1>
                                </div>

                                <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">


                                    {privilage.write && (
                                        <button
                                            onClick={() =>
                                                navigate("/pcms/save/production_planning")
                                            }
                                            className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                                        >
                                            <FontAwesomeIcon
                                                style={{ color: "#fff" }}
                                                icon={faPlus}
                                                size="sm"
                                            />{" "}
                                            {constLabel?.lbl_create_pro_plan
                                                ? constLabel.lbl_create_pro_plan
                                                : "Create Production Plan"}
                                        </button>
                                    )}

                                    <Tooltip id={"show-tooltip"} place="top" />
                                    <div
                                        className="dropdown"
                                        data-tooltip-id={"show-tooltip"}
                                        data-tooltip-content={"Show / Hide Columns"}
                                    >
                                        <div
                                            className="d-md-flex justify-content-start align-items-center"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            {/* <FontAwesomeIcon icon={faListCheck} size="lg" /> */}
                                            {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                                            <img
                                                src={List}
                                                height="32"
                                                width="32"
                                                className="cursor_pointer"
                                            />
                                        </div>

                                        <ul className="dropdown-menu px-2">
                                            {showLabel !== undefined && showLabel.length > 0
                                                ? showLabel.map((val, ind, arr) => (
                                                    <li key={ind}>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                style={{ width: "20px", height: "20px" }}
                                                                onChange={() => handleClick(ind)}
                                                                value=""
                                                                checked={val.flag}
                                                            />
                                                            <label
                                                                className=""
                                                                style={{ fontSize: "13px" }}
                                                                htmlFor="flexCheckDefault"
                                                            >
                                                                {val.label}
                                                            </label>
                                                        </div>
                                                    </li>
                                                ))
                                                : null}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <div className="mt-3">
                                {dataList.loading ? (
                                    <div className="text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                ) : dataList.data.length > 0 ? (
                                    <>
                                        <div
                                            className="table-responsive"
                                        >
                                            <table className="table table-bordered">
                                                <thead className="table-grey roboto-bold">
                                                    <tr className="">
                                                        <th scope="col">{"Sr.No"}</th>
                                                        {showLabel.map((v, i) =>
                                                            v.label === "Prod Plan Id" && v.flag ? (<th key={i} scope="col">{constLabel?.lbl_prod_plan_id ? constLabel.lbl_prod_plan_id : "Prod Plan Id"}</th>) :
                                                                v.label === "Plan Date" && v.flag ? (<th key={i} scope="col">{constLabel?.lbl_plan_date ? constLabel.lbl_plan_date : "Plan Date"}</th>) :
                                                                    v.label === "Organization" && v.flag ? (<th key={i} scope="col">{constLabel?.lbl_org ? constLabel.lbl_org : "Organization"}</th>) :
                                                                        v.label === "Location" && v.flag ? (<th key={i} scope="col">{constLabel?.lbl_location ? constLabel.lbl_location : "Location"}</th>) :
                                                                            v.label === "Ignore Available Qty" && v.flag ? (<th key={i} scope="col">{constLabel?.lbl_ignore_avail_qty ? constLabel.lbl_ignore_avail_qty : "Ignore Available Qty"}</th>) :
                                                                                v.label === "Resource Planning" && v.flag ? (<th key={i} scope="col">{constLabel?.lbl_resource_planning ? constLabel.lbl_resource_planning : "Resource Planning"}</th>) :
                                                                                    v.label === "Raw Material Planning" && v.flag ? (<th key={i} scope="col">{constLabel?.lbl_mat_resource_planning ? constLabel.lbl_mat_resource_planning : "Raw Material Planning"}</th>) :
                                                                                        v.label === "Status" && v.flag ? (<th key={i} scope="col">{constLabel?.status ? constLabel.status : "Status"}</th>) :
                                                                                            v.label === "Remarks" && v.flag ? (<th key={i} scope="col">{constLabel?.lbl_remarks ? constLabel.lbl_remarks : "Remarks"}</th>) :
                                                                                                null
                                                        )}
                                                        <th scope="col" width="15%">
                                                            {"Action"}
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody className="roboto">
                                                    {dataList.data.map((val, ind) => (
                                                        <tr key={ind}>
                                                            <td>{parseInt(ind) + 1 + parseInt(srNo)}</td>
                                                            {showLabel.map((item, idx) =>
                                                                item.label === "Prod Plan Id" && item.flag ?
                                                                    (<td key={idx}>{val.prod_plan_id ?? "NA"}</td>) :

                                                                    item.label === "Plan Date" && item.flag ? (<td key={idx}>{val.planning_date != "" &&
                                                                        val.planning_date !== null ? formatDate5(val.planning_date) : "NA"}</td>) :
                                                                        item.label === "Organization" && item.flag ? (<td key={idx}>{val.org_details !== null ? val.org_details?.comName : "NA"}</td>) :
                                                                            item.label === "Location" && item.flag ? (<td key={idx}>{val.loc_details !== null ? val.loc_details?.locationname : "NA"}</td>) :
                                                                                item.label === "Ignore Available Qty" && item.flag ? (<td key={idx}>{JSON.stringify(val.ignore_available_qty)}</td>) :
                                                                                    item.label === "Resource Planning" && item.flag ? (<td key={idx}>{JSON.stringify(val.resource_planning)}</td>) :
                                                                                        item.label === "Raw Material Planning" && item.flag ? (<td key={idx}>{JSON.stringify(val.material_resource_planning)}</td>) :
                                                                                            item.label === "Status" && item.flag ? (<td key={idx}>{val.status ?? "NA"}</td>) :
                                                                                                item.label === "Remarks" && item.flag ? (<td key={idx}>{val.remarks ?? "NA"}</td>) :
                                                                                                    null
                                                            )}
                                                            <td>
                                                                {/* {privilage.write &&
                                                                    <>
                                                                        <Tooltip
                                                                            id={"edit-tooltip" + ind}
                                                                            place="top"
                                                                        />
                                                                        <Edit
                                                                            data-tooltip-id={"edit-tooltip" + ind}
                                                                            data-tooltip-content={constLabel?.lbl_edit_work_order ? constLabel.lbl_edit_work_order : "Edit Production Plan"}
                                                                            className="menu_icon_grey cursor_pointer me-2"
                                                                            size={20}
                                                                            onClick={() =>
                                                                                navigate("/pcms/update-work-order", {
                                                                                    state: val,
                                                                                })
                                                                            }
                                                                        />
                                                                    </>
                                                                } */}
                                                                {privilage.write && val.status == "Draft" && (
                                                                    <>
                                                                        <Tooltip
                                                                            id={"edit-tooltip" + ind}
                                                                            place="top"
                                                                        />
                                                                        <Edit
                                                                            data-tooltip-id={"edit-tooltip" + ind}
                                                                            data-tooltip-content={constLabel?.lbl_update_work_order ?
                                                                                constLabel.lbl_update_work_order : "Update Production Plan"}
                                                                            className="menu_icon_grey cursor_pointer me-1"
                                                                            size={20}
                                                                            onClick={() => {
                                                                                navigate("/pcms/update/production_planning", { state: val })
                                                                            }}
                                                                        />
                                                                    </>
                                                                )}


                                                                {privilage.delete && (
                                                                    <>
                                                                        <Tooltip
                                                                            id={"delete-tooltip" + ind}
                                                                            place="top"
                                                                        />
                                                                        <Trash2
                                                                            data-tooltip-id={"delete-tooltip" + ind}
                                                                            data-tooltip-content={constLabel?.lbl_delete_work_order ? constLabel.lbl_delete_work_order : "Delete Production Plan"}
                                                                            className="menu_icon_red cursor_pointer"
                                                                            size={20}
                                                                            onClick={() => delProdPlan(val)}
                                                                        />
                                                                    </>
                                                                )}

                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        <div className="d-flex justify-content-between align-items-center">
                                            <p className="mb-0">
                                                Show
                                                <select
                                                    className="mx-1"
                                                    defaultValue={entriesPerPage}
                                                    onChange={(e) => {
                                                        setEntriesPerPage(e.target.value);
                                                        fetchProdPlan(1, e.target.value);

                                                    }}
                                                >
                                                    <option value={"10"}>10</option>
                                                    <option value="25">25</option>
                                                    <option value="50">50</option>
                                                    <option value="100">100</option>
                                                </select>
                                                Entries
                                            </p>
                                            <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                                                } to ${Math.min(
                                                    parseInt(entriesPerPage) + parseInt(srNo),
                                                    dataList.totalRecords
                                                )} of ${dataList.totalRecords} entries`}</p>
                                            <Pagination className="my-2">
                                                <Pagination.Prev
                                                    disabled={page === 1 ? true : false}
                                                    onClick={() => {
                                                        fetchProdPlan(page - 1, entriesPerPage, parseInt(srNo) - parseInt(entriesPerPage));
                                                        setSrNo((prevC) =>
                                                            page - 1 == 1
                                                                ? 0
                                                                : prevC - parseInt(entriesPerPage)
                                                        );
                                                        setPage(page - 1);
                                                    }}
                                                >
                                                    {"Prev"}
                                                </Pagination.Prev>

                                                <Pagination.Item active>{page}</Pagination.Item>

                                                <Pagination.Next
                                                    disabled={
                                                        page === maxPage ||
                                                            maxPage === 0 ||
                                                            entriesPerPage > dataList.data.length
                                                            ? true
                                                            : false
                                                    }
                                                    onClick={() => {

                                                        fetchProdPlan(page + 1, entriesPerPage, parseInt(srNo) + parseInt(entriesPerPage));
                                                        setSrNo(
                                                            (prevC) => prevC + parseInt(entriesPerPage)
                                                        );
                                                        setPage(page + 1);
                                                    }}
                                                >
                                                    {"Next"}
                                                </Pagination.Next>
                                            </Pagination>
                                        </div>
                                    </>
                                ) : (
                                    <div
                                        className="alert alert-danger mb-0 text-center my-4"
                                        role="alert"
                                    >
                                        {"No Production Plans Found !"}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ProductionPlanning
