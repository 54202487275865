import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
    getInternalCompany, getCustomer, getSOStatus, getProdList, getSalesOrder,
    create_production_plan, update_production_plan
} from '../../../api/index';
import refresh from "../../../assets/images/refresh.png";
import { Sales_Order_Col_Name } from "../../../common/Labels/labelConstant";
import {
    calMaxPage,
    convertDate,
    getLabels, convertDateYYMMDD
} from "../../../common/Functions/CommonFunctions";
import { Tooltip } from "react-tooltip";
import info from '../../../assets/images/info.png';
import { useSelector, useDispatch } from 'react-redux';
import { updateStepOne } from '../../../slices/production_planning/production_plan_slice';


const schema = yup
    .object()
    .shape({
        plan_date: yup.date().required("Please select plan date !"),
        organization: yup
            .array()
            .min(1, "Please select organization !")
            .required("Please select organization !"),
        location: yup
            .array()
            .min(1, "Please select location !")
            .required("Please select location !"),
        status: yup.array()
            .min(1, "Please select status !")
            .required("Please select status !"),
        employeePlanning: yup.boolean()
            .required("Required"),
        rawMaterialPlanning: yup.boolean()
            .required("Required"),
        monday: yup.boolean()
            .required("Required"),
        tuesday: yup.boolean()
            .required("Required"),
        wednesday: yup.boolean()
            .required("Required"),
        thursday: yup.boolean()
            .required("Required"),
        friday: yup.boolean()
            .required("Required"),
        saturday: yup.boolean()
            .required("Required"),
        sunday: yup.boolean()
            .required("Required"),
        remarks: yup.string().max(1000).notRequired("Please enter remarks !"),

    })
    .required();

function StepOne(props) {
    let navigate = useNavigate();

    const { nextStep, prevStep, steps, setSteps, prev_data, setSkipStep } = props;
    const dispatch = useDispatch();

    const StepOneSavedData = useSelector((state) => state.steps.StepOne);


    const [constLabel, setConstLabel] = useState({});

    // Dropdown states
    const [internalCompList, setInternalComp] = useState({ data: [], loading: false });
    const [gst_list, setGST_list] = useState({ data: [], loading: false });


    const [prod_List, setProd_List] = useState({ data: [], loading: false });
    const [locationList, setlocationList] = useState({ data: [], loading: false });
    const [customerList, setCustomerList] = useState({
        data: [],
        loading: false,
    });

    const statusList = [{ name: 'Draft' }, { name: "Finalized" }];

    // selectedValues of dropdown
    const [filter, setFilter] = useState({ customer: [], product: [], gst: [] });

    const [dataList, setDataList] = useState({
        data: [],
        loading: false,
        totalRecords: "",
        firstTime: false,

    });
    const [disableBtn, setDisableBtn] = useState(false);


    const {
        register,
        handleSubmit,
        formState,
        control,
        watch,
        getValues,
        setValue,
        setError,
        reset,
        clearErrors
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: { rawMaterialPlanning: true, sunday: true },
        mode: "onChange",
    });
    let { errors } = formState;

    // const organization = watch("organization");
    const employeePlanning = watch("employeePlanning");
    const rawMaterialPlanning = watch("rawMaterialPlanning");

    // const StepOneSavedData = JSON.parse(localStorage.getItem("StepOne"));

    useEffect(() => {
        console.log("prev_data", prev_data);
        console.log("StepOneSavedData", StepOneSavedData);
        fetchInternalComp();
        fetchCustomers();
        fetchProdList();



        if (StepOneSavedData) {

            setValue("plan_date", new Date(StepOneSavedData?.formData?.plan_date));
            setValue("organization", StepOneSavedData?.formData?.organization);
            setlocationList({
                ...locationList, data: StepOneSavedData?.formData?.organization[0].location_list
            });

            console.log(1)
            setValue("location", StepOneSavedData?.formData?.location);
            setValue("status", StepOneSavedData?.formData?.status);
            setValue("remarks", StepOneSavedData?.formData?.remarks);
            setValue("rawMaterialPlanning", StepOneSavedData?.formData?.rawMaterialPlanning);
            setValue("employeePlanning", StepOneSavedData?.formData?.employeePlanning);
            setValue("monday", StepOneSavedData?.formData?.monday);
            setValue("tuesday", StepOneSavedData?.formData?.tuesday);
            setValue("wednesday", StepOneSavedData?.formData?.wednesday);
            setValue("thursday", StepOneSavedData?.formData?.thursday);
            setValue("friday", StepOneSavedData?.formData?.friday);
            setValue("saturday", StepOneSavedData?.formData?.saturday);
            setValue("sunday", StepOneSavedData?.formData?.sunday);

            if (StepOneSavedData?.filter) {
                setFilter(StepOneSavedData?.filter);
            }

            // enable this to auto display so using localstorage
            // setDataList({ ...dataList, data: StepOneSavedData.selectedSalesOrder, loading: false, firstTime: true })
        }

        if (!StepOneSavedData && !prev_data) {
            setValue("status", [{ name: 'Draft' }]);
            setValue("plan_date", new Date());
        }

        if (!StepOneSavedData && prev_data) {

            setValue("plan_date", new Date(prev_data.draft_data?.stepOneData.formData?.plan_date));
            setValue("organization", prev_data.draft_data.stepOneData?.formData?.organization);
            setlocationList({
                ...locationList, data: prev_data.draft_data.stepOneData?.
                    formData?.organization[0].location_list
            });
            console.log(1)
            setValue("location", prev_data.draft_data.stepOneData?.formData?.location);
            setValue("status", prev_data.draft_data.stepOneData?.formData?.status);
            setValue("remarks", prev_data.draft_data.stepOneData?.formData?.remarks);
            setValue("rawMaterialPlanning", prev_data.draft_data.stepOneData?.formData?.rawMaterialPlanning);
            setValue("employeePlanning", prev_data.draft_data.stepOneData?.formData?.employeePlanning);
            setValue("monday", prev_data.draft_data.stepOneData?.formData?.monday);
            setValue("tuesday", prev_data.draft_data.stepOneData?.formData?.tuesday);
            setValue("wednesday", prev_data.draft_data.stepOneData?.formData?.wednesday);
            setValue("thursday", prev_data.draft_data.stepOneData?.formData?.thursday);
            setValue("friday", prev_data.draft_data.stepOneData?.formData?.friday);
            setValue("saturday", prev_data.draft_data.stepOneData?.formData?.saturday);
            setValue("sunday", prev_data.draft_data.stepOneData?.formData?.sunday);

            if (prev_data.draft_data.stepOneData?.filter) {
                setFilter(prev_data.draft_data.stepOneData?.filter);
            }

            // enable this to auto display so using localstorage
            // setDataList({ ...dataList, data: StepOneSavedData.selectedSalesOrder, loading: false, firstTime: true })

        }

        // reset(StepOneSavedData.formData);

        // setValue("status", [{ name: 'Draft' }]);

    }, []);

    // useEffect(() => {
    //     console.log("organization", organization);
    //     console.log("StepOneSavedData", StepOneSavedData);
    //     console.log("prev_data", prev_data);
    //     if (organization && organization.length > 0) {
    //         setlocationList({ ...locationList, data: organization[0].location_list });


    //         if (organization[0].location_list && organization[0].location_list.length > 0) {
    //             const defaultLocation = organization[0].location_list.find((val) => val.default_location == true);

    //             if (!StepOneSavedData && !prev_data) {
    //                 if (defaultLocation) {
    //                     console.log(1)
    //                     setValue("location", [defaultLocation]);
    //                 }
    //             } else if (!StepOneSavedData && prev_data) {
    //                 setValue("location", prev_data.draft_data.stepOneData?.formData?.location);
    //             } else if (StepOneSavedData) {
    //                 setValue("location", StepOneSavedData?.formData?.location);
    //             }
    //         }
    //     }
    //     else {
    //         setValue("location", []);
    //     }
    // }, [organization]);

    useEffect(() => {
        const stepperContainer = document.querySelector('.stepper-container');

        if (rawMaterialPlanning && employeePlanning) {
            setSteps([{ label: 'Configure Plan', completed: false },
            { label: 'Select Product', completed: false },
            { label: 'Create Material Request', completed: false },
            { label: 'Resource Planning', completed: false },
            { label: 'Preview', completed: false }]);
            stepperContainer.style.maxWidth = '900px';

        } else if (rawMaterialPlanning && !employeePlanning) {
            setSteps([{ label: 'Configure Plan', completed: false },
            { label: 'Select Product', completed: false },
            { label: 'Create Material Request', completed: false }]);
            stepperContainer.style.maxWidth = '600px';

        } else if (!rawMaterialPlanning && employeePlanning) {
            setSteps([{ label: 'Configure Plan', completed: false },
            { label: 'Select Product', completed: false },
            { label: 'Resource Planning', completed: false },
            { label: 'Preview', completed: false }]);
            stepperContainer.style.maxWidth = '700px';

        } else {
            setError("rawMaterialPlanning", "Select atleast one")
        }
    }, [employeePlanning, rawMaterialPlanning]);


    function fetchCustomers() {
        setCustomerList({ ...customerList, loading: true, data: [] });
        setGST_list({ data: [], loading: true });

        // temporary sending page & entriesPerPage for demo, remove this after demo
        // getCustomer({ partyType: "Customer", page: 1, entriesPerPage: 20 }).then(
        getCustomer({ partyType: "Customer" }).then(
            (res) => {
                const result = res.data.data.filter(
                    (item) => item.organization !== null
                );
                const arr = res.data.data.filter(
                    (item) =>
                        item.gst !== null && item.gst !== undefined && item.gst !== ""
                );
                console.log("arr", arr);

                setGST_list({ data: arr, loading: false });
                setCustomerList({
                    ...customerList,
                    loading: false,
                    data: result,
                });
            },
            (err) => {
                //
                setCustomerList({ ...customerList, loading: false, data: [] });
                setGST_list({ data: [], loading: false });
                toast.error("Something went wrong in suppliers!", {
                    autoClose: 3000,
                });
            }
        );
    }


    function fetchProdList() {
        setProd_List({ ...prod_List, loading: true, data: [] });

        getProdList().then(
            (res) => {
                let result = res.data.data.filter(
                    (item) =>
                        item.productName !== null &&
                        item.productName !== undefined &&
                        item.productName !== ""
                );
                setProd_List({ ...prod_List, loading: false, data: result });
            },
            (err) => {
                setProd_List({ ...prod_List, loading: false, data: [] });
            }
        );
    }


    function fetchInternalComp() {
        setInternalComp({ ...internalCompList, data: [], loading: true });

        getInternalCompany({ with_location: 1 }).then(
            (res) => {
                console.log("res", res);
                setInternalComp({
                    ...internalCompList,
                    data: res.data.data,
                    loading: false,
                });

                const defaultOrg = res.data.data.find((val) => val.default_org == true);

                if (!StepOneSavedData && !prev_data) {
                    if (defaultOrg) {
                        setValue("organization", [defaultOrg]);

                        const default_location =
                            defaultOrg.location_list.find((val) => val.default_location == true);
                        if (default_location) {

                            setValue("location", [default_location]);
                        }
                    }
                }
            },
            (err) => {
                toast.error("Fetching organization list failed !", {
                    autoClose: 3000,
                });
                setInternalComp({ ...internalCompList, data: [], loading: false });
            }
        );
    }

    function fetchSalesOrder() {
        const icon = document.getElementById('refresh-icon-img');
        icon.classList.add('spin');

        setDataList({ ...dataList, loading: true, firstTime: true });
        console.log("filter", filter);
        const payload = {};
        if (filter?.customer?.length > 0) {
            payload.customer_list = JSON.stringify(filter?.customer.map((val) => val.uuid));
        } else {
            delete payload.customer_list;
        }


        payload.so_status = "Approved";

        if (filter?.gst_number?.length > 0) {
            payload.gst_number = JSON.stringify([filter.gst]);
        } else {
            delete payload.gst_number;
        }



        if (filter?.product?.length > 0) {
            payload.productList = JSON.stringify(filter.product.map((val) => val.uuid));
        } else {
            delete payload.productList;
        }

        getSalesOrder(payload).then(
            (res) => {
                console.log("res", res);
                icon.classList.remove('spin');



                // setting every thing as unchecked
                const newSalesOrderList = res.data.data.map((val) => {

                    // if step data is present in local storage then for showing selected sales order below code is required
                    if (StepOneSavedData?.selectedSalesOrder) {
                        const foundSalesorder = StepOneSavedData.selectedSalesOrder.find((item) => item.uuid == val.uuid);

                        if (foundSalesorder) {
                            val.checked = true;
                        } else {
                            val.checked = false;
                        }

                    } else if (prev_data && prev_data.draft_data.stepOneData.selectedSalesOrder) {
                        const foundSalesorder = prev_data.draft_data.stepOneData.selectedSalesOrder
                            .find((item) => item.uuid == val.uuid);

                        if (foundSalesorder) {
                            val.checked = true;
                        } else {
                            val.checked = false;
                        }
                    } else {
                        val.checked = false;
                    }

                    return val
                })

                setDataList({
                    ...dataList,
                    data: newSalesOrderList,
                    loading: false,
                    totalRecords: res.data.total,
                    firstTime: true
                });
            },
            (err) => {
                console.log("err", err);
                icon.classList.remove('spin');

                setDataList({ ...dataList, loading: false, data: [], firstTime: true });
                toast.error(err.response.data.message, {
                    autoClose: 2000,
                });
            }
        );
    }


    const onSubmit = (data) => {
        console.log("data", data);

        if (!data.employeePlanning && !data.rawMaterialPlanning) {
            setError("rawMaterialPlanning", { type: "custom", message: "Select atleast one of the above options" });
            return
        } else {
            clearErrors(["rawMaterialPlanning"])
        }

        if (!data.monday && !data.tuesday && !data.wednesday && !data.thursday
            && !data.friday && !data.saturday && !data.sunday
        ) {
            setError("monday", { type: "custom", message: "Select atleast one of the above options" });
            return
        } else {
            clearErrors(["rawMaterialPlanning"])
        }

        const selectedSalesOrder = dataList.data.filter((val) => val.checked == true);

        const stepOneData = {
            formData: data,
            filter: filter,
            selectedSalesOrder: selectedSalesOrder
        }

        if (dataList.data.length == 0 || selectedSalesOrder.length == 0) {

            toast.error("Atleast one sales order is required !", {
                autoClose: 2000,
            });
            return
        }



        const payload = {
            planning_date: convertDateYYMMDD(new Date(stepOneData.formData.plan_date), "-"),
            org_uuid: stepOneData.formData.organization[0].uuid,
            location_uuid: stepOneData.formData.location[0].uuid,
            material_planning: data.rawMaterialPlanning,
            resource_planning: data.employeePlanning,
            remarks: stepOneData.formData.remarks,
            status: stepOneData.formData.status[0].name,
            draft_data: {
                stepOneData: stepOneData,
            },
        }


        if (!data.rawMaterialPlanning && data.employeePlanning) {
            setSkipStep(1);
        } else {
            setSkipStep(0);
        }


        if ((StepOneSavedData && StepOneSavedData.prod_plan_uuid) || prev_data) {
            console.log("need to update production plan");

            // prev_data existing means we are updating
            if (prev_data) {
                payload.uuid = prev_data.uuid;
            } else {
                payload.uuid = StepOneSavedData.prod_plan_uuid;
            }


            localStorage.setItem("StepOne", JSON.stringify(payload));

            update_production_plan(payload).then((res) => {
                console.log("res", res);
                setDisableBtn(true);

                stepOneData.prod_plan_uuid = res.data.data.uuid


                dispatch(updateStepOne(stepOneData));
                console.log("stepOneData", stepOneData);


                toast.success("Production plan updated successfully", {
                    autoClose: 2000,
                });

                setTimeout(() => {
                    nextStep();
                }, 2000);
            }, (err) => {
                console.log("err", err);
                setDisableBtn(false);
                toast.error(err.response.data.message, {
                    autoClose: 2000,
                });
            })
        } else {

            localStorage.setItem("StepOne", JSON.stringify(payload));
            create_production_plan(payload).then((res) => {
                console.log("res", res);
                setDisableBtn(true);

                stepOneData.prod_plan_uuid = res.data.data.uuid


                dispatch(updateStepOne(stepOneData));
                console.log("stepOneData", stepOneData);


                toast.success("Production plan created successfully", {
                    autoClose: 2000,
                });

                setTimeout(() => {
                    nextStep();
                }, 2000);
            }, (err) => {
                console.log("err", err);
                setDisableBtn(false);
                toast.error(err.response.data.message, {
                    autoClose: 2000,
                });
            })
        }



    }


    return (
        <React.Fragment>
            <ToastContainer />
            <div className='row StepOne'>
                <div className='col-12'>

                    <div className='card  shadow-sm'>
                        <div className='card-body'>
                            <div className='row'>
                                <p className='mb-2 mt-1' id='pro_plan_label'> {constLabel?.lbl_holiday_planning ? constLabel.lbl_holiday_planning : "Production Plan"}</p>
                                <div className='col-12 col-md-3'>
                                    <div className="form-group mt-md-0 mt-3">
                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="col-form-label roboto-bold"
                                        >
                                            {constLabel?.lbl_plan_date ? constLabel.lbl_plan_date : "Plan Date"}
                                            <span className="text-danger"> *</span>
                                        </label>
                                        <Controller
                                            control={control}
                                            name="plan_date"
                                            // defaultValue={new Date()}
                                            render={({ field }) => (
                                                <DatePicker
                                                    className={
                                                        errors.plan_date
                                                            ? "form-control  is-invalid"
                                                            : "form-control"
                                                    }
                                                    placeholderText="Select date"
                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field.value}
                                                    // defaultValue={new Date()}
                                                    minDate={new Date()}
                                                    dateFormat="dd/MM/yyyy"
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className='col-12 col-md-3'>
                                    <div className="form-group mt-md-0 mt-3  typeahead-container">
                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="col-form-label roboto-bold"
                                        >
                                            {constLabel?.lbl_org ? constLabel.lbl_org : "Organization"}
                                            <span className="text-danger"> *</span>
                                        </label>


                                        <Controller
                                            name={`organization`}
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="comName"
                                                    multiple={false}
                                                    options={internalCompList.data}
                                                    isLoading={internalCompList.loading}
                                                    placeholder="Select organization"
                                                    onChange={(selected) => {
                                                        setValue("organization", selected);
                                                        if (selected && selected.length > 0) {
                                                            setlocationList({ ...locationList, data: selected[0].location_list });


                                                            if (selected[0].location_list && selected[0].location_list.length > 0) {
                                                                const defaultLocation = selected[0].location_list.find((val) => val.default_location == true);

                                                                if (defaultLocation) {
                                                                    console.log(1)
                                                                    setValue("location", [defaultLocation]);
                                                                } else {
                                                                    setValue("location", []);
                                                                }
                                                            }
                                                        }
                                                        else {
                                                            setValue("location", []);
                                                        }
                                                    }}
                                                    onBlur={onBlur}
                                                    selected={value}
                                                    isInvalid={errors.organization ? true : false}
                                                />
                                            )}
                                        />

                                        {!internalCompList.loading && !errors.organization && <span className="arrow-icon"></span>}

                                        {errors.organization && (
                                            <span className="text-danger err-msg">
                                                {errors.organization.message}
                                            </span>
                                        )}

                                    </div>
                                </div>
                                <div className='col-12 col-md-3'>
                                    <div className="form-group mt-md-0 mt-3 typeahead-container">
                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="col-form-label roboto-bold"
                                        >
                                            {constLabel?.lbl_location ? constLabel.lbl_location : "Location"}
                                            <span className="text-danger"> *</span>
                                        </label>


                                        <Controller
                                            name={`location`}
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="locationname"
                                                    multiple={false}
                                                    options={locationList.data}
                                                    isLoading={locationList.loading}
                                                    placeholder="Select location"
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    selected={value}
                                                    isInvalid={errors.location ? true : false}
                                                />
                                            )}
                                        />

                                        {!locationList.loading && !errors.location &&
                                            <span className="arrow-icon"></span>
                                        }
                                        {errors.location && (
                                            <span className="text-danger err-msg">
                                                {errors.location.message}
                                            </span>
                                        )}

                                    </div>
                                </div>
                                <div className='col-12 col-md-3'>
                                    <div className="form-group mt-md-0 mt-3 typeahead-container">
                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="col-form-label roboto-bold"
                                        >
                                            {constLabel?.lbl_location ? constLabel.lbl_location : "Status"}
                                            <span className="text-danger"> *</span>
                                        </label>


                                        <Controller
                                            name={`status`}
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="name"
                                                    multiple={false}
                                                    options={statusList}
                                                    isLoading={false}
                                                    placeholder="Select status"
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    selected={value}
                                                    isInvalid={errors.status ? true : false}
                                                    disabled
                                                />
                                            )}
                                        />

                                        {!errors.status &&
                                            <span className="arrow-icon"></span>
                                        }

                                        {errors.status && (
                                            <span className="text-danger err-msg">
                                                {errors.status.message}
                                            </span>
                                        )}

                                    </div>
                                </div>
                            </div>

                            {/* <div className='row'> */}
                            <div className='row'>
                                <div className='col-12 col-md-4'>
                                    <div className='card shadow-sm  mt-4'>
                                        <div className='card-body'>
                                            <div className="form-group">

                                                <p className='mb-2' id='pro_plan_label'>{constLabel?.lbl_resource_planning ? constLabel.lbl_resource_planning : "Select Planning For"}

                                                    <Tooltip id={"open-tooltip3"} place="top" />
                                                    <img src={info} alt='info' className='cursor_pointer'
                                                        data-tooltip-id={"open-tooltip3"}
                                                        data-tooltip-content={"Both can be selected"}
                                                        height={20} width={20} />
                                                </p>

                                                <div>
                                                    <div>
                                                        <label className=''>
                                                            <input
                                                                className="form-check-input me-2 "
                                                                type="checkbox"
                                                                {...register("rawMaterialPlanning")}
                                                            />Raw Material Planning</label>
                                                    </div>

                                                    <div>
                                                        <label>
                                                            <input
                                                                className="form-check-input me-md-2 "
                                                                type="checkbox"
                                                                {...register("employeePlanning")}
                                                            />Resource Planning</label>
                                                    </div>

                                                    <div>
                                                        {errors.rawMaterialPlanning && (
                                                            <span className="text-danger err-msg">
                                                                {errors.rawMaterialPlanning.message}
                                                            </span>
                                                        )}
                                                    </div>

                                                </div>


                                            </div>
                                        </div>

                                    </div>

                                </div>



                                <div className={employeePlanning == true ? "col-12 col-md-8" : 'col-12 col-md-8 invisible'}>
                                    <div className='card shadow-sm mt-4'>
                                        <div className='card-body'>
                                            <div className="form-group">
                                                <p className='mb-2' id='pro_plan_label'> {constLabel?.lbl_holiday_planning ? constLabel.lbl_holiday_planning : "Select Weekend Plan"}</p>
                                                {/* <label
                                                        style={{ fontSize: "14px" }}
                                                        className="col-form-label roboto-bold"
                                                    >
                                                        {constLabel?.lbl_holiday_planning ? constLabel.lbl_holiday_planning : "Select Weekend Plan"}
                                                    </label> */}


                                                <div>

                                                    <label className=''>
                                                        <input
                                                            className="form-check-input me-2 "
                                                            type="checkbox"
                                                            {...register("monday")}
                                                        />Monday</label>


                                                    <label>
                                                        <input
                                                            className="form-check-input me-2 ms-4 "
                                                            type="checkbox"
                                                            {...register("tuesday")}
                                                        />Tuesday</label>
                                                    <label>
                                                        <input
                                                            className="form-check-input me-2 ms-4 "
                                                            type="checkbox"
                                                            {...register("wednesday")}
                                                        />Wednesday</label>
                                                    <label>
                                                        <input
                                                            className="form-check-input me-2 ms-4 "
                                                            type="checkbox"
                                                            {...register("thursday")}
                                                        />Thursday</label>
                                                    <label>
                                                        <input
                                                            className="form-check-input me-2 ms-4 "
                                                            type="checkbox"
                                                            {...register("friday")}
                                                        />Friday</label>
                                                    <label>
                                                        <input
                                                            className="form-check-input me-2 ms-4 "
                                                            type="checkbox"
                                                            {...register("saturday")}
                                                        />Saturday</label>
                                                    <label>
                                                        <input
                                                            className="form-check-input me-2 ms-4 "
                                                            type="checkbox"
                                                            {...register("sunday")}
                                                        />Sunday</label>


                                                </div>

                                                <div>
                                                    {errors.monday && (
                                                        <span className="text-danger err-msg">
                                                            {errors.monday.message}
                                                        </span>
                                                    )}
                                                </div>

                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </div>



                            {/* </div> */}
                        </div>
                    </div>

                    <div className='card  shadow-sm mt-4'>
                        <div className='card-body'>
                            <div className='col-12'>
                                <div className="form-group">
                                    <label
                                        style={{ fontSize: "14px" }}
                                        className="col-form-label roboto-bold"
                                    >
                                        {constLabel?.lbl_remarks ? constLabel.lbl_remarks : "Remarks"}

                                    </label>


                                    <textarea className={
                                        errors.remarks
                                            ? "form-control  is-invalid"
                                            : "form-control "
                                    }
                                        id="remarks"
                                        name="remarks"
                                        type="text"
                                        placeholder="Enter remarks"
                                        {...register("remarks")}
                                        rows={1}>

                                    </textarea>
                                    {errors.remarks && (
                                        <span className="text-danger err-msg">
                                            {errors.remarks.message}
                                        </span>
                                    )}

                                </div>
                            </div>

                        </div>
                    </div>



                    <div className='card  shadow-sm mt-4'>
                        <div className='card-body'>
                            <div className='row'>

                                <p className='mb-2' id='pro_plan_label'>Sales Order Filters</p>


                                <div className='col-12 col-md-4'>
                                    <div className='form-group typeahead-container'>

                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="col-form-label roboto-bold d-flex"
                                        >
                                            <span> {constLabel?.lbl_customer ? constLabel.lbl_customer : "Customer"}</span>
                                            <Tooltip id={"open-tooltip"} place="top" />
                                            <img src={info} alt='info' className='cursor_pointer'
                                                data-tooltip-id={"open-tooltip"}
                                                data-tooltip-content={"Multiple customers can be selected"}
                                                height={20} width={20} />
                                        </label>


                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="organization"
                                            multiple={true}
                                            options={customerList.data}
                                            isLoading={customerList.loading}
                                            placeholder="Select Customer"
                                            selected={filter.customer}
                                            onChange={(selected) => {
                                                console.log('selected', selected);
                                                setFilter({ ...filter, customer: selected });
                                            }}
                                        />

                                        {!customerList.loading &&
                                            <span className="arrow-icon"></span>
                                        }
                                    </div>
                                </div>

                                {/* <div className='col-12 col-md-4 '>
                                    <div className='form-group'>

                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="col-form-label roboto-bold d-flex"
                                        >
                                            <span> {constLabel?.lbl_product ? constLabel.lbl_product : "Product"}</span>
                                            <Tooltip id={"open-tooltip2"} place="top" />
                                            <img src={info} alt='info' className='cursor_pointer'
                                                data-tooltip-id={"open-tooltip2"}
                                                data-tooltip-content={"Multiple products can be selected"}
                                                height={20} width={20} />
                                        </label>


                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="productName"
                                            multiple={true}
                                            options={prod_List.data}
                                            isLoading={prod_List.loading}
                                            placeholder="Select Product"
                                            selected={filter.product}
                                            onChange={(selected) => {
                                                console.log('selected', selected);
                                                setFilter({ ...filter, product: selected });
                                            }}
                                        />

                                    </div>
                                </div>
                                <div className='col-12 col-md-4'>
                                    <div className='form-group'>

                                        <label
                                            style={{ fontSize: "14px" }}
                                            className="col-form-label roboto-bold"
                                        >
                                            {constLabel?.lbl_product ? constLabel.lbl_product : "GST"}
                                        </label>


                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="gst"
                                            multiple={false}
                                            options={gst_list.data}
                                            isLoading={gst_list.loading}
                                            placeholder="Select GST"
                                            selected={filter.gst}
                                            onChange={(selected) => {
                                                console.log('selected', selected);
                                                setFilter({ ...filter, gst: selected });
                                            }}
                                        />

                                    </div>
                                </div> */}

                                <div className='col-12'>
                                    <div className='form-group mt-4'>

                                        <div>
                                            <button
                                                disabled={dataList.loading}
                                                onClick={() => fetchSalesOrder()}
                                                className='btn button-primary'
                                            >Get Sales Orders  <img className='ms-2' src={refresh}
                                                alt="refresh" id='refresh-icon-img' /></button>
                                        </div>
                                    </div>




                                    <div className='row mt-4'>
                                        <div className='d-flex'>
                                            <p className='mb-2 mt-1' id='pro_plan_label'>Sales Orders</p>

                                            {/* <div
                                                onClick={() => fetchSalesOrder()}
                                             className="refresh-icon ms-0 ms-md-3 shadow-sm px-2">
                                            <span> Get Sales Orders</span>

                                              </div> */}
                                        </div>



                                        <div>
                                            <i><p className="mt-2 mb-0"><span className="text-danger roboto-bold">Note - </span>Only Approved sales orders will be visible here.</p></i>
                                        </div>

                                        <div className='col-12'>

                                            {dataList.data.length > 0 ? (
                                                <>
                                                    <div className="table-responsive">
                                                        <table className="table mt-2 table-bordered">
                                                            <thead className="table-grey roboto-bold">
                                                                <tr className="">
                                                                    <th scope="col"></th>

                                                                    <th scope="col">{"Sr.No"}</th>


                                                                    <th scope="col">
                                                                        {constLabel?.lbl_so_id
                                                                            ? constLabel.lbl_so_id
                                                                            : "SO Id"}
                                                                    </th>

                                                                    <th scope="col">
                                                                        {constLabel?.lbl_party_po_ref
                                                                            ? constLabel.lbl_party_po_ref
                                                                            : "Party PO Ref"}
                                                                    </th>

                                                                    <th scope="col">
                                                                        {constLabel?.lbl_order_date
                                                                            ? constLabel.lbl_order_date
                                                                            : "Order Date"}
                                                                    </th>

                                                                    <th scope="col">
                                                                        {constLabel?.lbl_order_due_date
                                                                            ? constLabel.lbl_order_due_date
                                                                            : "Order Due Date"}
                                                                    </th>

                                                                    <th scope="col">
                                                                        {constLabel?.lbl_organization_name
                                                                            ? constLabel.lbl_organization_name
                                                                            : "Organization Name"}
                                                                    </th>

                                                                    <th scope="col">
                                                                        {constLabel?.lbl_customer_name
                                                                            ? constLabel.lbl_customer_name
                                                                            : "Customer"}


                                                                    </th>




                                                                </tr>
                                                            </thead>
                                                            <tbody className="roboto">
                                                                {dataList.data.map((val, ind) => (
                                                                    <tr key={ind}>
                                                                        <td>
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                checked={val.checked}
                                                                                onChange={(e) => {
                                                                                    // console.log("e", e);
                                                                                    dataList.data[ind].checked = !val.checked;
                                                                                    console.log("dataList", dataList);

                                                                                    setDataList({
                                                                                        ...dataList, data: dataList.data,
                                                                                        loading: false,
                                                                                        firstTime: true
                                                                                    });
                                                                                }}
                                                                            />
                                                                        </td>

                                                                        <td>{parseInt(ind) + 1}</td>

                                                                        <td>
                                                                            {val.so_id != "" && val.so_id !== null
                                                                                ? val.so_id
                                                                                : "NA"}
                                                                        </td>

                                                                        <td>
                                                                            {val.party_po_ref != "" &&
                                                                                val.party_po_ref !== null
                                                                                ? val.party_po_ref
                                                                                : "NA"}
                                                                        </td>

                                                                        <td>
                                                                            {val.order_date != "" &&
                                                                                val.order_date !== null
                                                                                ? convertDate(val.order_date, "-")
                                                                                : "NA"}
                                                                        </td>

                                                                        <td>
                                                                            {val.order_due_date != "" &&
                                                                                val.order_due_date !== null
                                                                                ? convertDate(val.order_due_date, "-")
                                                                                : "NA"}
                                                                        </td>


                                                                        <td>
                                                                            {val.org_id?.comName != "" &&
                                                                                val.org_id?.comName !== null
                                                                                ? val.org_id?.comName
                                                                                : "NA"}
                                                                        </td>

                                                                        <td>
                                                                            {val.customer_name != "" &&
                                                                                val.customer_name != null
                                                                                ? val.customer_name
                                                                                : "NA"}
                                                                        </td>

                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </>
                                            ) :
                                                dataList.firstTime && !dataList.loading &&
                                                < div
                                                    className="alert alert-danger mb-0 text-center my-4"
                                                    role="alert"
                                                >
                                                    No Sales Orders Found !
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>



                    <div className='d-flex justify-content-end mt-4'>
                        <button
                            disabled={disableBtn}
                            className='btn button-primary px-4 ms-0 ms-md-2'
                            onClick={handleSubmit(onSubmit)}
                        >Next</button>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default StepOne