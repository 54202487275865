import React, { useState, useEffect, useRef } from "react";
import logo from "../../../assets/images/proditm.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { mobile_otp, mobile_verify_otp } from "../../../constant";
import axios from "axios";
import OTPInput from "./OTPInput";

const schema = yup
  .object()
  .shape({
    email: yup.string().required("Email is required !").email("Enter valid email !"),
    contact_no: yup
      .string()
      .required("Contact no is required !")
      .matches(/^[0-9]*$/, "Enter valid contact number")
      .min(10, "Invalid contact no."),
  })
  .required();

function OTPValidation(props) {
  let navigate = useNavigate();

  const [mobOtp, setMobOtp] = useState(Array(4).fill(""));
  const mobInputs = useRef([]);

  const [emailOtp, setEmailOtp] = useState(Array(4).fill(""));
  const emailInputs = useRef([]);

  const [otpCalled, setOtpCalled] = useState(false);

  const [time, setTime] = useState(0); // Timer starts at 0
  const [isActive, setIsActive] = useState(false); // Control timer start/stop

  const { register, handleSubmit, formState, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { errors } = formState;

  useEffect(() => {
    let timer;
    if (isActive && time > 0) {
      timer = setTimeout(() => {
        setTime(time - 1);
      }, 1000);
    }

    // If time reaches 0, deactivate the timer
    if (time === 0) {
      setIsActive(false);
    }

    return () => clearTimeout(timer); // Cleanup the timer
  }, [time, isActive]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
  };

  const startTimer = () => {
    setTime(120); // Set timer to 5 minutes (300 seconds)
    setIsActive(true); // Activate the timer
  };

  const onSubmit = (data) => {
    // console.log("data", data);
    setOtpCalled(true);
    props.setMain_obj({ ...props.main_obj, company_detail: {...props.main_obj?.company_detail, contact_no: data.contact_no, email : data.email } });
    startTimer();
    send_otp(data.email, data.contact_no);
    
  };

  function resendOtp(){
    // console.log("props", props);
    startTimer();
    send_otp(props.main_obj?.company_detail?.email, props.main_obj?.company_detail?.contact_no );

    setMobOtp(Array(4).fill("")); // Reset the mobOtp array
    mobInputs.current.forEach((input) => {
      if (input) input.value = ""; // Clear each input field
    });

    setEmailOtp(Array(4).fill("")); // Reset the mobOtp array
    emailInputs.current.forEach((input) => {
      if (input) input.value = ""; // Clear each input field
    });
  }

  function verifyOtp(){

      let base_url = process.env.REACT_APP_COGNITO_AUTH_URL
      const url = `${base_url}${mobile_verify_otp}`;
      // console.log("url",url)
      const payload = {
        mobile : props.main_obj?.company_detail?.contact_no,
        mobileOtpInput : mobOtp.join(""),
        email : props.main_obj?.company_detail?.email,
        emailOtpInput : emailOtp.join("")
      }

      axios.post(url, payload)
      .then((res) => {
        // console.log("verify res", res);
        toast.success(res.data.message, {autoClose: 2000});
        props.setMain_obj({ ...props.main_obj, company_detail: {...props.main_obj?.company_detail, token: res.data.token } });
        props.nextStep();
      })
      .catch((err) => {
        console.log("err", err);
      });
  } 

  function send_otp(email, contact){
    
  let base_url = process.env.REACT_APP_COGNITO_AUTH_URL
  const url = `${base_url}${mobile_otp}`;
  // console.log("url",url)

    axios.post(url, { mobile: contact, email: email })
    .then((res) => {
      // console.log("otp res", res);
      // toast.success(res.data.message, {autoClose: 2000});
    })
    .catch((err) => {
      console.log("err", err);
    });
  }

  function maskMobile(mobile) {
    return mobile.replace(/\d(?=\d{4})/g, '*');
  }
  
  function maskEmail(email) {
    return email.replace(/(?<=^.{2}).*(?=@)/, '*'.repeat(5));
  }


  return (
    <>
      <ToastContainer />
          <div className="col-12 col-md-6 pt-5 align-items-center d-flex flex-column pb-2">
            <div className="text-center">
              <img
                src={logo}
                className="img-fluid mx-auto"
                alt="Logo"
                style={{ height: "70px", width: "150px" }} // Adjust image size
              />
              <h3 className="bold w-100 mt-3">Welcome to PRODi !</h3>
              <h4 className="w-100 my-0 mt-2">Experience the seamless</h4>
              <h4 className="w-100">onboarding process</h4>

              <p className="bold w-100 my-0 mt-3">OTP verification and validation.</p>
            </div>

            <div className="d-flex flex-column justify-content-center mt-2">
              {time > 0 && <h3 className="text-muted body2">{"OTP has sent to your registered mobile no " + maskMobile(props.main_obj?.company_detail?.contact_no) + " and email " + maskEmail(props.main_obj?.company_detail?.email) }.</h3>}
            </div>
            
            {/* <div className="d-flex justify-content-center"> */}
            { otpCalled == true ? null :
              <div className="row col-10 mt-4">
                <div className="col-12 col-md-6 mt-2">
                  <div className="form-group mt-md-0 mt-3">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label body3 roboto-bold"
                    >
                      {"Email"}<span className="text-danger"> *</span>
                    </label>

                    <input
                      type="email"
                      className={
                        errors.email
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm "
                      }
                      id="email"
                      name="email"
                      placeholder="Enter email"
                      defaultValue={
                        props.main_obj?.company_detail?.email &&
                        (props.main_obj?.company_detail?.email !== "" ||
                          props.main_obj?.company_detail?.email !== null)
                          ? props.main_obj?.company_detail?.email
                          : ""
                      }
                      {...register("email")}
                    />
                    <span className="text-danger err-msg">{errors.email?.message}</span>
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-2">
                  <div className="form-group mt-md-0 mt-3">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label body3 roboto-bold"
                    >
                      {"Contact No"}<span className="text-danger"> *</span>
                    </label>

                    <input
                      type="text"
                      className={
                        errors.contact_no
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm "
                      }
                      id="contact_no"
                      name="contact_no"
                      placeholder="Enter contact no"
                      maxLength={10}
                      defaultValue={
                        props.main_obj.contact_no &&
                        props.main_obj.contact_no
                      }
                      {...register("contact_no")}
                    />
                    <span className="text-danger err-msg">
                      {errors.contact_no?.message}
                    </span>
                  </div>
                </div>
              </div>
            }

            { otpCalled == true ? null : 
              <div className="w-50 d-flex justify-content-between">
                <button
                  className="btn button-secondary w-100 mt-4 roboto-bold me-5"
                  type="submit"
                  onClick={() => props.prevStep()}
                >
                  Back{" "}
                </button>
                <button
                  className="btn button-primary w-100 mt-4 roboto-bold"
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                //   disabled={lockbtn}
                >
                  Send OTP{" "}
                </button>
              </div>
            }

           

            {otpCalled ?
              <>   
                <OTPInput
                  length={4}
                  mobOtp={mobOtp}
                  setMobOtp={setMobOtp}
                  mobInputs={mobInputs}
                  emailOtp={emailOtp}
                  setEmailOtp={setEmailOtp}
                  emailInputs={emailInputs}
                  resendOtp={resendOtp} // Pass down the resend otp and clear inputs function
                />
                <div className="d-flex flex-column justify-content-center mt-2">
                    {time > 0 && <h3 className="text-danger body2">OTP expires in: {formatTime(time)}</h3>}
                    {time === 0 && isActive && (
                      <p className="text-muted body2">OTP has expired. Please request a new one.</p>
                    )}
                </div>
                <div className="w-50 d-flex justify-content-between">
                  <button
                    className="btn button-secondary w-100 mt-2 roboto-bold me-5"
                    type="submit"
                    onClick={() => verifyOtp()}
                    disabled={isActive == false ? true : false}
                  >
                    Verify OTP{" "}
                  </button>
                  <button
                    className="btn button-primary w-100 mt-2 roboto-bold"
                    type="submit"
                    onClick={() => resendOtp()}
                    disabled={isActive == true ? true :  false}
                  >
                    Resend OTP{" "}
                  </button>
                </div>
              </>
            : null }

            {/* </div> */}
          </div>
    </>
  );
}

export default OTPValidation;
