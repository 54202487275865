import React, { useState, useEffect, useReducer } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlus,
  faBan,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Modal, Spinner } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { Download, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import axios from "axios";
import { getLocation, getLoctionType, getLocationMapping, saveLocationMapping, deleteLocationMapping } from "../../../../api";
import { calMaxPage, setAccessPrivilage } from "../../../../common/Functions/CommonFunctions";
import EditLocationMapping from "./EditLocationMapping";
import nameConstant from "../../../../common/Labels/nameConstant.json";
import { screenList } from "../../../../common/Labels/screenList";
import { useSelector } from "react-redux";

const schema = yup
  .object()
  .shape({
    locationName: yup
      .array()
      .required("Please select location !")
      .min(1, "Please select location !"),
    locationType: yup
      .array()
      .required("Please location type !")
      .min(1, "Please select location type !"),

  })
  .required();

function LocationMapping() {

  const [visible, setVisible] = useState(false);

  const [loc_name, setLoc_Name] = useState({ data: [], state: false });
  const [loc_type, setLoc_Type] = useState({ data: [], state: false });

  const [apiBySearch, setApiBySearch] = useState(false);
  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");

  const [constLabel, setConstLabel] = useState({});


  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const [privilage, setPrivilage] = useState(setAccessPrivilage(loginData, "Location Mapping"));


  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  /*
  # Niranjan 04-12-2023
  # Get Module Name and Screen List by compare Module Name
  # Get Screen Name and Label List by compare Screen Name
*/
  useEffect(() => {
    if (Object.keys(nameConstant.data).length > 0) {
      const result = nameConstant.data.find(
        (module) => module.module_name === "Master"
      );
      
      if (Object.keys(result).length > 0) {
        const res_list = result.screen_list.find(
          (item) => item.screen_name === screenList.location_mapping_screen
        );
        
        setConstLabel(res_list.labelList);
      } else {
        setConstLabel({});
      }
    } else {
      setConstLabel({});
    }
  }, []);

  let { errors } = formState;

  useEffect(() => {
    if (visible) {
      fetchLocations();
      fetchLocationType();
    }
  }, [visible]);

  function fetchLocationType() {
    setLoc_Type({ ...loc_type, state: true, data: [] });
    getLoctionType().then(
      (res) => {
        
        setLoc_Type({
          ...loc_type, state: false, data: res.data.data,
        });
      },
      (err) => {
        //
        setLoc_Type({ ...loc_type, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }

  function fetchLocations() {
    setLoc_Name({ ...loc_name, state: true, data: [] });
    getLocation().then(
      (res) => {
        
        setLoc_Name({ ...loc_name, state: false, data: res.data.data });
      },
      (err) => {
        //
        setLoc_Name({ ...loc_name, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }

  const initialState = {
    loading: true,
    error: "",
    mappingList: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "HANDLE_ACTION":
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          mappingList: action.mappingList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    
    dispatch({
      type: "HANDLE_ACTION",
      page: state.page,
      loading: state.loading,
      error: state.error,
      mappingList: state.mappingList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetchLocationMapping();
  }, [state.page, state.itemsPerPage]);

  function fetchLocationMapping(para1, para2) {
    dispatch({
      type: "HANDLE_ACTION",
      page: para1 === "refresh" ? 1 : state.page,
      loading: true,
      error: state.error,
      mappingList: state.mappingList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      pageNo: state.page,
      entriesPerPage: state.itemsPerPage,
    };

    //

    if (para1 === "search") {
      reqPayload = {
        pageNo: state.page,
        entriesPerPage: state.itemsPerPage,
        searchVal: para2,
      };
    }

    getLocationMapping(reqPayload).then(
      (res) => {
        
        
        setTotalRecords(res.data.total);

        dispatch({
          type: "HANDLE_ACTION",
          page: state.page,
          loading: false,
          error: state.error,
          mappingList: res.data.data,
          itemsPerPage: state.itemsPerPage,
          maxPage:
            para1 === "search"
              ? calMaxPage(res.data.data.length)
              : calMaxPage(res.data.total),
        });
      },
      (err) => {
        

        if (err.response.status === 500) {
          toast.error("Something went wrong !", { autoClose: 5000 });
        }
      }
    );
  }

  const onSubmit = (data) => {
    

    if (data !== "") {
      const reqPayload = {
        typename: data.locationType[0]?.typename,
        location: data.locationName[0]?.locationname,
      };

      

      saveLocationMapping(reqPayload)
        .then(
          (res) => {
            
            if (res.status === 200 || res.status === 201) {
              toast.success("Location Mapping created Successfully !", {
                autoClose: 3000,
              });
              setVisible(false);
              fetchLocationMapping();
            }
          },
          (err) => {
            
            if (err.response.status === 400) {
              
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
              reset();
            }
          }
        )
    } else {
      errors.showMessages();
    }
  };

  // Deleting location type
  const delMapping = (val) => {
    
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          uuid: val.uuid,
        };
        deleteLocationMapping(payload).then(
          (res) => {
            
            toast.success("Location mapping deleted successfully !", {
              autoClose: 3000,
            });
            fetchLocationMapping();
          },
          (err) => {
            
            toast.error("Something went wrong !", { autoClose: 3000 });
          }
        );
      }
    });
  };

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }


  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}
      <div className="min-vh-100" id="root_div_main">
        <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>{constLabel?.lbl_add_location_mapping ? constLabel.lbl_add_location_mapping : "Add Location Mapping"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form action="">
              <div className="form-group row mt-2">
                <label style={{ fontSize: "14px" }} className="col-form-label col-md-12 roboto-bold">
                  {constLabel?.lbl_location_type ? constLabel.lbl_location_type : "Location Type"}
                  <span>
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-12">
                  <Controller
                    name="locationType"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="typename"

                        multiple={false}
                        options={loc_type.data !== undefined ? loc_type.data : []}
                        isLoading={loc_type.state}
                        placeholder="Select location type"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.locationType ? true : false}
                      />
                    )}
                  />
                  <span className="text-danger err-msg">
                    {errors.locationType?.message}
                  </span>
                </div>
              </div>

              <div className="form-group row mt-2">
                <label style={{ fontSize: "14px" }} className="col-form-label col-md-12 roboto-bold">
                  {constLabel?.lbl_location_name ? constLabel.lbl_location_name : "Location Name"}
                  <span>
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-12">
                  <Controller
                    name="locationName"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="locationname"

                        multiple={false}
                        options={loc_name.data !== undefined ? loc_name.data : []}
                        isLoading={loc_name.state}
                        placeholder="Select location"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.locationName ? true : false}
                      />
                    )}
                  />
                  <span className="text-danger err-msg">
                    {errors.locationName?.message}
                  </span>
                </div>
              </div>

            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleSubmit(onSubmit)}
              className="f-16 btn btn-yellow "
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
            </button>
          </Modal.Footer>
        </Modal>
        <div id="mySidebar" className="customsidebar ">
          <a className="closebtn" onClick={() => closeNav()}>
            ×
          </a>

          <div className=" content">
            <div className="filter row">
              <label
                className="filterLabel mb-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_search ? constLabel.lbl_search : "Search"}
              </label>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Search by name"
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      fetchLocationMapping("search", e.target.value);
                      setApiBySearch(true);
                    } else {
                      fetchLocationMapping();
                    }
                  }}
                />
              </div>
            </div>
            <div className="my-3 d-flex justify-content-end">
              <button className="btn btn-sm btn-yellow">
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}

                {constLabel?.lbl_clear_filter ? constLabel.lbl_clear_filter : "Clear Filter"}
              </button>
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="bold">{"Location Mapping"}</h1>
            <div>
              <span
                className="cursor_pointer px-3 me-0 me-md-2"
                onClick={() => {
                  openNav();
                }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "5px" }}
                  icon={faFilter}
                  size="sm"
                />
                {constLabel?.lbl_filter_menu ? constLabel.lbl_filter_menu : "Filter Menu"}
              </span>

              {privilage.write &&
                <button
                  className="btn button-primary px-4"
                  onClick={() => {
                    setVisible(true);
                    reset();
                  }}
                >
                  <FontAwesomeIcon
                    style={{ color: "#fff" }}
                    icon={faPlus}
                    size="sm"
                  />{" "}
                  {constLabel?.lbl_add_location_mapping ? constLabel.lbl_add_location_mapping : "Add Location Mapping"}
                </button>
              }
            </div>
          </div>
          <div className="card border-0 mt-3">
            <div className="d-flex justify-content-end mt-1 me-3">
              {/* <div className="col-md-12 roboto-bold">
            <div className="input-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search by category"
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    fetchLocationMapping("search", e.target.value);
                    setApiBySearch(true);
                  } else {
                    fetchLocationMapping();
                  }
                }}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <FontAwesomeIcon
                    style={{ color: "#918c8c", padding: "2px" }}
                    icon={faSearch}
                    size="lg"
                  />
                </span>
              </div>
            </div>
          </div> */}
              {/* <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">{"Download CSV"}</Tooltip>}
            >
              <div className="downloadIcon">
                <Download
                  className="my-download-svg"
                  // onClick={() => ExportToExcel("xlsx")}
                  onClick={fetchDataAndDownloadCSV}
                />
              </div>
            </OverlayTrigger> */}
            </div>
            <div className="card-body">
              <div className="">
                {state.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : state.mappingList && state.mappingList.length > 0 ? (
                  <div className="table-responsive">
                    <table
                      className="table table-bordered table-sm"
                      width={"100%"}
                    >
                      <thead className="table-grey roboto">
                        <tr className="f-14 ">
                          <th scope="col" width="15%">
                            {"Sr.No"}
                          </th>
                          <th scope="col">{constLabel?.lbl_location_type ? constLabel.lbl_location_type : "Location Type"}</th>
                          <th scope="col">{constLabel?.lbl_location_name ? constLabel.lbl_location_name : "Location Name"}</th>
                          <th scope="col" width="15%">
                            {"Action"}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="roboto">
                        {state.mappingList.map((val, i) => (
                          <tr key={i} className="">
                            <td>{i + srNo + 1}</td>
                            <td>{val.typename}</td>
                            <td>{val.location}</td>
                            <td>
                              {privilage.write &&
                                <EditLocationMapping
                                  data={val}
                                  constLabel={constLabel}
                                  fetchLocationMapping={fetchLocationMapping}
                                />}


                              {privilage.delete && <>
                                <Tooltip id={"delete-tooltip"} place="top" />
                                <div
                                  data-tooltip-id={"delete-tooltip"}
                                  data-tooltip-content={constLabel?.lbl_delete_mapping ? constLabel.lbl_delete_mapping : "Delete Mapping"}
                                  className="deleteIcon me-2"
                                  onClick={() => delMapping(val)}
                                >
                                  <Trash2 color="#FFFFFF" size={18} />
                                </div>
                              </>}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : apiBySearch ? (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"No mapping list Found !"}
                  </p>
                ) : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"Mapping list is empty"}
                  </p>
                )}

                {state.loading ? null : (
                  <div className="mt-4 d-flex justify-content-between">
                    <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                      } to ${Math.min(
                        parseInt(state.itemsPerPage) + parseInt(srNo),
                        totalRecords
                      )} of ${totalRecords} entries`}</p>
                    <Pagination className="my-2">
                      <Pagination.Prev
                        disabled={state.page === 1 ? true : false}
                        onClick={() => {
                          dispatch({
                            type: "HANDLE_ACTION",
                            page: state.page - 1,
                            loading: state.loading,
                            error: state.error,
                            mappingList: state.mappingList,
                            itemsPerPage: state.itemsPerPage,
                            maxPage: state.maxPage,
                          });
                          setSrNo((prevC) => prevC - 10);
                        }}
                      >
                        {"Prev"}
                      </Pagination.Prev>

                      <Pagination.Item active>{state.page}</Pagination.Item>

                      <Pagination.Next
                        disabled={
                          state.page === state.maxPage || state.maxPage === 0
                            ? true
                            : false
                        }
                        onClick={() => {
                          dispatch({
                            type: "HANDLE_ACTION",
                            page: state.page + 1,
                            loading: state.loading,
                            error: state.error,
                            mappingList: state.mappingList,
                            itemsPerPage: state.itemsPerPage,
                            maxPage: state.maxPage,
                          });
                          setSrNo((prevC) => prevC + 10);
                        }}
                      >
                        {"Next"}
                      </Pagination.Next>
                    </Pagination>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default LocationMapping