import React, { useEffect, useState } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear, faArrowRightFromBracket, faInfo, faCircleChevronDown, faChevronDown, } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../slices/authentication/authSlice";
import Dropdown from "react-bootstrap/Dropdown";
import routes from "../Routes/routes";
import nc from "../assets/images/icons/navrbar_icons/nc.svg";
import nc_dashboard2 from "../assets/images/icons/navrbar_icons/nc_dashboard2.svg";
import pi_icon from "../assets/images/icons/navrbar_icons/pi_icon.svg";
import po_icon from "../assets/images/icons/navrbar_icons/po_icon.svg";
import quotation_icon from "../assets/images/icons/navrbar_icons/quotation_icon.svg";
import rmi_icon from "../assets/images/icons/navrbar_icons/rmi_icon.svg";
import sales_order_icon2 from "../assets/images/icons/navrbar_icons/sales_order_icon2.svg";
import user_master_icon from "../assets/images/icons/navrbar_icons/user_master_icon.svg";
import production_data_icon from "../assets/images/icons/navrbar_icons/production_data_icon.svg";
import work_order_icon from "../assets/images/icons/navrbar_icons/work_order_icon.svg";
import job_card_icon2 from "../assets/images/icons/navrbar_icons/job_card_icon2.svg";
import goods_challan_icon from "../assets/images/icons/navrbar_icons/goods_challan_icon.svg";
import inventory_icon from "../assets/images/icons/navrbar_icons/inventory_icon.svg";
import production_plan from "../assets/images/icons/navrbar_icons/production_plan.png";

const NavbarComp = () => {
  let navigate = useNavigate();
  let url = useLocation();
  let dispatch = useDispatch();
  const [navMenu, setNavMenu] = useState([]);
  const [subRoutesOfActive, setSubRoutesOfActive] = useState([]);
  const rmi_config = JSON.parse(localStorage.getItem("configuration"));

  let ncRoutes = [
    "/pcms/nc",
    "/pcms/nc-create",
    "/pcms/nc-update",
    "/pcms/nc-dashboard",
  ];
  const login_data = useSelector((state) => state.auth);
  const new_login_data = login_data.loginData?.data?.response;

  const userInfo = localStorage.getItem("user_name");
  // console.log("userInfo", userInfo);

  function findAndSetSubRoutes(screens) {
    let tempList = [];
    for (let i = 0; i < screens.length; i++) {
      tempList.push([]);
      tempList[i].push(screens[i].route);
      if (screens[i].screens !== undefined) {
        for (let j = 0; j < screens[i].screens.length; j++) {
          let data = routes.filter(
            (rou) => rou.screenForMaster == screens[i].screens[j].screen_name
          );

          if (data.length > 0) tempList[i].push(data[0].path);
        }
      }
    }

    const index = tempList.findIndex((array) => array.includes(url.pathname));

    // let currModule = screens.filter((val) => url.pathname == val.route);

    let temp = [];

    if (index >= 0) {
      screens[index]?.screens?.map((val) =>
        routes.filter((rou) => {
          if (val.screen_name.toLowerCase() == "nc") {
            if (
              rou.screenForMaster?.toLowerCase() ==
              val.screen_name.toLowerCase() ||
              rou.screenForMaster?.toLowerCase() == "quality"
            ) {
              temp.push(rou.path);
            }
          } else {
            if (
              rou.screenForMaster?.toLowerCase() ==
              val.screen_name.toLowerCase()
            ) {
              temp.push(rou.path);
            }
          }
        })
      );
      setSubRoutesOfActive(temp);
    }
  }

  useEffect(() => {
    let tempNavMenu = [];
    // console.log("new_login_data", new_login_data);

    if (
      new_login_data?.role_name !== "admin" &&
      new_login_data?.role_name !== "client_admin"
    ) {
      // tempNavMenu.push({ name: 'Master', route: "/pcms/masterMenu" });
      for (let i = 0; i < new_login_data?.module_list.length; i++) {
        // if (new_login_data?.module_list[i].screens.length < 4 && new_login_data?.module_list[i].screens.length !== 0) {

        switch (new_login_data?.module_list[i].module) {
          case "Operations Master":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "/pcms/operation",
            });
            break;
          case "Quality":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "/pcms/nc",
              screens:
                login_data.loginData.data.response.module_list[i].screens,
            });
            break;
          case "Attendance":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "",
            });
            break;
          case "Payroll":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "",
            });
            break;
          case "Invoices":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "",
            });
            break;
          case "Sales":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "",
            });
            break;
          case "Dispatch":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "",
            });
            break;
          case "KPI Definition":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "",
            });
            break;
          case "MIS":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "",
            });
            break;
          case "Quality Dashboard":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "/pcms/nc-dashboard",
            });
            break;
          case "Production Dashboard":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "",
            });
            break;
          case "Production Lot":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "",
            });
            break;
          case "Production Cut Lot":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "",
            });
            break;
          case "Role Management":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "/pcms/roles",
            });
            break;
          case "User Management":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "/pcms/appUsers",
            });
            break;
          case "Machine Master":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "",
            });
            break;
          case "Item Operations":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "",
            });
            break;
          case "Item Master":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "",
            });
            break;
          case "Party Master":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "",
            });
            break;
          case "Inventory Management":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "",
            });
            break;
          case "Module Master":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "/pcms/modules",
            });
            break;
          case "Product":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "/pcms/product",
              screens:
                login_data.loginData.data.response.module_list[i].screens,
            });
            break;
          case "Customer Type":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "/pcms/customerType",
              screens:
                login_data.loginData.data.response.module_list[i].screens,
            });
            break;
          case "Customer":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "/pcms/customer",
              screens:
                login_data.loginData.data.response.module_list[i].screens,
            });
            break;
          case "Supplier Type":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "/pcms/supplierType",
              screens:
                login_data.loginData.data.response.module_list[i].screens,
            });
            break;
          case "Master":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "/pcms/masterMenu",
              screens:
                login_data.loginData.data.response.module_list[i].screens,
            });
            break;
          case "Supplier":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "/pcms/supplier",
              screens:
                login_data.loginData.data.response.module_list[i].screens,
            });
            break;
          case "Operation Category":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "/pcms/operationCategory",
              screens:
                login_data.loginData.data.response.module_list[i].screens,
            });
            break;
          case "Production":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "/pcms/production-data",
              screens:
                login_data.loginData.data.response.module_list[i].screens,
            });
            break;
          case "Employee Master":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "/pcms/employee",
              screens:
                login_data.loginData.data.response.module_list[i].screens,
            });
            break;

          case "Purchase":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "/",
              screens:
                login_data.loginData.data.response.module_list[i].screens,
            });
            break;
          case "Inventory":
            tempNavMenu.push({
              name: login_data.loginData.data.response.module_list[i].module,
              route: "/",
              screens:
                login_data.loginData.data.response.module_list[i].screens,
            });
            break;
        }

        // }
      }
    }

    setNavMenu(tempNavMenu);
    findAndSetSubRoutes(tempNavMenu);
  }, [url.pathname]);

  function logoutuser() {
    localStorage.clear();

    dispatch(logout());
    navigate("/login");
  }

  function findRoute(scrval) {
    // else if (scrval.screen_name.toLowerCase() == "nc dashboard" || scrval.screen_name.toLowerCase() == "quality dashboard") {

    //   if (rou.screenForMaster?.toLowerCase() == scrval.screen_name.toLowerCase()
    //     || "nc dashboard" == scrval.screen_name?.toLowerCase()) {

    //     return rou
    //   }
    // }
    let result = [];
    // console.log("routes", routes);
    // console.log("scrval", scrval);

    for (let i = 0; i < routes.length; i++) {
      if (routes[i].screenForMaster !== undefined) {
        if (scrval.screen_name.toLowerCase() == "nc") {
          if (
            routes[i].screenForMaster?.toLowerCase() ==
            scrval.screen_name.toLowerCase() ||
            routes[i].screenForMaster?.toLowerCase() == "quality"
          ) {
            result.push(routes[i]);
          }
        } else if (
          scrval.screen_name.toLowerCase() == "nc dashboard" ||
          scrval.screen_name.toLowerCase() == "quality dashboard"
        ) {
          if (routes[i].screenForMaster?.toLowerCase() == "quality dashboard") {
            result.push(routes[i]);
          }
        } else {
          if (
            routes[i].screenForMaster?.toLowerCase() ==
            scrval.screen_name.toLowerCase()
          ) {
            result.push(routes[i]);
          }
        }
      }
    }

    // if the we dont find the route required to navigate then give slash
    if (result.length > 0) {
      return result[0].path;
    } else {
      return "/";
    }
  }
  // function findRoute2(sval) {

  //   let result = routes.filter((rou) => {
  //     if (sval.screen_name.toLowerCase() == "nc") {
  //       if (rou.screenForMaster?.toLowerCase() == sval.screen_name.toLowerCase()
  //         || rou.screenForMaster?.toLowerCase() == "quality") {
  //         return rou
  //       }
  //     } else {
  //       if (rou.screenForMaster?.toLowerCase() == sval.screen_name.toLowerCase()) {
  //         return rou
  //       }
  //     }

  //     if (sval.screen_name.toLowerCase() == "nc dashboard" || sval.screen_name.toLowerCase() == "quality dashboard") {

  //       if (rou.screenForMaster?.toLowerCase() == sval.screen_name.toLowerCase()
  //         || "nc dashboard" == sval.screen_name?.toLowerCase()) {

  //         return rou
  //       }
  //     }

  //   });

  //   return result[0].path
  // }

  function AdminOptions() {
    return (
      <Nav className="me-auto" id="main_link_nav">
        {new_login_data?.role_name !== "admin" &&
          new_login_data?.role_name !== "client_admin" ? (
          navMenu.map((val, ind) =>
            val.screens !== undefined &&
              val.screens?.length > 0 &&
              val.screens?.length < 5 ? (
              <NavDropdown
                key={ind}
                active={
                  subRoutesOfActive.includes(url.pathname) &&
                  subRoutesOfActive.includes(val.route)
                }
                title={val.name}
                id="nav-dropdown"
              >
                {val.screens.map((sval, si) => (
                  <NavDropdown.Item
                    key={si}
                    active={url.pathname == findRoute(sval)}
                    onClick={() => {
                      navigate(findRoute(sval));
                    }}
                  >
                    {sval.display_name}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>
            ) : val.screens !== undefined && val.screens?.length > 4 ? (
              <Nav.Link
                key={ind}
                onClick={() => navigate("/pcms/grid-menu", { state: val })}
                active={
                  url.pathname == "/pcms/grid-menu" ||
                  subRoutesOfActive.includes(url.pathname) ||
                  subRoutesOfActive.includes(val.route)
                }
              >
                {val.name}
              </Nav.Link>
            ) : (
              // val.screens !== undefined && val.screens?.length > 0 ?
              <Nav.Link
                key={ind}
                onClick={() => navigate(val.route)}
                active={url.pathname == val.route}
              >
                {val.name}
              </Nav.Link>
            )
            // : null
          )
        ) : (
          // below will be executed only if the user role is admin or client_admin
          <>
            <Nav.Link
              onClick={() => navigate("/pcms/adminDashboard")}
              active={url.pathname == "/pcms/adminDashboard"}
            >
              {"Home"}
            </Nav.Link>

            <NavDropdown
              title="NC"
              id="nav-dropdown"
              active={ncRoutes.includes(url.pathname)}
            >
              <NavDropdown.Item onClick={() => navigate("/pcms/nc")}>
                <img
                  src={nc}
                  height="20"
                  width="20"
                  className="me-2 nav_icon_color"
                />

                {"NC"}
              </NavDropdown.Item>
              <Dropdown.Divider />
              <NavDropdown.Item onClick={() => navigate("/pcms/nc-dashboard")}>
                <img
                  src={nc_dashboard2}
                  height="21"
                  width="21"
                  className="me-2 nav_icon_color"
                />

                {"NC Dashboard"}
              </NavDropdown.Item>
            </NavDropdown>

            <Nav.Link
              active={url.pathname == "/pcms/masterMenu"}
              onClick={() => navigate("/pcms/masterMenu")}
            >
              {"Masters"}
            </Nav.Link>

            <NavDropdown title="Purchase" id="nav-dropdown">
              {/* <NavDropdown.Item onClick={() => navigate('/pcms/purchaseOrder')}>PO</NavDropdown.Item> */}
              <NavDropdown.Item onClick={() => navigate("/pcms/quotation")}>
                <img
                  src={quotation_icon}
                  height="20"
                  width="20"
                  className="me-2 nav_icon_color"
                />
                {"Supplier Quotation"}
              </NavDropdown.Item>
              <Dropdown.Divider />
              <NavDropdown.Item
                onClick={() => navigate("/pcms/purchase-order")}
              >
                <img
                  src={po_icon}
                  height="22"
                  width="22"
                  className="me-2 nav_icon_color"
                />
                {"Purchase Order"}
              </NavDropdown.Item>
              <Dropdown.Divider />
              <NavDropdown.Item
                onClick={() => navigate("/pcms/purchaseInward")}
              >
                <img
                  src={pi_icon}
                  height="22"
                  width="22"
                  className="me-2 nav_icon_color"
                />
                {"Purchase Inward"}
              </NavDropdown.Item>
              <Dropdown.Divider />
              <NavDropdown.Item
                onClick={() => navigate("/pcms/material-request")}
              >
                <img
                  src={pi_icon}
                  height="22"
                  width="22"
                  className="me-2 nav_icon_color"
                />
                {"Material Request"}
              </NavDropdown.Item>
              <Dropdown.Divider />
              {/* <NavDropdown.Item onClick={() => navigate('/pcms/rawMaterialInward')}>RMI</NavDropdown.Item> */}
              {rmi_config && rmi_config.isBarConfigure && (
                <NavDropdown.Item onClick={() => navigate("/pcms/rmi")}>
                  <img
                    src={rmi_icon}
                    height="22"
                    width="22"
                    className="me-2 nav_icon_color"
                  />
                  {"RMI"}
                </NavDropdown.Item>
              )}
            </NavDropdown>

            <NavDropdown title="Sales" id="nav-dropdown">
              <NavDropdown.Item onClick={() => navigate("/pcms/sales-order")}>
                <img
                  src={sales_order_icon2}
                  height="21"
                  width="21"
                  className="me-2 nav_icon_color"
                />
                {"Sales Order"}
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="User Management" id="nav-dropdown">
              <NavDropdown.Item onClick={() => navigate("/pcms/appUsers")}>
                <img
                  src={user_master_icon}
                  height="20"
                  width="20"
                  className="me-2 nav_icon_color"
                />

                {"User"}
              </NavDropdown.Item>
              {/* <NavDropdown.Item onClick={() => navigate('/pcms/roles')}>Role</NavDropdown.Item> */}
            </NavDropdown>

            <NavDropdown title="Production" id="nav-dropdown">
              <NavDropdown.Item
                onClick={() => navigate("/pcms/production_planning")}
              >
                <img
                  src={production_plan}
                  height="21"
                  width="21"
                  className="me-2 nav_icon_color"
                />
                {"Production Planning"}
              </NavDropdown.Item>
              <Dropdown.Divider />

              <NavDropdown.Item
                onClick={() => navigate("/pcms/production-data")}
              >
                <img
                  src={production_data_icon}
                  height="21"
                  width="21"
                  className="me-2 nav_icon_color"
                />
                {"Production Data"}
              </NavDropdown.Item>
              <Dropdown.Divider />
              <NavDropdown.Item
                onClick={() => navigate("/pcms/production-dashboard")}
              >
                <img
                  src={nc_dashboard2}
                  height="21"
                  width="21"
                  className="me-2 nav_icon_color"
                />
                {"Production Dashboard"}
              </NavDropdown.Item>
              <Dropdown.Divider />
              <NavDropdown.Item onClick={() => navigate("/pcms/work-order")}>
                <img
                  src={work_order_icon}
                  height="21"
                  width="21"
                  className="me-2 nav_icon_color"
                />
                {"Work Order"}
              </NavDropdown.Item>
              <Dropdown.Divider />

              <NavDropdown.Item onClick={() => navigate("/pcms/job-card")}>
                <img
                  src={job_card_icon2}
                  height="22"
                  width="22"
                  className="me-2 nav_icon_color"
                />
                {"Job Card"}
              </NavDropdown.Item>
              {/* rmi_config.isBarConfigure	&&<NavDropdown.Item onClick={() => navigate('/pcms/cutlot')}>{"Cut Lot"}</NavDropdown.Item> */}
            </NavDropdown>

            <NavDropdown title="Inventory" id="nav-dropdown">
              <NavDropdown.Item
                onClick={() => navigate("/pcms/goodsMovementChallan")}
              >
                <img
                  src={goods_challan_icon}
                  height="20"
                  width="20"
                  className="me-2 nav_icon_color"
                />
                {"Goods Challan"}
              </NavDropdown.Item>
              <Dropdown.Divider />
              <NavDropdown.Item onClick={() => navigate("/pcms/dispatch")}>
                <img
                  src={pi_icon}
                  height="20"
                  width="20"
                  className="me-2 nav_icon_color"
                />
                {"Dispatch"}
              </NavDropdown.Item>
              <Dropdown.Divider />
              <NavDropdown.Item onClick={() => navigate("/pcms/report/stock-availability")}>
                <img
                  src={inventory_icon}
                  height="20"
                  width="20"
                  className="me-2 nav_icon_color"
                />
                {"Stock Availability"}
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link
              onClick={() => navigate("/pcms/machineMQTT")}
              active={url.pathname == "/pcms/machineMQTT"}
            >
              {"Machine Dashboard"}
            </Nav.Link>
            {/* <NavDropdown title="Report" id="nav-dropdown">
              <NavDropdown.Item
                onClick={() => navigate("/pcms/report/stock-availability")}
              >
                <img
                  src={inventory_icon}
                  height="22"
                  width="22"
                  className="me-2 nav_icon_color"
                />
                {"Stock Availability"}
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => navigate('/pcms/report/stock-ledger')}>{"Stock Ledger"}</NavDropdown.Item>
            </NavDropdown> */}
          </>
        )}
      </Nav>
    );
  }

  return (
    <Navbar expand="lg" id="navbar">
      <Container fluid style={{ padding: "0px 22px" }}>
        <Navbar.Toggle aria-controls="navbar-light-example" />

        <AdminOptions />

        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="navbar-light-example">
          <Nav className="ms-auto">
            <NavDropdown
              id="nav-dropdown"
              title={
                <span style={{ color: "black" }}>
                  Welcome <b>{userInfo}</b>{" "}
                  <FontAwesomeIcon
                    style={{ color: "black" }}
                    icon={faChevronDown}
                  // size="lg"
                  />
                </span>
              }
              menuVariant="light"
              align={"start"}
            >
              {new_login_data?.role_name == "admin" ||
                new_login_data?.role_name == "client_admin" ? (
                <NavDropdown.Item
                  onClick={() => navigate("/pcms/orgnization/hierarchy-view")}
                >
                  {/* // onClick={() => navigate("/pcms/orgnization/hierarchyview")}> */}
                  <FontAwesomeIcon
                    icon={faInfo}
                    size="sm"
                    className="me-2 nav_icon_color"
                  />
                  {"Company Details"}
                </NavDropdown.Item>
              ) : null}
              <NavDropdown.Item
                onClick={() => navigate("/pcms/setting")}>

                <FontAwesomeIcon
                  icon={faGear}
                  size="sm"
                  className="me-2 nav_icon_color"
                />

                {"Setting"}</NavDropdown.Item>
              <Dropdown.Divider />
              <NavDropdown.Item
                onClick={() => logoutuser()}
                className="text-danger"
              >
                <FontAwesomeIcon
                  icon={faArrowRightFromBracket}
                  size="sm"
                  className="me-2 icon-danger"
                />
                {"Logout"}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default NavbarComp;
