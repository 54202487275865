import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import NavbarComp from "../Navbar/NavbarComp";
import AppContent from "./AppContent";
import Footer from "../FooterSection/Footer"
import NavbarErrorBoundary from "../Components/Common/NavbarErrorBoundary";
import NullHandleErrorBoundary from "../Components/Common/ErrorBoundaries/NullHandleErrorBoundary";

function DefaultLayout() {
  return (
    <React.Fragment>
      <div className="min-vh-100" id="root_div">
        {/* <Sidebar /> */}
        {/* <NavbarErrorBoundary> */}
        <NavbarComp />
        {/* </NavbarErrorBoundary> */}
        <div id="root_body" >
          <NullHandleErrorBoundary>
            {/* <div id="root_body" style={{ marginLeft: "64px" }}> */}
            <AppContent />
          </NullHandleErrorBoundary>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default DefaultLayout;
