import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faBan,
  faFilter,
  faCog,
  faAdd,
  faDownload,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Modal, Spinner } from "react-bootstrap";
import { Eye, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
// import XLSX from "xlsx";
import { Tooltip } from "react-tooltip";
import {
  getGlobalRouting,
  getOperationCat,
  deleteOperation,
  createOperation,
  getOperation,
  getMachineList,
  get_operation_template,
  upload_operation,
  deleteGlobalRouting,
  createGlobalRouting,
} from "../../../api/index";
import {
  calMaxPage_new,
  getLabels,
  isExcelFile,
  setAccessPrivilage,
} from "../../../common/Functions/CommonFunctions";
import Edit_Global_Routing from "./Edit_Global_Routing";
import { Rounting_Col_name } from "../../../common/Labels/labelConstant";
import { useSelector } from "react-redux";
import List from "../../../assets/images/icons/project_menu/checklist.png";

const schema = yup
  .object()
  .shape({
    routeName: yup.string().required("Route name is required !"),
    items: yup.array().of(
      yup.object().shape({
        sequenceNo: yup
          .string()
          .required("Sequence no is required !")
          .matches(/^[0-9]*$/, "Enter sequence no"),
        operation: yup
          .array()
          .min(1, "Please select operation !")
          .required("Please select operation !"),
        operationCost: yup
          .string()
          .matches(/^$|^\d+(\.\d+)?$/, "Enter operation cost")
          .notRequired(),
        manHourCost: yup
          .string()
          .matches(/^$|^\d+(\.\d+)?$/, "Enter man hour cost")
          .notRequired(),
        machine: yup
          .array()
          // .min(1, "Please select machine !")
          .notRequired(),
        cycleTime: yup
          .string()
          .matches(/^$|^\d+(\.\d+)?$/, "Enter valid cycle time !")
          .notRequired(),
        settingTime: yup
          .string()
          .matches(/^$|^\d+(\.\d+)?$/, "Enter valid setting time !")
          .notRequired(),
      })
    ),
  })
  .required();

function Global_Routing() {
  const [showLabel, setShowLabel] = useState(Rounting_Col_name);
  const [visible, setVisible] = useState(false);

  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [route_data, setRoute_Data] = useState({data: [], loading: false});
  const [constLabel, setConstLabel] = useState({});

  const [machineList, setMachineList] = useState({ data: [], loading: false });
  const [operList, setOperList] = useState({ data: [], loading: false });

  const [filterConfig, setFilterConfig] = useState({ searchVal: "" });

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const [privilage, setPrivilage] = useState(
    setAccessPrivilage(loginData, "Routing Master")
  );

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      items: [
        {
          sequenceNo: "",
          operation: [],
          operationCost: "0",
          manHourCost: "0",
          machine: [],
          cycleTime: "0",
          settingTime: "0",
        },
      ],
    },
  }); // initialise the hook

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  useEffect(() => {
    // resetting the form
    if (visible) {
      fetchOperations();
      fetchMachineList();
    }
  }, [visible]);

  useEffect(() => {
    setConstLabel(getLabels("Master", "Routing Master"));
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchGlobalRouting(page, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function fetchGlobalRouting(pg, epp) {
    setDataList({ ...dataList, loading: true, data: [] });

    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
    };

    // if (filterConfig.searchVal !== "") {
    //   // payload.productName_list = filterConfig.productName;
    //   payload.searchVal = filterConfig.searchVal;

    //   if (
    //     filterConfig.searchVal?.length > 0 &&
    //     page !== 1 &&
    //     sea_val == false
    //   ) {
    //     payload.pageNo = 1;
    //     payload.entriesPerPage = entriesPerPage;

    //     setPage(1);
    //     setSrNo(0);
    //   }
    // } else {
    //   delete payload.searchVal;
    // }

    getGlobalRouting(payload).then(
      (res) => {
        console.log("res", res);
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });

        // if (
        //   filterConfig.searchVal !== "" &&
        //   filterConfig.searchVal?.length > 0
        // ) {
        //   setSea_val(true);
        // } else {
        //   setSea_val(false);
        // }

        // console.log('calMaxPage_new(res.data.total)', calMaxPage_new(res.data.total,epp));
        setMaxPage(calMaxPage_new(res.data.total, epp));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, loading: false, data: [] });
      }
    );

  }

  function fetchOperations() {
    setOperList({ ...operList, data: [], loading: true });
    getOperation().then(
      (res) => {
        if (res.data.data.length > 0) {
          const result = res.data.data.filter((item) => item.operationName !== "");
          setOperList({ ...operList, data: result, loading: false });
        } else {
          toast.error("Operations not found !");
        }
      },
      (err) => {
        setOperList({ ...operList, data: [], loading: false });
      }
    );
  }

  function fetchMachineList() {
    setMachineList({ ...machineList, data: [], loading: true });
    getMachineList().then(
      (res) => {
        if (res.data.data.length > 0) {
          const result = res.data.data.map((item) => ({
            ...item,
            machineNameSerialNo: item.machineName + " - " + item.serialNo,
          }));
          setMachineList({ ...machineList, data: result, loading: false });
        } else {
          setMachineList({ ...machineList, data: [], loading: false });
        }
      },
      (err) => {
        setMachineList({ ...machineList, data: [], loading: false });
      }
    );
  }

  const onSubmit = (data) => {
    if (data !== "") {
      let routeArr = [];
      if (data.items?.length > 0) {
        for (let i = 0; i < data.items?.length; i++) {
          const payload = {
            sequence_no: Number(data.items?.[i]?.sequenceNo),
            operation_id: data.items?.[i]?.operation?.[0]?.uuid,
            operationname: data.items?.[i]?.operation?.[0]?.operationName,
            operationCategoryId: data.items?.[i]?.operation?.[0]?.operationCategoryId,
            operation_category_id: data.items?.[i]?.operation?.[0]?.operation_category_id,
            oprationCategoryName: data.items?.[i]?.operation?.[0]?.oprationCategoryName,
            operation_cost: data.items?.[i]?.operationCost ? Number(data.items?.[i]?.operationCost) : 0,
            man_hour_cost: data.items?.[i]?.manHourCost ? Number(data.items?.[i]?.manHourCost) : 0,
            machine_id_id: data.items[i]?.machine ? data.items[i]?.machine?.[0]?.uuid : null ,
            machine_name: data.items[i]?.machine ? data.items[i]?.machine?.[0]?.machineName : null,
            serialNo: data.items[i]?.machine ? data.items[i]?.machine?.[0]?.serialNo : null,
            cycle_time: data.items[i]?.cycleTime ? data.items[i]?.cycleTime : null,
            setting_time: data.items[i]?.settingTime ? data.items[i]?.settingTime : null,
          };
          routeArr.push(payload);
        }
      }

      const reqPayload = {
        routeName: data.routeName,
        routeData: routeArr,
      };

      // console.log("reqPayload", reqPayload);

      createGlobalRouting(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Routing created successfully !", {
              autoClose: 2000,
            });
            fetchGlobalRouting(page, entriesPerPage);
            setVisible(false);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  // Deleting global routing master
  const delRouting = (val) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          uuid: val.uuid,
        };
        deleteGlobalRouting(payload).then(
          (res) => {
            toast.success("Routing data deleted successfully !", {
              autoClose: 2000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchGlobalRouting(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchGlobalRouting(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", { autoClose: 3000 });
          }
        );
      }
    });
  };

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function clearFilter() {
    setFilterConfig({ ...filterConfig, searchVal: "" });
    setSrNo(0);
    setPage(1);
  }

  function toggleDiv(ind) {
    const selRow = document.getElementById("collapse_row" + ind);

    selRow.classList.toggle("expanded");
  }


  return (
    <React.Fragment>
      <ToastContainer />
      
      {/* Add Item Modal*/}
      <div className="min-vh-100" id="root_div_main">

        <Modal
          show={visible}
          onHide={() => setVisible(false)}
          size={"xl"}
          backdrop="static"
        >
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>
              {constLabel?.lbl_add_routing
                ? constLabel.lbl_add_routing
                : "Add Routing"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form action="">
              <div className="row">
                <div className="col-12 col-md-3">
                  <label
                    className="lbl-style roboto-bold"
                    style={{ fontSize: "14px" }}
                  >
                    {constLabel?.lbl_route_name
                      ? constLabel.lbl_route_name
                      : "Route Name"}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className={
                      errors.routeName
                        ? "form-control is-invalid mt-2"
                        : "form-control mt-2 "
                    }
                    id="routeName"
                    name="routeName"
                    placeholder="Enter route name"
                    {...register("routeName")}
                  />
                  {errors.routeName && (
                    <span className="text-danger err-msg">
                      {errors.routeName.message}
                    </span>
                  )}
                </div>
              </div>
              <hr className="mb-1" />
              <div className="d-flex justify-content-start align-items-center">
                <p className="roboto-bold mb-0" style={{ fontSize: "16px" }}>
                  {constLabel?.lbl_routing_detail
                    ? constLabel?.lbl_routing_detail
                    : "Routing Detail"}
                </p>

              </div>
              <hr className="mt-1" />
              <div className="col-12">
                {/* {fields.length > 0 ? ( */}
                <div className="">
                  <table className="table my-2 table-stirped table-bordered">
                    <thead>
                      <tr>
                        <th>
                          {constLabel?.lbl_sequence_no
                            ? constLabel?.lbl_sequence_no
                            : "Sequence No"}
                          <span className="text-danger"> *</span>
                        </th>
                        <th>
                          {constLabel?.lbl_operation
                            ? constLabel?.lbl_operation
                            : "Operation"}
                          <span className="text-danger"> *</span>
                        </th>
                        <th>
                          {constLabel?.lbl_operation_cost
                            ? constLabel?.lbl_operation_cost
                            : "Operation Cost"}
                        </th>
                        <th>
                          {constLabel?.lbl_man_hour_cost
                            ? constLabel?.lbl_man_hour_cost
                            : "Man Hour Cost"}
                        </th>
                        <th>
                          {constLabel?.lbl_machine
                            ? constLabel?.lbl_machine
                            : "Machine"}
                        </th>
                        <th>
                          {constLabel?.lbl_cycle_time
                            ? constLabel?.lbl_cycle_time
                            : "Cycle Time"}
                        </th>
                        <th>
                          {constLabel?.lbl_setting_time
                            ? constLabel?.lbl_setting_time
                            : "Setting Time"}
                        </th>
                        <th>{"Action"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map(({ id }, index) => (
                        <tr key={id}>

                          <td style={{ verticalAlign: "top" }}>
                            <input
                              className={
                                errors.items?.[index]?.sequenceNo
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              name={`items[${index}].sequenceNo`}
                              {...register(`items[${index}].sequenceNo`)}
                              placeholder="Enter sequence no"
                            />

                            {errors.items?.[index]?.sequenceNo && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.sequenceNo.message}
                              </span>
                            )}
                            {index + 1 == fields.length ? (
                              <div>
                                <button
                                  style={{ fontSize: "16px" }}
                                  className="btn btn-sm button-primary px-2 mt-2"
                                  onClick={() => {
                                    append({
                                      sequenceNo: "",
                                      operation: [],
                                      operationCost: "0",
                                      manHourCost: "0",
                                      machine: [],
                                      cycleTime: "0",
                                      settingTime: "0",
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon
                                    style={{
                                      color: "#ffffff",
                                      marginRight: "5px",
                                    }}
                                    icon={faAdd}
                                    size="sm"
                                  />{" "}
                                  {constLabel?.lbl_add
                                    ? constLabel.lbl_add
                                    : "Add"}
                                </button>
                              </div>
                            ) : null}
                          </td>

                          <td style={{ verticalAlign: "top" }} width={"20%"}>
                            <Controller
                              name={`items[${index}].operation`}
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  size="sm"
                                  id="basic-typeahead"
                                  labelKey="operationName"
                                  multiple={false}
                                  options={operList.data}
                                  isLoading={operList.loading}
                                  placeholder="Select operation"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={
                                    errors.items?.[index]?.operation
                                      ? true
                                      : false
                                  }
                                />
                              )}
                            />
                            {errors.items?.[index]?.operation && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.operation.message}
                              </span>
                            )}

                            
                          </td>

                          <td style={{ verticalAlign: "top" }}>
                            <input
                              className={
                                errors.items?.[index]?.operationCost
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              name={`items[${index}].operationCost`}
                              {...register(`items[${index}].operationCost`)}
                              placeholder="Enter sequence no"
                            />

                            {errors.items?.[index]?.operationCost && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.operationCost.message}
                              </span>
                            )}
                          </td>

                          <td style={{ verticalAlign: "top" }}>
                            <input
                              className={
                                errors.items?.[index]?.manHourCost
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              name={`items[${index}].manHourCost`}
                              {...register(`items[${index}].manHourCost`)}
                              placeholder="Enter sequence no"
                            />

                            {errors.items?.[index]?.manHourCost && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.manHourCost.message}
                              </span>
                            )}
                          </td>

                          <td style={{ verticalAlign: "top" }} width={"20%"}>
                            <Controller
                              name={`items[${index}].machine`}
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  size="sm"
                                  id="basic-typeahead"
                                  labelKey="machineNameSerialNo"
                                  multiple={false}
                                  options={machineList.data}
                                  isLoading={machineList.loading}
                                  placeholder="Select machine"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={
                                    errors.items?.[index]?.machine
                                      ? true
                                      : false
                                  }
                                />
                              )}
                            />
                            {errors.items?.[index]?.machine && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.machine.message}
                              </span>
                            )}

                            {/* {index + 1 == fields.length ? (
                              <div>
                                <button
                                  style={{ fontSize: "16px" }}
                                  className="btn btn-sm button-primary px-2 mt-2"
                                  onClick={() => {
                                    append({
                                      machine: [],
                                      cycleTime: "",
                                      settingTime: "",
                                      toolChangeTime: "",
                                      machineRate: "",
                                    });
                                  }}
                                >
                                  <FontAwesomeIcon
                                    style={{
                                      color: "#ffffff",
                                      marginRight: "5px",
                                    }}
                                    icon={faAdd}
                                    size="sm"
                                  />{" "}
                                  {constLabel?.lbl_add
                                    ? constLabel.lbl_add
                                    : "Add"}
                                </button>
                              </div>
                            ) : null} */}
                          </td>

                          <td style={{ verticalAlign: "top" }}>
                            <input
                              className={
                                errors.items?.[index]?.cycleTime
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              name={`items[${index}].cycleTime`}
                              {...register(`items[${index}].cycleTime`)}
                              placeholder="Enter cycle time"
                            />

                            {errors.items?.[index]?.cycleTime && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.cycleTime.message}
                              </span>
                            )}
                          </td>

                          <td style={{ verticalAlign: "top" }}>
                            <input
                              className={
                                errors.items?.[index]?.settingTime
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              name={`items[${index}].settingTime`}
                              {...register(`items[${index}].settingTime`)}
                              placeholder="Enter setting time"
                            />

                            {errors.items?.[index]?.settingTime && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.settingTime.message}
                              </span>
                            )}
                          </td>

                          <td
                            style={{
                              textAlign: "center",
                              verticalAlign: "top",
                            }}
                          >
                            <Tooltip
                              id={"delete-tooltip" + index}
                              place="top"
                            />
                            <Trash2
                              data-tooltip-id={"delete-tooltip" + index}
                              data-tooltip-content={"Delete"}
                              className="menu_icon_red cursor_pointer"
                              size={20}
                              onClick={
                                index > 0
                                  ? () => {
                                      remove(index);
                                    }
                                  : null
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* ) : (
                  <div
                    className="alert alert-danger mb-0 text-center"
                    role="alert"
                  >
                    {"No machine data found !"}
                  </div>
                )} */}
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleSubmit(onSubmit)}
              className="f-16 btn btn-yellow "
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
            </button>
          </Modal.Footer>
        </Modal>

        <div id="mySidebar" className="customsidebar ">
          <a className="closebtn" onClick={() => closeNav()}>
            ×
          </a>

          <div className=" content">
            <div className="filter row">
              <label
                className="filterLabel mb-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_search ? constLabel.lbl_search : "Search"}
              </label>
              <div className="col-12">
                <input
                  type="text"
                  id="searchField"
                  className="form-control form-control-sm"
                  placeholder="Search by name"
                  value={filterConfig.searchVal}
                  onChange={(val) => {
                    const inputValue = val.target.value;
                    if (!inputValue.trim()) {
                      // Input contains only whitespace, don't trigger the search.
                      if (filterConfig.searchVal.length == 1) {
                        setFilterConfig({ ...filterConfig, searchVal: "" });
                      }

                      if (inputValue.length == 0) {
                        setFilterConfig({ ...filterConfig, searchVal: "" });
                      }
                    } else {
                      setFilterConfig({
                        ...filterConfig,
                        searchVal: inputValue,
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="my-3 d-flex justify-content-end">
              <button
                className="btn btn-sm btn-yellow"
                onClick={() => clearFilter()}
              >
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {constLabel?.lbl_clear_filter
                  ? constLabel.lbl_clear_filter
                  : "Clear Filter"}
              </button>
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="card border-0">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="d-md-flex justify-content-start align-items-center col-12 col-md-4">
                  <h1 className="bold">
                    {constLabel?.lbl_routing_master
                      ? constLabel.lbl_routing_master
                      : "Routing Master"}
                  </h1>
                </div>
                <div className="d-md-flex justify-content-end align-items-center col-12 col-md-8">
         
                  {privilage.write && (
                    <button
                      className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                      onClick={() => {
                        setVisible(true);
                        reset();
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faPlus}
                        size="sm"
                      />{" "}
                      {constLabel?.lbl_add_routing
                        ? constLabel.lbl_add_routing
                        : "Add Routing"}
                    </button>
                  )}

                  <Tooltip id={"show-tooltip"} place="top" />
                  <div
                    className="dropdown"
                    data-tooltip-id={"show-tooltip"}
                    data-tooltip-content={"Show / Hide Columns"}
                  >
                    <div
                      className="d-md-flex justify-content-start align-items-center"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {/* <FontAwesomeIcon icon={faCog} size="lg" /> */}
                      {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                      <img
                        src={List}
                        height="32"
                        width="32"
                        className="cursor_pointer"
                      />
                    </div>

                    <ul className="dropdown-menu px-2">
                      {showLabel !== undefined && showLabel.length > 0
                        ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                {dataList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : dataList && dataList.data?.length > 0 ? (
                  <>
                    <div className="table-responsive pc">
                      <table className="table table-bordered">
                        <thead className="table-grey roboto">
                          <tr className="f-14 ">
                            <th scope="col" width="10%">
                              {"Sr.No"}
                            </th>
                            {showLabel.map((v, i) =>
                              v.label === "Route Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_route_name
                                    ? constLabel.lbl_route_name
                                    : "Route Name"}
                                </th>
                              ) : null
                            )}
                            
                            <th scope="col" width="10%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className=" roboto">
                          {dataList.data?.map((val, i) => (
                            <React.Fragment key={i}>
                              <tr key={i}>
                                <td>{i + parseInt(srNo) + 1}</td>
                                {showLabel.map((item, idx) =>
                                  item.label === "Route Name" && item.flag ? (
                                    <td key={idx}>
                                      {val.routeName !== "" &&
                                      val.routeName !== null
                                        ? val.routeName
                                        : "NA"}
                                    </td>
                                  ) : null
                                )}
                                
                                <td className="">
                                  {privilage.write && (
                                    <Edit_Global_Routing
                                      data={val}
                                      constLabel={constLabel}
                                      fetchGlobalRouting={fetchGlobalRouting}
                                      page={page}
                                      entriesPerPage={entriesPerPage}
                                    />
                                  )}

                                  {privilage.view && (
                                    <>
                                      <Tooltip
                                        id={"view-tooltip" + i}
                                        place="top"
                                      />
                                        <Eye
                                          data-tooltip-id={"view-tooltip" + i}
                                          data-tooltip-content={
                                            constLabel?.lbl_route_data
                                              ? constLabel?.lbl_route_data
                                              : "Route Data"
                                          }
                                          className="menu_icon_grey cursor_pointer me-2"
                                          size={20}
                                          onClick={() => {
                                            toggleDiv(i, val);
                                          }}
                                        />
                                    </>
                                  )}

                                  {/* {privilage.delete && (
                                    <>
                                      <Tooltip
                                        id={"delete-tooltip" + i}
                                        place="top"
                                      />
                                      <Trash2
                                        data-tooltip-id={"delete-tooltip" + i}
                                        data-tooltip-content={
                                          constLabel?.lbl_delete_routing
                                            ? constLabel?.lbl_delete_routing
                                            : "Delete Routing"
                                        }
                                        className="menu_icon_red cursor_pointer"
                                        size={20}
                                        onClick={() => delRouting(val)}
                                      />
                                    </>
                                  )} */}

                                </td>
                              </tr>
                              <tr
                              className={"collapsible-row"}
                              id={"collapse_row" + i}
                            >
                              <td colSpan="12">
                                <div className="collapse-content">
                                  <div className="row mb-3">
                                    <div className="col-12 col-md-2">
                                      <label className="roboto-bold">
                                        {constLabel?.lbl_sequence_no
                                          ? constLabel.lbl_sequence_no
                                          : "Sequence No"}
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-3">
                                      <label className="roboto-bold">
                                        {constLabel?.lbl_operation_name
                                          ? constLabel.lbl_operation_name
                                          : "Operation Name"}
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-2">
                                      <label className="roboto-bold">
                                      {constLabel?.lbl_operation_cost
                                        ? constLabel.lbl_operation_cost
                                        : "Operation Cost"}
                                      </label>
                                    </div>
                                    <div className="col-12 col-md-2">
                                      <label className="roboto-bold">
                                        {constLabel?.lbl_man_hour_cost
                                          ? constLabel.lbl_man_hour_cost
                                          : "Man Hour Cost"}
                                      </label>
                                    </div>
                                  </div>
                                  {val.routeData &&
                                    val.routeData.length > 0 &&
                                    val.routeData.map((vv, index) => (
                                      <div className="row " key={index}>
                                        <div className="col-12 col-md-2">
                                          <label className="roboto">
                                          {vv.sequence_no !== "" &&
                                            vv.sequence_no !== null
                                              ? vv.sequence_no
                                              : "NA"}
                                          </label>
                                        </div>

                                        <div className="col-12 col-md-3">
                                          <label className="roboto">
                                          {vv.operationname !== "" &&
                                            vv.operationname !== null
                                              ? vv.operationname
                                              : "NA"}
                                          </label>
                                        </div>
                                        <div className="col-12 col-md-2">
                                          <label className="roboto">
                                          {vv.operation_cost !== "" &&
                                            vv.operation_cost !== null
                                              ? vv.operation_cost
                                              : "NA"}
                                          </label>
                                        </div>

                                        <div className="col-12 col-md-2">
                                          <label className="roboto">
                                          {vv.man_hour_cost !== "" &&
                                            vv.man_hour_cost !== null
                                              ? vv.man_hour_cost
                                              : "NA"}
                                          </label>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </td>
                              </tr>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <p className="mb-0">
                        Show
                        <select
                          className="mx-1"
                          defaultValue={entriesPerPage}
                          onChange={(e) => {
                            setEntriesPerPage(e.target.value);
                            fetchGlobalRouting(1, e.target.value);
                            setSrNo(0);
                          }}
                        >
                          <option value={"10"}>10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        Entries
                      </p>
                      <p className="mb-0">{`Showing ${
                        parseInt(srNo) + 1
                      } to ${Math.min(
                        parseInt(entriesPerPage) + parseInt(srNo),
                        dataList.totalRecords
                      )} of ${dataList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchGlobalRouting(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1
                                ? 0
                                : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage || maxPage === 0 ? true : false
                          }
                          onClick={() => {
                            fetchGlobalRouting(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  </>
                ) : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"Routings not found !"}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Global_Routing;
