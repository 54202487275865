import React, { useState, useEffect } from "react";
import { getSalesOrder } from "../../../../api";
import { Pagination, Modal } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { Eye } from "react-feather";
import SweetAlert from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import {
  calMaxPage,
  convertDate,
} from "../../../../common/Functions/CommonFunctions";

function SalesOrder(props) {
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [show, setShow] = useState(false);
  const [selData, setSelData] = useState({});

  const [dataList, setDataList] = useState({
    data: [],
    loading: false,
    totalRecords: "",
  });

  useEffect(() => {
    if (props !== undefined) {
      //
      fetch_salesOrders(page, entriesPerPage);
    }
  }, [props.data?.uuid]);

  function fetch_salesOrders(pg, epp) {
    setDataList({ ...dataList, loading: true, data: [] });

    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
      customer_id: props.data?.uuid !== undefined ? props.data?.uuid : "",
    };

    if (payload.customer_id == "" && payload.customer_id == null) {
      return;
    }

    getSalesOrder(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });
        setMaxPage(calMaxPage(res.data.total));
      },
      (err) => {
        setDataList({ ...dataList, loading: true, data: [] });
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      {dataList.loading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : dataList && dataList.data?.length > 0 ? (
        <>
          <table className="table table-responsive table-sm table-bordered">
            <thead className="table-grey">
              <tr>
                <th scope="col">{"Sr.No"}</th>
                <th scope="col">{"Party PO Ref"}</th>
                <th scope="col">{"Order Date"}</th>
                <th scope="col">{"Due Date"}</th>
                <th scope="col">{"Invoice To"}</th>
                <th scope="col">{"Dispatch To"}</th>
                <th scope="col">{"Organization"}</th>
                <th scope="col">{"Status"}</th>
                <th scope="col">{"Action"}</th>
              </tr>
            </thead>
            <tbody>
              {dataList.data.map((val, i) => (
                <tr key={i} className="f-14">
                  <td>{parseInt(i) + 1 + parseInt(srNo)}</td>
                  <td>{val.party_po_ref}</td>
                  <td>{convertDate(val.order_date, "-")}</td>
                  <td>{convertDate(val.order_due_date, "-")}</td>
                  <td>{val.invoice_to?.organization}</td>
                  <td>{val.dispatch_to?.organization}</td>
                  <td>{val.org_id?.comName}</td>
                  <td>
                    {val.status?.status_name != "" &&
                    val.status?.status_name != null
                      ? val.status?.status_name
                      : "NA"}
                  </td>
                  <td>
                    <Tooltip id={"view-tooltip" + i} place="top" />
                    <Eye
                      data-tooltip-id={"view-tooltip" + i}
                      data-tooltip-content={
                        props.constLabel?.lbl_view_so
                          ? props.constLabel.lbl_view_so
                          : "View SO"
                      }
                      className="menu_icon_grey cursor_pointer"
                      size={18}
                      onClick={() => {
                        setShow(true);
                        setSelData(val);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 d-flex justify-content-between">
            <p className="mb-0">{`Showing ${parseInt(srNo) + 1} to ${
              dataList.data.length + entriesPerPage * page - entriesPerPage
            } of ${dataList.totalRecords} entries`}</p>
            <Pagination className="my-2">
              <Pagination.Prev
                disabled={page === 1 ? true : false}
                onClick={() => {
                  fetch_salesOrders(
                    page - 1,
                    entriesPerPage,
                    parseInt(srNo) - parseInt(entriesPerPage)
                  );
                  setSrNo((prevC) =>
                    page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                  );
                  setPage(page - 1);
                }}
              >
                {"Prev"}
              </Pagination.Prev>

              <Pagination.Item active>{page}</Pagination.Item>

              <Pagination.Next
                disabled={
                  page === maxPage ||
                  maxPage === 0 ||
                  entriesPerPage > dataList.data.length
                    ? true
                    : false
                }
                onClick={() => {
                  fetch_salesOrders(
                    page + 1,
                    entriesPerPage,
                    parseInt(srNo) + parseInt(entriesPerPage)
                  );
                  setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                  setPage(page + 1);
                }}
              >
                {"Next"}
              </Pagination.Next>
            </Pagination>
          </div>
        </>
      ) : (
        <div
          className="alert alert-sm alert-danger mb-0 text-center my-1"
          role="alert"
        >
          {"Sales order list is empty"}
        </div>
      )}

      <Modal
        show={show}
        onHide={() => setShow(false)}
        size="xl"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_sales_order_detail
              ? props.constLabel.lbl_sales_order_detail
              : "Sales Order Detail"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div
              className="bg-lightgrey"
              style={{ padding: "12px", borderRadius: "12px" }}
            >
              <div className="row my-2">
                <div className="col-12 col-md-3">
                  <p className="m-0 nc_detail_label">
                    {props.constLabel?.lbl_so_id
                      ? props.constLabel.lbl_so_id
                      : "SO No"}
                  </p>
                  <p className="mb-2 roboto-bold">{selData.so_id}</p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="m-0 nc_detail_label">
                    {props.constLabel?.lbl_order_date
                      ? props.constLabel.lbl_order_date
                      : "Order Date"}
                  </p>
                  <p className="mb-2 roboto-bold">
                    {convertDate(selData.order_date, "-")}
                  </p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="m-0 nc_detail_label">
                    {props.constLabel?.lbl_order_due_date
                      ? props.constLabel.lbl_order_due_date
                      : "Order Due Date"}
                  </p>
                  <p className="mb-2 roboto-bold">
                    {convertDate(selData.order_due_date, "-")}
                  </p>
                </div>

                <div className="col-12 col-md-3">
                  <p className="m-0 nc_detail_label">
                    {props.constLabel?.lbl_organization
                      ? props.constLabel.lbl_organization
                      : "Organization"}
                  </p>
                  <p className="mb-2 roboto-bold">{selData.org_id?.comName}</p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="m-0 nc_detail_label">
                    {props.constLabel?.lbl_invoice_to
                      ? props.constLabel.lbl_invoice_to
                      : "Invoice To"}
                  </p>
                  <p className="mb-2 roboto-bold">
                    {selData.invoice_to?.organization}
                  </p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="m-0 nc_detail_label">
                    {props.constLabel?.lbl_dispatch_to
                      ? props.constLabel.lbl_dispatch_to
                      : "Dispatch To"}
                  </p>
                  <p className="mb-2 roboto-bold">
                    {selData.dispatch_to?.organization}
                  </p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="m-0 nc_detail_label">
                    {props.constLabel?.lbl_customer_name
                      ? props.constLabel.lbl_customer_name
                      : "Customer Name"}
                  </p>
                  <p className="mb-2 roboto-bold">{selData.customer_name}</p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="m-0 nc_detail_label">
                    {props.constLabel?.lbl_party_po_ref
                      ? props.constLabel.lbl_party_po_ref
                      : "Party PO Ref"}
                  </p>
                  <p className="mb-2 roboto-bold">
                    {selData.party_po_ref == "" && selData.party_po_ref == null
                      ? "NA"
                      : selData.party_po_ref}
                  </p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="m-0 nc_detail_label">
                    {props.constLabel?.lbl_status
                      ? props.constLabel.lbl_status
                      : "Status"}
                  </p>
                  <p className="mb-2 roboto-bold">
                    {selData.status?.status_name == "" &&
                    selData.status?.status_name == null
                      ? "NA"
                      : selData.status?.status_name}
                  </p>
                </div>
                <div className="col-12 col-md-3">
                  <p className="m-0 nc_detail_label">
                    {props.constLabel?.lbl_remark
                      ? props.constLabel.lbl_remark
                      : "Remark"}
                  </p>
                  <p className="mb-2 roboto-bold">
                    {selData.internal_remark == "" &&
                    selData.internal_remark == null
                      ? "NA"
                      : selData.internal_remark}
                  </p>
                </div>
              </div>
            </div>

            <hr className="my-2" />
            <h4 className="roboto-bold mb-0">
              {props.constLabel?.lbl_product_detail
                ? props.constLabel.lbl_product_detail
                : "Product Detail"}
            </h4>
            <hr className="my-2" />

            <div className="my-3">
              <div
                className="table-responsive"
                style={{ maxHeight: "300px", overflowY: "auto" }}
              >
                <table className="table table-bordered">
                  <thead className="table-grey">
                    <tr>
                      <th scope="col">
                        {props.constLabel?.lbl_product_type
                          ? props.constLabel.lbl_product_type
                          : "Product Type"}
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_product_name
                          ? props.constLabel.lbl_product_name
                          : "Product Name"}
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_quantity
                          ? props.constLabel.lbl_quantity
                          : "Quantity"}
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_unit
                          ? props.constLabel.lbl_unit
                          : "Unit"}
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_rate
                          ? props.constLabel.lbl_rate
                          : "Rate "}{" "}
                        <span>&#8377;</span>
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_gst
                          ? props.constLabel.lbl_gst
                          : "GST "}
                        (%)
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_discount
                          ? props.constLabel.lbl_discount
                          : "Discount "}
                        (%)
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_amount
                          ? props.constLabel.lbl_amount
                          : "Amount "}{" "}
                        <span>&#8377;</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {selData?.so_order_lines?.map((val, ind) => (
                      <tr key={ind}>
                        <td>{val.product_type}</td>

                        <td style={{ verticalAlign: "top" }}>
                          {val.productname}
                        </td>

                        <td style={{ verticalAlign: "top" }}>{val.quantity}</td>

                        <td style={{ verticalAlign: "top" }}>{val.unit}</td>

                        <td style={{ verticalAlign: "top" }}>{val.rate}</td>
                        <td style={{ verticalAlign: "top" }}>{val.gst_rate}</td>
                        <td style={{ verticalAlign: "top" }}>{val.discount}</td>

                        <td style={{ verticalAlign: "top" }}>
                          {parseFloat(val.amount).toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div
              className="p-3 ms-auto col-12 col-md-4"
              style={{
                border: "1px solid #c6c6c6",
                borderRadius: "2px",
                fontSize: "14px",
              }}
            >
              <div className="row mb-2">
                <div className="col-12 row ms-auto">
                  <label className="col-12 col-md-6 roboto-bold">
                    {"Sub Total"} <span>&#8377;</span>
                  </label>

                  <div className="col-12 col-md-6 ">
                    {parseFloat(selData.sub_total).toFixed(2)}
                  </div>
                </div>
              </div>

              <hr />
              {selData && selData.cgst_cost !== 0 ? (
                <>
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label className="col-12 col-md-6 roboto-bold">
                        {"CGST "} &#8377;
                      </label>

                      {/* <div className="col-12 col-md-4">
                          {SOdata.cgst}
                        </div> */}
                      <div className="col-12 col-md-6">
                        {parseFloat(selData.cgst_cost).toFixed(2)}
                      </div>
                    </div>
                  </div>

                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label className="col-12 col-md-6 roboto-bold">
                        {"SGST "} &#8377;
                      </label>

                      {/* <div className="col-12 col-md-4">
                          {SOdata.sgst}
                        </div> */}
                      <div className="col-12 col-md-6">
                        {parseFloat(selData.sgst_cost).toFixed(2)}
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              {selData && selData.igst_cost !== 0 ? (
                <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label className="col-12 col-md-6 roboto-bold">
                      {"IGST "} &#8377;
                    </label>

                    {/* <div className="col-12 col-md-4">
                        {SOdata.igst}
                      </div> */}
                    <div className="col-12 col-md-6">
                      <span>&#8377;</span>
                      {parseFloat(selData.igst_cost).toFixed(2)}
                    </div>
                  </div>
                </div>
              ) : null}

              {selData && selData.ugst_cost !== 0 ? (
                <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label className="col-12 col-md-6 roboto-bold">
                      {"UGST "} &#8377;
                    </label>

                    {/* <div className="col-12 col-md-4">
                        {SOdata.ugst}
                      </div> */}
                    <div className="col-12 col-md-6">
                      {parseFloat(selData.ugst_cost).toFixed(2)}
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row mb-2">
                <div className="col-12 row ms-auto">
                  <label className="col-12 col-md-6 roboto-bold">
                    {"Total Amount "} &#8377;
                  </label>

                  <div className="col-12 col-md-6">
                    {parseFloat(selData.final_total).toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-grey px-4" onClick={() => setShow(false)}>
            {props.constLabel?.lbl_close ? props.constLabel.lbl_close : "Close"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default SalesOrder;
