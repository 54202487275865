import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SweetAlert from "sweetalert2";
import * as yup from "yup";
import {
  faSearch,
  faRefresh,
  faPlus,
  faEye,
  faEyeSlash,
  faCog,

} from "@fortawesome/free-solid-svg-icons";
import {

  getOperatorList,
  deleteAppUser,
  get_user_api,
  get_role_api,
  save_user_api,
  delete_user_api
} from "../../../api/index";
import {
  calMaxPage,
  getLabels,
} from "../../../common/Functions/CommonFunctions";
import { Pagination, Modal } from "react-bootstrap";
import { Trash2, Plus, PlusSquare } from "react-feather";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import EditAppUser from "./EditAppUser";
// import { useSelector, useDispatch } from "react-redux";
// import {
//   setCurrentPage,
//   fetchUsers,
// } from "../../../slices/usermanagement/user";
// import runOneSignal from '../../../onesignal';
import { Tooltip } from "react-tooltip";
import { User_Col_Name } from "../../../common/Labels/labelConstant";
import LinkCampaign from "./LinkCampaign";
import List from "../../../assets/images/icons/project_menu/checklist.png"

const schema = yup
  .object()
  .shape({
    userType: yup.string().required("Please select user type"),
    employee: yup.array().when("userType", {
      is: "Employee",
      then: yup
        .array()
        .min(1, "Please select employee")
        .required("Please select employee"),
    }),
    first_name: yup.string().when("userType", {
      is: "External",
      then: yup.string().required("Please enter firstname"),
    }),
    last_name: yup.string().when("userType", {
      is: "External",
      then: yup.string().required("Please enter lastname"),
    }),
    role: yup
      .array()
      .min(1, "Please select role")
      .required("Please select role"),
    email: yup
      .string()
      .required("Please enter email")
      .email("Please enter valid email"),
    mobile: yup
      .string()
      .required("Please enter mobile no")
      .matches(/^[0-9]{10}$/, "Please enter only numbers"),
    username: yup.string().required("Please enter username"),
    // .matches(/^[a-zA-Z0-9]*$/, "Only alphabets and number allowed !"),
    password: yup
      .string()
      .required("Please enter password")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@$&])(?=.{8,})/,
        "1 uppercase, 1 number, 1 special character and atleast 8 character"
      ),
    confirmPassword: yup
      .string()
      .required("Please enter the same password")
      .oneOf([yup.ref("password"), null], "Passwords must match"),
  })
  .required();

function AppUsers() {
  const localData = JSON.parse(localStorage.getItem('localData'));
  const [showLabel, setShowLabel] = useState(User_Col_Name);

  const [userRole, setUserRole] = useState({ data: [], state: false });
  const [empList, setEmpList] = useState({ data: [], state: false });

  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const [visible, setVisible] = useState(false);
  // const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);

  const [selItem, setSelItem] = useState({});

  const [search, setSearch] = useState({ value: "", status: false });
  const [myReset, setMyReset] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [userdata, setUserData] = useState({ data: [], loading: true, totalRecords: "" });

  const [constLabel, setConstLabel] = useState({});

  const { register, handleSubmit, formState, control, reset, watch, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: {
        userType: "Employee",
      },
    }); // initialise the hook

  let { errors } = formState;

  let usertype = watch("userType");



  // const dispatch = useDispatch();
  // const { data, loading, totalPages, page, entriesPerPage, maxPage } =
  //   useSelector((state) => state.users);

  useEffect(() => {
    // runOneSignal();
    setConstLabel(getLabels("User Management", "User"));
  }, []);

  // useEffect(() => {


  //   //dispatch(fetchUsers(page, entriesPerPage));
  // }, [dispatch, page, entriesPerPage]);

  useEffect(() => {
    fetch(1, entriesPerPage);
    fetchRoles();
    fetchEmployees();
  }, []);

  useEffect(() => {

    setValue("email", "");
    setValue("mobile", "");
    setValue("username", "");
    setValue("password", "");
    setValue("confirmPassword", "");
    setValue("first_name", "");
    setValue("last_name", "");
    setValue("role", []);
    setValue("employee", []);
  }, [usertype]);

  function fetchRoles() {
    setUserRole({ ...userRole, data: [], state: true });

    get_role_api().then(
      (res) => {

        setUserRole({
          ...userRole,
          data: res.data.data.response.data,
          state: false,
        });
      },
      (err) => {

        setUserRole({ ...userRole, data: [], state: false });
      }
    );
  }

  function fetchEmployees() {
    setEmpList({ ...empList, data: [], state: true });

    getOperatorList().then(
      (res) => {

        setEmpList({
          ...empList,
          data: res.data.data,
          state: false,
        });
      },
      (err) => {

        setEmpList({ ...empList, data: [], state: false });
      }
    );
  }



  function fetch(pg, epp) {
    const localdata = JSON.parse(localStorage.getItem("localData"));

    setUserData({ ...userdata, data: [], loading: true, totalRecords: "" })
    const payload = {
      limit: epp,
      offset: pg - 1, 
      client_id:localdata.client_id
    }
    get_user_api(payload).then(
      (res) => {

        setUserData({ ...userdata, data: res.data.data.response.data, loading: false, totalRecords: res.data.data.response.total })
        setMaxPage(calMaxPage(res.data.data.response.total));
      },
      (err) => {

        setUserData({ ...userdata, data: [], loading: false, totalRecords: "" })

      }
    );

  }

  const onSubmit = (data) => {


    if (data !== "") {
      let reqPayload = {};

      if (data.userType == "Employee") {
        reqPayload.username = data.username;
        reqPayload.email = data.email;
        reqPayload.contact_no = data.mobile;
        reqPayload.password = data.password;
        reqPayload.employee_id = data.employee[0].uuid;
        reqPayload.user_type = data.userType;
        reqPayload.role_id = data.role[0].role_id;
        reqPayload.role_name = data.role[0].name;
        // reqPayload.first_name = data.employee[0].FirstName;
        // reqPayload.last_name = data.employee[0].LastName;
        reqPayload.employee_name = `${data.employee[0].FirstName} ${data.employee[0].LastName}`
        reqPayload.client_id = localData.client_id;
        reqPayload.app_id = localData.app_id;
        reqPayload.name = data.employee[0].FirstName;
      } else {
        delete reqPayload.employee_id;
        reqPayload.role_id = data.role[0].role_id;
        reqPayload.role_name = data.role[0].name;
        reqPayload.username = data.username;
        // reqPayload.first_name = data.first_name;
        // reqPayload.last_name = data.last_name;
        reqPayload.name = `${data.first_name} ${data.last_name}`
        reqPayload.email = data.email;
        reqPayload.contact_no = data.mobile;
        reqPayload.password = data.password;
        reqPayload.user_type = data.userType;
        reqPayload.client_id = localData.client_id;
        reqPayload.app_id = localData.app_id;
      }




      save_user_api(reqPayload)
        .then(
          (res) => {

            if (res.status === 200 || res.status === 201) {
              toast.success("User created successfully", {
                autoClose: 2000,
              });
              setVisible(false);
              // dispatch(fetchUsers(1, entriesPerPage));
              fetch()
            }
          },
          (err) => {

            if (err.response.status == 400) {
              toast.error("Username already exist !", {
                autoClose: 2000,
              });
            } else {
              toast.error(err.response.data.data.response, {
                autoClose: 2000,
              });
            }
          }
        )
        .catch((error) => {

          toast.error("Something went wrong", {
            autoClose: 2000,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  // Deleting User
  const delUser = (val) => {

    SweetAlert.fire({
      title: "Are you sure? \n" + val.username,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          user_id: val.user_id,
        };
        delete_user_api(payload).then(
          (res) => {

            toast.success("User deleted successfully !", {
              autoClose: 3000,
            });

            if (userdata.data.length == 1 && page > 1) {
              fetch(page - 1, entriesPerPage);
            } else {
              fetch(page, entriesPerPage);
            }
          },
          (err) => {

            toast.error(err.response.data.error, { autoClose: 3000 });
          }
        );
      }
    });
  };

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function handleClick(ind) {


    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }
  return (
    <React.Fragment>
      <ToastContainer />

      {/* <EditAppUser
        selItem={selItem}
        setVisible2={setVisible2}
        visible2={visible2}
        constLabel={constLabel}
      /> */}

      {visible3 &&
        <LinkCampaign
          visible3={visible3}
          setVisible3={setVisible3}
          constLabel={constLabel}
          selItem={selItem}
        />}


      <div className="min-vh-100" id="root_div_main">
        <div id="mySidebar" className="customsidebar">
          <a className="closebtn" onClick={() => closeNav()}>
            ×
          </a>

          <div className=" content">
            <div>
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                Select name
              </label>

              <Typeahead
                id="basic-typeahead-single"
                // labelKey="materialcode"
                name="name"
                multiple={false}
                size="sm"
                options={["User 1", "User 2", "User 3"]}
                isLoading={false}
                placeholder="Select User"
              />
            </div>

            <div>
              <button
                onClick={() => {
                  setSearch({ ...search, status: false, value: "" });
                  setMyReset(true);
                }}
                className="btn btn-sm btn-yellow px-3 mt-3"
              >
                <FontAwesomeIcon
                  style={{ color: "#000" }}
                  icon={faRefresh}
                  size="sm"
                />{" "}
                myReset
              </button>

              <button
                onClick={() => {
                  if (search.value.length > 0) {
                    setSearch({ ...search, status: true });
                    setMyReset(false);
                  } else {
                    toast.error("Please enter product id to search !", {
                      position: "top-right",
                      autoClose: 2000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                    setSearch({ ...search, status: false });
                  }
                }}
                className="btn btn-sm btn-grey px-3 mt-3 ms-2"
              >
                <FontAwesomeIcon
                  style={{ color: "#fff" }}
                  icon={faSearch}
                  size="sm"
                />{" "}
                {"Search"}
              </button>
            </div>
          </div>
        </div>

        <div className="content-wrapper">
            <div className="card border-0">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="d-md-flex justify-content-start col-12 col-md-6">
                    {/* <Tooltip id={"open-tooltip"} place="top" />
                    <img
                      src={Slide}
                      height="32"
                      width="32"
                      className="cursor_pointer my-1 me-2"
                      onClick={() => toggleNav()}
                      data-tooltip-id={"open-tooltip"}
                      data-tooltip-content={"Sidebar"}
                    /> */}
                   <h1 className="bold">
                    {constLabel?.lbl_users ? constLabel.lbl_users : "Users"}
                  </h1>
                  </div>

                  <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                   
                      <button
                        className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                        onClick={() => {
                          setVisible(true);
                          reset();
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}
                       {constLabel?.lbl_create_user
                          ? constLabel.lbl_create_user
                          : "Create User"}
                      </button>
                   
                    <Tooltip id={"show-tooltip"} place="top" />
                    <div
                      className="dropdown"
                      data-tooltip-id={"show-tooltip"}
                      data-tooltip-content={"Show / Hide Columns"}
                    >
                      <div
                        className="d-md-flex justify-content-start align-items-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <FontAwesomeIcon icon={faListCheck} size="lg" /> */}
                        {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                        <img
                          src={List}
                          height="32"
                          width="32"
                          className="cursor_pointer"
                        />
                      </div>

                      <ul className="dropdown-menu px-2">
                        {showLabel !== undefined && showLabel.length > 0
                          ? showLabel.map((val, ind, arr) => (
                              <li key={ind}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    style={{ width: "20px", height: "20px" }}
                                    onChange={() => handleClick(ind)}
                                    value=""
                                    checked={val.flag}
                                  />
                                  <label
                                    className=""
                                    style={{ fontSize: "13px" }}
                                    htmlFor="flexCheckDefault"
                                  >
                                    {val.label}
                                  </label>
                                </div>
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  {userdata.loading == true ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : userdata.data?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead className="table-grey">
                          <tr className="">
                            <th>{"Sr. No"}</th>
                            {/* <th>
                              {constLabel?.lbl_name
                                ? constLabel.lbl_name
                                : "Name"}
                            </th> */}
                            {/* <th>
                              {constLabel?.lbl_role
                                ? constLabel.lbl_role
                                : "Role"}
                            </th> */}
                            {showLabel.map((v, i) =>
                              v.label === "User Name" && v.flag ? (
                                <th key={i}>
                                  {constLabel?.lbl_user_name
                                    ? constLabel.lbl_user_name
                                    : "Username"}
                                </th>) :
                                v.label === "Email" && v.flag ? (
                                  <th key={i}>
                                    {constLabel?.lbl_email
                                      ? constLabel.lbl_email
                                      : "Email"}
                                  </th>) :
                                  v.label === "Mobile No" && v.flag ? (
                                    <th key={i}>
                                      {constLabel?.lbl_mobile_no
                                        ? constLabel.lbl_mobile_no
                                        : "Mobile No"}
                                    </th>) : null
                            )}
                            <th width="10%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className=" roboto">
                          {userdata.data?.map((val, i) => (
                            <tr key={i}>
                              <td>{i + srNo + 1}</td>
                              {/* <td>{val.first_name + " " + val.last_name}</td> */}
                              {/* <td>{val.role?.role_name}</td> */}
                              {showLabel.map((item, idx) =>
                                item.label === "User Name" && item.flag ? (<td key={idx}>{val.username ? val.username : "NA"}</td>) :
                                  item.label === "Email" && item.flag ? (<td key={idx}>{val.email ? val.email : "NA"}</td>) :
                                    item.label === "Mobile No" && item.flag ? (<td key={idx}>{val.mobile ? val.mobile : "NA"}</td>) : null
                              )}
                              <td className="">

                                <Tooltip
                                  id={"link_cam-tooltip" + i}
                                  place="top"
                                />
                                <PlusSquare
                                  data-tooltip-id={"link_cam-tooltip" + i}
                                  data-tooltip-content={
                                    constLabel?.lbl_link_campaign
                                      ? constLabel.lbl_link_campaign
                                      : "Link Campaign"
                                  }
                                  className="menu_icon_grey cursor_pointer me-2"
                                  size={20}
                                  onClick={() => {
                                    setVisible3(true);
                                    setSelItem(val);
                                  }}
                                />


                                <Tooltip
                                  id={"delete-tooltip" + i}
                                  place="top"
                                />
                                <Trash2
                                  data-tooltip-id={"delete-tooltip" + i}
                                  data-tooltip-content={
                                    constLabel?.lbl_delete_user
                                      ? constLabel.lbl_delete_user
                                      : "Delete User"
                                  }
                                  className="menu_icon_red cursor_pointer"
                                  size={20}
                                  onClick={() => delUser(val)}
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p className="text-danger text-center mb-0">
                     {" No Users Found"}
                    </p>
                  )}

                  {userdata.loading ? (
                    false
                  ) : (
                    <div className="d-flex justify-content-between align-itmes-center mt-2">
                      <p className="mb-0">
                    Show
                    <select
                      className="mx-1"
                      defaultValue={entriesPerPage}
                      onChange={(e) => {
                        setEntriesPerPage(e.target.value);
                        fetch(page, e.target.value);
                      }}
                    >
                      <option value={"10"}>10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    Entries
                  </p>
                      <p className="mb-0">{`Showing ${parseInt(srNo) + 1} to ${userdata.data.length + entriesPerPage * page - entriesPerPage
                        } of ${userdata.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetch(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) => prevC - parseInt(entriesPerPage));
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                              maxPage === 0 ||
                              entriesPerPage > userdata.data.length
                              ? true
                              : false
                          }
                          onClick={() => {

                            fetch(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  )}
                </div>
              </div>
            </div>
        </div>

        <Modal
          show={visible}
          onHide={() => setVisible(false)}
          size="lg"
          backdrop="static"
        >
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>{constLabel?.lbl_create_user ? constLabel?.lbl_create_user : "Create User"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form action="" autoComplete="off">
              <div className="row mt-2">
                <div className="col-12 col-md-6">
                  <div className="form-group row">
                    <label className="form-label col-12 col-md-4">
                      {constLabel?.lbl_user_type ? constLabel?.lbl_user_type : "User Type"}
                    </label>
                    <div className="col-12 col-md-8">
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="userType"
                          id="inlineRadio1"
                          value="Employee"
                          {...register("userType")}
                          checked={usertype === "Employee"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio1"
                        >
                          {constLabel?.lbl_employee ? constLabel?.lbl_employee : "Employee"}
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="userType"
                          id="inlineRadio2"
                          value="External"
                          {...register("userType")}
                          checked={usertype === "External"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="inlineRadio2"
                        >
                          {constLabel?.lbl_external ? constLabel?.lbl_external : "External"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <hr />

              <div className="row mt-2">
                <div className="col-md-6">
                  {usertype !== "Employee" ? (
                    <div className="form-group row mb-3">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-4"
                      >
                        {constLabel?.lbl_first_name ? constLabel?.lbl_first_name : "First Name"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>
                      <div className="col-12 col-md-8">
                        <input
                          type="text"
                          className={
                            errors.first_name
                              ? "form-control form-control-sm is-invalid "
                              : "form-control form-control-sm"
                          }
                          id="first_name"
                          name="first_name"
                          placeholder="Enter first name"
                          {...register("first_name")}
                        />
                        {errors.first_name && (
                          <span className="text-danger err-msg">
                            {errors.first_name.message}
                          </span>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="form-group row mb-3">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-4"
                      >
                        {constLabel?.lbl_employee ? constLabel?.lbl_employee : "Employee"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>
                      <div className="col-12 col-md-8">
                        <Controller
                          name="employee"
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => {
                            const modifiedOptions = empList.data.map(
                              (option) => ({
                                ...option,
                                fullName: `${option.FirstName} ${option.LastName}`,
                              })
                            );

                            return (
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="fullName" // Use the new fullName property for labelKey
                                className=""
                                multiple={false}
                                size="sm"
                                options={modifiedOptions} // Use the modified options
                                isLoading={empList.state}
                                placeholder="Select employee"
                                onChange={onChange}
                                selected={value}
                                onBlur={onBlur}
                                isInvalid={errors.employee ? true : false}
                              />
                            );
                          }}
                        />

                        {errors.employee && (
                          <span className="text-danger err-msg">
                            {errors.employee.message}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                {usertype !== "Employee" ? (
                  <div className="col-md-6">
                    <div className="form-group row mb-3">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-4"
                      >
                        {constLabel?.lbl_last_name ? constLabel?.lbl_last_name : "Last Name"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>
                      <div className="col-12 col-md-8">
                        <input
                          type="text"
                          className={
                            errors.last_name
                              ? "is-invalid form-control form-control-sm"
                              : " form-control form-control-sm"
                          }
                          id="last_name"
                          name="last_name"
                          placeholder="Enter last name"
                          {...register("last_name")}
                        />
                        {errors.last_name && (
                          <span className="text-danger err-msg">
                            {errors.last_name.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="col-md-6">
                  <div className="form-group row mb-3">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-4"
                    >
                      {constLabel?.lbl_role ? constLabel?.lbl_role : "Role"}
                      <span>
                        <i style={{ color: "red" }}>*</i>
                      </span>
                    </label>
                    <div className="col-12 col-md-8">
                      <Controller
                        name="role"
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="name"
                            className=""
                            multiple={false}
                            size="sm"
                            options={
                              userRole.data !== undefined ? userRole.data : []
                            }
                            isLoading={userRole.loading}
                            placeholder="Select role"
                            onChange={onChange}
                            selected={value}
                            onBlur={onBlur}
                            isInvalid={errors.role ? true : false}
                          />
                        )}
                      />

                      {errors.role && (
                        <span className="text-danger err-msg">
                          {errors.role.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group row mb-3">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-4"
                    >
                      {constLabel?.lbl_email ? constLabel?.lbl_email : "Email"}
                      <span>
                        <i style={{ color: "red" }}>*</i>
                      </span>
                    </label>
                    <div className="col-12 col-md-8">
                      <input
                        type="email"
                        className={
                          errors.email
                            ? "form-control form-control-sm is-invalid"
                            : " form-control form-control-sm"
                        }
                        id="email"
                        name="email"
                        placeholder="Enter email"
                        {...register("email")}
                      />
                      {errors.email && (
                        <span className="text-danger err-msg">
                          {errors.email.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group row mb-3">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-4"
                    >
                      {constLabel?.lbl_user_name ? constLabel?.lbl_user_name : "User Name"}
                      <span>
                        <i style={{ color: "red" }}>*</i>
                      </span>
                    </label>
                    <div className="col-12 col-md-8">
                      <input
                        type="text"
                        className={
                          errors.username
                            ? "form-control form-control-sm is-invalid"
                            : " form-control form-control-sm"
                        }
                        id="username"
                        name="username"
                        placeholder="Enter user name"
                        {...register("username")}
                      />
                      {errors.username && (
                        <span className="text-danger err-msg">
                          {errors.username.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group row mb-3">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-4"
                    >
                      {constLabel?.lbl_mobile_no ? constLabel?.lbl_mobile_no : "Mobile No"}
                      <span>
                        <i style={{ color: "red" }}>*</i>
                      </span>
                    </label>
                    <div className="col-12 col-md-8">
                      <input
                        type="text"
                        className={
                          errors.mobile
                            ? "form-control form-control-sm is-invalid"
                            : " form-control form-control-sm"
                        }
                        id="mobile"
                        name="mobile"
                        maxLength={10}
                        placeholder="Enter mobile"
                        {...register("mobile")}
                      />
                      {errors.mobile && (
                        <span className="text-danger err-msg">
                          {errors.mobile.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group row mb-3">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-4"
                    >
                      {constLabel?.lbl_password ? constLabel?.lbl_password : "Password"}
                      <span>
                        <i style={{ color: "red" }}>*</i>
                      </span>
                    </label>
                    <div className="col-12 col-md-8">
                      <div className="input-group">
                        <input
                          type={showPassword ? "text" : "password"}
                          className={
                            errors.password
                              ? "form-control form-control-sm is-invalid"
                              : " form-control form-control-sm"
                          }
                          id="password"
                          name="password"
                          maxLength={20}
                          placeholder="Enter password"
                          {...register("password")}
                          autoComplete="new-password"
                        />
                        <span className="input-group-text">
                          {showPassword ? (
                            <FontAwesomeIcon
                              style={{ color: "#212529", cursor: "pointer" }}
                              icon={faEyeSlash}
                              size="sm"
                              onClick={() => setShowPassword(false)}
                            />
                          ) : (
                            <FontAwesomeIcon
                              style={{ color: "#212529", cursor: "pointer" }}
                              icon={faEye}
                              size="sm"
                              onClick={() => setShowPassword(true)}
                            />
                          )}
                        </span>
                      </div>

                      {errors.password && (
                        <span className="text-danger err-msg">
                          {errors.password.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group row mb-3">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-4"
                    >
                      {constLabel?.lbl_confirm_password ? constLabel?.lbl_confirm_password : "Confirm Password"}
                    </label>
                    <div className="col-12 col-md-8">
                      <div className="input-group">
                        <input
                          type={showPassword2 ? "text" : "password"}
                          className={
                            errors.confirmPassword
                              ? "form-control form-control-sm is-invalid"
                              : " form-control form-control-sm"
                          }
                          id="confirmPassword"
                          name="confirmPassword"
                          maxLength={20}
                          placeholder="Enter confirm password"
                          {...register("confirmPassword")}
                          autoComplete="new-password"
                        />

                        <span className="input-group-text">
                          {showPassword2 ? (
                            <FontAwesomeIcon
                              style={{ color: "#212529", cursor: "pointer" }}
                              icon={faEyeSlash}
                              size="sm"
                              onClick={() => setShowPassword2(false)}
                            />
                          ) : (
                            <FontAwesomeIcon
                              style={{ color: "#212529", cursor: "pointer" }}
                              icon={faEye}
                              size="sm"
                              onClick={() => setShowPassword2(true)}
                            />
                          )}
                        </span>
                      </div>
                      {errors.confirmPassword && (
                        <span className="text-danger err-msg">
                          {errors.confirmPassword.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-yellow px-4"
              onClick={handleSubmit(onSubmit)}
            >
              {constLabel?.lbl_save ? constLabel?.lbl_save : "Save"}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </React.Fragment>
  );
}

export default AppUsers;
