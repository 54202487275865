import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { Tooltip } from "react-tooltip";
import { Edit, Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  update_store,
  get_store_type,
  getInternalCompany,
  getLocation,
  getCustomer,
  get_store,
} from "../../../api";
import { custom_name } from "../../../common/Labels/labelConstant";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Store name is required!"),
    store_type: yup
      .array()
      .min(1, "Please select store type !")
      .required("Please select store type !"),
    location: yup
      .array()
      .min(1, "Please select location !")
      .required("Please select location !"),
    // party: yup
    //   .array()
    //   .min(1, "Please select party !")
    //   .required("Please select party !"),
    // internal_company: yup
    //   .array()
    //   .min(1, "Please select organisation !")
    //   .required("Please select organisation !"),
  })
  .required();

function EditStoreMaster(props) {
  const [visible, setVisible] = useState(false);
  const [storeTypes, setStoreTypes] = useState({ data: [], loading: false });
  const [locList, setLocList] = useState({ data: [], loading: false });
  const [partyList, setPartyList] = useState({ data: [], loading: false });
  const [parentstoreList, setParentstoreList] = useState({
    data: [],
    loading: false,
  });

  const [intCompList, setIntCompList] = useState({ data: [], loading: false });
  const [storeList, setStoreList] = useState({ data: [], loading: false });

  const { register, handleSubmit, formState, control, reset, watch, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    if (visible == true) {
      fetchStoreTypes();
      fetchLocations();
      fetchInternalCompany();
      fetchParties();
      fetchStores();
    }
  }, [visible]);

  function fetchStoreTypes() {
    setStoreTypes({ ...storeTypes, data: [], loading: true });

    get_store_type().then(
      (res) => {
        setStoreTypes({ ...storeTypes, data: res.data.data, loading: false });
      },
      (err) => {
        setStoreTypes({ ...storeTypes, data: [], loading: false });
      }
    );
  }

  function fetchLocations() {
    setLocList({ ...locList, data: [], loading: true });

    getLocation().then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.locationname != null
        );

        setLocList({ ...locList, data: result, loading: false });
        const locArr = res.data.data.filter(
          (item) => item.uuid === props.data?.location_id
        );

        setValue("location", locArr);
      },
      (err) => {
        setLocList({ ...locList, data: [], loading: false });
      }
    );
  }

  function fetchInternalCompany() {
    setIntCompList({ ...intCompList, data: [], loading: true });

    getInternalCompany().then(
      (res) => {
        setIntCompList({ ...intCompList, data: res.data.data, loading: false });

        if(props.data?.internal_company_id !== null){
          const comArr = res.data.data.filter(
            (item) => item.uuid === props.data?.internal_company_id
          );
          setValue("internal_company", comArr);
        } else {
          setValue("internal_company", []);
        }
  
      },
      (err) => {
        setIntCompList({ ...intCompList, data: [], loading: false });
      }
    );
  }

  function fetchParties() {
    setPartyList({ ...partyList, data: [], loading: true });

    getCustomer().then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.organization !== "" && item.organization !== null
        );

        if(props.data?.party_id !== null){
          const partyArr = result.filter(
            (item) => item.uuid === props.data?.party_id
          );
          setValue("party", partyArr);
        } else {
          setValue("party", []);
        }
        

        setPartyList({ ...partyList, data: result, loading: false });
      },
      (err) => {
        setPartyList({ ...partyList, data: [], loading: false });
      }
    );
  }

  function fetchStores() {
    setStoreList({ ...storeList, data: [], loading: true });
    get_store({ parent_list: true }).then(
      (res) => {
        setStoreList({ ...storeList, data: res.data.data, loading: false });
        const storeArr = res.data.data.filter(
          (item) => item.uuid === props.data?.parent_id_id?.uuid
        );
        setValue("parent_store", storeArr);

        let arr = res.data.data.filter(
          (item) => item.location_id == props.data?.location_id
        );
        setParentstoreList({ data: arr, loading: false });
      },
      (err) => {
        setStoreList({ ...storeList, data: [], loading: false });
      }
    );
  }

  const onSubmit = (data) => {
    // console.log("data", data);
    if (data !== "") {
      const reqPayload = {
        uuid: props.data.uuid,
        name: data.name.trim(),
        store_types: data.store_type[0]?.name,
        location_id: data.location[0]?.uuid,
        // internal_company_id: data.internal_company[0]?.uuid,
        // internal_company_name: data.internal_company[0]?.comName,
        parent_id_id: data.parent_store?.[0]?.uuid,
      };

      if (data.party !== undefined && data.party?.length > 0) {
        reqPayload.party_id = data.party?.[0]?.uuid;
        reqPayload.party_name = data.party?.[0]?.organization;
      } else {
        reqPayload.internal_company_id = data.internal_company?.[0]?.uuid;
        reqPayload.internal_company_name = data.internal_company?.[0]?.comName;
      }

      update_store(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Store updated successfully !", {
              autoClose: 2000,
            });
            setVisible(false);
            props.fetchStoreList(props.page, props.entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <Edit
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_edit_store
            ? props.constLabel.lbl_edit_store
            : "Edit Store"
        }
        className="menu_icon_grey cursor_pointer me-2"
        size={20}
        onClick={() => {
          // setUpdateForm(val)
          setVisible(true); reset();
        }}
        />
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_update_store
              ? props.constLabel.lbl_update_store
              : "Update Store"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            {/* Organization */}
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {props.constLabel?.lbl_internal_company
                  ? props.constLabel?.lbl_internal_company
                  : "Internal Company"}
                {/* <span className="text-danger"> *</span> */}
              </label>
              <div className="col-md-12">
                <Controller
                  name="internal_company"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="comName"
                      multiple={false}
                      options={
                        intCompList.data !== undefined ? intCompList.data : []
                      }
                      // options={[{name : null}, {name: "null"}]}
                      isLoading={intCompList.state}
                      placeholder="Select internal company"
                      onChange={(selected) => {
                        onChange(selected);
                        if (selected && selected.length > 0) {
                          setValue("party", []);
                        }
                      }}
                      onBlur={onBlur}
                      selected={value}
                      // isInvalid={errors.internal_company ? true : false}
                    />
                  )}
                />
                {/* <span className="text-danger err-msg">
                  {errors.internal_company?.message}
                </span> */}
              </div>
            </div>

            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-4 roboto-bold"
              >
                {props.constLabel?.lbl_party
                  ? props.constLabel?.lbl_party
                  : "Party"}
                {/* <span className="text-danger"> *</span> */}
              </label>
              <div className="col-md-12">
                <Controller
                  name="party"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="organization"
                      multiple={false}
                      // size="sm"
                      options={
                        partyList.data !== undefined ? partyList.data : []
                      }
                      isLoading={partyList.loading}
                      placeholder="Select party"
                      onChange={(selected) => {
                        onChange(selected);
                        if (selected && selected.length > 0) {
                          setValue("internal_company", []);
                        }
                      }}
                      onBlur={onBlur}
                      selected={value}
                      // isInvalid={errors.party ? true : false}
                    />
                  )}
                />
                {/* <span className="text-danger err-msg">
                    {errors.party?.message}
                  </span> */}
              </div>
            </div>

            {/* Location */}
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {props.constLabel?.lbl_location_name
                  ? props.constLabel.lbl_location_name
                  : "Location Name"}
                <span className="text-danger">*</span>
              </label>
              <div className="col-md-12">
                <Controller
                  name="location"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="locationname"
                      multiple={false}
                      options={locList.data !== undefined ? locList.data : []}
                      // options={[{name : null}, {name: "null"}]}
                      isLoading={locList.loading}
                      placeholder="Select location"
                      onChange={(selected) => {
                        onChange(selected);
                        if (selected && selected.length > 0) {
                          let arr = storeList.data.filter(
                            (item) => item.location_id == selected[0].uuid
                          );
                          setParentstoreList({ data: arr, loading: false });
                          // setValue("parent_store", []);
                        } else {
                          setParentstoreList({ data: [], loading: false });
                          setValue("parent_store", []);
                        }
                      }}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.location ? true : false}
                    />
                  )}
                />
                <span className="text-danger err-msg">
                  {errors.location?.message}
                </span>
              </div>
            </div>

            {/* Parent Store */}
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {props.constLabel?.lbl_parent_store
                  ? props.constLabel?.lbl_parent_store
                  : "Parent Store"}
              </label>
              <div className="col-md-12">
                <Controller
                  name="parent_store"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      multiple={false}
                      options={
                        parentstoreList.data !== undefined
                          ? parentstoreList.data
                          : []
                      }
                      // options={[{name : null}, {name: "null"}]}
                      isLoading={parentstoreList.loading}
                      placeholder="Select parent store"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      // isInvalid={errors.parent_store ? true : false}
                    />
                  )}
                />
                {/* <span className="text-danger err-msg">
                    {errors.parent_store?.message}
                  </span> */}
              </div>
            </div>

            {/* Store type */}
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {props.constLabel?.lbl_store_type
                  ? props.constLabel.lbl_store_type
                  : "Store Type"}
                <span className="text-danger">*</span>
              </label>
              <div className="col-md-12">
                <Controller
                  name="store_type"
                  control={control}
                  defaultValue={
                    props.data?.store_types !== null
                      ? [{ name: props.data?.store_types }]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      defaultSelected={
                        props.data?.store_types !== null
                          ? [{ name: props.data?.store_types }]
                          : []
                      }
                      multiple={false}
                      options={
                        storeTypes.data !== undefined ? storeTypes.data : []
                      }
                      // options={[{name : null}, {name: "null"}]}
                      isLoading={storeTypes.loading}
                      placeholder="Select store type"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.store_type ? true : false}
                    />
                  )}
                />
                <span className="text-danger err-msg">
                  {errors.store_type?.message}
                </span>
              </div>
            </div>
            {/* Store name */}
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {props.constLabel?.lbl_store_name
                  ? props.constLabel.lbl_store_name
                  : "Store Name"}
                <span className="text-danger">*</span>
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className={
                    errors.name ? "form-control  is-invalid" : "form-control "
                  }
                  id="name"
                  name="name"
                  placeholder="Enter store name"
                  defaultValue={props.data?.name}
                  {...register("name")}
                />
                <span className="text-danger err-msg">
                  {errors.name?.message}
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditStoreMaster;
