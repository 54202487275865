import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert2";
import { faBan, faFilter, faCog } from "@fortawesome/free-solid-svg-icons";
import { get_rmi, getProdList, getSupplier } from "../../../../api/index";
import DatePicker from "react-datepicker";
import {
  calMaxPage_new,
  convertDate,
  convertDateYYMMDD,
  formatDateString,
  getLabels,
  setAccessPrivilage,
  splitString,
  getFinancialYearStartDate
} from "../../../../common/Functions/CommonFunctions";
import { useSelector } from "react-redux";
import { Pagination } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { RMI_Col_Name } from "../../../../common/Labels/labelConstant";
import { Tooltip } from "react-tooltip";
import { Repeat, Image } from "react-feather";
import RMIGallery from "./RMIGallary";
import List from "../../../../assets/images/icons/project_menu/checklist.png";
import Slide from "../../../../assets/images/icons/project_menu/collapse.png";

function RMI() {
  const [supplierList, setSupplierList] = useState({
    data: [],
    loading: false,
  });
  const [prodList, setProdList] = useState({ data: [], loading: true });

  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });

  const [filterConfig, setFilterConfig] = useState({
    suppliername: [],
    productid: [],
    challanno: "",
    material_code: "",
    ponum: "",
  });
  const [galleryModal, setGalleryModal] = useState({ mode: false, data: {} });

  const [startDate, setStartDate] = useState(getFinancialYearStartDate(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const [constLabel, setConstLabel] = useState({});
  const [showLabel, setShowLabel] = useState(RMI_Col_Name);

  const { control } = useForm({ mode: "onChange" }); // initialise the hook

  let navigate = useNavigate();

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const [privilage, setPrivilage] = useState(
    setAccessPrivilage(loginData, "Purchase")
  );

  useEffect(() => {
    setConstLabel(getLabels("Purchase", "RMI"));
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchRMIList(1, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig, startDate, endDate]);

  useEffect(() => {
    fetchSuppliers();
    fetchProducts();
  }, []);

  function getPrvMonthDateFromToday() {
    const today = new Date();
    const endDate = new Date(today);
    endDate.setMonth(today.getMonth() - 1);
    return endDate;
  }

  function fetchRMIList(pg, epp) {
    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
      startDate: convertDateYYMMDD(new Date(startDate), "-"),
      endDate: convertDateYYMMDD(new Date(endDate), "-"),
    };
    setDataList({ ...dataList, data: [], loading: true });

    if (filterConfig?.suppliername?.length > 0) {
      payload.suppliername = filterConfig.suppliername?.[0]?.name;
      // payload.suppliername = JSON.stringify(
      //   filterConfig.suppliername.map((item) => item.name)
      // );
    } else {
      delete payload.suppliername;
    }

    if (filterConfig.challanno != "") {
      // payload.searchVal = filterConfig.challanno;
      payload.challanno = filterConfig.challanno.trim(); //Search by challan no
    } else {
      delete payload.challanno;
    }

    if (filterConfig.material_code != "") {
      // payload.searchVal = filterConfig.material_code;
      payload.material_code = filterConfig.material_code.trim(); //Search by material code
    } else {
      delete payload.material_code;
    }

    if (filterConfig.ponum != "") {
      // payload.searchVal = filterConfig.material_code;
      payload.ponum = filterConfig.ponum.trim(); //Search by po number
    } else {
      delete payload.ponum;
    }

    if (filterConfig?.productid?.length > 0) {
      payload.productid = filterConfig.productid?.[0]?.productId;
      // payload.productid = JSON.stringify(
      //   filterConfig.productid.map((item) => item.name)
      // );
    } else {
      delete payload.productid;
    }

    get_rmi(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });
        setMaxPage(calMaxPage_new(parseInt(res.data.total), epp));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, data: [], loading: false });
      }
    );
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }
  function clearFilter() {
    setFilterConfig({
      ...filterConfig,
      suppliername: [],
      productid: [],
      challanno: "",
      material_code: "",
      ponum: "",
    });
    setStartDate(getFinancialYearStartDate(new Date()));
    setEndDate(new Date());
    setSrNo(0);
    setPage(1);
  }

  function fetchSuppliers() {
    setSupplierList({ ...supplierList, loading: true, data: [] });
    getSupplier({ partyType: "Supplier" }).then(
      (res) => {
        const result = res.data.data.filter((item) => item.name !== null);
        setSupplierList({
          ...supplierList,
          loading: false,
          data: result,
        });
      },
      (err) => {
        setSupplierList({ ...supplierList, loading: false, data: [] });
        toast.error("Something went wrong in suppliers!", {
          autoClose: 3000,
        });
      }
    );
  }

  function fetchProducts() {
    setProdList({ ...prodList, loading: true, data: [] });
    getProdList().then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.productName !== null
        );
        setProdList({
          ...prodList,
          loading: false,
          data: result,
        });
      },
      (err) => {
        setProdList({ ...prodList, loading: false, data: [] });
        toast.error("Something went wrong in suppliers!", {
          autoClose: 3000,
        });
      }
    );
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  const handleFromDateChange = (date) => {
    if (endDate && date > endDate) {
      return;
    }
    setStartDate(date);
  };

  const handleToDateChange = (date) => {
    if (startDate && date < startDate) {
      return;
    }
    setEndDate(date);
  };

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div id="mySidebar" className="customsidebar ">
        <a className="closebtn" onClick={() => toggleNav()}>
          ×
        </a>

        {galleryModal.mode && (
          <RMIGallery
            galleryModal={galleryModal}
            setGalleryModal={setGalleryModal}
            constLabel={constLabel}
          />
        )}

        <div className=" content">
          <div className="filter row">
            <label
              className="filterLabel mb-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {constLabel?.lbl_selectDateRange
                ? constLabel.lbl_selectDateRange
                : "Select Date Range"}
            </label>
            <div className="fromDate col-12 col-md-6">
              <DatePicker
                id="fromDate"
                className="form-control"
                selected={startDate}
                showYearDropdown
                scrollableMonthYearDropdown
                dateFormat="dd/MM/yyyy"
                placeholderText="From Date"
                onChange={(date) => handleFromDateChange(date)}
                maxDate={new Date()}
              />
            </div>

            <div className="toDate col-12 col-md-6">
              <DatePicker
                id="toDate"
                className="form-control"
                showYearDropdown
                scrollableMonthYearDropdown
                selected={endDate}
                dateFormat="dd/MM/yyyy"
                placeholderText="To Date"
                onChange={(date) => handleToDateChange(date)}
                maxDate={new Date()}
              />
            </div>
          </div>
          <div>
            <label
              className="filterLabel my-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {"Select Supplier"}
            </label>

            <Typeahead
              id="basic-typeahead-single"
              labelKey="name"
              name="suppliername"
              // size="sm"
              multiple={false}
              options={supplierList.data}
              isLoading={supplierList.loading}
              selected={filterConfig.suppliername}
              onChange={(val) => {
                if (val.length > 0) {
                  setFilterConfig({ ...filterConfig, suppliername: val });
                } else {
                  setFilterConfig({ ...filterConfig, suppliername: [] });
                }
              }}
              placeholder="Search supplier name "
            />
          </div>
          <div className="mt-2">
            <label
              className="filterLabel my-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {"Select Product"}
            </label>

            <Typeahead
              id="basic-typeahead-single"
              labelKey="productName"
              name="productid"
              // size="sm"
              multiple={false}
              options={prodList.data}
              isLoading={prodList.loading}
              selected={filterConfig.productid}
              onChange={(val) => {
                if (val.length > 0) {
                  setFilterConfig({ ...filterConfig, productid: val });
                } else {
                  setFilterConfig({ ...filterConfig, productid: [] });
                }
              }}
              placeholder="Search product name "
            />
          </div>
          <div className="mt-2">
            <label
              className="filterLabel mb-1 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {constLabel?.lbl_challan_no
                ? constLabel?.lbl_challan_no
                : "Challan No"}
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Search by challan no"
              value={filterConfig.challanno}
              onChange={(val) => {
                const inputValue = val.target.value;
                if (!inputValue.trim()) {
                  // Input contains only whitespace, don't trigger the search.
                  if (filterConfig.challanno.length == 1) {
                    setFilterConfig({ ...filterConfig, challanno: "" });
                  }
                } else {
                  setFilterConfig({
                    ...filterConfig,
                    challanno: inputValue,
                  });
                }
              }}
            />
          </div>
          <div className="mt-2">
            <label
              className="filterLabel mb-1 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {constLabel?.lbl_material_code
                ? constLabel?.lbl_material_code
                : "Material Code"}
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Search by material code"
              value={filterConfig.material_code}
              onChange={(val) => {
                const inputValue = val.target.value;
                if (!inputValue.trim()) {
                  // Input contains only whitespace, don't trigger the search.
                  if (filterConfig.material_code.length == 1) {
                    setFilterConfig({ ...filterConfig, material_code: "" });
                  }
                } else {
                  setFilterConfig({
                    ...filterConfig,
                    material_code: inputValue,
                  });
                }
              }}
            />
          </div>
          <div className="mt-2">
            <label
              className="filterLabel mb-1 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {constLabel?.lbl_po_no ? constLabel?.lbl_po_no : "PO Number"}
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Search by po number"
              value={filterConfig.ponum}
              onChange={(val) => {
                const inputValue = val.target.value;
                if (!inputValue.trim()) {
                  // Input contains only whitespace, don't trigger the search.
                  if (filterConfig.ponum.length == 1) {
                    setFilterConfig({ ...filterConfig, ponum: "" });
                  }
                } else {
                  setFilterConfig({
                    ...filterConfig,
                    ponum: inputValue,
                  });
                }
              }}
            />
          </div>
          <div className="my-3 d-flex justify-content-end">
            <button
              onClick={() => clearFilter()}
              className="btn btn-sm btn-yellow "
            >
              <FontAwesomeIcon
                style={{ color: "#344454" }}
                icon={faBan}
                size="sm"
              />{" "}
              {constLabel?.lbl_clear_filter
                ? constLabel.lbl_clear_filter
                : "Clear Filter"}
            </button>
          </div>
        </div>
      </div>
      <div className="min-vh-100" id="root_div_main">
        <div className="content-wrapper">
          <div className="card border-0">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="d-md-flex justify-content-start col-12 col-md-6">
                  <Tooltip id={"open-tooltip"} place="top" />
                  <img
                    src={Slide}
                    height="32"
                    width="32"
                    className="cursor_pointer my-1 me-2"
                    onClick={() => toggleNav()}
                    data-tooltip-id={"open-tooltip"}
                    data-tooltip-content={"Sidebar"}
                  />
                  <h1 className="bold">
                    {constLabel?.lbl_raw_material_inward
                      ? constLabel?.lbl_raw_material_inward
                      : "Raw Material Inward"}
                  </h1>
                </div>

                <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                  <Tooltip id={"show-tooltip"} place="top" />
                  <div
                    className="dropdown"
                    data-tooltip-id={"show-tooltip"}
                    data-tooltip-content={"Show / Hide Columns"}
                  >
                    <div
                      className="d-md-flex justify-content-start align-items-center"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {/* <FontAwesomeIcon icon={faListCheck} size="lg" /> */}
                      {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                      <img
                        src={List}
                        height="32"
                        width="32"
                        className="cursor_pointer"
                      />
                    </div>

                    <ul className="dropdown-menu px-2">
                      {showLabel !== undefined && showLabel.length > 0
                        ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                {dataList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : dataList.data.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead className="table-grey roboto-bold">
                          <tr className="">
                            <th scope="col">{"Sr.No"}</th>

                            {showLabel.map((v, i) =>
                              v.label === "RMI Id" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_rmi_id
                                    ? constLabel.lbl_rmi_id
                                    : "RMI Id"}
                                </th>
                              ) : v.label === "PO Number" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_po_no
                                    ? constLabel.lbl_po_no
                                    : "PO Number"}
                                </th>
                              ) : v.label === "Material Code" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_material_code
                                    ? constLabel.lbl_material_code
                                    : "Material Code"}
                                </th>
                              ) : v.label === "Inward Date" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_inward_date
                                    ? constLabel.lbl_inward_date
                                    : "Inward Date"}
                                </th>
                              ) : v.label === "Material Type" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_material_type
                                    ? constLabel.lbl_material_type
                                    : "Material Type"}
                                </th>
                              ) : // ) : v.label === "PO/SO No" && v.flag ? (
                              //   <th key={i} scope="col">
                              //     {constLabel?.lbl_so_po_no
                              //       ? constLabel.lbl_so_po_no
                              //       : "PO/SO No"}
                              //   </th>
                              v.label === "Product Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_product_name
                                    ? constLabel.lbl_product_name
                                    : "Product Name"}
                                </th>
                              ) : v.label === "Challan No" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_challan_no
                                    ? constLabel.lbl_challan_no
                                    : "Challan No"}
                                </th>
                              ) : v.label === "Inspection Status" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_inspection_status
                                    ? constLabel.lbl_inspection_status
                                    : "Inspection Status"}
                                </th>
                              ) : v.label === "Available Qty" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_available_qty
                                    ? constLabel.lbl_available_qty
                                    : "Available Qty"}
                                </th>
                              ) : v.label === "Inward Qty" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_inward_qty
                                    ? constLabel.lbl_inward_qty
                                    : "Inward Qty"}
                                </th>
                              ) : v.label === "Supplier Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_supplier_name
                                    ? constLabel.lbl_supplier_name
                                    : "Supplier Name"}
                                </th>
                              ) : v.label === "Heat No" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_heat_no
                                    ? constLabel.lbl_heat_no
                                    : "Heat No"}
                                </th>
                              ) : v.label === "Price" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_price
                                    ? constLabel.lbl_price
                                    : "Price"}
                                </th>
                              ) : v.label === "Adj" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_adj
                                    ? constLabel.lbl_adj
                                    : "Adj"}
                                </th>
                              ) : v.label === "Forwarding" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_forwarding
                                    ? constLabel.lbl_forwarding
                                    : "Forwarding"}
                                </th>
                              ) : v.label === "Freight" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_freight
                                    ? constLabel.lbl_freight
                                    : "Freight"}
                                </th>
                              ) : v.label === "Grand Amt" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_grand_amt
                                    ? constLabel.lbl_grand_amt
                                    : "Grand Amt"}
                                </th>
                              ) : v.label === "GST" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_gst
                                    ? constLabel.lbl_gst
                                    : "GST"}
                                </th>
                              ) : v.label === "Invoice Date" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_invoice_date
                                    ? constLabel.lbl_invoice_date
                                    : "Invoice Date"}
                                </th>
                              ) : v.label === "Net Amt" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_net_amt
                                    ? constLabel.lbl_net_amt
                                    : "Net Amt"}
                                </th>
                              ) : v.label === "Weight RCPL" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_weight_rcpl
                                    ? constLabel.lbl_weight_rcpl
                                    : "Weight RCPL"}
                                </th>
                              ) : v.label === "Weight Transport" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_weight_transport
                                    ? constLabel.lbl_weight_transport
                                    : "Weight Transport"}
                                </th>
                              ) : v.label === "Total" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_total
                                    ? constLabel.lbl_total
                                    : "Total"}
                                </th>
                              ) : null
                            )}

                            <th scope="col" width="15%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className=" roboto">
                          {dataList.data.map((val, ind) => (
                            <tr key={ind}>
                              <td>{parseInt(ind) + 1 + parseInt(srNo)}</td>
                              {showLabel.map((item, idx) =>
                                item.label === "RMI Id" && item.flag ? (
                                  <td key={idx}>
                                    {" "}
                                    {val.toolinvenId != "" &&
                                    val.toolinvenId !== null
                                      ? splitString(val.toolinvenId)
                                      : "NA"}
                                  </td>
                                ) : item.label === "PO Number" && item.flag ? (
                                  <td key={idx}>
                                    {" "}
                                    {val.ponum != "" && val.ponum !== null
                                      ? val.ponum
                                      : "NA"}
                                  </td>
                                ) : item.label === "Material Code" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {" "}
                                    {val.material_code
                                      ? val.material_code
                                      : "NA"}
                                  </td>
                                ) : item.label === "Inward Date" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.inward_date
                                      ? formatDateString(val.inward_date)
                                      : "NA"}
                                  </td>
                                ) : item.label === "Material Type" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.productDetails &&
                                    val.productDetails?.materialTypeDetail &&
                                    val.productDetails?.materialTypeDetail
                                      ?.material_type
                                      ? val.productDetails?.materialTypeDetail
                                          ?.material_type
                                      : "NA"}
                                  </td>
                                ) : // ) : item.label === "PO/SO No" && item.flag ? (
                                //   <td key={idx}>
                                //     {val.po_status ? val.po_status : "NA"}
                                //   </td>
                                item.label === "Product Name" && item.flag ? (
                                  <td key={idx}>
                                    {" "}
                                    {val.productname ? val.productname : "NA"}
                                  </td>
                                ) : item.label === "Challan No" && item.flag ? (
                                  <td key={idx}>
                                    {val.challanno ? val.challanno : "NA"}
                                  </td>
                                ) : item.label === "Inspection Status" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.inspectionstatus
                                      ? val.inspectionstatus
                                      : "NA"}
                                  </td>
                                ) : item.label === "Available Qty" &&
                                  item.flag ? (
                                  <td key={idx}>{val.qty ? val.qty : "NA"}</td>
                                ) : item.label === "Inward Qty" && item.flag ? (
                                  <td key={idx}>
                                    {val.acceptyqty ? val.acceptyqty : "NA"}
                                  </td>
                                ) : item.label === "Supplier Name" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.suppliername ? val.suppliername : "NA"}
                                  </td>
                                ) : item.label === "Heat No" && item.flag ? (
                                  <td key={idx}>
                                    {val.heat_no != "" && val.heat_no !== null
                                      ? val.heat_no
                                      : "NA"}
                                  </td>
                                ) : item.label === "Price" && item.flag ? (
                                  <td key={idx}>
                                    {val.price ? val.price : "NA"}
                                  </td>
                                ) : item.label === "Adj" && item.flag ? (
                                  <td key={idx}>{val.adj ? val.adj : "NA"}</td>
                                ) : item.label === "Forwarding" && item.flag ? (
                                  <td key={idx}>
                                    {val.forwarding ? val.forwarding : "NA"}
                                  </td>
                                ) : item.label === "Freight" && item.flag ? (
                                  <td key={idx}>
                                    {val.freight ? val.freight : "NA"}
                                  </td>
                                ) : item.label === "Grand Amt" && item.flag ? (
                                  <td key={idx}>
                                    {val.grand_amt ? val.grand_amt : "NA"}
                                  </td>
                                ) : item.label === "GST" && item.flag ? (
                                  <td key={idx}>{val.gst ? val.gst : "NA"}</td>
                                ) : item.label === "Invoice Date" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.invoice_date
                                      ? formatDateString(val.invoice_date)
                                      : "NA"}
                                  </td>
                                ) : item.label === "Net Amt" && item.flag ? (
                                  <td key={idx}>
                                    {val.net_amt ? val.net_amt : "NA"}
                                  </td>
                                ) : item.label === "Weight RCPL" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.weight_rcpl ? val.weight_rcpl : "NA"}
                                  </td>
                                ) : item.label === "Weight Transport" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.weight_transport
                                      ? val.weight_transport
                                      : "NA"}
                                  </td>
                                ) : item.label === "Total" && item.flag ? (
                                  <td key={idx}>
                                    {val.total ? val.total : "NA"}
                                  </td>
                                ) : null
                              )}

                              <td>
                                {privilage.write && (
                                  <>
                                    <Tooltip
                                      id={"gallery-tooltip" + ind}
                                      place="top"
                                    />
                                    <Image
                                      data-tooltip-id={"gallery-tooltip" + ind}
                                      data-tooltip-content={
                                        constLabel?.lbl_rmi_gallery
                                          ? constLabel.lbl_rmi_gallery
                                          : "	RMI Gallery"
                                      }
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20}
                                      onClick={() =>
                                        setGalleryModal({
                                          ...galleryModal,
                                          mode: true,
                                          data: val,
                                        })
                                      }
                                    />
                                  </>
                                )}

                                {val?.productDetails?.materialTypeDetail
                                  ?.material_type == "BAR"
                                  ? privilage.write && (
                                      <>
                                        <Tooltip
                                          id={"conversion-tooltip"}
                                          place="top"
                                        />
                                        <Repeat
                                          data-tooltip-id={"conversion-tooltip"}
                                          data-tooltip-content={
                                            constLabel?.lbl_rmi_material_conversion
                                              ? constLabel?.lbl_rmi_material_conversion
                                              : "Raw Material Conversion"
                                          }
                                          className="menu_icon_grey cursor_pointer"
                                          size={20}
                                          onClick={() => {
                                            navigate("/pcms/rmi-conversion", {
                                              state: val,
                                            });
                                          }}
                                        />
                                      </>
                                    )
                                  : null}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="mb-0">
                        Show
                        <select
                          className="mx-1"
                          defaultValue={entriesPerPage}
                          onChange={(e) => {
                            setEntriesPerPage(e.target.value);
                            fetchRMIList(1, e.target.value);
                          }}
                        >
                          <option value={"10"}>10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        Entries
                      </p>
                      <p className="mb-0">{`Showing ${
                        parseInt(srNo) + 1
                      } to ${Math.min(
                        parseInt(entriesPerPage) + parseInt(srNo),
                        dataList.totalRecords
                      )} of ${dataList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchRMIList(page - 1, entriesPerPage);
                            setSrNo((prevC) =>
                              page - 1 == 1
                                ? 0
                                : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage || maxPage === 0 ? true : false
                          }
                          onClick={() => {
                            fetchRMIList(page + 1, entriesPerPage);
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  </>
                ) : (
                  <div
                    className="alert alert-danger mb-0 text-center my-4"
                    role="alert"
                  >
                    {"RMI data not found !"}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default RMI;
