import React, { useState, useEffect} from 'react'
import { useForm, Controller } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Edit, Edit2 } from 'react-feather';
import { Modal } from 'react-bootstrap';
import { updateLocation, getLoctionType, getCustomer, getInternalCompany } from '../../../../api';
import { Tooltip } from 'react-tooltip';

const schema = yup
  .object()
  .shape({
    locationType: yup
      .array()
      .required("Please select location type !")
      .min(1, "Please select location type"),
    locationname: yup
      .string()
      .required("Location name is required !"),
    locationaddress: yup
      .string()
      .required("Location address is required !"),
  })
  .required();

function EditLocation(props) {
const [visible, setVisible] = useState(false);

const [loc_Type, setLoc_Type]= useState({ data: [], state: false });
const [cust_data, setCust_Data]= useState({ data: [], state: false });

const [internalCompList, setInternalComp] = useState({ data: [], loading: false });

const { register, handleSubmit, formState, control, reset, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    }); // initialise the hook

    let { errors } = formState;

    useEffect(() => {
      
      if(visible){
        fetchLoctionType();
        fetchCustomer();
        fetchInternalComp();
      }
    }, [visible])
  
    function fetchLoctionType() {
      setLoc_Type({ ...loc_Type, data: [], state: true });
  
      getLoctionType().then((res) => {
          
          setLoc_Type({ ...loc_Type, data: res.data.data, state: false });
      }, (err) => {
          
          setLoc_Type({ ...loc_Type, data: [], state: false });
      })
    }

    function fetchCustomer() {
      setCust_Data({ ...cust_data, data: [], state: true });
  
      getCustomer().then((res) => {
          
          const result = res.data.data.filter(item => item.organization !== "" && item.organization !== null);
          setCust_Data({ ...cust_data, data: result, state: false });

          if(props.data?.party_id !== "" && props.data?.party_id !== null ){
            const ress = result.filter((item) => item.uuid === props.data?.party_id);
            
            setValue('party', ress);
          }
      }, (err) => {
          
          setCust_Data({ ...cust_data, data: [], state: false });
      })
    }

    function fetchInternalComp() {
      setInternalComp({ ...internalCompList, data: [], loading: true });
  
      getInternalCompany().then(
        (res) => {
          
          const result = res.data.data.filter(item => item.comName !== "" && item.comName !== null);
          setInternalComp({
            ...internalCompList,
            data: result,
            loading: false,
          });
          if(props.data?.internal_company_id !== "" && props.data?.internal_company_id !== null ){
            const ress = result.filter((item) => item.uuid === props.data?.internal_company_id);
            
            setValue('company', ress);
          }
        },
        (err) => {
          
          setInternalComp({ ...internalCompList, data: [], loading: false });
        }
      );
    }

    const onSubmit = (data) => {
        // console.log("data", data);
    
        if (data !== "") {
          const reqPayload = {
            uuid: props.data?.uuid,
            // locationId: props.data.locationId,
            locationtype: data.locationType[0]?.typename,
            location_type_id: data.locationType[0]?.uuid,
            locationname: data.locationname,
            locationaddress: data.locationaddress,
            // party_id:( data.party !== undefined || data.party !== null ) ? data.party[0]?.uuid : null,
            // internal_company_id: data.company[0]?.uuid,
          };
    
          if(data.party !== undefined && data.party.length > 0){
            reqPayload.party_id = data.party?.[0]?.uuid;
            reqPayload.party_name = data.party?.[0]?.organization;
            // reqPayload.internal_company_id = null;

          } else {
            reqPayload.internal_company_id = data.company?.[0]?.uuid
            reqPayload.company_name = data.company?.[0]?.comName;
            // reqPayload.party_name = null;
          }

          // console.log("reqPayload", reqPayload);
          
    
          updateLocation(reqPayload)
            .then(
              (res) => {
                
                if (res.status === 200 || res.status === 201) {
                  toast.success("Location updated Successfully !", {
                    autoClose: 3000,
                  });
                  setVisible(false);
                  props.fetchLocation(props.page, props.entriesPerPage);
                }
              },
              (err) => {
                
                if (err.response.status === 400) {
                  
                  toast.error(err.response.data.message, {
                    autoClose: 2000,
                  });
                  
                }
              }
            )
        } else {
          errors.showMessages();
        }
      };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <Edit
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_edit_location
            ? props.constLabel?.lbl_edit_location
            : "Edit Location"
        }
        className="menu_icon_grey cursor_pointer me-2"
        size={20}
        onClick={() => {
          setVisible(true);
          reset();
        }}
      />
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>{props.constLabel?.lbl_update_location ? props.constLabel?.lbl_update_location : "Update Location"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <form action="">
              <div className="form-group row mt-2">
                <label style={{fontSize: "14px"}} className="col-form-label col-md-12 roboto-bold">
                {props.constLabel?.lbl_location_type ? props.constLabel.lbl_location_type : "Location Type"}
                  <span>
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-12">
                  <Controller
                    name="locationType"
                    control={control}
                    defaultValue={[props.data?.location_type_id]}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        defaultSelected={[props.data?.location_type_id]}
                        labelKey="typename"
                       
                        multiple={false}
                        options={loc_Type.data !== undefined ? loc_Type.data : []}
                        isLoading={loc_Type.state}
                        placeholder='Select location type'
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.locationType ? true : false}
                      />
                    )}
                  />
                  <span className="text-danger err-msg">
                    {errors.locationType?.message}
                  </span>
                </div>
              </div>

              <div className="form-group row mt-2">
                <label style={{fontSize: "14px"}} className="col-form-label col-md-12 roboto-bold">
                {props.constLabel?.lbl_location_name ? props.constLabel.lbl_location_name : "Location Name"}
                  <span>
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text"
                    className={errors.locationname ? "form-control  is-invalid"
                        : "form-control "}
                    id="locationname"
                    name="locationname"
                    placeholder="Enter location name"
                    defaultValue={props.data?.locationname}
                    {...register("locationname")}
                  />
                  <span className="text-danger err-msg">
                    {errors.locationname?.message}
                  </span>
                </div>
              </div>

              <div className="form-group row mt-2">
                <label style={{fontSize: "14px"}} className="col-form-label col-md-12 roboto-bold">
                {props.constLabel?.lbl_address ? props.constLabel.lbl_address : "Address"}
                  <span>
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text"
                    className={errors.locationaddress ? "form-control  is-invalid"
                        : "form-control "}
                    id="locationaddress"
                    name="locationaddress"
                    placeholder="Enter address"
                    defaultValue={props.data?.locationaddress}
                    {...register("locationaddress")}
                  />
                  <span className="text-danger err-msg">
                    {errors.locationaddress?.message}
                  </span>
                </div>
              </div>

              <div className="form-group row mt-2">
                <label style={{fontSize: "14px"}} className="col-form-label col-md-12 roboto-bold">
                {props.constLabel?.lbl_party_name ? props.constLabel.lbl_party_name : "Party Name"}
                </label>
                <div className="col-md-12">
                  <Controller
                    name="party"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="organization"
                        multiple={false}
                   
                        // options={["Party-1", "Party-2", "Party-3"]}
                        options={cust_data.data !== undefined ? cust_data.data : []}
                        isLoading={cust_data.state}
                        placeholder="Select party"
                        onChange={(selected) => {
                          onChange(selected)
                        if(selected&&selected.length>0){
                          setValue("company",[])
                        }
                        }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.party ? true : false}
                      />
                    )}
                  />
                </div>
              </div>

              <div className="form-group row mt-2">
                <label style={{fontSize: "14px"}} className="col-form-label col-md-12 roboto-bold">
                {props.constLabel?.lbl_company_name ? props.constLabel.lbl_company_name : "Company Name"}
                </label>
                <div className="col-md-12">
                  <Controller
                    name="company"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="comName"
                        multiple={false}
                        options={internalCompList.data !== undefined ? internalCompList.data : []}
                        isLoading={internalCompList.loading}
                        placeholder="Select company"
                        onChange={(selected) => {
                          onChange(selected)
                        if(selected&&selected.length>0){
                          setValue("party",[])
                        }
                        }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.company ? true : false}
                      />
                    )}
                  />
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleSubmit(onSubmit)}
              className="f-16 btn btn-yellow "
            >
              {props.constLabel?.lbl_save ? props.constLabel.lbl_save : "Save"}
            </button>
          </Modal.Footer>
        </Modal>

    </React.Fragment>
  )
}

export default EditLocation