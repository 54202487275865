import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faBan,
  faFilter,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Modal } from "react-bootstrap";
import { Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import {
  delete_inspection_type,
  get_inspection_type,
  create_inspection_type,
} from "../../../api";
import {
  calMaxPage_new,
  getLabels,
  setAccessPrivilage,
} from "../../../common/Functions/CommonFunctions";
import EditInspectionType from "./EditInspectionType";
import { Tooltip } from "react-tooltip";
import { Inspection_Type_Col_Name } from "../../../common/Labels/labelConstant";
import { useSelector } from "react-redux";
import List from "../../../assets/images/icons/project_menu/checklist.png"

const schema = yup
  .object()
  .shape({
    inspection_name: yup
      .string()
      .trim()
      .required("Inspection type is required!"),
  })
  .required();

function InspectionType() {
    const [showLabel, setShowLabel] = useState(Inspection_Type_Col_Name);
    const [visible, setVisible] = useState(false);
    const [page, setPage] = useState(1);
    const [srNo, setSrNo] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [dataList, setDataList] = useState({
      data: [],
      loading: true,
      totalRecords: "",
    });
    const [sea_val, setSea_val] = useState(false);
  
    const [filterConfig, setFilterConfig] = useState({ searchVal: "" });
  
    const [constLabel, setConstLabel] = useState({});
  
    const loginData = useSelector(
      (state) => state.auth?.loginData?.data?.response
    );
    const [privilage] = useState(setAccessPrivilage(loginData, "Inspection Type"));
  
    const { register, handleSubmit, formState, control, reset, watch } = useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook
  
    let { errors } = formState;
  
    useEffect(() => {
      setConstLabel(getLabels("Master", "Inspection Type"));
      const debounceTimeout = setTimeout(() => {
        setPage(1);
        fetchInspection_Type(page, entriesPerPage);
      }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)
  
      return () => {
        clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
      };
    }, [filterConfig]);
  
    function clearFilter() {
      setFilterConfig({ ...filterConfig, searchVal: "" });
      setSrNo(0);
      setPage(1);
    }
  
    function fetchInspection_Type(pg, epp) {
      setDataList({ ...dataList, loading: true, data: [] });
  
      const payload = {
        pageNo: pg,
        entriesPerPage: epp,
      };
  
      if (filterConfig.searchVal !== "") {
        // payload.productName_list = filterConfig.productName;
        payload.searchVal = filterConfig.searchVal;
  
        if (
          filterConfig.searchVal?.length > 0 &&
          page !== 1 &&
          sea_val == false
        ) {
          payload.pageNo = 1;
          payload.entriesPerPage = entriesPerPage;
          setPage(1);
          setSrNo(0);
        }
      } else {
        delete payload.searchVal;
      }
  
      get_inspection_type(payload).then(
        (res) => {
          setDataList({
            ...dataList,
            loading: false,
            data: res.data.data,
            totalRecords: res.data.total,
          });

          if (
            filterConfig.searchVal != "" &&
            filterConfig.searchVal?.length > 0
          ) {
            setSea_val(true);
          } else {
            setSea_val(false);
          }

          setMaxPage(calMaxPage_new(res.data.total, epp));
          if (pg == 1) {
            setSrNo(0);
            setPage(1);
          }
        },
        (err) => {
          setDataList({ ...dataList, loading: false, data: [] });
        }
      );
    }
  
    const onSubmit = (data) => {
      if (data !== "") {
        const reqPayload = {
            inspection_name: data.inspection_name.trim(),
        };
  
        create_inspection_type(reqPayload).then(
          (res) => {
            if (res.status === 200 || res.status === 201) {
              toast.success("Inspection type created successfully !", {
                autoClose: 2000,
              });
              setVisible(false);
              fetchInspection_Type(page, entriesPerPage);
            }
          },
          (err) => {
            if (err.response.status === 400) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
              // reset();
            }
          }
        );
      } else {
        errors.showMessages();
      }
    };
  
    // Deleting GST slab
    const delInspection_Type = (val) => {
      SweetAlert.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this record !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ok",
        cancelButtonText: "cancel",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          // Firing delete api
          const payload = {
            uuid: val.uuid,
          };
          delete_inspection_type(payload).then(
            (res) => {
              toast.success("Inspection type deleted successfully !", {
                autoClose: 3000,
              });
              if (dataList.data.length == 1 && page > 1) {
                fetchInspection_Type(page - 1, entriesPerPage);
                setSrNo(parseInt(srNo - entriesPerPage));
                setPage(page - 1);
              } else {
                fetchInspection_Type(page, entriesPerPage);
              }
            },
            (err) => {
              console.log("err", err);
              toast.error(err.response.data.message, { autoClose: 3000 });
            }
          );
        }
      });
    };
  
    function openNav() {
      document.getElementById("mySidebar").style.width = "320px";
      document.getElementById("root_div_main").style.marginLeft = "320px";
      setTimeout(() => {
        document.getElementById("mySidebar").style.overflowX = "visible";
      }, [200]);
    }
  
    function closeNav() {
      document.getElementById("mySidebar").style.width = "0";
      document.getElementById("root_div_main").style.marginLeft = "0";
  
      document.getElementById("mySidebar").style.overflowX = "hidden";
    }
  
    function handleClick(ind) {
      setShowLabel((showLabel) => {
        const updatedCardShow = showLabel.map((val, i) => {
          if (ind === i) {
            // Toggle the flag for the clicked checkbox
            return { ...val, flag: !val.flag };
          }
          return val;
        });
  
        return updatedCardShow;
      });
    }

  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}
      <div className="min-vh-100" id="root_div_main">
        <Modal
          show={visible}
          onHide={() => {
            setVisible(false);
            reset();
          }}
          size={"md"}
        >
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>
              {constLabel?.lbl_add_inspection_type
                ? constLabel.lbl_add_inspection_type
                : "Add Inspection Type"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form action="">
              <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 roboto-bold"
                >
                  {constLabel?.lbl_inspection_type
                    ? constLabel?.lbl_inspection_type
                    : "Inspection Type"}
                  <span className="text-danger"> *</span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text"
                    className={
                      errors.inspection_name
                        ? "form-control  is-invalid"
                        : "form-control "
                    }
                    id="inspection_name"
                    name="inspection_name"
                    placeholder="Enter inspection type"
                    {...register("inspection_name")}
                  />
                  <span className="text-danger err-msg">
                    {errors.inspection_name?.message}
                  </span>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleSubmit(onSubmit)}
              className="f-16 btn btn-yellow "
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
            </button>
          </Modal.Footer>
        </Modal>

        {privilage.read && (
          <div id="mySidebar" className="customsidebar ">
            <a className="closebtn" onClick={() => closeNav()}>
              ×
            </a>

            <div className=" content">
              <div>
                <label
                  className="filterLabel mb-1 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {constLabel?.lbl_search ? constLabel.lbl_search : "Search"}
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  placeholder="Search by name"
                  value={filterConfig.searchVal}
                  onChange={(val) => {
                    const inputValue = val.target.value;
                    if (!inputValue.trim()) {
                      // Input contains only whitespace, don't trigger the search.
                      if (filterConfig.searchVal.length == 1) {
                        setFilterConfig({ ...filterConfig, searchVal: "" });
                      }
                    } else {
                      setFilterConfig({
                        ...filterConfig,
                        searchVal: inputValue,
                      });
                    }
                    if(inputValue.length == 0){
                      setFilterConfig({ ...filterConfig, searchVal: "" });
                    }
                  }}
                />
              </div>
              <div className="my-3 d-flex justify-content-end">
                <button
                  onClick={() => clearFilter()}
                  className="btn btn-sm btn-yellow "
                >
                  <FontAwesomeIcon
                    style={{ color: "#344454" }}
                    icon={faBan}
                    size="sm"
                  />{" "}
                  {constLabel?.lbl_clear_filter
                    ? constLabel.lbl_clear_filter
                    : "Clear Filter"}
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="content-wrapper">
          <div className="card border-0">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="d-md-flex justify-content-start align-items-center col-12 col-md-6">
                  {/* <FontAwesomeIcon
                      onClick={() => toggleNav()}
                      className="cursor_pointer mb-2"
                      style={{
                        color: "Black",
                        marginRight: "10px",
                      }}
                      icon={faBars}
                      size="lg"
                    /> */}
                 <h1 className="bold">
                  {constLabel?.lbl_inspection_type
                    ? constLabel?.lbl_inspection_type
                    : "Inspection Type"}
                </h1>
                </div>
                <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                  <div className="col-12 col-md-6 me-md-2 me-md-0">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search by name"
                      value={filterConfig.searchVal}
                      onChange={(val) => {
                        const inputValue = val.target.value;
                        if (!inputValue.trim()) {
                          // Input contains only whitespace, don't trigger the search.
                          if (filterConfig.searchVal.length == 1) {
                            setFilterConfig({ ...filterConfig, searchVal: "" });
                          }

                          if(inputValue.length == 0){
                            setFilterConfig({ ...filterConfig, searchVal: "" });
                          }
                        } else {
                          setFilterConfig({
                            ...filterConfig,
                            searchVal: inputValue,
                          });
                        }
                      }}
                    />
                  </div>
                  {privilage.write && (
                    <button
                      className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                      onClick={() => {
                        setVisible(true);
                        reset();
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faPlus}
                        size="sm"
                      />{" "}
                      {constLabel?.lbl_add_inspection_type
                        ? constLabel?.lbl_add_inspection_type
                        : "Add Inspection Type"}
                    </button>
                  )}

                  <Tooltip id={"show-tooltip"} place="top" />
                  <div
                    className="dropdown"
                    data-tooltip-id={"show-tooltip"}
                    data-tooltip-content={"Show / Hide Columns"}
                  >
                    <div
                      className="d-md-flex justify-content-start align-items-center"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {/* <FontAwesomeIcon icon={faCog} size="lg" /> */}
                      {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                      <img src={List} height='32' width='32' className="cursor_pointer" />
                    </div>

                    <ul className="dropdown-menu px-2">
                      {showLabel !== undefined && showLabel.length > 0
                        ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                {dataList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : dataList && dataList.data?.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                        width={"100%"}
                      >
                        <thead className="table-grey roboto">
                          <tr className="">
                            <th scope="col" width="15%">
                              {"Sr.No"}
                            </th>
                            {showLabel.map((v, i) =>
                              v.label === "Inspection Type" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_inspection_type
                                    ? constLabel?.lbl_inspection_type
                                    : "Inspection Type"}
                                </th>
                              ) : null
                            )}
                            <th scope="col" width="15%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="roboto">
                          {dataList.data?.map((val, i) => (
                            <tr key={i} className="">
                              {/* <td>{val.NCReasonId}</td> */}
                              <td>{i + parseInt(srNo) + 1}</td>
                              {showLabel.map((item, idx) =>
                                item.label === "Inspection Type" && item.flag ? (
                                  <td key={idx}>
                                    {val.inspection_name != "" &&
                                    val.inspection_name !== null
                                      ? val.inspection_name
                                      : "NA"}
                                  </td>
                                ) : null
                              )}
                              <td>
                                {privilage.write && (
                                  <EditInspectionType
                                    data={val}
                                    constLabel={constLabel}
                                    fetchInspection_Type={fetchInspection_Type}
                                    page={page}
                                    entriesPerPage={entriesPerPage}
                                  />
                                )}

                                {privilage.delete && (
                                  <>
                                    <Tooltip id={"delete-tooltip" + i} place="top" />
                                      <Trash2
                                         data-tooltip-id={"delete-tooltip" + i}
                                         data-tooltip-content={
                                          constLabel?.lbl_delete_inspection_type
                                            ? constLabel?.lbl_delete_inspection_type
                                            : "Delete Inspection Type"
                                          }
                                        className="menu_icon_red cursor_pointer"
                                        size={20} 
                                        onClick={(e) => {
                                          if(val.client_id == null){
                                            toast.error("You are not authorized to delete this record", {
                                              autoClose: 2000
                                            })
                                          } else {
                                            delInspection_Type(val);
                                          }
                                        }}
                                      />
                                  </>
                                )}

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <p className="mb-0">
                        Show
                        <select
                          className="mx-1"
                          defaultValue={entriesPerPage}
                          onChange={(e) => {
                            setEntriesPerPage(e.target.value);
                            fetchInspection_Type(1, e.target.value);
                          }}
                        >
                          <option value={"10"}>10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        Entries
                      </p>
                      <p className="mb-0">{`Showing ${
                        parseInt(srNo) + 1
                      } to ${Math.min(
                        parseInt(entriesPerPage) + parseInt(srNo),
                        dataList.totalRecords
                      )} of ${dataList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchInspection_Type(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1
                                ? 0
                                : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                            maxPage === 0 ||
                            entriesPerPage > dataList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            fetchInspection_Type(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  </>
                ) : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"Inspection type not found !"}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default InspectionType