import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { po_api_1 } from "../../constant/index";
import {
  PDFViewer,
  Document,
  Text,
  View,
  Image,
  Page,
  Link,
  StyleSheet,
  Font,
  PDFDownloadLink,
  BlobProvider,
} from "@react-pdf/renderer";
import numWords from "num-words";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Inter from "../../assets/fonts/Inter-Regular.ttf";
import Inter600 from "../../assets/fonts/Inter-SemiBold.ttf";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "react-bootstrap/Spinner";
import { faEye, faDownload } from "@fortawesome/free-solid-svg-icons";
import { convertDate } from "../../common/Functions/CommonFunctions";

function POWebView() {
  let url_data = useParams();
    const po_id = window.atob(url_data.id);
//   const po_id = url_data.id;

  let token;
  let token_data = url_data.token.split(" ");
  let authToken;
  let idtoken;
  if (token_data.length == 2) {
    authToken = window.atob(token_data[0]);
    idtoken = window.atob(token_data[1]);
    // authToken = token_data[0];
    // idtoken = token_data[1];
  } else {
    token = window.atob(url_data.token);
    // token = url_data.token;
  }

  const [total, setTotal] = useState("");
  const [POData, setPOData] = useState({ data: [] });
  const [error, setError] = useState("");
  const pdfRef = useRef(null);

  Font.register({
    family: "Inter, sans-serif",
    fonts: [
      {
        src: Inter,
      },
      {
        src: Inter600,
        fontWeight: 600,
      },
    ],
  });

  const styles = StyleSheet.create({
    viewer: {
      width: "100%",
      height: window.innerHeight,
    },
    page: {
      paddingTop: 15,
      paddingBottom: 40,
      paddingHorizontal: 15,
      color: "black",
      fontFamily: "Inter, sans-serif",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 28,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "black",
    },
    footer: {
      position: "absolute",
      fontSize: 12,
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "black",
      paddingTop: "50px",
      paddingBottom: "10",
      paddingLeft: "15",
      paddingRight: "15",
      // borderTop:'1px solid black',
    },
    row1: {
      flexDirection: "row",
      border: "1px solid black",
    },
    row2: {
      flexDirection: "row",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
    },
    row3: {
      flexDirection: "row",
      fontSize: "10px",
      width: "100%",
    },
    tablColumn1: {
      width: "5%",
      fontSize: "10px",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn1Data: {
      width: "5%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn2: {
      width: "30%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn2Data: {
      width: "30%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn3: {
      width: "10%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn3Data: {
      width: "10%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn4: {
      width: "10%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn4Data: {
      width: "10%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn5: {
      width: "15%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn5Data: {
      width: "15%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn6: {
      width: "8%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn6Data: {
      width: "8%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn7: {
      width: "10%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn7Data: {
      width: "10%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
      textAlign: "right",
    },
    tablColumn8: {
      width: "8%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn8Data: {
      width: "8%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
      textAlign: "right",
    },
    tablColumn9: {
      width: "14%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn9Data: {
      width: "14%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
      textAlign: "right",
    },
    subDivRow: {
      flexDirection: "row",
    },
    column: {
      width: "50%",
      // borderRight: '1px solid black',
      // backgroundColor: 'red',
      fontSize: "10px",
    },
    title: {
      textAlign: "center",
      fontWeight: 600,
    },
    companyName: {
      fontWeight: "600",
      fontSize: "12px",
    },
    signatureBlock: {
      width: "100%",
      marginLeft: "auto",
      marginTop: "100px",
      borderTop: "1px solid black",
      borderLeft: "1px solid black",
      padding: "5px",
    },
  });

  useEffect(() => {
    let headers;
    if (token_data.length == 2) {
      headers = { "X-AUTH-TOKEN": `${authToken}`, "X-ID-TOKEN": `${idtoken}` };
    } else {
      headers = {
        Auth: `Bearer ${token}`,
      };
    }

    // const url = process.env.REACT_APP_API_URL + po_api_1;
    const url = process.env.REACT_APP_API_INV_URL + "/" + po_api_1;

    axios
      .get(url, {
        params: { uuid: po_id }, // Include query parameters in the URL
        headers: headers, // Include the bearer token in headers
      })
      .then((response) => {
        // Handle the API response
        // console.log("response", response);
        setPOData({ ...POData, data: response.data.data[0] });

        const tot = response.data.data[0].PO_line_item.reduce(
          (accumulator, currentValue) => {
            // Extract the 'number' property from each object and add it to the accumulator
            return parseFloat(accumulator) + parseFloat(currentValue.amount);
          },
          0
        );

        setTotal(tot);
      })
      .catch((error) => {
        // Handle errors

        if (error.response.status == 401) {
          setError(
            "Access Denied: You are not authorized to view this content."
          );
        } else {
          setError(error.response.data.error);
          // toast.error(error.response.data.error, {
          //     autoClose: 2000,
          // });
        }
      });
  }, []);

  const MyDoc = () => {
    return (
      <Document>
        <Page style={styles.page}>
          <Text style={styles.title}>{"Purchase Order"}</Text>
          <View style={[styles.row1, { marginTop: "10px" }]}>
            <View style={styles.column}>
              <View style={{ padding: "10px" }}>
                <Text>Invoice To</Text>
                <Text style={styles.companyName}>
                  {POData.data.invoice_to_id?.comName}
                </Text>
                <Text>{POData.data.invoice_to_id?.comaddress}</Text>
                <Text>GST : {POData.data.invoice_to_id?.comGSTnumber}</Text>
              </View>
            </View>
            <View style={[styles.column, { borderLeft: "1px solid black" }]}>
              <View style={[styles.subDivRow]}>
                <View style={[styles.column, { padding: "5px" }]}>
                  <Text>Voucher Number</Text>
                  <Text>{POData.data.voucher_no}</Text>
                </View>
                <View
                  style={[
                    styles.column,
                    { padding: "5px", borderLeft: "1px solid black" },
                  ]}
                >
                  <Text>Date</Text>
                  <Text>{convertDate(POData.data.po_date, "-")}</Text>
                </View>
              </View>
              <View
                style={[styles.subDivRow, { borderTop: "1px solid black" }]}
              >
                <View style={[styles.column, { padding: "5px" }]}>
                  <Text></Text>
                </View>
                <View
                  style={[
                    styles.column,
                    { padding: "5px", borderLeft: "1px solid black" },
                  ]}
                >
                  <Text>Mode / Terms of Payment</Text>
                  <Text>{POData.data.credit_period}</Text>
                </View>
              </View>
              <View
                style={[styles.subDivRow, { borderTop: "1px solid black" }]}
              >
                <View
                  style={[styles.column, { padding: "5px", padding: "5px" }]}
                >
                  <Text>Reference No & Date</Text>
                </View>
                <View
                  style={[
                    styles.column,
                    { padding: "5px", borderLeft: "1px solid black" },
                  ]}
                >
                  <Text>Other References</Text>
                </View>
              </View>
              <View
                style={[styles.subDivRow, { borderTop: "1px solid black" }]}
              >
                <View
                  style={[styles.column, { padding: "5px", padding: "5px" }]}
                >
                  <Text>Despatch through</Text>
                </View>
                <View
                  style={[
                    styles.column,
                    { padding: "5px", borderLeft: "1px solid black" },
                  ]}
                >
                  <Text>Destination</Text>
                </View>
              </View>
            </View>
          </View>
          <View style={[styles.row2]}>
            <View style={styles.column}>
              <View style={{ padding: "10px" }}>
                <Text>Dispatch To</Text>
                <Text style={styles.companyName}>
                  {POData.data.despatch_to_id?.comName}
                </Text>
                <Text>{POData.data.despatch_to_id?.comaddress}</Text>
                <Text>GST : {POData.data.despatch_to_id?.comGSTnumber}</Text>
              </View>
            </View>

            <View
              style={[
                styles.column,
                { borderLeft: "1px solid black", padding: "10px" },
              ]}
            >
              <Text>Terms of Delivery</Text>
            </View>
          </View>

          <View style={[styles.row2]}>
            <View style={styles.column}>
              <View style={{ padding: "10px" }}>
                <Text>Supplier (Bill From)</Text>
                <Text style={styles.companyName}>
                  {POData.data.supplier_id?.name}
                </Text>
                <Text>{POData.data.supplier_id?.address}</Text>
                <Text>{POData.data.supplier_id?.email}</Text>
                <Text>GST : {POData.data.supplier_id?.gst}</Text>
              </View>
            </View>
          </View>

          <View style={[styles.row3, { marginTop: "10px" }]}>
            <View style={styles.tablColumn1}>
              <Text>Sr No</Text>
            </View>
            <View style={styles.tablColumn2}>
              <Text>Description of Goods</Text>
            </View>

            <View style={styles.tablColumn4}>
              <Text>Qty</Text>
            </View>
            <View style={styles.tablColumn5}>
              <Text>{"\u20B9"} Unit Price</Text>
            </View>
            <View style={styles.tablColumn6}>
              <Text>Unit</Text>
            </View>
            <View style={styles.tablColumn7}>
              <Text>GST (%)</Text>
            </View>
            <View style={styles.tablColumn8}>
              <Text>Discount</Text>
            </View>
            <View style={styles.tablColumn9}>
              <Text> {"\u20B9"} Amount</Text>
            </View>
          </View>

          {POData.data.PO_line_item?.map((val, i) => (
            <View key={i} style={[styles.row3]}>
              <View style={styles.tablColumn1Data}>
                <Text>{i + 1}</Text>
              </View>
              <View style={styles.tablColumn2Data}>
                <Text>{val.productname}</Text>
              </View>
              <View style={styles.tablColumn4Data}>
                <Text>{val.quantity}</Text>
              </View>
              <View style={styles.tablColumn5Data}>
                <Text>{val.rate}</Text>
              </View>
              <View style={styles.tablColumn6Data}>
                <Text>{val.unit}</Text>
              </View>
              <View style={styles.tablColumn7Data}>
                <Text>{val.gst_rate}</Text>
              </View>
              <View style={styles.tablColumn8Data}>
                <Text>{val.discount}</Text>
              </View>
              <View style={styles.tablColumn9Data}>
                <Text>{Number(val.amount).toFixed(2)}</Text>
              </View>
            </View>
          ))}

          <View style={[styles.row3]}>
            <View style={styles.tablColumn1Data}>
              <Text></Text>
            </View>
            <View style={styles.tablColumn2Data}>
              <Text>Total</Text>
            </View>

            <View style={styles.tablColumn4Data}>
              <Text></Text>
            </View>
            <View style={styles.tablColumn5Data}>
              <Text></Text>
            </View>
            <View style={styles.tablColumn6Data}>
              <Text></Text>
            </View>
            <View style={styles.tablColumn7Data}>
              <Text></Text>
            </View>
            <View style={styles.tablColumn8Data}>
              <Text></Text>
            </View>
            <View style={styles.tablColumn9Data}>
              <Text>{Number(total).toFixed(2)}</Text>
            </View>
          </View>

          <View
            style={[
              styles.row3,
              {
                padding: "10px",

                borderLeft: "1px solid black",
                borderRight: "1px solid black",
                fontWeight: 600,
                display: "flex",
                justifyContent: "flex-end",
              },
            ]}
          >
            <View style={{ width: "45%" }}>
              <Text>Remark :</Text>
              <Text style={{ marginTop: "5px" }}>
                {POData.data.remark !== "" && POData.data.remark !== null
                  ? POData.data.remark
                  : "N/A"}
              </Text>
            </View>
            <View style={{ width: "5%" }}></View>

            <View style={{ width: "30%" }}>
              {/* <Text>Discount :</Text> */}
              {/* <Text style={{ marginTop: '5px' }}>Amount After Discount :</Text> */}
              {POData.data.cgst_cost !== 0 ? (
                <Text style={{ marginTop: "5px" }}>CGST :</Text>
              ) : null}
              {POData.data.sgst_cost !== 0 ? (
                <Text style={{ marginTop: "5px" }}>SGST :</Text>
              ) : null}
              {POData.data.igst_cost !== 0 ? (
                <Text style={{ marginTop: "5px" }}>IGST :</Text>
              ) : null}
              {POData.data.ugst_cost !== 0 ? (
                <Text style={{ marginTop: "5px" }}>UGST :</Text>
              ) : null}
              <Text style={{ marginTop: "5px" }}>Final Amount :</Text>
              {/* <Text></Text> */}
            </View>
            <View style={{ width: "20%", textAlign: "right" }}>
              {/* <Text>{data.discount + " %      "}{((total * data.discount) / 100).toFixed(2) + " \u20B9"}</Text> */}
              {/* <Text style={{ marginTop: '5px' }}>{data.sub_total + " \u20B9"}</Text> */}
              {POData.data.cgst_cost !== 0 ? (
                <Text style={{ marginTop: "5px" }}>
                  {POData.data.cgst_cost + " \u20B9"}
                </Text>
              ) : null}
              {POData.data.sgst_cost !== 0 ? (
                <Text style={{ marginTop: "5px" }}>
                  {POData.data.sgst_cost + " \u20B9"}
                </Text>
              ) : null}
              {POData.data.igst_cost !== 0 ? (
                <Text style={{ marginTop: "5px" }}>
                  {POData.data.igst_cost + " \u20B9"}
                </Text>
              ) : null}
              {POData.data.ugst_cost !== 0 ? (
                <Text style={{ marginTop: "5px" }}>
                  {POData.data.ugst_cost + " \u20B9"}
                </Text>
              ) : null}
              <Text style={{ marginTop: "5px" }}>
                {POData.data.net_amount + " \u20B9"}
              </Text>
              {/* <Text></Text> */}
            </View>
          </View>

          <View style={[styles.row2]}>
            <View style={{ padding: "10px" }}>
              <Text style={{ fontSize: "12px" }}>{`Amount (In Words)`}</Text>
              <Text
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  textTransform: "capitalize",
                }}
              >
                {numWords(parseInt(POData.data.net_amount)) + " Rupees Only"}
              </Text>
            </View>

            <View style={styles.signatureBlock}>
              <Text
                style={{
                  textAlign: "right",
                  fontSize: "12px",
                  padding: "5px",
                }}
              >
                For{" "}
                <Text style={{ fontWeight: "600" }}>
                  {POData.data.invoice_to_id?.comName}
                </Text>
              </Text>

              <View
                style={{
                  flexDirection: "row",
                  fontSize: "12px",
                  marginTop: "50px",
                }}
              >
                <View style={{ width: "30%" }}>
                  <Text>Prepared By</Text>
                </View>
                <View style={{ width: "30%" }}>
                  <Text>Verified By</Text>
                </View>
                <View style={{ width: "40%" }}>
                  <Text>Authorised Signatory</Text>
                </View>
              </View>
            </View>
          </View>

          <Text
            style={styles.pageNumber}
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
          <Text style={styles.footer} fixed>
            This is a Computer Generated Document
          </Text>
        </Page>
      </Document>
    );
  };

  return (
    <React.Fragment>
      {error.length > 0 ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh", overflow: "hidden" }}
        >
          <div
            className="alert alert-danger mb-0 text-center px-5"
            role="alert"
          >
            {error}
          </div>
        </div>
      ) : (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "100vh", overflow: "hidden" }}
        >
          {/* <BlobProvider document={MyDoc}>
                        {({ blob, url, loading, error }) => {
                            // Do whatever you need with blob here

                            if (loading) {
                                <div>Loading</div>
                                return
                            }
                            if (error) {
                                <div>{error}</div>
                                return
                            }
                            if (url) {
                                return <iframe src={url} height="100%" width="100%"></iframe>
                            }
                        }}
                    </BlobProvider> */}

          {/* <PDFViewer style={styles.viewer}>
            <Document title={`Purchase Order - ${POData.data?.poid}`}>
              <MyDoc />
            </Document>
          </PDFViewer> */}

          <PDFDownloadLink
            document={<MyDoc />}
            fileName={`${POData.data.po_date}-${POData.data.voucher_no}.pdf`}
          >
            {({ blob, url, loading, error }) => (
              <button className="btn btn-yellow px-4">
                {loading ? (
                  <Spinner animation="border" size="sm" className="me-2" />
                ) : (
                  <FontAwesomeIcon
                    style={{ color: "#212529", cursor: "pointer" }}
                    icon={faDownload}
                    size="sm"
                    className="me-2"
                  />
                )}
                {loading ? "Loading Report" : "Download Report"}
              </button>
            )}
          </PDFDownloadLink>
        </div>
      )}
    </React.Fragment>
  );
}

export default POWebView;
