import React, { useState, useEffect, lazy, Suspense } from "react";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { faCog, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { Pagination, Spinner } from "react-bootstrap";
import { Edit2, Eye, Trash2, Image, Edit } from "react-feather";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";
import Chart from "react-apexcharts";
import {
  getBITypes,
  getNCList,
  getNCStatus,
  deleteNCAPI,
  getBIReason,
  getBIProductEmp,
  getBIProductOpr,
  getBIProdMonthly,
  getBIReasonMonthly,
  getBIProductReason,
  getBIEmployeeProd,
  getBIEmployeeOpr,
  getBIEmpReason,
  getBIProductReasonStack,
  getBIProductOprStack,
  getBIProductEmpStack,
  getBINCReason,
  getBIDisposalAction,
  getEmp_NC_count_excl,
} from "../../api/index";
import {
  convertDateYYMMDD,
  calMaxPage,
  convertDate,
  getLabels,
  excelReportDate,
  getFinancialYearStartDate,
} from "../../common/Functions/CommonFunctions";
import { Tooltip } from "react-tooltip";
import ImageGallery from "./ImageGallery";
import List from "../../assets/images/icons/project_menu/checklist.png";
import NotFound from "../../assets/images/not-found.gif";
import ExcelJS from "exceljs";

const AgeNC = lazy(() => import("./AgeNC"));
const schema = yup
  .object()
  .shape({
    // fromDate: yup.date().typeError("Please select from date !").required("Please select from date !"),
    fromDate: yup
      .date()
      .typeError("Please select From date!")
      .required("Please select a From date!"),
    toDate: yup
      .date()
      .typeError("Please select To date!")
      .required("Please select a To date!"),
  })
  .required();

function NCDashboard() {
  let navigate = useNavigate();
  const app_env = process.env.REACT_APP_ENV == "development" ? "dev" : "prod";
  const { control, formState, setError } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  const percentages = [
    { value: 25, color: "#EEAE2D" },
    { value: 75, color: "#344454" },
    { value: 50, color: "#FF134E" },
  ];

  const totalPercentage = percentages.reduce(
    (total, item) => total + item.value,
    0
  );
  const [startDate, setStartDate] = useState(getFinancialYearStartDate(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const [visible, setVisible] = useState(false);
  const [empListTop, setEmpListTop] = useState({ data: [], loading: true });
  const [empListLow, setEmpListLow] = useState({ data: [], loading: true });
  const [prdList, setPrdList] = useState({ data: [], loading: true });
  const [oprList, setOprList] = useState({ data: [], loading: true });
  const [reasonList, setReasonList] = useState({ data: [], loading: true });
  const [prodWithEmp, setProdWithEmp] = useState({ data: [], loading: true });
  const [prodWithEmpStack, setProdWithEmpStack] = useState({
    data: [],
    loading: true,
  });
  const [prodWithOpr, setProdWithOpr] = useState({ data: [], loading: true });
  const [prodWithOprStack, setProdWithOprStack] = useState({
    data: [],
    loading: true,
  });

  const [prodMonthly, setProdMonthly] = useState({ data: [], loading: true });
  const [reasonMonthly, setReasonMonthly] = useState({
    data: [],
    loading: true,
  });
  const [prodWithReason, setProdWithReason] = useState({
    data: [],
    loading: true,
  });
  const [prodWithReasonStack, setProdWithReasonStack] = useState({
    data: [],
    loading: true,
  });
  const [empWithProd, setEmpWithProd] = useState({ data: [], loading: true });
  const [empWithOpr, setEmpWithOpr] = useState({ data: [], loading: true });
  const [empWithReason, setEmpWithReason] = useState({
    data: [],
    loading: true,
  });
  const [empWithNCReason, setEmpWithNCReason] = useState({
    data: [],
    loading: true,
  });
  const [disposal, setDisposal] = useState({ data: [], loading: true });

  // Excel data report use states
  const [empNC_excel, setEmpNC_Excel] = useState({ data: [], loading: true });

  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [ncList, setNcList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });

  const [disActionList, setDisActionList] = useState();

  const [selItem, setSelItem] = useState({});
  const [selItem2, setSelItem2] = useState({});
  const [show2, setShow2] = useState(false);
  const [galVisible, setGalVisible] = useState(false);

  const [lockExcl, setLockExcl] = useState(false);

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage, setPrivilage] = useState({});
  const abortController = new AbortController();

  const [constLabel, setConstLabel] = useState({});

  let startAngle = 330;
  // const colors = [
  //   "#4E7DAE",
  //   "#FF134E",
  //   "#344454",
  //   "#EEAE2D",
  //   "#ABBCCC",
  //   "#8D8D8D",
  //   "#2ECC71",
  //   "#8E44AD",
  //   "#D35400",
  //   "#85C1E9",
  // ];
  const colors = [
    "#4E7DAE",
    "#FF134E",
    "#344454",
    "#EEAE2D",
    "#ABBCCC",
    "#8D8D8D",
    "#2ECC71",
    "#8E44AD",
    "#D35400",
    "#85C1E9",
    "#FF5733",
    "#8C94D6",
    "#D68FD6",
    "#6F1E51",
    "#00FF7F",
    "#FFD700",
    "#F08080",
    "#20B2AA",
    "#DA70D6",
    "#C71585",
    "#6495ED",
    "#7FFF00",
    "#B0E0E6",
    "#9370DB",
    "#F0E68C",
    "#4682B4",
    "#CD5C5C",
    "#32CD32",
    "#DB7093",
    "#FFA500",
    "#800080",
    "#FF4500",
    "#00CED1",
    "#808000",
    "#8B008B",
    "#FF6347",
    "#008080",
    "#556B2F",
    "#9932CC",
    "#FF8C00",
    "#008B8B",
    "#FFDAB9",
    "#000080",
    "#8B0000",
    "#20B2AA",
    "#FF1493",
    "#00FF00",
    "#FA8072",
    "#800000",
    "#BA55D3",
    "#F5FFFA",
    "#FF69B4",
    "#00BFFF",
    "#FFE4C4",
    "#8A2BE2",
    "#E6E6FA",
    "#FFB6C1",
    "#66CDAA",
    "#00FA9A",
    "#191970",
  ];

  const [cardShow, setCardShow] = useState([
    { name: "Monthly Non-Conformance by Root Cause", flag: false },
    { name: "Monthly Non-Conformance by Product", flag: false },
    { name: " Top Employees by Non-Conformance", flag: true },
    { name: "High-Risk Products for Non-Conformance", flag: true },
    { name: "Non-Conformance by Cause", flag: true },
    { name: " Top Operations Contributing to Non-Conformance", flag: true },
    { name: "Employees with less NC", flag: false },
    { name: "Product with Employee", flag: false },
    { name: "Product with Operation", flag: false },
    { name: "Top Products by Non-Conformance and Root Cause", flag: false },
    { name: "Employee with Product", flag: false },
    { name: "Employee with Operation", flag: false },
    { name: "Employee with Reason", flag: false },
    { name: "Top Employees by Non-Conformance and Reasons", flag: false },
    { name: "Distribution of Disposal Actions", flag: false },
  ]);

  function getPrvMonthDateFromToday() {
    const today = new Date();
    const endDate = new Date(today);
    endDate.setMonth(today.getMonth() - 1);
    return endDate;
  }

  useEffect(() => {
    setConstLabel(getLabels("Quality", "Quality Dashboard"));
    // setEmpListTop({ ...empListTop, loading: true, data: [] });
    // setPrdList({ ...prdList, loading: true, data: [] });
    // setOprList({ ...oprList, loading: true, data: [] });

    // fetchEmpTop();
    // fetchPrd();
    // fetchOpr();
    let isUserAdmin;
    if (
      loginData.role_name.trim() == "admin" ||
      loginData.role_name.trim() == "client_admin"
    ) {
      isUserAdmin = true;
    } else {
      isUserAdmin = false;
    }

    if (isUserAdmin) {
      setPrivilage({
        read: true,
        write: true,
        view: true,
        delete: true,
      });
    } else {
      for (let i = 0; i < loginData.module_list.length; i++) {
        if (loginData.module_list[i].module == "Quality Dashboard") {
          setPrivilage(loginData.module_list[i]);
        } else {
          for (let i = 0; i < loginData.module_list.length; i++) {
            if (loginData.module_list[i].module == "Quality Dashboard") {
              setPrivilage(loginData.module_list[i]);
            } else {
              if (loginData.module_list[i].screens !== undefined) {
                for (
                  let j = 0;
                  j < loginData.module_list[i].screens.length;
                  j++
                ) {
                  if (
                    loginData.module_list[i].screens[j].screen_name ==
                    "Quality Dashboard"
                  ) {
                    setPrivilage(loginData.module_list[i]);
                  }
                }
              }
            }
          }
        }
      }
    }

    let mounted = true;
    if (mounted) {
      fetchDisposalList();
    }
    return () => {
      mounted = false;
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    let debounceTimeout;
    if (
      startDate !== undefined &&
      startDate !== null &&
      endDate !== undefined &&
      endDate !== null
    ) {
      debounceTimeout = setTimeout(() => {
        setPage(1);
        fetchNCList(1, entriesPerPage);

        setEmpListTop({ ...empListTop, loading: true, data: [] });
        setEmpListLow({ ...empListLow, loading: true, data: [] });
        setPrdList({ ...prdList, loading: true, data: [] });
        setOprList({ ...oprList, loading: true, data: [] });
        setReasonList({ ...reasonList, loading: true, data: [] });
        setProdWithEmp({ ...prodWithEmp, loading: true, data: [] });
        setProdWithEmpStack({ ...prodWithEmpStack, loading: true, data: [] });

        setProdWithOpr({ ...prodWithOpr, loading: true, data: [] });
        setProdWithOprStack({ ...prodWithOprStack, loading: true, data: [] });
        setProdMonthly({ ...prodMonthly, loading: true, data: [] });
        setReasonMonthly({ ...reasonMonthly, loading: true, data: [] });
        setProdWithReason({ ...prodWithReason, loading: true, data: [] });
        setProdWithReasonStack({ ...reasonMonthly, loading: true, data: [] });
        setEmpWithProd({ ...empWithProd, loading: true, data: [] });
        setEmpWithOpr({ ...empWithOpr, loading: true, data: [] });
        setEmpWithReason({ ...empWithReason, loading: true, data: [] });
        setEmpWithNCReason({ ...empWithNCReason, loading: true, data: [] });
        setDisposal({ ...disposal, loading: true, data: [] });

        fetchEmpLow();
        fetchEmpTop();
        fetchPrd();
        fetchOpr();
        fetchReason();
        //fetchProdWithEmp();
        fetchProdWithEmpStack();
        // fetchProdWithOpr();
        fetchProdWithOprStack();
        fetchProdMonthly();
        fetchReasonMonthly();
        //  fetchProdWithReason();
        fetchProdWithReasonStack();
        fetchEmpWithProd();
        fetchEmpWithOpr();
        fetchEmpWithReason();
        fetchEmpWithNCReason();
        fetchDisposalAction();
        // fetchLotList(startDate, endDate);
      }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)
    }

    return () => {
      abortController.abort();
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [startDate, endDate, disActionList]);

  function fetchDisposalList() {
    getNCStatus(undefined, { signal: abortController.signal }).then(
      (res) => {
        if (res.data.data) {
          const pendingItems = res.data.data.filter(
            (item) => item.statusname === "Pending"
          );

          setDisActionList(pendingItems);
        }
      },
      (err) => {}
    );
  }

  function fetchNCList(pg, epp) {
    setNcList({ ...ncList, loading: true, data: [] });

    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
      startDate: convertDateYYMMDD(new Date(startDate), "-"),
      endDate: convertDateYYMMDD(new Date(endDate), "-"),
      ncstatus:
        disActionList !== undefined && disActionList.length > 0
          ? `["${disActionList[0].uuid}"]`
          : [],
    };

    getNCList(payload, { signal: abortController.signal }).then(
      (res) => {
        //
        setNcList({
          ...ncList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });
        setMaxPage(calMaxPage(res.data.total));
      },
      (err) => {
        setNcList({ ...ncList, loading: false, data: [] });
      }
    );
  }

  function fetchEmpTop() {
    getBITypes(
      {
        startDate: convertDateYYMMDD(startDate, "-"),
        endDate: convertDateYYMMDD(endDate, "-"),
        type: "employee",
        limit: "higher", // This is for filter employees with most nc records,
        env: app_env,
      },
      { signal: abortController.signal }
    ).then(
      (res) => {
        //
        const result = res?.data?.data?.slice(0, 3).map((val) => {
          // val.x = val.nc_employee_name;

          val.x = "";
          val.y = val.count;
          return val;
        });

        const result2 = res?.data?.data?.slice(0, 3).map((val) => {
          val.title = val.nc_employee_name;
          val.cols = 2;
          return val;
        });

        const state2 = {
          series: [{ name: "NC Count", data: result }],
          options: {
            chart: {
              type: "bar",
              height: 380,
              toolbar: {
                show: false,
              },
            },
            colors: colors,
            plotOptions: {
              bar: {
                columnWidth: "55%",
                distributed: true,
              },
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"],
            },
            dataLabels: {
              enabled: true,
            },
            legend: {
              show: true,
              position: "bottom",
              markers: {
                width: 8,
                height: 8,
              },
              fontSize: "10px",
              customLegendItems: result.map((item) => item.nc_employee_name),
            },
            xaxis: {
              type: "Employees",
              group: {
                style: {
                  fontSize: "10px",
                  fontWeight: 6,
                },
                groups: result2,
              },
            },
          },
        };

        setEmpListTop({ ...empListTop, loading: false, data: state2 });
      },
      (err) => {
        setEmpListTop({ ...empListTop, loading: false, data: [] });
      }
    );
  }

  function fetchEmpLow() {
    getBITypes(
      {
        startDate: convertDateYYMMDD(startDate, "-"),
        endDate: convertDateYYMMDD(endDate, "-"),
        type: "employee",
        limit: "lower",
        env: app_env, // This is for filter employees with low nc records
      },
      { signal: abortController.signal }
    ).then(
      (res) => {
        //
        const result = res?.data?.data?.slice(0, 3).map((val) => {
          // val.x = val.nc_employee_name;

          val.x = "";
          val.y = val.count;
          return val;
        });

        const result2 = res?.data?.data?.slice(0, 3).map((val) => {
          val.title = val.nc_employee_name;
          val.cols = 2;
          return val;
        });

        const state2 = {
          series: [{ name: "NC Count", data: result }],
          options: {
            chart: {
              type: "bar",
              height: 380,
              toolbar: {
                show: false,
              },
            },
            colors: colors,
            plotOptions: {
              bar: {
                columnWidth: "55%",
                distributed: true,
              },
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"],
            },
            dataLabels: {
              enabled: true,
            },
            legend: {
              show: true,
              position: "bottom",
              markers: {
                width: 8,
                height: 8,
              },
              fontSize: "10px",
              customLegendItems: result.map((item) => item.nc_employee_name),
            },
            xaxis: {
              type: "Employees",
              group: {
                style: {
                  fontSize: "10px",
                  fontWeight: 6,
                },
                groups: result2,
              },
            },
            // labels: {
            //     style: {
            //         colors: colors,
            //         fontSize: '10px'
            //     }
            // }
          },
        };

        setEmpListLow({ ...empListLow, loading: false, data: state2 });
      },
      (err) => {
        setEmpListLow({ ...empListLow, loading: false, data: [] });
      }
    );
  }

  function fetchPrd() {
    getBITypes(
      {
        startDate: convertDateYYMMDD(startDate, "-"),
        endDate: convertDateYYMMDD(endDate, "-"),
        type: "product",
        env: app_env,
      },
      { signal: abortController.signal }
    ).then(
      (res) => {
        //
        const result = res?.data?.data?.slice(0, 3).map((val) => {
          // val.x = val.nc_product;
          val.x = "";
          val.y = val.count;
          return val;
        });

        const result2 = res?.data?.data?.slice(0, 3).map((val) => {
          val.title = val.nc_product;
          val.cols = 2;
          return val;
        });

        const state2 = {
          series: [{ name: "NC Count", data: result }],
          // series: [{
          //     name: 'Net Profit',
          //     data: [44, 57, 66]
          //   }, {
          //     name: 'Revenue',
          //     data: [76, 91, 114]
          //   }, {
          //     name: 'Free Cash Flow',
          //     data: [41, 26, 53]
          //   }],

          options: {
            chart: {
              type: "bar",
              height: 380,
              toolbar: {
                show: false,
              },
            },
            colors: colors,
            plotOptions: {
              bar: {
                columnWidth: "45%",
                distributed: true,
              },
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"],
            },
            dataLabels: {
              enabled: true,
            },
            legend: {
              show: true,
              position: "bottom",
              markers: {
                width: 8,
                height: 8,
              },
              fontSize: "10px",
              customLegendItems: result.map((item) => item.nc_product),
            },
            xaxis: {
              type: "Products",
              show: false,
              group: {
                style: {
                  fontSize: "10px",
                  fontWeight: 6,
                },
                groups: result2,
              },
            },
          },
        };

        setPrdList({ ...prdList, loading: false, data: state2 });
      },
      (err) => {
        setPrdList({ ...prdList, loading: false, data: [] });
      }
    );
  }

  function fetchOpr() {
    getBITypes(
      {
        startDate: convertDateYYMMDD(startDate, "-"),
        endDate: convertDateYYMMDD(endDate, "-"),
        type: "operation",
        env: app_env,
      },
      { signal: abortController.signal }
    ).then(
      (res) => {
        //
        const result = res?.data?.data?.slice(0, 3).map((val) => {
          // val.x = val.nc_operation_name;
          val.x = "";
          val.y = val.count;
          return val;
        });

        const result2 = res?.data?.data?.slice(0, 3).map((val) => {
          val.title = val.nc_operation_name;
          val.cols = 2;
          return val;
        });

        const state2 = {
          series: [{ name: "NC Count", data: result }],
          options: {
            chart: {
              type: "bar",
              height: 380,
              toolbar: {
                show: false,
              },
            },
            colors: colors,
            plotOptions: {
              bar: {
                columnWidth: "45%",
                distributed: true,
              },
            },
            dataLabels: {
              enabled: true,
            },
            legend: {
              show: true,
              position: "bottom",
              markers: {
                width: 8,
                height: 8,
              },
              fontSize: "10px",
              customLegendItems: result.map((item) => item.nc_operation_name),
            },
            xaxis: {
              type: "Operations",
              group: {
                style: {
                  fontSize: "10px",
                  fontWeight: 6,
                },
                groups: result2,
              },
            },
          },
        };

        setOprList({ ...oprList, loading: false, data: state2 });
      },
      (err) => {
        setOprList({ ...oprList, loading: false, data: [] });
      }
    );
  }

  function fetchReason() {
    getBIReason(
      {
        startDate: convertDateYYMMDD(startDate, "-"),
        endDate: convertDateYYMMDD(endDate, "-"),
        type: "number",
        env: app_env,
      },
      { signal: abortController.signal }
    ).then(
      (res) => {
        const result = res?.data?.nc_reason?.slice(0, 3).map((val) => {
          // val.x = val.nc_operation_name;
          val.x = "";
          val.y = val.count;
          return val;
        });

        const result2 = res?.data?.nc_reason?.slice(0, 3).map((val) => {
          val.title = val.nc_operation_name;
          val.cols = 2;
          return val;
        });

        const seriesData = res?.data.nc_reason?.map((item) =>
          Number(item.count)
        );
        const labels = res?.data?.nc_reason?.map((item) => item.name);
        // const labels = arrData.slice(0, 3).map(item => item.name);
        // const seriesData = arrData.slice(0, 3).map(item => Number(item.count));

        const state2 = {
          series: seriesData,
          options: {
            chart: {
              type: "pie",
              height: 380,
            },
            colors: colors,
            plotOptions: {
              pie: {
                customScale: 1.1,
                offsetX: 0,
                offsetY: 0,
                pie: {
                  size: "70%",
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
            legend: {
              show: true,
              position: "bottom",
              markers: {
                width: 8,
                height: 8,
              },
              fontSize: "10px",
            },
            labels,
          },
        };

        setReasonList({ ...reasonList, loading: false, data: state2 });
      },
      (err) => {
        setReasonList({ ...reasonList, loading: false, data: [] });
      }
    );
  }

  function fetchProdWithEmp() {
    getBIProductEmp({
      startDate: convertDateYYMMDD(startDate, "-"),
      endDate: convertDateYYMMDD(endDate, "-"),
      // type: "operation"
    }).then(
      async (res) => {
        const tempList = [
          { name: "product", data: [] },
          { name: "Employee", data: [] },
        ];

        let nameD = [[], []];

        res?.data?.data?.slice(0, 3).map((val) => {
          tempList[0].data.push(parseInt(val.count));
          nameD[0].push(val.nc_product);
          nameD[1].push(val.nc_employee_name[0].nc_employee_name);
          tempList[1].data.push(parseInt(val.nc_employee_name[0].count));
        });

        const state2 = {
          series: tempList,
          options: {
            chart: {
              type: "bar",
              height: 350,
              toolbar: {
                show: false,
                autoSelected: "pan",
              },
            },
            xaxis: {
              categories: ["", "", ""],
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "55%",
                endingShape: "rounded",
              },
            },
            colors: colors,
            dataLabels: {
              enabled: true,
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"],
            },

            fill: {
              opacity: 1,
            },
            tooltip: {
              y: {
                formatter: function (
                  value,
                  { series, seriesIndex, dataPointIndex, w }
                ) {
                  return `${nameD[seriesIndex][dataPointIndex]}, count : ${value}`;
                },
              },
            },
          },
        };

        setProdWithEmp({ ...prodWithEmp, loading: false, data: state2 });
      },
      (err) => {
        setProdWithEmp({ ...prodWithEmp, loading: false, data: [] });
      }
    );
  }

  function fetchProdWithEmpStack() {
    getBIProductEmpStack(
      {
        startDate: convertDateYYMMDD(startDate, "-"),
        endDate: convertDateYYMMDD(endDate, "-"),
        env: app_env,
        // type: "operation"
      },
      { signal: abortController.signal }
    ).then(
      async (res) => {
        const product_array = res?.data?.data?.map(
          (entry) => `${entry.nc_product}`
        );
        let master_nc_employee_name_array = [];
        res?.data?.data?.forEach((item, ind) => {
          let arr = [];
          arr = item.nc_employee_name.map((el) => {
            return el.nc_employee_name;
          });

          master_nc_employee_name_array = [
            ...master_nc_employee_name_array,
            ...arr,
          ];
        });
        let uniqueArray_nc = [...new Set(master_nc_employee_name_array)];

        let result = [];
        for (let i = 0; i < uniqueArray_nc.length; i++) {
          let obj = {};
          obj.name = uniqueArray_nc[i];
          let counts = [];
          res?.data?.data?.forEach((ele) => {
            let check = ele.nc_employee_name.filter(
              (obj) => obj.nc_employee_name === uniqueArray_nc[i]
            );
            if (check.length > 0) {
              counts.push(check[0].count);
            } else {
              counts.push(0);
            }
          });
          obj.data = counts;
          result.push(obj);
        }

        const state2 = {
          series: result,
          options: {
            chart: {
              type: "bar",
              height: 350,
              stacked: true,
              toolbar: {
                show: false,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                dataLabels: {
                  show: false,
                  total: {
                    enabled: true,
                  },
                },
              },
            },
            colors: colors,
            xaxis: {
              categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            },
            legend: {
              position: "bottom",
              markers: {
                width: 8,
                height: 8,
              },
              fontSize: "12px",
              show: true,
            },
            fill: {
              opacity: 1,
            },
            tooltip: {
              x: {
                formatter: function (value, { seriesIndex, dataPointIndex }) {
                  return `${product_array[dataPointIndex]}`;
                },
              },
            },
          },
        };

        setProdWithEmpStack({
          ...prodWithEmpStack,
          loading: false,
          data: state2,
        });
      },
      (err) => {
        setProdWithEmpStack({ ...prodWithEmpStack, loading: false, data: [] });
      }
    );
  }

  function fetchProdWithOpr() {
    getBIProductOpr({
      startDate: convertDateYYMMDD(startDate, "-"),
      endDate: convertDateYYMMDD(endDate, "-"),
      // type: "operation"
    }).then(
      (res) => {
        const tempList = [
          { name: "Product", data: [] },
          { name: "Operation", data: [] },
        ];

        let nameD = [[], []];

        res?.data?.data?.slice(0, 3).map((val) => {
          tempList[0].data.push(parseInt(val.count));
          nameD[0].push(val.nc_product);
          nameD[1].push(val.nc_operation_name[0].nc_operation_name);
          tempList[1].data.push(parseInt(val.nc_operation_name[0].count));
        });

        const state2 = {
          series: tempList,
          options: {
            chart: {
              type: "bar",
              height: 350,
              toolbar: {
                show: false,
                autoSelected: "pan",
              },
            },
            xaxis: {
              categories: ["", "", ""],
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "55%",
                endingShape: "rounded",
              },
            },
            colors: colors,
            dataLabels: {
              enabled: true,
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"],
            },

            fill: {
              opacity: 1,
            },
            tooltip: {
              y: {
                formatter: function (
                  value,
                  { series, seriesIndex, dataPointIndex, w }
                ) {
                  return `${nameD[seriesIndex][dataPointIndex]}, count : ${value}`;
                },
              },
            },
          },
        };

        setProdWithOpr({ ...prodWithOpr, loading: false, data: state2 });
      },
      (err) => {
        setProdWithOpr({ ...prodWithOpr, loading: true, data: [] });
      }
    );
  }

  function fetchProdWithOprStack() {
    getBIProductOprStack(
      {
        startDate: convertDateYYMMDD(startDate, "-"),
        endDate: convertDateYYMMDD(endDate, "-"),
        env: app_env,
        // type: "operation"
      },
      { signal: abortController.signal }
    ).then(
      (res) => {
        const product_array = res?.data?.data?.map(
          (entry) => `${entry.nc_product}`
        );
        let master_nc_operation_name_array = [];
        res?.data?.data?.forEach((item, ind) => {
          let arr = [];
          arr = item.nc_operation_name.map((el) => {
            return el.nc_operation_name;
          });

          master_nc_operation_name_array = [
            ...master_nc_operation_name_array,
            ...arr,
          ];
        });
        let uniqueArray_nc = [...new Set(master_nc_operation_name_array)];

        let result = [];
        for (let i = 0; i < uniqueArray_nc.length; i++) {
          let obj = {};
          obj.name = uniqueArray_nc[i];
          let counts = [];
          res?.data?.data?.forEach((ele) => {
            let check = ele.nc_operation_name.filter(
              (obj) => obj.nc_operation_name === uniqueArray_nc[i]
            );
            if (check.length > 0) {
              counts.push(check[0].count);
            } else {
              counts.push(0);
            }
          });
          obj.data = counts;
          result.push(obj);
        }

        const state2 = {
          series: result,
          options: {
            chart: {
              type: "bar",
              height: 350,
              stacked: true,
              toolbar: {
                show: false,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                dataLabels: {
                  show: false,
                  total: {
                    enabled: true,
                  },
                },
              },
            },
            colors: colors,
            xaxis: {
              categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            },
            legend: {
              position: "bottom",
              markers: {
                width: 8,
                height: 8,
              },
              fontSize: "12px",
              show: true,
            },
            fill: {
              opacity: 1,
            },
            tooltip: {
              x: {
                formatter: function (value, { seriesIndex, dataPointIndex }) {
                  return `${product_array[dataPointIndex]}`;
                },
              },
            },
          },
        };

        setProdWithOprStack({ ...prodWithOpr, loading: false, data: state2 });
      },
      (err) => {
        setProdWithOprStack({ ...prodWithOpr, loading: false, data: [] });
      }
    );
  }

  function fetchProdMonthly() {
    getBIProdMonthly(
      {
        endDate: convertDateYYMMDD(endDate, "-"),
        env: app_env,
        // type: "operation"
      },
      { signal: abortController.signal }
    ).then(
      (res) => {
        const mappedMonths = res?.data?.data[0]?.data.map(
          (entry) => `${entry.month}-${entry.year}`
        );

        const result = res?.data?.data?.map((item) => ({
          name: item.product_name,
          data: item.data.map((entry) => parseInt(entry.nc_count)),
        }));

        const state2 = {
          series: result,
          options: {
            chart: {
              height: 400,
              type: "line",
              dropShadow: {
                enabled: true,
                color: "#000",
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2,
              },
              toolbar: {
                show: false,
                // autoSelected: 'pan'
              },
              zoom: {
                enabled: false,
              },
            },
            colors: colors,
            dataLabels: {
              enabled: true,
            },
            stroke: {
              curve: "straight",
              width: 2,
            },
            grid: {
              borderColor: "#e7e7e7",
              row: {
                colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.5,
              },
            },
            markers: {
              size: 0.5,
            },
            xaxis: {
              categories: mappedMonths,
            },
            legend: {
              position: "bottom",
              markers: {
                width: 8,
                height: 8,
              },
              fontSize: "10px",
              show: true,
            },
          },
        };

        setProdMonthly({ ...prodMonthly, loading: false, data: state2 });
      },
      (err) => {
        setProdMonthly({ ...prodMonthly, loading: true, data: [] });
      }
    );
  }

  function fetchReasonMonthly_new() {
    getBIReasonMonthly(
      {
        endDate: convertDateYYMMDD(endDate, "-"),
        env: app_env,
      },
      { signal: abortController.signal }
    ).then(
      (res) => {
        const reason_array = res?.data?.data?.map(
          (entry) => `${entry.reason_name}`
        );
        let master_nc_operation_name_array = [];
        res?.data?.data?.forEach((item, ind) => {
          let arr = [];
          arr = item.nc_operation_name.map((el) => {
            return el.nc_operation_name;
          });

          master_nc_operation_name_array = [
            ...master_nc_operation_name_array,
            ...arr,
          ];
        });
        let uniqueArray_nc = [...new Set(master_nc_operation_name_array)];

        let result = [];
        for (let i = 0; i < uniqueArray_nc.length; i++) {
          let obj = {};
          obj.name = uniqueArray_nc[i];
          let counts = [];
          res?.data?.data?.forEach((ele) => {
            let check = ele.nc_operation_name.filter(
              (obj) => obj.nc_operation_name === uniqueArray_nc[i]
            );
            if (check.length > 0) {
              counts.push(check[0].count);
            } else {
              counts.push(0);
            }
          });
          obj.data = counts;
          result.push(obj);
        }

        const state2 = {
          series: result,
          options: {
            chart: {
              type: "bar",
              height: 350,
              stacked: true,
              toolbar: {
                show: false,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                dataLabels: {
                  show: false,
                  total: {
                    enabled: true,
                  },
                },
              },
            },
            colors: colors,
            xaxis: {
              categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            },
            legend: {
              position: "bottom",
              markers: {
                width: 8,
                height: 8,
              },
              fontSize: "12px",
              show: true,
            },
            fill: {
              opacity: 1,
            },
            tooltip: {
              x: {
                formatter: function (value, { seriesIndex, dataPointIndex }) {
                  return `${reason_array[dataPointIndex]}`;
                },
              },
            },
          },
        };

        setProdWithOprStack({ ...prodWithOpr, loading: false, data: state2 });
      },
      (err) => {
        setProdWithOprStack({ ...prodWithOpr, loading: false, data: [] });
      }
    );
  }

  function fetchReasonMonthly() {
    getBIReasonMonthly(
      {
        endDate: convertDateYYMMDD(endDate, "-"),
        env: app_env,
        // type: "operation"
      },
      { signal: abortController.signal }
    ).then(
      (res) => {
        const mappedMonths = res?.data?.data[0]?.data.map(
          (entry) => `${entry.month}-${entry.year}`
        );

        const result = res?.data?.data?.map((item) => ({
          name: item.reason_name,
          data: item.data.map((entry) => parseInt(entry.nc_count)),
        }));

        const state2 = {
          series: result,
          options: {
            chart: {
              type: "bar",
              height: 350,
              stacked: true,
              toolbar: {
                show: false,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                dataLabels: {
                  show: false,
                  total: {
                    enabled: true,
                  },
                },
              },
            },
            colors: colors,
            xaxis: {
              categories: mappedMonths,
            },
            legend: {
              position: "bottom",
              markers: {
                width: 8,
                height: 8,
              },
              fontSize: "12px",
              show: true,
            },
            fill: {
              opacity: 1,
            },
          },
        };

        setReasonMonthly({ ...reasonMonthly, loading: false, data: state2 });
      },
      (err) => {
        setReasonMonthly({ ...reasonMonthly, loading: false, data: [] });
      }
    );
  }

  function fetchProdWithReason() {
    getBIProductReason({
      startDate: convertDateYYMMDD(startDate, "-"),
      endDate: convertDateYYMMDD(endDate, "-"),
      // type: "operation"
    }).then(
      (res) => {
        const tempList = [
          { name: "Product", data: [] },
          { name: "NC Reason", data: [] },
        ];

        let nameD = [[], []];

        res?.data?.data?.slice(0, 3).map((val) => {
          tempList[0].data.push(parseInt(val.count));
          nameD[0].push(val.nc_product);
          nameD[1].push(val.nc_reason[0].nc_reason);
          tempList[1].data.push(parseInt(val.nc_reason[0].count));
        });

        const state2 = {
          series: tempList,
          options: {
            chart: {
              type: "bar",
              height: 350,
              toolbar: {
                show: false,
                autoSelected: "pan",
              },
            },
            xaxis: {
              categories: ["", "", ""],
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "55%",
                endingShape: "rounded",
              },
            },
            colors: colors,
            dataLabels: {
              enabled: true,
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"],
            },

            fill: {
              opacity: 1,
            },
            tooltip: {
              y: {
                formatter: function (value, { seriesIndex, dataPointIndex }) {
                  return `${nameD[seriesIndex][dataPointIndex]}, count : ${value}`;
                },
              },
            },
          },
        };

        setProdWithReason({ ...prodWithReason, loading: false, data: state2 });
      },
      (err) => {
        setProdWithReason({ ...prodWithReason, loading: false, data: [] });
      }
    );
  }

  function fetchProdWithReasonStack() {
    getBIProductReasonStack(
      {
        startDate: convertDateYYMMDD(startDate, "-"),
        endDate: convertDateYYMMDD(endDate, "-"),
        env: app_env,
        // type: "operation"
      },
      { signal: abortController.signal }
    ).then(
      (res) => {
        const product_array = res?.data?.data?.map(
          (entry) => `${entry.nc_product}`
        );
        let master_nc_reason_array = [];
        res?.data?.data?.forEach((item, ind) => {
          let arr = [];
          arr = item.nc_reason.map((el) => {
            return el.nc_reason;
          });

          master_nc_reason_array = [...master_nc_reason_array, ...arr];
        });
        let uniqueArray_nc = [...new Set(master_nc_reason_array)];

        let result = [];
        for (let i = 0; i < uniqueArray_nc.length; i++) {
          let obj = {};
          obj.name = uniqueArray_nc[i];
          let counts = [];
          res?.data?.data?.forEach((ele) => {
            let check = ele.nc_reason.filter(
              (obj) => obj.nc_reason === uniqueArray_nc[i]
            );
            if (check.length > 0) {
              counts.push(check[0].count);
            } else {
              counts.push(0);
            }
          });
          obj.data = counts;
          result.push(obj);
        }

        const state2 = {
          series: result,
          options: {
            chart: {
              type: "bar",
              height: 350,
              stacked: true,
              toolbar: {
                show: false,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                dataLabels: {
                  show: false,
                  total: {
                    enabled: true,
                  },
                },
              },
            },
            colors: colors,
            xaxis: {
              categories: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            },
            legend: {
              position: "bottom",
              markers: {
                width: 8,
                height: 8,
              },
              fontSize: "12px",
              show: true,
            },
            fill: {
              opacity: 1,
            },
            tooltip: {
              x: {
                formatter: function (value, { seriesIndex, dataPointIndex }) {
                  return `${product_array[dataPointIndex]}`;
                },
              },
            },
          },
        };

        setProdWithReasonStack({
          ...prodWithReasonStack,
          loading: false,
          data: state2,
        });
      },
      (err) => {
        setProdWithReasonStack({
          ...prodWithReasonStack,
          loading: false,
          data: [],
        });
      }
    );
  }

  function fetchEmpWithProd() {
    getBIEmployeeProd(
      {
        startDate: convertDateYYMMDD(startDate, "-"),
        endDate: convertDateYYMMDD(endDate, "-"),
        env: app_env,
        // type: "operation"
      },
      { signal: abortController.signal }
    ).then(
      (res) => {
        const tempList = [
          { name: "Employee", data: [] },
          { name: "Product", data: [] },
        ];

        let nameD = [[], []];

        res?.data?.data?.slice(0, 3).map((val) => {
          tempList[0].data.push(parseInt(val.count));
          nameD[0].push(val.nc_employee_name);
          nameD[1].push(val.nc_product[0].nc_product);
          tempList[1].data.push(parseInt(val.nc_product[0].count));
        });

        const state2 = {
          series: tempList,
          options: {
            chart: {
              type: "bar",
              height: 350,
              toolbar: {
                show: false,
                autoSelected: "pan",
              },
            },
            xaxis: {
              categories: ["", "", ""],
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "55%",
                endingShape: "rounded",
              },
            },
            colors: colors,
            dataLabels: {
              enabled: true,
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"],
            },

            fill: {
              opacity: 1,
            },
            tooltip: {
              y: {
                formatter: function (
                  value,
                  { series, seriesIndex, dataPointIndex, w }
                ) {
                  return `${nameD[seriesIndex][dataPointIndex]}, count : ${value}`;
                },
              },
              fixed: {
                enabled: true,
                position: "topRight",
                offsetX: 0,
                offsetY: 0,
              },
            },
          },
        };

        setEmpWithProd({ ...empWithProd, loading: false, data: state2 });
      },
      (err) => {
        setEmpWithProd({ ...empWithProd, loading: false, data: [] });
      }
    );
  }

  function fetchEmpWithOpr() {
    getBIEmployeeOpr(
      {
        startDate: convertDateYYMMDD(startDate, "-"),
        endDate: convertDateYYMMDD(endDate, "-"),
        env: app_env,
        // type: "operation"
      },
      { signal: abortController.signal }
    ).then(
      (res) => {
        const tempList = [
          { name: "Employee", data: [] },
          { name: "Operation", data: [] },
        ];

        let nameD = [[], []];

        res?.data?.data?.slice(0, 3).map((val) => {
          tempList[0].data.push(parseInt(val.count));
          nameD[0].push(val.nc_employee_name);
          nameD[1].push(val.nc_operation_name[0].nc_operation_name);
          tempList[1].data.push(parseInt(val.nc_operation_name[0].count));
        });

        const state2 = {
          series: tempList,
          options: {
            chart: {
              type: "bar",
              height: 350,
              toolbar: {
                show: false,
                autoSelected: "pan",
              },
            },
            xaxis: {
              categories: ["", "", ""],
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "55%",
                endingShape: "rounded",
              },
            },
            colors: colors,
            dataLabels: {
              enabled: true,
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"],
            },

            fill: {
              opacity: 1,
            },
            tooltip: {
              y: {
                formatter: function (
                  value,
                  { series, seriesIndex, dataPointIndex, w }
                ) {
                  return `${nameD[seriesIndex][dataPointIndex]}, count : ${value}`;
                },
              },
              fixed: {
                enabled: true,
                position: "topRight",
                offsetX: 0,
                offsetY: 0,
              },
            },
          },
        };

        setEmpWithOpr({ ...empWithOpr, loading: false, data: state2 });
      },
      (err) => {
        setEmpWithOpr({ ...empWithOpr, loading: false, data: [] });
      }
    );
  }

  function fetchEmpWithReason() {
    getBIEmpReason(
      {
        startDate: convertDateYYMMDD(startDate, "-"),
        endDate: convertDateYYMMDD(endDate, "-"),
        env: app_env,
        // type: "operation"
      },
      { signal: abortController.signal }
    ).then(
      (res) => {
        const tempList = [
          { name: "Employee", data: [] },
          { name: "NC Reason", data: [] },
        ];

        let nameD = [[], []];

        res?.data?.data?.slice(0, 3).map((val) => {
          tempList[0].data.push(parseInt(val.count));
          nameD[0].push(val.nc_employee_name);
          nameD[1].push(val.nc_reason[0].nc_reason);
          tempList[1].data.push(parseInt(val.nc_reason[0].count));
        });

        const state2 = {
          series: tempList,
          options: {
            chart: {
              type: "bar",
              height: 350,
              toolbar: {
                show: false,
                autoSelected: "pan",
              },
            },
            xaxis: {
              categories: ["", "", ""],
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: "55%",
                endingShape: "rounded",
              },
            },
            colors: colors,
            dataLabels: {
              enabled: true,
            },
            stroke: {
              show: true,
              width: 2,
              colors: ["transparent"],
            },

            fill: {
              opacity: 1,
            },
            tooltip: {
              y: {
                formatter: function (
                  value,
                  { series, seriesIndex, dataPointIndex, w }
                ) {
                  return `${nameD[seriesIndex][dataPointIndex]}, count : ${value}`;
                },
              },
              fixed: {
                enabled: true,
                position: "topRight",
                offsetX: 0,
                offsetY: 0,
              },
            },
          },
        };

        setEmpWithReason({ ...empWithReason, loading: false, data: state2 });
      },
      (err) => {
        setEmpWithReason({ ...empWithReason, loading: false, data: [] });
      }
    );
  }

  function fetchEmpWithNCReason() {
    getBINCReason(
      {
        startDate: convertDateYYMMDD(startDate, "-"),
        endDate: convertDateYYMMDD(endDate, "-"),
        env: app_env,
        // type: "operation"
      },
      { signal: abortController.signal }
    ).then(
      (res) => {
        if(res.data.data?.length > 0){
          setEmpNC_Excel({...empNC_excel, data: res.data.data, loading: false});
        }
        const employees = res?.data?.data.map(
          (entry) => `${entry.employee_name}`
        ); 

        let reason_arr = [];
        res?.data?.data?.forEach((item, ind) => {
          let arr = [];
          arr = item.nc_reason.map((el) => {
            return el.ncreason;
          });

          reason_arr = [
            ...reason_arr,
            ...arr,
          ];
        });
        let uniqueReason = [...new Set(reason_arr)];

        let result = [];
        for (let i = 0; i < uniqueReason.length; i++) {
          let obj = {};
          obj.name = uniqueReason[i];
          let counts = [];
          res?.data?.data?.forEach((ele) => {
            let check = ele.nc_reason.filter(
              (obj) => obj.ncreason === uniqueReason[i]
            );
            if (check.length > 0) {
              counts.push(check[0].count);
            } else {
              counts.push(0);
            }
          });
          obj.data = counts;
          result.push(obj);
        }
        
        // console.log("result", result);

        const state2 = {
          series: result,
          options: {
            chart: {
              type: "bar",
              height: 350,
              stacked: true,
              toolbar: {
                show: false,
              },
            },
            responsive: [
              {
                breakpoint: 480,
                options: {
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                },
              },
            ],
            plotOptions: {
              bar: {
                horizontal: false,
                // columnWidth: result.length === 1 ? '10%' : (result.length > 1 && result.length < 3 ? '20%' : '50%'),
                columnWidth: 
                    result.length === 1 ? '10%' :
                    result.length <= 5 ? `${20 + (result.length - 1) * 5}%` : // 2 = 25%, 3 = 30%, up to 5 = 40%
                    result.length <= 10 ? `${35 + (result.length - 5) * 5}%` : // 6 = 45%, 7 = 50%, up to 10 = 65%
                    '70%', // default if length > 10
                dataLabels: {
                  show: true,
                  total: {
                    enabled: true,
                  },
                },
              },
            },
            colors: colors,
            xaxis: {
              categories: employees,
            },
            legend: {
              position: "bottom",
              markers: {
                width: 8,
                height: 8,
              },
              fontSize: "12px",
              show: true,
            },
            fill: {
              opacity: 1,
            },
            // tooltip: {
            //   enabled: true,
            //   y: {
            //     formatter: function (val, { seriesIndex, dataPointIndex, w }) {
            //       const ncReason = result[seriesIndex].ncReasons[dataPointIndex];
            //       const ncData = result[seriesIndex].data[dataPointIndex];
            //       return `${ncReason} = ${ncData}`;
            //     },
            //   },
            // },
          },
        };

        setEmpWithNCReason({
          ...empWithNCReason,
          loading: false,
          data: state2,
        });
      },
      (err) => {
        setEmpWithNCReason({ ...empWithNCReason, loading: false, data: [] });
      }
    );
  }

  // Disposal action pie chart function
  function fetchDisposalAction() {
    getBIDisposalAction(
      {
        startDate: convertDateYYMMDD(startDate, "-"),
        endDate: convertDateYYMMDD(endDate, "-"),
        type: "number",
        env: app_env,
      },
      { signal: abortController.signal }
    ).then(
      (res) => {
        const seriesData = res?.data?.disposal_action
          ?.filter((item) => item.ncstatus !== null)
          .map((item) => Number(item.count));
        const labels = res?.data?.disposal_action
          ?.filter((item) => item.ncstatus !== null)
          .map((item) => item.ncstatus);

        const state2 = {
          series: seriesData,
          options: {
            chart: {
              type: "pie",
              height: 380,
            },
            colors: colors,
            plotOptions: {
              pie: {
                customScale: 1.1,
                offsetX: 0,
                offsetY: 0,
                pie: {
                  size: "70%",
                },
              },
            },
            dataLabels: {
              enabled: true,
            },
            legend: {
              show: true,
              position: "bottom",
              markers: {
                width: 8,
                height: 8,
              },
              fontSize: "10px",
            },
            labels,
          },
        };

        setDisposal({ ...disposal, loading: false, data: state2 });
      },
      (err) => {
        setDisposal({ ...disposal, loading: false, data: [] });
      }
    );
  }

  function handleClick(ind, checkVal) {
    //

    let trueCount = 0;
    cardShow.map((val) => {
      if (val.flag == true) {
        trueCount += 1;
      }
    });

    if (checkVal == true) {
      setCardShow((prevCardShow) => {
        return prevCardShow.map((val, vali) => {
          if (vali == ind) {
            val.flag = false;
          }
          return val;
        });
      });
    } else {
      const lastIndex = cardShow.lastIndexOf((item) => item.flag == true);

      setCardShow((prevCardShow) => {
        const res = prevCardShow.map((val, vali) => {
          if (vali == ind) {
            val.flag = true;
          }
          return val;
        });

        return res;
      });
    }
  }

  const delNC = (val) => {
    //
    SweetAlert.fire({
      title: "Are you sure to delete \n" + val.camshaft_type + " ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteNCAPI({ uuid: val.uuid }).then(
          (res) => {
            //
            toast.success("NC deleted successfully", {
              autoClose: 2000,
            });

            if (ncList.data.length == 1 && page > 1) {
              fetchNCList(page - 1, entriesPerPage);
            } else {
              fetchNCList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  const handleFromDateChange = (date) => {
    if (endDate && date > endDate) {
      return;
    }
    setStartDate(date);
  };

  const handleToDateChange = (date) => {
    if (startDate && date < startDate) {
      return;
    }
    setEndDate(date);
  };

  async function goToDownload() {
    setLockExcl(true);
    // console.log("inside download excel", filterConfig);
    const payload = {
      startDate: convertDateYYMMDD(new Date(startDate), "-"),
      endDate: convertDateYYMMDD(new Date(endDate), "-"),
    };

    await getEmp_NC_count_excl(payload).then(
      (res) => {
        console.log("res", res);
        if (res) {
          // excel_download(res.data.data);
          // console.log("res", res);
          base64ToExcel(res.data);
        }
      },
      (err) => {
        // console.log("err", err);
        toast.error(err.message, {
          autoClose: 2000,
        });
        setLockExcl(false);
      }
    );
  }

  function base64ToExcel(response) {
    const { statusCode, headers, body, isBase64Encoded } = response;

    // Assume base64String contains the base64 representation of the Excel file
    const base64String = body; // Replace this with your base64 string

    // Convert the base64 string into a Uint8Array
    const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));

    // Create a Blob object from the Uint8Array
    const blob = new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a link element with download attribute
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toDateString().split(" ").join("_");
    link.download = `Emp_NC_count_report_${currentDate}.xlsx`;

    // Programmatically click on the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Cleanup: remove the link and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    setLockExcl(false);
  }

  const excel_download = async (excel_data) => {
    setLockExcl(true);
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    // Adding the title in the center of the sheet
    const title = `Employee NC Report - ${excelReportDate(new Date())}`;
    worksheet.mergeCells('B2:D2'); // Merge cells from C2 to E2 for title
    const titleCell = worksheet.getCell('B2');
    titleCell.value = title;
    titleCell.alignment = { horizontal: 'center' }; // Center alignment
    titleCell.font = { size: 16, bold: true }; // Make the title bold and larger
    titleCell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' },
    };

    worksheet.addRow([]); // Empty row
  
    // Define the title row
    const keyTitleMap = [
      "Employee Name",
      "Total Count",
      "NC Reason",
      "Reason Count"
    ];
  
    // Flatten the JSON data structure into rows for Excel
    const flattenedData = excel_data.data?.map((employee, index) => {
      return employee?.nc_reason.map((reasonItem, reasonIndex) => [
        reasonIndex === 0 ? employee.employee_name : '', // Only show the employee name on the first reason
        reasonIndex === 0 ? employee.count : '', // Only show the total count on the first reason
        reasonItem.ncreason,
        reasonItem.count
      ]);
    }).flat();
  
    const data = [keyTitleMap, ...flattenedData];
    
    // Add data to the worksheet
    worksheet.addRows(data);
  
    // Adjust cell width based on string length
    worksheet.columns.forEach((column) => {
      let maxLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        const length = cell.value ? String(cell.value).length : 10;
        if (length > maxLength) {
          maxLength = length;
        }
      });
      column.width = maxLength < 10 ? 10 : maxLength + 2; // Set a minimum width
    });
  
    // Set background color for the first row
    worksheet.getRow(4).eachCell({ includeEmpty: true }, (cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "3081D0" }, // Blue background color
      };
      cell.font = {
        color: { argb: "ffffff" }, // White text
        bold: true,
      };
    });
  
    // Save the workbook to a file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
  
    // Create a link and trigger the download
    const link = document.createElement("a");
    link.href = url;
   
    link.download = `Employee NC Report - ${excelReportDate(new Date())}.xlsx`;
    document.body.appendChild(link);
    link.click();
  
    setLockExcl(false);
    // Cleanup
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };
  

  return (
    <React.Fragment>
      <ToastContainer />
      {galVisible ? (
        <ImageGallery
          galVisible={galVisible}
          fetchNCList={fetchNCList}
          page={page}
          entriesPerPage={entriesPerPage}
          setGalVisible={setGalVisible}
          val={selItem2}
        />
      ) : null}
      <div className="nc_dashboard mb-4">
        <div className="mt-2">
          <h1 className="bold">
            {constLabel?.lbl_qc_dashboard
              ? constLabel?.lbl_qc_dashboard
              : "QC Dashboard"}
            {/* {"NC Dashboard: Production Overview"} */}
          </h1>

          <div className="card border-0">
            <div className="card-body row">
              <div className="col-12 col-md-8">
                <div className="row">
                  <div
                    className="col-12 col-md-2 d-flex align-items-center"
                    style={{ maxWidth: "120px" }}
                  >
                    <p className="mb-0">
                      {constLabel?.lbl_from_date
                        ? constLabel?.lbl_from_date
                        : "From Date"}
                    </p>
                  </div>
                  <div className="col-12 col-md-4">
                    <div style={{ maxWidth: "250px" }}>
                      <Controller
                        control={control}
                        name="fromDate"
                        render={({ field }) => (
                          <DatePicker
                            id="fromDate"
                            className="form-control form-control-sm"
                            selected={startDate}
                            onChange={(date) => {
                              field.onChange(date);
                              handleFromDateChange(date);
                            }}
                            showYearDropdown
                            scrollableMonthYearDropdown
                            dateFormat="dd/MM/yyyy"
                            placeholderText="From Date"
                            maxDate={new Date()}
                            autoComplete="false"
                          />
                        )}
                      />
                      {errors.fromDate && (
                        <span className="text-danger err-msg">
                          {errors.fromDate.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    className="col-12 col-md-2 d-flex align-items-center"
                    style={{ maxWidth: "120px" }}
                  >
                    <p className="mb-0">
                      {constLabel?.lbl_to_date
                        ? constLabel?.lbl_to_date
                        : "To Date"}
                    </p>
                  </div>
                  <div className="col-12 col-md-4">
                    <div style={{ maxWidth: "250px" }}>
                      <Controller
                        control={control}
                        name="toDate"
                        render={({ field }) => (
                          <DatePicker
                            id="toDate"
                            className="form-control form-control-sm"
                            selected={endDate}
                            onChange={(date) => {
                              field.onChange(date);
                              handleToDateChange(date);
                            }}
                            showYearDropdown
                            scrollableMonthYearDropdown
                            dateFormat="dd/MM/yyyy"
                            placeholderText="To Date"
                            maxDate={new Date()}
                            autoComplete="false"
                          />
                        )}
                      />
                      {errors.toDate && (
                        <span className="text-danger err-msg">
                          {errors.toDate.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <p className="roboto-bold" style={{ fontSize: "18px" }}>
              {constLabel?.lbl_quick_stats
                ? constLabel?.lbl_quick_stats
                : "Quick stats"}
            </p>

            <Tooltip id={"show-tooltip"} place="top" />
            <div
              className="dropdown"
              data-tooltip-id={"show-tooltip"}
              data-tooltip-content={"Show / Hide Columns"}
            >
              <div
                className="d-flex justify-content-center align-items-center"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {/* <FontAwesomeIcon
                  style={{ color: "#fff" }}
                  icon={faCog}
                  size="sm"
                /> */}
                <img
                  src={List}
                  height="32"
                  width="32"
                  className="cursor_pointer"
                />
              </div>

              <ul
                className="dropdown-menu px-2 shadow-sm"
                style={{
                  maxHeight: "300px",
                  overflowY: "scroll",
                  width: "200px",
                }}
              >
                {cardShow !== undefined && cardShow.length > 0
                  ? cardShow.map((val, ind, arr) => (
                      <li key={ind}>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            onChange={(e) => handleClick(ind, val.flag)}
                            value=""
                            checked={val.flag}
                            id={val.name}
                          />
                          <label
                            className="ms-1"
                            style={{ fontSize: "13px" }}
                            htmlFor={val.name}
                          >
                            {val.name}
                          </label>
                        </div>
                      </li>
                    ))
                  : null}
              </ul>
            </div>
          </div>

          {cardShow.map((card, index) =>
            card.name === "Monthly Non-Conformance by Product" &&
            card.flag ? (
              <div key={index} className="col-12 col-md-12 mt-3">
                <div className="card border-0">
                  <div className="card-body">
                    <h4 className="roboto-bold">
                      Monthly Non-Conformance by Product
                    </h4>
                    <p className="roboto-light nc_detail_label">
                      Tracking the frequency of non-conformance issues across different products on a monthly
                    </p>
                    {prodMonthly.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : prodMonthly.data.options &&
                      prodMonthly.data.series &&
                      prodMonthly.data?.series?.length ? (
                      <Chart
                        options={prodMonthly.data?.options}
                        series={prodMonthly.data?.series}
                        type="line"
                        width={"100%"}
                        height={400}
                      />
                    ) : (
                      <div
                        className="alert alert-danger mb-0 text-center my-1"
                        role="alert"
                      >
                        {
                          "Non Conformance-Monthly Product Wises data not found !"
                        }
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : card.name === " Top Employees by Non-Conformance" && card.flag ? (
              <div key={index} className="col-12 col-md-3 mt-3">
                <div className="card border-0 ">
                  <div className="card-body">
                    <h4 className="roboto-bold"> Top Employees by Non-Conformance</h4>
                    <p className="roboto-light nc_detail_label">
                      Employee-wise breakdown of non-conformance incidents
                    </p>
                    {empListTop.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : empListTop.data.options &&
                      empListTop.data.series &&
                      empListTop.data?.series[0]?.data.length > 0 ? (
                      <Chart
                        options={empListTop.data?.options}
                        series={empListTop.data?.series}
                        type="bar"
                        width={280}
                        height={240}
                      />
                    ) : (
                      <div
                        className="text-center"
                        // className="alert alert-danger mb-0 text-center my-1"
                        // role="alert"
                      >
                        {/* {"Employee NC data not found !"} */}
                        <img src={NotFound} height={100} width={100} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : card.name === "High-Risk Products for Non-Conformance" && card.flag ? (
              <div key={index} className="col-12 col-md-3 mt-3">
                <div className="card border-0">
                  <div className="card-body">
                    <h4 className="roboto-bold">High-Risk Products for Non-Conformance</h4>
                    <p className="roboto-light nc_detail_label">
                      Products with the highest NC counts over the period
                    </p>
                    {prdList.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : prdList.data.options &&
                      prdList.data.series &&
                      prdList.data?.series[0]?.data?.length > 0 ? (
                      <Chart
                        options={prdList.data?.options}
                        series={prdList.data?.series}
                        type="bar"
                        width={280}
                        height={240}
                      />
                    ) : (
                      <div
                        className="text-center"
                        // className="alert alert-danger mb-0 text-center my-1"
                        // role="alert"
                      >
                        {/* {"Products NC count data not found !"} */}
                        <img src={NotFound} height={100} width={100} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : card.name === "Non-Conformance by Cause" && card.flag ? (
              <div key={index} className="col-12 col-md-3 mt-3">
                <div className="card border-0">
                  <div className="card-body">
                    <h4 className="roboto-bold">Non-Conformance by Cause</h4>
                    <p className="roboto-light nc_detail_label">
                      Breakdown of NC reasons by percentage
                    </p>
                    {reasonList.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : reasonList.data.options &&
                      reasonList.data.series &&
                      reasonList.data?.series?.length ? (
                      <Chart
                        options={reasonList.data?.options}
                        series={reasonList.data?.series}
                        type="pie"
                        width={300}
                        height={290}
                      />
                    ) : (
                      <div
                        className="text-center"
                        // className="alert alert-danger mb-0 text-center my-1"
                        // role="alert"
                      >
                        {/* {"NC reason count data not found !"} */}
                        <img src={NotFound} height={100} width={100} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : card.name === " Top Operations Contributing to Non-Conformance" && card.flag ? (
              <div key={index} className="col-12 col-md-3 mt-3">
                <div className="card border-0">
                  <div className="card-body">
                    <h4 className="roboto-bold"> Top Operations Contributing to Non-Conformance</h4>
                    <p className="roboto-light nc_detail_label">
                      Key operational processes with the most NC occurrences
                    </p>
                    {oprList.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : oprList.data.options &&
                      oprList.data.series &&
                      oprList.data?.series[0]?.data?.length > 0 ? (
                      <Chart
                        options={oprList.data?.options}
                        series={oprList.data?.series}
                        type="bar"
                        width={280}
                        height={240}
                      />
                    ) : (
                      <div
                        className="text-center"
                        // className="alert alert-danger mb-0 text-center my-1"
                        // role="alert"
                      >
                        {/* {"Operations NC count data not found !"} */}
                        <img src={NotFound} height={100} width={100} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : card.name === "Employees with less NC" && card.flag ? (
              <div key={index} className="col-12 col-md-3 mt-3">
                <div className="card border-0">
                  <div className="card-body">
                    <h4 className="roboto-bold">Employees with less NC</h4>
                    <p className="roboto-light nc_detail_label">
                      Employee wise NC count
                    </p>
                    {empListLow.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : empListLow.data.options &&
                      empListLow.data.series &&
                      empListLow.data?.series[0]?.data?.length > 0 ? (
                      <Chart
                        options={empListLow.data?.options}
                        series={empListLow.data?.series}
                        type="bar"
                        width={280}
                        height={240}
                      />
                    ) : (
                      <div
                        className="text-center"
                        // className="alert alert-danger mb-0 text-center my-1"
                        // role="alert"
                      >
                        {/* {"Employee with less NC data not found !"} */}
                        <img src={NotFound} height={100} width={100} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : card.name === "Product with Employee" && card.flag ? (
              <div key={index} className="col-12 col-md-12 mt-3">
                <div className="card border-0">
                  <div className="card-body">
                    <h4 className="roboto-bold">Product with Employee</h4>
                    <p className="roboto-light nc_detail_label">
                      Top product with top employee
                    </p>
                    {prodWithEmpStack.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : prodWithEmpStack.data.options &&
                      prodWithEmpStack.data.series &&
                      prodWithEmpStack.data?.series?.length > 0 ? (
                      <Chart
                        options={prodWithEmpStack.data?.options}
                        series={prodWithEmpStack.data?.series}
                        type="bar"
                        width={"100%"}
                        height={500}
                      />
                    ) : (
                      <div
                        className="text-center"
                        // className="alert alert-danger mb-0 text-center my-1"
                        // role="alert"
                      >
                        {/* {"Product with employee data not found !"} */}
                        <img src={NotFound} height={100} width={100} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : card.name === "Product with Operation" && card.flag ? (
              <div key={index} className="col-12 col-md-12 mt-3">
                <div className="card border-0">
                  <div className="card-body">
                    <h4 className="roboto-bold">Product with Operation</h4>
                    <p className="roboto-light nc_detail_label">
                      Top product with top operation
                    </p>
                    {prodWithOprStack.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : prodWithOprStack.data.options &&
                      prodWithOprStack.data.series &&
                      prodWithOprStack.data?.series?.length > 0 ? (
                      <Chart
                        options={prodWithOprStack.data?.options}
                        series={prodWithOprStack.data?.series}
                        type="bar"
                        width={"100%"}
                        height={500}
                      />
                    ) : (
                      <div
                        className="text-center"
                        // className="alert alert-danger mb-0 text-center my-1"
                        // role="alert"
                      >
                        {/* {"Product with operations data not found !"} */}
                        <img src={NotFound} height={100} width={100} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : card.name === "Monthly Non-Conformance by Root Cause" && card.flag ? (
              <div key={index} className="col-12 col-md-12 mt-3">
                <div className="card border-0">
                  <div className="card-body">
                    <h4 className="roboto-bold">Monthly Non-Conformance by Root Cause</h4>
                    <p className="roboto-light nc_detail_label">
                      Monthly breakdown of non-conformance reasons in the manufacturing process
                    </p>
                    {reasonMonthly.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : reasonMonthly.data.options &&
                      reasonMonthly.data.series &&
                      reasonMonthly.data?.series?.length ? (
                      <Chart
                        options={reasonMonthly.data?.options}
                        series={reasonMonthly.data?.series}
                        type="bar"
                        width={"100%"}
                        height={350}
                      />
                    ) : (
                      <div
                        className="text-center"
                        // className="alert alert-danger mb-0 text-center my-1"
                        // role="alert"
                      >
                        {/* {"Monthly Non-Conformance by Root Cause data not found !"} */}
                        <img src={NotFound} height={100} width={100} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : card.name === "Top Products by Non-Conformance and Root Cause" && card.flag ? (
              <div key={index} className="col-12 col-md-12 mt-3">
                <div className="card border-0">
                  <div className="card-body">
                    <h4 className="roboto-bold">Top Products by Non-Conformance and Root Cause</h4>
                    <p className="roboto-light nc_detail_label">
                    Product-wise breakdown of non-conformance issues and their contributing operational root causes
                    </p>
                    {prodWithReasonStack.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : prodWithReasonStack.data.options &&
                      prodWithReasonStack.data.series &&
                      prodWithReasonStack.data?.series?.length > 0 ? (
                      <Chart
                        options={prodWithReasonStack.data?.options}
                        series={prodWithReasonStack.data?.series}
                        type="bar"
                        width={"100%"}
                        height={500}
                      />
                    ) : (
                      <div
                        className="text-center"
                        // className="alert alert-danger mb-0 text-center my-1"
                        // role="alert"
                      >
                        {/* {"Product with reasons data found !"} */}
                        <img src={NotFound} height={100} width={100} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : // <div key={index} className="col-12 col-md-3 mt-3">
            //   <div className="card border-0">
            //     <div className="card-body">
            //       <h4 className="roboto-bold">Product with Reason</h4>
            //       <p className="roboto-light nc_detail_label">
            //         Top product with most nc reason
            //       </p>
            //       {prodWithReason.loading ? (
            //         <div className="text-center">
            //           <div className="spinner-border" role="status">
            //             <span className="visually-hidden">Loading...</span>
            //           </div>
            //         </div>
            //       ) : (
            //         prodWithReason.data.options &&
            //         prodWithReason.data.series && (
            //           <Chart
            //             options={prodWithReason.data?.options}
            //             series={prodWithReason.data?.series}
            //             type="bar"
            //             width={280}
            //             height={240}
            //           />
            //         )
            //       )}
            //     </div>
            //   </div>
            // </div>
            card.name === "Employee with Product" && card.flag ? (
              <div key={index} className="col-12 col-md-3 mt-3">
                <div className="card border-0">
                  <div className="card-body" style={{ minHeight: "250px" }}>
                    <h4 className="roboto-bold">Employee with Product</h4>
                    <p className="roboto-light nc_detail_label">
                      Top Employee with top product
                    </p>
                    {empWithProd.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : empWithProd.data.options &&
                      empWithProd.data.series &&
                      empWithProd.data?.series[0]?.data?.length > 0 ? (
                      <Chart
                        options={empWithProd.data?.options}
                        series={empWithProd.data?.series}
                        type="bar"
                        width={280}
                        height={240}
                      />
                    ) : (
                      <div
                        className="text-center"
                        // className="alert alert-danger mb-0 text-center my-1"
                        // role="alert"
                      >
                        {/* {"Employee with products data not found !"} */}
                        <img src={NotFound} height={100} width={100} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : card.name === "Employee with Operation" && card.flag ? (
              <div key={index} className="col-12 col-md-3 mt-3">
                <div className="card border-0">
                  <div className="card-body" style={{ minHeight: "250px" }}>
                    <h4 className="roboto-bold">Employee with Operation</h4>
                    <p className="roboto-light nc_detail_label">
                      Top Employee with top operation
                    </p>
                    {empWithOpr.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : empWithOpr.data.options &&
                      empWithOpr.data.series &&
                      empWithOpr.data?.series[0]?.data?.length > 0 ? (
                      <Chart
                        options={empWithOpr.data?.options}
                        series={empWithOpr.data?.series}
                        type="bar"
                        width={280}
                        height={240}
                      />
                    ) : (
                      <div
                        className="text-center"
                        // className="alert alert-danger mb-0 text-center my-1"
                        // role="alert"
                      >
                        {/* {"Employee with operations data not found !"} */}
                        <img src={NotFound} height={100} width={100} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : card.name === "Employee with Reason" && card.flag ? (
              <div key={index} className="col-12 col-md-3 mt-3">
                <div className="card border-0">
                  <div className="card-body" style={{ minHeight: "250px" }}>
                    <h4 className="roboto-bold">Employee with Reason</h4>
                    <p className="roboto-light nc_detail_label">
                      Top Employee with top nc reason
                    </p>
                    {empWithReason.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : empWithReason.data.options &&
                      empWithReason.data.series &&
                      empWithReason.data?.series[0]?.data?.length > 0 ? (
                      <Chart
                        options={empWithReason.data?.options}
                        series={empWithReason.data?.series}
                        type="bar"
                        width={280}
                        height={240}
                      />
                    ) : (
                      <div
                        className="text-center"
                        // className="alert alert-danger mb-0 text-center my-1"
                        // role="alert"
                      >
                        {/* {"Employee with top NC reason data not found !"} */}
                        <img src={NotFound} height={100} width={100} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : card.name === "Top Employees by Non-Conformance and Reasons" && card.flag ? (
              <div key={index} className="col-12 col-md-12 mt-3">
                <div className="card border-0">
                  <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <h4 className="roboto-bold">Top Employees by Non-Conformance and Reasons</h4>
                    <Tooltip id={"export-tooltip"} place="top" />
                    <button
                      className="btn btn-light-grey btn-sm me-0 me-md-2 my-2 mt-md-0"
                      disabled={lockExcl}
                      // onClick={() => goToDownload()}
                      onClick={() => excel_download(empNC_excel)}
                      data-tooltip-id={"export-tooltip"}
                      data-tooltip-content={
                        constLabel?.lbl_emp_nc_count_excel
                          ? constLabel.lbl_emp_nc_count_excel
                          : "Employee NC Count Excel"
                      }
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faFileExcel}
                        size="sm"
                      />{" "}
                      {lockExcl ? <Spinner animation="border" size="sm" /> : null}{" "}
                    </button>
                  </div>
                    <p className="roboto-light nc_detail_label">
                    Employee-wise distribution of non-conformance issues, categorized by specific reasons for better 
                    traceability
                    </p>
                    {empWithNCReason.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : empWithNCReason.data.options &&
                      empWithNCReason.data.series &&
                      empWithNCReason.data?.series?.length ? (
                      <Chart
                        options={empWithNCReason.data?.options}
                        series={empWithNCReason.data?.series}
                        type="bar"
                        width={"100%"}
                        height={350}
                      />
                    ) : (
                      <div
                        className="text-center"
                        // className="alert alert-danger mb-0 text-center my-1"
                        // role="alert"
                      >
                        {/* {"Monthly Non-Conformance by Root Cause data not found !"} */}
                        <img src={NotFound} height={100} width={100} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : card.name === "Distribution of Disposal Actions" && card.flag ? (
              <div key={index} className="col-12 col-md-3 mt-3">
                <div className="card border-0">
                  <div className="card-body">
                    <h4 className="roboto-bold">Distribution of Disposal Actions</h4>
                    <p className="roboto-light nc_detail_label">
                      Breakdown of various actions taken for non-conforming products, categorized by disposal reason
                    </p>
                    {disposal.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : disposal.data.options &&
                      disposal.data.series &&
                      disposal.data?.series?.length ? (
                      <Chart
                        options={disposal.data?.options}
                        series={disposal.data?.series}
                        type="pie"
                        width={300}
                        height={290}
                      />
                    ) : (
                      <div
                        className="text-center"
                        // className="alert alert-danger mb-0 text-center my-1"
                        // role="alert"
                      >
                        {/* {"NC reason count data not found !"} */}
                        <img src={NotFound} height={100} width={100} />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null
          )}

          <Suspense
            fallback={
              <div className="text-center mt-4">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            }
          >
            <AgeNC
              startDate={startDate}
              endDate={endDate}
              setSelItem={setSelItem}
              setShow2={setShow2}
              privilage={privilage}
              delNC={delNC}
              setSelItem2={setSelItem2}
              setGalVisible={setGalVisible}
            />
          </Suspense>

          <div className="d-flex justify-content-between mt-4">
            <p className="roboto-bold" style={{ fontSize: "18px" }}>
              {constLabel?.lbl_nc_pending
                ? constLabel?.lbl_nc_pending
                : "NC : Pending"}
            </p>
            <div>
              <button
                // onClick={() => clearFilter()}
                className="btn btn-sm btn-grey "
              >
                <FontAwesomeIcon
                  // style={{ color: "#fffff" }}
                  icon={faCircle}
                  size="sm"
                />{" "}
                {constLabel?.lbl_filter ? constLabel?.lbl_filter : "Filter"}
              </button>
            </div>
          </div>

          <div className="col-12">
            <div className="card border-0 mb-4">
              <div className="card-body">
                {ncList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : ncList.data?.length > 0 ? (
                  <>
                    <div className="entriesPerPage roboto-bold d-flex align-items-end mb-3">
                      <p className="mb-0">
                        Show
                        <select
                          className="mx-1"
                          defaultValue={"10"}
                          value={entriesPerPage}
                          onChange={(e) => {
                            setEntriesPerPage(e.target.value);
                            fetchNCList(page, e.target.value);
                          }}
                        >
                          <option value={"10"}>10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        Entries
                      </p>
                    </div>
                    <div className="table-responsive">
                      <table className="table mt-2 table-bordered">
                        <thead className="table-grey roboto-bold">
                          <tr className="">
                            <th scope="col">{"SrNo"}</th>
                            {/* <th scope="col">{"Tag No"}</th> */}
                            <th scope="col">{"Date"}</th>
                            <th scope="col">{"Camshaft"}</th>
                            <th scope="col">{"NC Tag"}</th>
                            {/* <th scope="col">{"Job No"}</th> */}
                            <th scope="col">{"Lot No"}</th>
                            <th scope="col">{"Lot Size"}</th>
                            {/* <th scope="col">{"Operation"}</th> */}
                            {/* <th scope="col">{"M/C No"}</th> */}
                            <th scope="col">{"NC Status"}</th>
                            <th scope="col">{"Job No"}</th>
                            {/* <th scope="col">{"Quantity"}</th> */}
                            {/* <th scope="col">{"Operation"}</th> */}
                            {/* <th scope="col">{"Disposal Action"}</th> */}
                            {/* <th scope="col">{"Operator Name"}</th> */}
                            {/* <th scope="col">{"Material Code"}</th> */}
                            <th scope="col" width="15%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="roboto">
                          {ncList.data.map((val, i) => (
                            <tr key={i}>
                              <td style={{ verticalAlign: "middle" }}>
                                {parseInt(i) + 1 + parseInt(srNo)}
                              </td>
                              {/* <td>{val.nc_tag}</td> */}
                              <td>{convertDate(val.nc_date, "-")}</td>
                              <td>{val.camshaft_type}</td>
                              <td>{val.nc_tag}</td>
                              {/* <td>{val.job_no}</td> */}
                              <td>{val.lot_no}</td>
                              {/* <td>{val.qty}</td> */}
                              <td>{val.lot_size}</td>
                              {/* <td>{val.operation_inwhich}</td> */}
                              {/* <td>{val.machine_id}</td> */}
                              <td>{val.ncstatus}</td>
                              <td>{val.job_no}</td>
                              {/* <td>{val.lot_size}</td> */}

                              {/* <td>{val.operation}</td> */}
                              {/* <td>{val.ncstatus}</td> */}
                              {/* <td>{val.operator}</td> */}
                              {/* <td>{val.RMICode}</td> */}
                              <td className="">
                                {loginData.loginData?.data?.response
                                  .role_name !== "client_admin" &&
                                loginData.loginData?.data?.response
                                  .role_name !== "admin" ? (
                                  privilage.write == true ? (
                                    <>
                                      <Tooltip
                                        id={"edit-tooltip" + i}
                                        place="top"
                                      />
                                      <Edit
                                        data-tooltip-id={"edit-tooltip" + i}
                                        data-tooltip-content={
                                          constLabel?.lbl_edit_nc
                                            ? constLabel.lbl_edit_nc
                                            : "Edit NC"
                                        }
                                        className="menu_icon_grey cursor_pointer me-2"
                                        size={20}
                                        onClick={() => {
                                          // setVisible(true);
                                          // setSelItem2(val)
                                          const newVal = { ...val };

                                          if (
                                            newVal.machine_id_id !== null &&
                                            newVal.camshaft_type_id !== null &&
                                            newVal.lot_no_id !== null &&
                                            newVal.operation_id_id !== null &&
                                            newVal.operator_id !== null
                                          ) {
                                            newVal.machine_id_id.machineNameSerialNo =
                                              newVal.machine_id_id.machineName +
                                              " - " +
                                              newVal.machine_id_id.serialNo;
                                            navigate("/pcms/nc-update", {
                                              state: newVal,
                                            });
                                          } else {
                                            toast.error(
                                              "Cannot update old nc",
                                              {
                                                autoClose: 2000,
                                              }
                                            );
                                          }
                                        }}
                                      />
                                    </>
                                  ) : null
                                ) : (
                                  <>
                                    <Tooltip
                                      id={"edit-tooltip" + i}
                                      place="top"
                                    />
                                    <Edit
                                      data-tooltip-id={"edit-tooltip" + i}
                                      data-tooltip-content={
                                        constLabel?.lbl_edit_nc
                                          ? constLabel.lbl_edit_nc
                                          : "Edit NC"
                                      }
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20}
                                      onClick={() => {
                                        // setVisible(true);
                                        // setSelItem2(val)
                                        const newVal = { ...val };

                                        if (
                                          newVal.machine_id_id !== null &&
                                          newVal.camshaft_type_id !== null &&
                                          newVal.lot_no_id !== null &&
                                          newVal.operation_id_id !== null &&
                                          newVal.operator_id !== null
                                        ) {
                                          newVal.machine_id_id.machineNameSerialNo =
                                            newVal.machine_id_id.machineName +
                                            " - " +
                                            newVal.machine_id_id.serialNo;
                                          navigate("/pcms/nc-update", {
                                            state: newVal,
                                          });
                                        } else {
                                          toast.error("Cannot update old nc", {
                                            autoClose: 2000,
                                          });
                                        }
                                      }}
                                    />
                                  </>
                                )}

                                {loginData.loginData?.data?.response
                                  .role_name !== "client_admin" &&
                                loginData.loginData?.data?.response
                                  .role_name !== "admin" ? (
                                  privilage.view == true ? (
                                    <>
                                      <Tooltip
                                        id={"view-tooltip" + i}
                                        place="top"
                                      />
                                      <Eye
                                        data-tooltip-id={"view-tooltip" + i}
                                        data-tooltip-content={"View NC"}
                                        className="menu_icon_grey cursor_pointer me-2"
                                        size={20}
                                        onClick={() => {
                                          setSelItem(val);
                                          setShow2(true);
                                        }}
                                      />
                                    </>
                                  ) : null
                                ) : (
                                  <>
                                    <Tooltip
                                      id={"view-tooltip" + i}
                                      place="top"
                                    />
                                    <Eye
                                      data-tooltip-id={"view-tooltip" + i}
                                      data-tooltip-content={"View NC"}
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20}
                                      onClick={() => {
                                        setSelItem(val);
                                        setShow2(true);
                                      }}
                                    />
                                  </>
                                )}

                                {loginData.loginData?.data?.response
                                  .role_name !== "client_admin" &&
                                loginData.loginData?.data?.response
                                  .role_name !== "admin" ? (
                                  privilage.view == true ? (
                                    <>
                                      <Tooltip
                                        id={"image-tooltip" + i}
                                        place="top"
                                      />
                                      <Image
                                        data-tooltip-id={"image-tooltip" + i}
                                        data-tooltip-content={
                                          constLabel?.lbl_image_gallary
                                            ? constLabel.lbl_image_gallary
                                            : "Image Gallary"
                                        }
                                        className="menu_icon_grey cursor_pointer me-2"
                                        size={20}
                                        onClick={() => {
                                          setGalVisible(true);
                                          setSelItem2(val);
                                        }}
                                      />
                                    </>
                                  ) : null
                                ) : (
                                  <>
                                    <Tooltip
                                      id={"image-tooltip" + i}
                                      place="top"
                                    />
                                    <Image
                                      data-tooltip-id={"image-tooltip" + i}
                                      data-tooltip-content={"Image Gallary"}
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20}
                                      onClick={() => {
                                        setGalVisible(true);
                                        setSelItem2(val);
                                      }}
                                    />
                                  </>
                                )}

                                {loginData.loginData?.data?.response
                                  .role_name !== "client_admin" &&
                                loginData.loginData?.data?.response
                                  .role_name !== "admin" ? (
                                  privilage.delete == true ? (
                                    <>
                                      <Tooltip
                                        id={"delete-tooltip" + i}
                                        place="top"
                                      />
                                      <Trash2
                                        data-tooltip-id={"delete-tooltip" + i}
                                        data-tooltip-content={"Delete NC"}
                                        className="menu_icon_red cursor_pointer"
                                        size={20}
                                        onClick={() => delNC(val)}
                                      />
                                    </>
                                  ) : null
                                ) : (
                                  <>
                                    <Tooltip
                                      id={"delete-tooltip" + i}
                                      place="top"
                                    />
                                    <Trash2
                                      data-tooltip-id={"delete-tooltip" + i}
                                      data-tooltip-content={"Delete NC"}
                                      className="menu_icon_red cursor_pointer"
                                      size={20}
                                      onClick={() => delNC(val)}
                                    />
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-4 d-flex justify-content-between">
                      <p className="mb-0">{`Showing ${parseInt(srNo) + 1} to ${
                        ncList.data.length +
                        entriesPerPage * page -
                        entriesPerPage
                      } of ${ncList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchNCList(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1
                                ? 0
                                : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                            maxPage === 0 ||
                            entriesPerPage > ncList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            fetchNCList(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  </>
                ) : (
                  <div
                    className="alert alert-danger mb-0 text-center my-1"
                    role="alert"
                  >
                    {"No Pending NC Found !"}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show2} onHide={() => setShow2(false)} size="lg">
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"NC Details"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-md-flex justify-content-between">
            <div>
              <label className="roboto-light nc_detail_label">Cam Shaft</label>
              <p className="roboto-bold">{selItem.camshaft_type}</p>
            </div>
            <div className="text-end">
              <label className="roboto-light nc_detail_label">NC Tag</label>
              <p className="roboto-bold">{selItem.nc_tag}</p>
            </div>
          </div>
          <div className="d-md-flex justify-content-between">
            <div>
              <label className="roboto-light nc_detail_label">
                Operator Name
              </label>
              <p className="roboto-bold">{selItem.operator}</p>
            </div>
            <div className="text-end">
              <label className="roboto-light nc_detail_label">Date</label>
              {/* <p className='roboto-bold'>{convertDate(new Date(selItem.nc_date), "-")}</p> */}
              <p className="roboto-bold">{selItem.nc_date}</p>
            </div>
          </div>

          <div
            className="bg-lightgrey"
            style={{ padding: "12px", borderRadius: "12px" }}
          >
            <div className="row">
              <div className="col-12 col-md-6">
                <div>
                  <p className="m-0 nc_detail_label">{`Job No`}</p>
                  <p className="mb-2 roboto-bold">{selItem.job_no}</p>
                </div>
                <div>
                  <p className="m-0 nc_detail_label">{`Machine`}</p>
                  <p className="mb-2 roboto-bold">{`${selItem.machine_id_id?.machineName} - ${selItem.machine_id_id?.serialNo}`}</p>
                </div>

                <div>
                  <p className="m-0 nc_detail_label">{`Root Cause At Operation`}</p>
                  <p className="mb-2 roboto-bold">{`${
                    selItem.operation ? selItem.operation : "NA"
                  }`}</p>
                </div>

                <div>
                  <p className="m-0 nc_detail_label">{`Reason For NC`}</p>
                  <p className="mb-2 roboto-bold">{`${
                    selItem.ncreason ? selItem.ncreason : "NA"
                  }`}</p>
                </div>
                <div>
                  <p className="m-0 nc_detail_label">{`Material Code`}</p>
                  <p className="mb-2 roboto-bold">{`${
                    selItem.camshaft_type_id?.material
                      ? selItem.camshaft_type_id?.material
                      : "NA"
                  }`}</p>
                </div>
              </div>
              <div className="col-12 col-md-6 ">
                <div>
                  <p className="m-0 nc_detail_label">{`Lot Size`}</p>
                  {/* <p className='mb-2 roboto-bold'>{selItem.qty ? selItem.qty : "NA"}</p> */}
                  <p className="mb-2 roboto-bold">{`${
                    selItem.lot_size ? selItem.lot_size : "NA"
                  }`}</p>
                </div>

                <div>
                  <p className="m-0 nc_detail_label">{`Lot No`}</p>
                  <p className="mb-2 roboto-bold">{selItem.lot_no}</p>
                </div>
                <div>
                  <p className="m-0 nc_detail_label">{`Root Cause`}</p>
                  <p className="mb-2 roboto-bold">{`${
                    selItem.remark ? selItem.remark : "NA"
                  }`}</p>
                </div>

                <div>
                  <p className="m-0 nc_detail_label">{`Quantity`}</p>
                  {/* <p className='mb-2 roboto-bold'>{`${selItem.lot_size ? selItem.lot_size : "NA"}`}</p> */}
                  <p className="mb-2 roboto-bold">
                    {selItem.qty ? selItem.qty : "NA"}
                  </p>
                </div>

                <div>
                  <p className="m-0 nc_detail_label">{`Operation in which NC Detected`}</p>
                  <p className="mb-2 roboto-bold">{`${
                    selItem.operation_inwhich ? selItem.operation_inwhich : "NA"
                  }`}</p>
                </div>
              </div>
            </div>
          </div>
          <hr className="mb-1 mt-4" />
          <div className="d-md-flex justify-content-between">
            <p className="roboto-bold mb-0" style={{ fontSize: "18px" }}>
              NC History
            </p>
          </div>
          <hr className="my-1" />
          <div className="" style={{ padding: "12px", borderRadius: "12px" }}>
            <div className="row">
              {selItem?.nc_history?.length > 0 ? (
                <div className="po_revision_history">
                  <div className="po_line"></div>

                  <div
                    className="row mx-auto"
                    style={{
                      marginTop: "-10px",
                      width: "100%",
                      overflowX: "auto",
                      fontSize: "14px",
                    }}
                  >
                    <div className="d-flex pb-2">
                      {selItem?.nc_history?.map((val, ind, arr) => (
                        <div
                          className="mx-4 text-center "
                          key={ind}
                          style={{ width: "250px" }}
                        >
                          <FontAwesomeIcon
                            icon={faCircle}
                            style={{
                              fontSize: "14px",
                              color: "#4E7DAE",
                            }}
                          />

                          <div
                            className="card border-0 mt-3 cursor_pointer"
                            style={{
                              background: "honeydew",
                              minWidth: "250px",
                            }}
                          >
                            <div className="card-body">
                              <div className="row">
                                <p
                                  className="col roboto-bold"
                                  style={{
                                    textAlign: "left",
                                    fontSize: "12px",
                                    lineHeight: "5px",
                                  }}
                                >
                                  {"Status"}
                                </p>
                                <p
                                  className="col roboto-bold"
                                  style={{
                                    textAlign: "left",
                                    color: "#9BA4B4",
                                    fontSize: "12px",
                                    lineHeight: "5px",
                                  }}
                                >
                                  {val.status}
                                </p>
                                {/* <p className='col-sm-8'>{val.po_date}</p> */}
                              </div>
                              <div className="row">
                                <p
                                  className="col roboto-bold"
                                  style={{
                                    textAlign: "left",
                                    fontSize: "12px",
                                    lineHeight: "5px",
                                  }}
                                >
                                  {"Status updated"}
                                </p>
                                <p
                                  className="col roboto-bold"
                                  style={{
                                    textAlign: "left",
                                    fontSize: "12px",
                                    color: "#9BA4B4",
                                    lineHeight: "5px",
                                  }}
                                >
                                  {val.status_update_date}
                                </p>
                                {/* <p className='col-sm-8'>{val.po_date}</p> */}
                              </div>
                              <div className="row">
                                <p
                                  className="col roboto-bold"
                                  style={{
                                    textAlign: "left",
                                    fontSize: "12px",
                                    lineHeight: "5px",
                                  }}
                                >
                                  {"Part No"}
                                </p>
                                <p
                                  className="col roboto-bold"
                                  style={{
                                    textAlign: "left",
                                    fontSize: "12px",
                                    color: "#9BA4B4",
                                    lineHeight: "5px",
                                  }}
                                >
                                  {val.part_no}
                                </p>
                                {/* <p className='col-sm-8'>{val.po_date}</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="alert alert-danger mb-0 text-center my-1"
                  role="alert"
                >
                  {"NC history not available !"}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-grey px-4" onClick={() => setShow2(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default NCDashboard;
