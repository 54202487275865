import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import CreateProductionData from "./CreateProductionData";
import EditProductionData from "./EditProductionData";
import ImageGallery from "../NC/ImageGallery";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faSearch,
  faFilter,
  faBan,
  faPlus,
  faCircle,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
// import { getNCList, getOperatorList } from "../../../src/api/index";
import { Pagination, Spinner } from "react-bootstrap";
import { Edit2, Eye, Trash2, Image, Edit } from "react-feather";
import {
  calMaxPage,
  convertDateYYMMDD,
  convertDate,
  formatDateString,
  getLabels,
  setAccessPrivilage,
  formatDateString2,
  formatDateString4, formatUTCDate,
  getShortReadableId,
  getFinancialYearStartDate
} from "../../common/Functions/CommonFunctions";
import Modal from "react-bootstrap/Modal";
import SweetAlert from "sweetalert2";
import {
  getMachineList,
  getLotNumList,
  getProdList,
  getProductionData,
  deleteProductionData,
  getOperationList,
  getProductionDataExcel,
  getOperatorList,
} from "../../api/index";
import { ncExcel } from "../../constant/index";
import { Production_Data_Col_name } from "../../common/Labels/labelConstant";
// import Sidebar from "../../Sidebar/Sidebar";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ExcelJS from "exceljs";
import List from "../../assets/images/icons/project_menu/checklist.png"
import Slide from "../../assets/images/icons/project_menu/collapse.png"

function ProductionData() {
  let navigate = useNavigate();
  const [startDate, setStartDate] = useState(getFinancialYearStartDate(new Date()));
  const [endDate, setEndDate] = useState(new Date());

  const [lockBtn, setLockBtn] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [opList, setOpList] = useState({ data: [], loading: false });
  const [lotList, setLotList] = useState({ data: [], loading: false });

  const [showLabel, setShowLabel] = useState(Production_Data_Col_name);
  const [constLabel, setConstLabel] = useState({});
  const [sea_val, setSea_val] = useState(false);
  const [productionList, setProductionList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [machineList, setMachineList] = useState({ data: [], loading: false });
  const [operatoList, setOperatorList] = useState({ data: [], loading: false });
  const [visible, setVisible] = useState(false);
  const [galVisible, setGalVisible] = useState(false);
  const [jobCardModal, setJobCardModal] = useState({ state: false, data: null });

  const [dataList, setDataList] = useState({ data: [], loading: true });
  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage, setPrivilage] = useState(
    setAccessPrivilage(loginData, "Production")
  );

  const [filterConfig, setFilterConfig] = useState({
    product_Name: [],
    operation_Name: [],
    machine_Name: [],
    lot_no: [],
    work_order_no: [],
    job_card_no: [],
    operator_name: [],
    shift: []
  });

  const [workShift, setWorkShift] = useState({
    data: [
      { id: 1, shiftName: "1" },
      { id: 2, shiftName: "2" },
      { id: 3, shiftName: "3" },
    ]
  });

  const [show, setShow] = useState(false);
  const [selItem, setSelItem] = useState({});
  const [selItem2, setSelItem2] = useState({});

  const [show2, setShow2] = useState(false);

  useEffect(() => {
    // console.table("filterConfig", filterConfig);
    setConstLabel(getLabels("Production", "Production Data"));
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchProductionData(1, entriesPerPage);
      // fetchLotList(startDate, endDate);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig, startDate, endDate]);

  // useEffect(() => {
  //   console.log("loginData", loginData);
  //   for (
  //     let i = 0;
  //     i < loginData.loginData?.data?.response.module_list.length;
  //     i++
  //   ) {
  //     if (
  //       loginData.loginData?.data?.response.module_list[i].module ==
  //       "Production"
  //     ) {
  //       setPrivilage(loginData.loginData?.data?.response.module_list[i]);
  //     }
  //   }
  // }, []);

  function getPrvMonthDateFromToday() {
    const today = new Date();
    const endDate = new Date(today);
    endDate.setMonth(today.getMonth() - 1);
    return endDate;
  }

  function clearFilter() {
    let obj = {
      ...filterConfig,
      product_Name: [],
      operation_Name: [],
    machine_Name: [],
      lot_no: [],
      work_order_no: [],
      job_card_no: [],
      operator_name: [],
      shift: []
    };
    delete obj.actualMoreThanPlanned;
    setIsChecked(false);
    setFilterConfig({ ...obj });
    setStartDate(getFinancialYearStartDate(new Date()));
    setEndDate(new Date());
  }

  function fetchProdList() {
    // setDataList({ ...dataList, loading: true, data: [] });

    getProdList().then(
      (res) => {
        // console.log("res", res);
        const filteredData = res.data.data.filter(
          (item) => item.id !== "" && item.productName !== null
        );
        setDataList({ ...dataList, loading: false, data: filteredData });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function fetchOperationList() {
    setOpList({ ...opList, data: [], loading: true });
    getOperationList().then(
      (res) => {
        // console.log("res", res);
        setOpList({ ...opList, data: res.data.data, loading: false });
      },
      (err) => {
        console.log("err", err);
        setOpList({ ...opList, data: [], loading: false });
      }
    );
  }


  function fetchOperatorList() {
    setOperatorList({ data: [], loading: true });
    let payload = {
      designation: "Operator"
    }
    getOperatorList(payload).then(
      (res) => {
        // console.log("res", res);
        let arr = res.data.data.map((item) => {
          let obj = {
            ...item,
            operator_name: `${item.FirstName} ${item.LastName}`
          };
          return obj
        })
        setOperatorList({ data: arr, loading: false });
      },
      (err) => {
        console.log("err", err);
        setOperatorList({ data: [], loading: false });
      }
    );
  }

  function fetchMachineList() {
    setMachineList({ ...machineList, data: [], loading: true });
    getMachineList().then(
      (res) => {
        // console.log("res", res);
        const result = res.data.data.map((item) => ({
          ...item,
          machineNameSerialNo: item.machineName + " - " + item.serialNo,
        }));
        setMachineList({ ...machineList, data: result, loading: false });
      },
      (err) => {
        console.log("err", err);
        setMachineList({ ...machineList, data: [], loading: false });
      }
    );
  }

  function fetchLotList(sd, ed) {
    setLotList({ ...lotList, data: [], loading: true });
    getLotNumList({
      start_date: convertDateYYMMDD(new Date(sd), "-"),
      end_date: convertDateYYMMDD(new Date(ed), "-"),
    }).then(
      (res) => {
        // console.log("lot res", res);
        setLotList({ ...lotList, data: res.data.data, loading: false });
      },
      (err) => {
        console.log("err", err);
        setLotList({ ...lotList, data: [], loading: false });
      }
    );
  }

  function fetchProductionData(pg, epp) {
    setProductionList({ ...productionList, loading: true, data: [] });

    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
      startDate: convertDateYYMMDD(new Date(startDate), "-"),
      endDate: convertDateYYMMDD(new Date(endDate), "-"),
      wo_details: true,
      jc_details: true
    };

    

    if (
      filterConfig.product_Name?.length > 0 ||
      filterConfig.operation_Name?.length > 0 ||
      filterConfig.machine_Name?.length > 0 ||
      filterConfig.operator_name?.length > 0 ||
      filterConfig.lot_no?.length > 0 ||
      filterConfig.work_order_no?.length > 0 ||
      filterConfig.job_card_no?.length > 0 ||
      filterConfig.shift?.length > 0 ||
      filterConfig.actualMoreThanPlanned !== false
    ) {
      payload.product_id = filterConfig.product_Name?.[0]?.uuid;
      payload.operation_id = filterConfig.operation_Name?.[0]?.uuid;
      payload.machine_id = filterConfig.machine_Name?.[0]?.uuid;
      payload.operator_id = filterConfig.operator_name?.[0]?.uuid;
      payload.lotNo = filterConfig.lot_no[0];
      payload.work_order_no = filterConfig.work_order_no[0];
      payload.job_card_no = filterConfig.job_card_no[0];
      payload.shift = filterConfig.shift?.[0]?.shiftName;
      payload.actualMoreThanPlanned = filterConfig.actualMoreThanPlanned;
      if (
        (filterConfig.product_Name?.length > 0 ||
          filterConfig.operation_Name?.length > 0 ||
          filterConfig.machine_Name?.length > 0 ||
          filterConfig.operator_name?.length > 0 ||
          filterConfig.lot_no?.length > 0 ||
          filterConfig.work_order_no?.length > 0 ||
          filterConfig.job_card_no?.length > 0 ||
          filterConfig.shift?.length > 0 ||
          filterConfig.actualMoreThanPlanned !== false) &&
        page !== 1 &&
        sea_val == false
      ) {
        payload.pageNo = 1;
        payload.entriesPerPage = entriesPerPage;
        setPage(1);
        setSrNo(0);
      }
    } else {
      delete payload.product_id;
      delete payload.operation_id;
      delete payload.machine_id;
      delete payload.operator_id;
      delete payload.lotNo;
      delete payload.work_order_no;
      delete payload.job_card_no;
      delete payload.shift;
      delete payload.actualMoreThanPlanned;
    }

    // console.log("payload", payload);
    // console.log(JSON.stringify(payload.camshaftType));

    getProductionData(payload).then(
      (res) => {
        // console.log("res", res);
        setProductionList({
          ...productionList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });

        if (
          (filterConfig.product_Name?.length > 0) ||
          (filterConfig.operation_Name?.length > 0) ||
          (filterConfig.machine_Name?.length > 0) ||
          (filterConfig.operator_name?.length > 0) ||
          (filterConfig.lot_no !== "" && filterConfig.lot_no?.length > 0) ||
          (filterConfig.work_order_no !== "" && filterConfig.work_order_no?.length > 0) ||
          (filterConfig.job_card_no !== "" && filterConfig.job_card_no?.length > 0) ||
          filterConfig.actualMoreThanPlanned !== false
        ) {
          setSea_val(true);
        } else {
          setSea_val(false);
        }

        // console.log("ma", calMaxPage(parseInt(res.data.total)));
        setMaxPage(calMaxPage(res.data.total));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        console.log("err", err);
        // toast.error(err.message, {
        //   autoClose: 2000,
        // });
        setProductionList({ ...productionList, loading: false, data: [] });
      }
    );
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);

    setTimeout(() => {
      fetchOperationList();
      fetchOperatorList()
      fetchProdList();
    }, [2000]);

    fetchMachineList();
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  const delProdData = (val) => {
    // console.log("val", val);
    SweetAlert.fire({
      // title: "Are you sure to delete \n" + val.camshaft_type + " ?",
      title: "Are you sure to delete ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteProductionData({ uuid: val.uuid }).then(
          (res) => {
            // console.log("res", res);
            toast.success("Production data deleted successfully", {
              autoClose: 2000,
            });

            if (productionList.data.length == 1 && page > 1) {
              fetchProductionData(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchProductionData(page, entriesPerPage);
            }
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  function handleClick(ind) {
    // console.log("ind", ind);

    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  const handleFromDateChange = (date) => {
    if (endDate && date > endDate) {
      return;
    }
    setStartDate(date);
  };

  const handleToDateChange = (date) => {
    if (startDate && date < startDate) {
      return;
    }
    setEndDate(date);
  };

  async function goToDownload() {
    setLockBtn(true);
    // console.log("inside download excel", filterConfig);
    const payload = {
      startDate: convertDateYYMMDD(new Date(startDate), "-"),
      endDate: convertDateYYMMDD(new Date(endDate), "-"),
    };

    if (filterConfig.product_Name.length > 0) {
      payload.product_id = filterConfig.product_Name?.[0]?.uuid;
    } else {
      delete payload.product_id;
    }
    if (filterConfig.operation_Name.length > 0) {
      // payload.operation_id = filterConfig.operation_Name?.[0]?.uuid;
      payload.operation_id = filterConfig.operation_Name?.[0]?.operationID;
    } else {
      delete payload.operation_id;
    }

    if (filterConfig.machine_Name.length > 0) {
      payload.machine_id = filterConfig.machine_Name?.[0]?.uuid;
    } else {
      delete payload.machine_id;
    }

    if (filterConfig.lot_no.length > 0) {
      // payload.lotNo = `["${filterConfig.lot_no[0]}"]`; //Only sent 1st index value for search
      payload.lotNo = filterConfig.lot_no[0]; //Only sent 1st index value for search
      // console.log("payload.lotNo", payload.lot_no);
    } else {
      delete payload.lotNo;
    }

    if (filterConfig.work_order_no.length > 0) {
      // payload.work_order_no = `["${filterConfig.work_order_no[0]}"]`; //Only sent 1st index value for search
      payload.work_order_no = filterConfig.work_order_no[0]; //Only sent 1st index value for search
      // console.log("payload.work_order_no", payload.work_order_no);
    } else {
      delete payload.work_order_no;
    }

    if (filterConfig.job_card_no.length > 0) {
      // payload.job_card_no = `["${filterConfig.job_card_no[0]}"]`; //Only sent 1st index value for search
      payload.job_card_no = filterConfig.job_card_no[0]; //Only sent 1st index value for search
      // console.log("payload.job_card_no", payload.job_card_no);
    } else {
      delete payload.job_card_no;
    }

    if (filterConfig.operator_name.length > 0) {
      payload.operator_id = filterConfig.operator_name?.[0]?.uuid;
    } else {
      delete payload.operator_id;
    }

    // await getProductionData(payload).then(
    await getProductionDataExcel(payload).then(
      (res) => {
        // console.log("res", res);
        if (res) {
          base64ToExcel(res.data);
        }
      },
      (err) => {
        console.log("err", err);
        toast.error(err.message, {
          autoClose: 2000,
        });
        setLockBtn(false);
      }
    );
  }

  function base64ToExcel(response) {
    const { statusCode, headers, body, isBase64Encoded } = response;

    // Assume base64String contains the base64 representation of the Excel file
    const base64String = body; // Replace this with your base64 string

    // Convert the base64 string into a Uint8Array
    const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));

    // Create a Blob object from the Uint8Array
    const blob = new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a link element with download attribute
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toDateString().split(" ").join("_");
    link.download = `ProductionReport_${currentDate}.xlsx`;

    // Programmatically click on the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Cleanup: remove the link and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    setLockBtn(false);
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);

      fetchOperationList();
      fetchOperatorList()
      fetchProdList();
      fetchMachineList();
    }
  }

  const handleCheckboxChange = () => {
    // Toggle the isChecked state when the checkbox is changed
    let val = !isChecked;
    if (val) {
      setFilterConfig({ ...filterConfig, actualMoreThanPlanned: true });
    } else {
      setFilterConfig({ ...filterConfig, actualMoreThanPlanned: false });
    }
    setIsChecked(!isChecked);
  };

  return (
    <div className="min-vh-100" id="root_div_main">
      {visible ? (
        <EditProductionData
          visible={visible}
          fetchProductionData={fetchProductionData}
          entriesPerPage={entriesPerPage}
          setVisible={setVisible}
          val={selItem2}
        />
      ) : null}
      {galVisible ? (
        <ImageGallery
          galVisible={galVisible}
          fetchProductionData={fetchProductionData}
          page={page}
          entriesPerPage={entriesPerPage}
          setGalVisible={setGalVisible}
          val={selItem2}
        />
      ) : null}

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />

      <div id="mySidebar" className="customsidebar ">
        <a className="closebtn" onClick={() => toggleNav()}>
          ×
        </a>

        <div className=" content">
          <div className="filter row">
            <label
              className="filterLabel mb-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {constLabel?.lbl_date_range
                ? constLabel.lbl_date_range
                : "Date Range"}
            </label>
            <div className="fromDate col-12 col-md-6">
              <DatePicker
                id="fromDate"
                className="form-control form-control-sm"
                selected={startDate}
                showYearDropdown
                scrollableMonthYearDropdown
                dateFormat="dd/MM/yyyy"
                placeholderText="From Date"
                // onChange={(date) => setStartDate(date)}
                onChange={(date) => handleFromDateChange(date)}
                maxDate={new Date()}
              />
            </div>

            <div className="toDate col-12 col-md-6">
              <DatePicker
                id="toDate"
                className="form-control form-control-sm"
                showYearDropdown
                scrollableMonthYearDropdown
                selected={endDate}
                dateFormat="dd/MM/yyyy"
                placeholderText="To Date"
                // onChange={(date) => setEndDate(date)}
                onChange={(date) => handleToDateChange(date)}
                maxDate={new Date()}
              />
            </div>
          </div>

          <div>
            <label
              className="filterLabel my-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {constLabel?.lbl_product ? constLabel.lbl_product : "Product"}
            </label>
            <Typeahead
              id="basic-typeahead-single"
              labelKey="productName"
              multiple={false}
              options={dataList.data}
              isLoading={dataList.loading}
              selected={filterConfig.product_Name}
              onChange={(val) => {
                // console.log("product_Name val", val);
                if (val.length > 0) {
                  // console.log(val);
                  setFilterConfig({ ...filterConfig, product_Name: val });
                } else {
                  setFilterConfig({ ...filterConfig, product_Name: [] });
                }
              }}
              placeholder="Select product name "
            />
          </div>

          <div>
            <label
              className="filterLabel my-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {constLabel?.lbl_operation
                ? constLabel.lbl_operation
                : "Operation"}
            </label>
            <Typeahead
              id="basic-typeahead-single"
              labelKey="operationName"
              multiple={false}
              options={opList.data}
              isLoading={opList.loading}
              selected={filterConfig.operation_Name}
              onChange={(val) => {
                // console.log("operationName val", val);
                if (val.length > 0) {
                  // console.log(val);
                  setFilterConfig({ ...filterConfig, operation_Name: val });
                } else {
                  setFilterConfig({ ...filterConfig, operation_Name: [] });
                }
              }}
              placeholder="Select operation name "
            />
          </div>

          <div>
            <label
              className="filterLabel my-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {constLabel?.lbl_machine ? constLabel.lbl_machine : "Machine"}
            </label>
            <Typeahead
              id="basic-typeahead-single"
              labelKey="machineNameSerialNo"
              multiple={false}
              options={machineList.data}
              isLoading={machineList.loading}
              selected={filterConfig.machine_Name}
              onChange={(val) => {
                // console.log("machineNameSerialNo val", val);
                if (val.length > 0) {
                  // console.log(val);
                  setFilterConfig({ ...filterConfig, machine_Name: val });
                } else {
                  setFilterConfig({ ...filterConfig, machine_Name: [] });
                }
              }}
              placeholder="Select machine name "
            />
          </div>

          <div>
            <label
              className="filterLabel my-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {constLabel?.lbl_operator
                ? constLabel.lbl_operator
                : "Operator"}
            </label>
            <Typeahead
              id="basic-typeahead-single"
              labelKey="operator_name"
              multiple={false}
              options={operatoList.data}
              isLoading={operatoList.loading}
              selected={filterConfig.operator_name}
              onChange={(val) => {
                // console.log("operationName val", val);
                if (val.length > 0) {
                  // console.log(val);
                  setFilterConfig({ ...filterConfig, operator_name: val });
                } else {
                  setFilterConfig({ ...filterConfig, operator_name: [] });
                }
              }}
              placeholder="Select operator name "
            />
          </div>

          <div className="mt-2">
            <label
              className="filterLabel mb-1 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {constLabel?.lbl_enterLotNo
                ? constLabel.lbl_enterLotNo
                : "Enter Lot No"}
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Search by lot no"
              value={
                filterConfig.lot_no.length > 0 ? filterConfig.lot_no[0] : ""
              }
              onChange={(val) => {
                const inputValue = val.target.value;
                if (!inputValue.trim()) {
                  // console.log("inputValue", inputValue);
                  // Input contains only whitespace, don't trigger the search.
                  if (filterConfig.lot_no.length == 1) {
                    setFilterConfig({ ...filterConfig, lot_no: [] });
                  }
                } else {
                  // console.log("lot no", inputValue);
                  setFilterConfig({ ...filterConfig, lot_no: [inputValue] });
                }
              }}
            />
          </div>

          <div className="mt-2">
            <label
              className="filterLabel mb-1 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {constLabel?.lbl_enterWorkOrderNo
                ? constLabel.lbl_enterWorkOrderNo
                : "Enter Work Order No"}
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Search by work order no"
              value={
                filterConfig.work_order_no.length > 0 ? filterConfig.work_order_no[0] : ""
              }
              onChange={(val) => {
                const inputValue = val.target.value;
                if (!inputValue.trim()) {
                  // console.log("inputValue", inputValue);
                  // Input contains only whitespace, don't trigger the search.
                  if (filterConfig.work_order_no.length == 1) {
                    setFilterConfig({ ...filterConfig, work_order_no: [] });
                  }
                } else {
                  // console.log("work order no", inputValue);
                  setFilterConfig({ ...filterConfig, work_order_no: [inputValue] });
                }
              }}
            />
          </div>

          <div className="mt-2">
            <label
              className="filterLabel mb-1 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {constLabel?.lbl_enterJobCardNo
                ? constLabel.lbl_enterJobCardNo
                : "Enter Job Card No"}
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Search by job card no"
              value={
                filterConfig.job_card_no.length > 0 ? filterConfig.job_card_no[0] : ""
              }
              onChange={(val) => {
                const inputValue = val.target.value;
                if (!inputValue.trim()) {
                  // console.log("inputValue", inputValue);
                  // Input contains only whitespace, don't trigger the search.
                  if (filterConfig.job_card_no.length == 1) {
                    setFilterConfig({ ...filterConfig, job_card_no: [] });
                  }
                } else {
                  // console.log("work order no", inputValue);
                  setFilterConfig({ ...filterConfig, job_card_no: [inputValue] });
                }
              }}
            />
          </div>

          <div>
            <label
              className="filterLabel my-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {constLabel?.lbl_shift ? constLabel.lbl_shift : "Shift"}
            </label>
            <Typeahead
              id="basic-typeahead-single"
              labelKey="shiftName"
              multiple={false}
              options={workShift.data}
              isLoading={workShift.loading}
              selected={filterConfig.shift}
              onChange={(val) => {
                // console.log("shift val", val);
                if (val.length > 0) {
                  // console.log(val);
                  setFilterConfig({ ...filterConfig, shift: val });
                } else {
                  setFilterConfig({ ...filterConfig, shift: [] });
                }
              }}
              placeholder="Select shift"
            />
          </div>

          <div>
              <div className="form-check form-switch mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="flexSwitchCheckChecked"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                <label
                  className="form-check-label lbl-style roboto-bold"
                  htmlFor="flexSwitchCheckChecked"
                >
                  {"Actual Qty more than Planned Qty"}
                </label>
              </div>
            </div>

          <div className="my-3 d-flex justify-content-end">
            <button
              onClick={() => clearFilter()}
              className="btn btn-sm btn-yellow "
            >
              <FontAwesomeIcon
                style={{ color: "#344454" }}
                icon={faBan}
                size="sm"
              />{" "}
              {constLabel?.lbl_clear_filter
                ? constLabel.lbl_clear_filter
                : "Clear Filter"}
            </button>
          </div>
        </div>
      </div>
      <div className="production_data">
        <div className="content-wrapper">
            <div className="card border-0">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="d-md-flex justify-content-start col-12 col-md-6">
                    <Tooltip id={"open-tooltip"} place="top" />
                    <img
                      src={Slide}
                      height="32"
                      width="32"
                      className="cursor_pointer my-1 me-2"
                      onClick={() => toggleNav()}
                      data-tooltip-id={"open-tooltip"}
                      data-tooltip-content={"Sidebar"}
                    />
                    <h1 className="bold">
                      {constLabel?.lbl_production_data
                        ? constLabel.lbl_production_data
                        : "Production Data"}
                    </h1>
                  </div>

                  <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                    {privilage.report && (
                      <button
                        className="btn btn-light-grey me-0 me-2 ms-0 ms-md-2"
                        disabled={lockBtn}
                        onClick={() => goToDownload()}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faDownload}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_production_report
                            ? constLabel.lbl_production_report
                            : "Production Report"}{" "}
                          {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
                      </button>
                    )}

                    {privilage.write && (
                      <button
                        className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                        onClick={() => {
                          navigate("/pcms/create/production-data");
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_create_production_data
                          ? constLabel.lbl_create_production_data
                          : "Create Production Data"}
                      </button>
                    )}

                    <Tooltip id={"show-tooltip"} place="top" />
                    <div
                      className="dropdown"
                      data-tooltip-id={"show-tooltip"}
                      data-tooltip-content={"Show / Hide Columns"}
                    >
                      <div
                        className="d-md-flex justify-content-start align-items-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <FontAwesomeIcon icon={faListCheck} size="lg" /> */}
                        {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                        <img
                          src={List}
                          height="32"
                          width="32"
                          className="cursor_pointer"
                        />
                      </div>

                      <ul className="dropdown-menu px-2">
                        {showLabel !== undefined && showLabel.length > 0
                          ? showLabel.map((val, ind, arr) => (
                              <li key={ind}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    style={{ width: "20px", height: "20px" }}
                                    onChange={() => handleClick(ind)}
                                    value=""
                                    checked={val.flag}
                                  />
                                  <label
                                    className=""
                                    style={{ fontSize: "13px" }}
                                    htmlFor="flexCheckDefault"
                                  >
                                    {val.label}
                                  </label>
                                </div>
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  {productionList.loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : productionList.data?.length > 0 ? (
                    <div className="">
                      <table className="table table-responsive table-bordered">
                        <thead className="table-grey roboto-bold">
                          <tr className="">
                            <th scope="col">{"SrNo"}</th>
                            {/* <th scope="col">{"Tag No"}</th> */}
                            {showLabel.map((v, i) =>
                              v.label === "Work Order No" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Work Order No"}
                                </th>
                              ): v.label === "Work Order Qty" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Work Order Qty"}
                                </th>
                              ): v.label === "Job Card No" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Job Card No"}
                                </th>
                              ) : v.label === "Lot No" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Lot No"}
                                </th>
                              ) : v.label === "Lot Size" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Lot Size"}
                                </th>
                              ) : v.label === "Product Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Product Name"}
                                </th>
                              ) : v.label === "Part No" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Part No"}
                                </th>
                              ) : v.label === "Operation" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Operation"}
                                </th>
                              ) : v.label === "Machine Id" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Machine Id"}
                                </th>
                              ) : v.label === "Operator Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Operator Name"}
                                </th>
                              ) :
                                v.label === "Start Date" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Start Date"}
                                  </th>
                                ) : v.label === "End Date" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"End Date"}
                                  </th>
                                ) : v.label === "Shift" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Shift"}
                                  </th>
                                ) : v.label === "No of Hours Worked" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"No of Hours Worked"}
                                  </th>
                                ) : v.label === "Planned Qty" &&
                                  v.flag ? (
                                  <th key={i} scope="col">
                                    {"Planned Qty"}
                                  </th>
                                ) : v.label === "Produced Qty" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Produced Qty"}
                                  </th>
                                ) : v.label === "Rejected Qty" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Rejected Qty"}
                                  </th>
                                ) : v.label === "Remark" && v.flag ? (
                                  <th key={i} scope="col">
                                    {"Remark"}
                                  </th>
                                ) : null
                            )}

                            <th scope="col" width="15%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="roboto">
                          {productionList.data.map((val, i) => (
                            <tr key={i}>
                              <td style={{ verticalAlign: "middle" }}>
                                {parseInt(i) + 1 + parseInt(srNo)}
                              </td>
                              {showLabel.map((item, idx) =>
                                item.label === "Work Order No" && item.flag ? (
                                  <td key={idx}>
                                    {val.work_order_no !== "" && val.work_order_no !== null
                                      ?
                                      <Link to="/pcms/update-work-order" state={val.WO_details} >{getShortReadableId(val.work_order_no)}</Link>
                                      : "NA"}
                                  </td>
                                ): item.label === "Work Order Qty" && item.flag ? (
                                  <td key={idx}>
                                    {val.WO_details !== "" && val.WO_details !== null
                                      ?val.WO_details.quantity : "NA"}
                                  </td>
                                ) : item.label === "Job Card No" && item.flag ? (
                                  <td key={idx}>
                                    {val.job_card_no !== "" && val.job_card_no !== null
                                      ?
                                      <Link onClick={() => setJobCardModal({
                                        ...jobCardModal,
                                        state: true, data: val.jobcard
                                      })}>{getShortReadableId(val.job_card_no)}</Link>
                                      : "NA"}
                                  </td>
                                ) : item.label === "Lot No" && item.flag ? (
                                  <td key={idx}>
                                    {val.lotNo !== "" && val.lotNo !== null
                                      ? val.lotNo
                                      : "NA"}
                                  </td>
                                ) : item.label === "Lot Size" && item.flag ? (
                                  <td key={idx}>
                                    {val.lot_size !== "" && val.lot_size !== null
                                      ? val.lot_size
                                      : "NA"}
                                  </td>
                                ) : item.label === "Product Name" && item.flag ? (
                                  <td key={idx}>
                                    {val?.product_id == null &&
                                      (val?.productName == "" ||
                                        val?.productName == null)
                                      ? "NA"
                                      : val?.product_id?.productName
                                        ? `${val?.product_id?.productName}`
                                        : `${val?.productName}`}
                                  </td>
                                ) : item.label === "Part No" && item.flag ? (
                                  <td key={idx}>
                                    {val.part_no !== "" && val.part_no !== null
                                      ? val.part_no
                                      : "NA"}
                                  </td>
                                ) : item.label === "Operation" && item.flag ? (
                                  <td key={idx}>
                                    {val?.operation_id == null &&
                                      (val?.operation == "" ||
                                        val?.operation == null)
                                      ? "NA"
                                      : val?.operation_id?.operationName
                                        ? `${val?.operation_id?.operationName}`
                                        : `${val?.operation}`}
                                  </td>
                                ) : item.label === "Machine Id" && item.flag ? (
                                  <td key={idx}>
                                    {val?.machine_id == null &&
                                      (val?.machineId == "" ||
                                        val?.machineId == null)
                                      ? "NA"
                                      : val?.machine_id?.machineName
                                        ? `${val?.machine_id?.machineName} - ${val?.machine_id?.serialNo}`
                                        : `${val?.machineId}`}
                                  </td>
                                ) : item.label === "Operator Name" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.employee_uuid !== null
                                      ? val.employee_uuid?.FirstName +
                                      " " +
                                      val.employee_uuid?.LastName
                                      : val.workerName}
                                  </td>
                                ) : item.label === "Start Date" && item.flag ? (
                                  <td key={idx}>
                                    {formatDateString2(val.startDate_time)}
                                  </td>
                                ) : item.label === "End Date" && item.flag ? (
                                  <td key={idx}>
                                    {formatDateString2(val.endDate_time)}
                                  </td>
                                ) : item.label === "Shift" && item.flag ? (
                                  <td key={idx}>
                                    {val.shift !== "" && val.shift !== null
                                      ? val.shift
                                      : "NA"}
                                  </td>
                                ) : item.label === "No of Hours Worked" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.totalworking_hours !== "" &&
                                      val.totalworking_hours !== null
                                      ? val.totalworking_hours
                                      : "NA"}
                                  </td>
                                ) : item.label === "Planned Qty" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.std_report_qty !== "" &&
                                      val.std_report_qty !== null
                                      ? val.std_report_qty
                                      : "NA"}
                                  </td>
                                ) : item.label === "Produced Qty" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.produced_qty !== "" &&
                                      val.produced_qty !== null
                                      ? val.produced_qty
                                      : "NA"}
                                  </td>
                                ) : item.label === "Rejected Qty" && item.flag ? (
                                  <td key={idx}>
                                    {val.rejected_qty !== "" &&
                                      val.rejected_qty !== null
                                      ? val.rejected_qty
                                      : "NA"}
                                  </td>
                                ) : item.label === "Remark" && item.flag ? (
                                  <td key={idx}>
                                    {val.remark !== "" && val.remark !== null
                                      ? val.remark
                                      : "NA"}
                                  </td>
                                ) : null
                              )}

                              <td className="">
                                {loginData.loginData?.data?.response.role_name !==
                                  "client_admin" &&
                                  loginData.loginData?.data?.response.role_name !==
                                  "admin" ? (
                                  privilage.write == true ? (
                                    <>
                                      <Tooltip
                                        id={"edit-tooltip" + i}
                                        place="top"
                                      />
                                      <Edit
                                        data-tooltip-id={"edit-tooltip" + i}
                                        data-tooltip-content={
                                          constLabel?.lbl_edit_production_data
                                            ? constLabel.lbl_edit_production_data
                                            : "Edit Production Data"
                                        }
                                        className="menu_icon_grey cursor_pointer me-2"
                                        size={20}
                                        onClick={() => {
                                          navigate(
                                            "/pcms/update/production-data",
                                            {
                                              state: val,
                                            }
                                          );
                                        }}
                                      />
                                    </>
                                  ) : null
                                ) : (
                                  <>
                                    <Tooltip
                                      id={"edit-tooltip" + i}
                                      place="top"
                                    />
                                    <Edit
                                      data-tooltip-id={"edit-tooltip" + i}
                                      data-tooltip-content={
                                        constLabel?.lbl_edit_production_data
                                          ? constLabel.lbl_edit_production_data
                                          : "Edit Production Data"
                                      }
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20}
                                      onClick={() => {
                                        navigate("/pcms/update/production-data", {
                                          state: val,
                                        });
                                      }}
                                    />
                                  </>
                                )}

                                {loginData.loginData?.data?.response.role_name !==
                                  "client_admin" &&
                                  loginData.loginData?.data?.response.role_name !==
                                  "admin" ? (
                                  privilage.read == true ? (
                                    <>
                                      <Tooltip id={"view-tooltip" + i} place="top" />
                                      <Eye
                                        data-tooltip-id={"view-tooltip" + i}
                                        data-tooltip-content={
                                          constLabel?.lbl_quick_view
                                            ? constLabel.lbl_quick_view
                                            : "View Production Data"
                                        }
                                        className="menu_icon_grey cursor_pointer me-2"
                                        size={20}
                                        onClick={() => {
                                          navigate(`/pcms/view-production-data/${val.uuid}`,
                                            {
                                              state: val,
                                            });
                                        }}
                                      />
                                    </>
                                  ) : null
                                ) : (
                                  <>
                                    <Tooltip id={"view-tooltip" + i} place="top" />
                                    <Eye
                                      data-tooltip-id={"view-tooltip" + i}
                                      data-tooltip-content={
                                        constLabel?.lbl_quick_view
                                          ? constLabel.lbl_quick_view
                                          : "View Production Data"
                                      }
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20}
                                      onClick={() => {
                                        navigate(`/pcms/view-production-data/${val.uuid}`,
                                          {
                                            state: val,
                                          });
                                      }}
                                    />
                                  </>
                                )}

                                {loginData.loginData?.data?.response.role_name !==
                                  "client_admin" &&
                                  loginData.loginData?.data?.response.role_name !==
                                  "admin" ? (
                                  privilage.delete == true ? (
                                    <>
                                      <Tooltip
                                        id={"delete-tooltip" + i}
                                        place="top"
                                      />
                                      <Trash2
                                        data-tooltip-id={"delete-tooltip" + i}
                                        data-tooltip-content={
                                          constLabel?.lbl_delete_production_data
                                            ? constLabel.lbl_delete_production_data
                                            : "Delete Production Data"
                                        }
                                        className="menu_icon_red cursor_pointer"
                                        size={20}
                                        onClick={() => delProdData(val)}
                                      />
                                    </>
                                  ) : null
                                ) : (
                                  <>
                                    <Tooltip
                                      id={"delete-tooltip" + i}
                                      place="top"
                                    />
                                    <Trash2
                                      data-tooltip-id={"delete-tooltip" + i}
                                      data-tooltip-content={
                                        constLabel?.lbl_delete_production_data
                                          ? constLabel.lbl_delete_production_data
                                          : "Delete Production Data"
                                      }
                                      className="menu_icon_red cursor_pointer"
                                      size={20}
                                      onClick={() => delProdData(val)}
                                    />
                                  </>
                                )}

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p className="text-center text-danger mb-0">
                      {"No production data found !"}
                    </p>
                  )}

                  {productionList.loading ? null : productionList.data?.length >
                    0 ? (
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <p className="mb-0">
                        Show
                        <select
                          className="mx-1"
                          defaultValue={entriesPerPage}
                          onChange={(e) => {
                            setEntriesPerPage(e.target.value);
                            fetchProductionData(1, e.target.value);
                          }}
                        >
                          <option value={"10"}>10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        Entries
                      </p>
                      <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                        } to ${Math.min(
                          parseInt(entriesPerPage) + parseInt(srNo),
                          productionList.totalRecords
                        )} of ${productionList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchProductionData(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                              maxPage === 0 ||
                              entriesPerPage > productionList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            // console.log("parseInt(srNo) + parseInt(entriesPerPage)", parseInt(srNo) + parseInt(entriesPerPage));
                            fetchProductionData(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
        </div>
      </div>

      <Modal show={show2} onHide={() => setShow2(false)} size="lg">
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_nc_detail ? constLabel.lbl_nc_detail : "NC Detail"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-md-flex justify-content-between">
            <div>
              <label className="roboto-light nc_detail_label">
                {constLabel?.lbl_cam_shaft
                  ? constLabel.lbl_cam_shaft
                  : "Cam Shaft"}
              </label>
              <p className="roboto-bold">{selItem.camshaft_type}</p>
            </div>
            <div className="text-end">
              <label className="roboto-light nc_detail_label">
                {constLabel?.lbl_nc_tag ? constLabel.lbl_nc_tag : "NC Tag"}
              </label>
              <p className="roboto-bold">{selItem.nc_tag}</p>
            </div>
          </div>
          <div className="d-md-flex justify-content-between">
            <div>
              <label className="roboto-light nc_detail_label">
                {constLabel?.lbl_operator_name
                  ? constLabel.lbl_operator_name
                  : "Operator Name"}
              </label>
              <p className="roboto-bold">{selItem.operator}</p>
            </div>
            <div className="text-end">
              <label className="roboto-light nc_detail_label">
                {constLabel?.lbl_date ? constLabel.lbl_date : "Date"}
              </label>
              <p className="roboto-bold">{convertDate(selItem.nc_date, "-")}</p>
              {/* <p className="roboto-bold">{selItem.nc_date}</p> */}
            </div>
          </div>

          <div
            className="bg-lightgrey"
            style={{ padding: "12px", borderRadius: "12px" }}
          >
            <div className="row">
              <div className="col-12 col-md-6">
                <div>
                  <p className="m-0 nc_detail_label">
                    {constLabel?.lbl_job_no ? constLabel.lbl_job_no : `Job No`}
                  </p>
                  <p className="mb-2 roboto-bold">{selItem.job_no}</p>
                </div>
                <div>
                  <p className="m-0 nc_detail_label">
                    {constLabel?.lbl_machine
                      ? constLabel.lbl_machine
                      : `Machine`}
                  </p>
                  <p className="mb-2 roboto-bold">{`${selItem.machine_id_id?.machineName} - ${selItem.machine_id_id?.serialNo}`}</p>
                </div>

                <div>
                  <p className="m-0 nc_detail_label">
                    {constLabel?.lbl_root_cause_at_operation
                      ? constLabel.lbl_root_cause_at_operation
                      : `Root Cause At Operation`}
                  </p>
                  <p className="mb-2 roboto-bold">{`${selItem.operation ? selItem.operation : "NA"
                    }`}</p>
                </div>

                <div>
                  <p className="m-0 nc_detail_label">
                    {constLabel?.lbl_reason_for_nc
                      ? constLabel.lbl_reason_for_nc
                      : `Reason For NC`}
                  </p>
                  <p className="mb-2 roboto-bold">{`${selItem.ncreason ? selItem.ncreason : "NA"
                    }`}</p>
                </div>
                <div>
                  <p className="m-0 nc_detail_label">
                    {constLabel?.lbl_material_code
                      ? constLabel.lbl_material_code
                      : `Material Code`}
                  </p>
                  <p className="mb-2 roboto-bold">{`${selItem.camshaft_type_id?.material
                    ? selItem.camshaft_type_id?.material
                    : "NA"
                    }`}</p>
                </div>
              </div>
              <div className="col-12 col-md-6 ">
                <div>
                  <p className="m-0 nc_detail_label">
                    {constLabel?.lbl_lot_size
                      ? constLabel.lbl_lot_size
                      : `Lot Size`}
                  </p>
                  {/* <p className='mb-2 roboto-bold'>{selItem.qty ? selItem.qty : "NA"}</p> */}
                  <p className="mb-2 roboto-bold">{`${selItem.lot_size ? selItem.lot_size : "NA"
                    }`}</p>
                </div>

                <div>
                  <p className="m-0 nc_detail_label">
                    {constLabel?.lbl_lot_no ? constLabel.lbl_lot_no : `Lot No`}
                  </p>
                  <p className="mb-2 roboto-bold">{selItem.lot_no}</p>
                </div>
                <div>
                  <p className="m-0 nc_detail_label">
                    {constLabel?.lbl_root_cause
                      ? constLabel.lbl_root_cause
                      : `Root Cause`}
                  </p>
                  <p className="mb-2 roboto-bold">{`${selItem.remark ? selItem.remark : "NA"
                    }`}</p>
                </div>

                <div>
                  <p className="m-0 nc_detail_label">
                    {constLabel?.lbl_quantity
                      ? constLabel.lbl_quantity
                      : `Quantity`}
                  </p>
                  {/* <p className='mb-2 roboto-bold'>{`${selItem.lot_size ? selItem.lot_size : "NA"}`}</p> */}
                  <p className="mb-2 roboto-bold">
                    {selItem.qty ? selItem.qty : "NA"}
                  </p>
                </div>

                <div>
                  <p className="m-0 nc_detail_label">
                    {constLabel?.lbl_operation_inWhich_nc_detected
                      ? constLabel.lbl_operation_inWhich_nc_detected
                      : `Operation in which NC Detected`}
                  </p>
                  <p className="mb-2 roboto-bold">{`${selItem.operation_inwhich ? selItem.operation_inwhich : "NA"
                    }`}</p>
                </div>
              </div>
            </div>
          </div>
          <hr className="mb-1 mt-4" />
          <div className="d-md-flex justify-content-between">
            <p className="roboto-bold mb-0" style={{ fontSize: "18px" }}>
              {constLabel?.lbl_nc_history
                ? constLabel.lbl_nc_history
                : "NC History"}
            </p>
          </div>
          <hr className="my-1" />
          <div className="" style={{ padding: "12px", borderRadius: "12px" }}>
            <div className="row">
              {selItem?.nc_history?.length > 0 ? (
                <div className="po_revision_history">
                  <div className="po_line"></div>

                  <div
                    className="row mx-auto"
                    style={{
                      marginTop: "-10px",
                      width: "100%",
                      overflowX: "auto",
                      fontSize: "14px",
                    }}
                  >
                    <div className="d-flex pb-2">
                      {selItem?.nc_history
                        .sort((a, b) => {
                          const dateA = new Date(a.updated_at);
                          const dateB = new Date(b.updated_at);
                          return dateB - dateA;
                        })
                        ?.map((val, ind, arr) => (
                          <div
                            className="mx-4 text-center "
                            key={ind}
                            style={{ width: "250px" }}
                          >
                            <FontAwesomeIcon
                              icon={faCircle}
                              style={{
                                fontSize: "14px",
                                color: "#4E7DAE",
                              }}
                            />

                            <div
                              className="card border-0 mt-3 cursor_pointer"
                              style={{
                                background: "honeydew",
                                minWidth: "250px",
                              }}
                            >
                              <div className="card-body">
                                <div className="row">
                                  <div className="col">
                                    <p
                                      className=" roboto-bold"
                                      style={{
                                        textAlign: "left",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {constLabel?.lbl_status
                                        ? constLabel.lbl_status
                                        : "Status"}
                                    </p>
                                  </div>
                                  <div className="col">
                                    <p
                                      className=" roboto-bold"
                                      style={{
                                        textAlign: "left",
                                        color: "#9BA4B4",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {val.status}
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <p
                                      className=" roboto-bold"
                                      style={{
                                        textAlign: "left",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {constLabel?.lbl_status_upadted
                                        ? constLabel.lbl_status_upadted
                                        : "Status updated"}
                                    </p>
                                  </div>
                                  <div className="col">
                                    <p
                                      className=" roboto-bold"
                                      style={{
                                        textAlign: "left",
                                        fontSize: "12px",
                                        color: "#9BA4B4",
                                      }}
                                    >
                                      {val.status_update_date}
                                    </p>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col">
                                    <p
                                      className=" roboto-bold"
                                      style={{
                                        textAlign: "left",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {constLabel?.lbl_part_no
                                        ? constLabel.lbl_part_no
                                        : "Part No"}
                                    </p>
                                  </div>
                                  <div className="col">
                                    <p
                                      className="col roboto-bold"
                                      style={{
                                        textAlign: "left",
                                        fontSize: "12px",
                                        color: "#9BA4B4",
                                      }}
                                    >
                                      {val.part_no}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="alert alert-danger mb-0 text-center my-1"
                  role="alert"
                >
                  {"NC history not available !"}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-grey px-4" onClick={() => setShow2(false)}>
            {constLabel?.lbl_close ? constLabel.lbl_close : "Close"}
          </button>
        </Modal.Footer>
      </Modal>


      <Modal show={jobCardModal.state} onHide={() => setJobCardModal({ ...jobCardModal, state: false, data: null })} size="lg">
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_job_card_detail ? constLabel.lbl_nc_detail : "Job Card Detail"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="selected_prod_data row m-2 p-3">
            <div className="col-12 col-md-4">
              <p className="prod_data_label m-0">Product</p>
              <p className="roboto-bold mb-2 mt-0">{jobCardModal.data?.productDetail.productName}</p>
            </div>

            <div className="col-12 col-md-4">
              <p className="prod_data_label m-0">Job card number</p>
              <p className="roboto-bold mb-2 mt-0">{getShortReadableId(jobCardModal.data?.job_card_no)}</p>
            </div>

            <div className="col-12 col-md-4">
              <p className="prod_data_label m-0">Status</p>
              <p className="roboto-bold mb-2 mt-0">{jobCardModal.data?.status}</p>
            </div>


            <div className="col-12 col-md-4">
              <p className="prod_data_label m-0">Actual Quantity</p>
              <p className="roboto-bold mb-2 mt-0">{jobCardModal.data?.actual_qty}</p>
            </div>

            <div className="col-12 col-md-4">
              <p className="prod_data_label m-0">Cycle Time</p>
              <p className="roboto-bold mb-2 mt-0">{`${jobCardModal.data?.cycle_time}`}</p>
            </div>

            <div className="col-12 col-md-4">
              <p className="prod_data_label m-0">Actual Cycle Time</p>
              <p className="roboto-bold mb-2 mt-0">{jobCardModal.data?.actual_cycle_time}</p>
            </div>

            <div className="col-12 col-md-4">
              <p className="prod_data_label m-0">Efficiency</p>
              <p className="roboto-bold mb-2 mt-0">{jobCardModal.data?.efficiency}</p>
            </div>

            <div className="col-12 col-md-4">
              <p className="prod_data_label m-0">Machine</p>
              <p className="roboto-bold mb-2 mt-0">{jobCardModal.data?.machineDetails.machineName}</p>
            </div>

            <div className="col-12 col-md-4">
              <p className="prod_data_label m-0">Operator</p>
              <p className="roboto-bold mb-2 mt-0">{jobCardModal.data?.operatorDetails.FirstName + " " + jobCardModal.data?.operatorDetails.LastName}</p>
            </div>

            <div className="col-12 col-md-4">
              <p className="prod_data_label m-0">Operation</p>
              <p className="roboto-bold mb-2 mt-0">{jobCardModal.data?.productOperDetails.operationname}</p>
            </div>

            <div className="col-12 col-md-4">
              <p className="prod_data_label m-0">Start Time</p>
              <p className="roboto-bold mb-2 mt-0">
                {jobCardModal.data !== null ?
                  formatUTCDate(jobCardModal.data?.start_time) : ""}</p>
            </div>
            <div className="col-12 col-md-4">
              <p className="prod_data_label m-0">End Time</p>
              <p className="roboto-bold mb-2 mt-0">
                {jobCardModal.data !== null ?
                  formatUTCDate(jobCardModal.data?.end_time) : ""}</p>
            </div>

            <div className="col-12 col-md-6">
              <p className="prod_data_label m-0">Remarks</p>
              <p className="roboto-bold mb-2 mt-0">{jobCardModal.data?.remarks ?? "NA"}</p>
            </div>

          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <button className="btn btn-grey px-4" onClick={() => setShow2(false)}>
            {constLabel?.lbl_close ? constLabel.lbl_close : "Close"}
          </button>
        </Modal.Footer> */}
      </Modal>
    </div >
  );
}

export default ProductionData;
