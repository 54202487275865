import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert2";
import {
  faBan,
  faCog,
  faFilter,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  deleteTask,
  getTask,
  getMachineList,
  getStatusMaster,
  fetch_Supp_Customer,
} from "../../../api";
import {
  calMaxPage,
  calMaxPage_new,
  convertDate,
  getLabels,
  setAccessPrivilage,
} from "../../../common/Functions/CommonFunctions";
import { Pagination } from "react-bootstrap";
import { Trash2, Edit2, Edit } from "react-feather";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { Task_Col_Name } from "../../../common/Labels/labelConstant";
import { useSelector } from "react-redux";
import List from "../../../assets/images/icons/project_menu/checklist.png";
import Slide from "../../../assets/images/icons/project_menu/collapse.png";

function TaskMaster() {
  let navigate = useNavigate();
  const [showLabel, setShowLabel] = useState(Task_Col_Name);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const [machineList, setMachineList] = useState({ data: [], loading: false });
  const [suppList, setSuppList] = useState({ data: [], loading: false });
  const [task_status, setTask_Status] = useState({ data: [], loading: false });

  const [constLabel, setConstLabel] = useState({});
  const [sea_val, setSea_val] = useState(false);
  const [filterConfig, setFilterConfig] = useState({
    machine_id: [],
    status: [],
    serviced_by: [],
    searchVal: "",
  });

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage, setPrivilage] = useState(
    setAccessPrivilage(loginData, "Task Master")
  );

  const { setValue, control, watch } = useForm({ mode: "onChange" }); // initialise the hook

  // useEffect(() => {
  //   fetchTaskList(1, entriesPerPage);
  //   fetchMachine();
  // }, []);

  useEffect(() => {
    /*
      # Niranjan 04-12-2023
      # Get Module Name and Screen List by compare Module Name
      # Get Screen Name and Label List by compare Screen Name
    */
    setConstLabel(getLabels("Master", "Task"));
    fetchMachine();
    fetchSupplier();
    fetchStatus();
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchTaskList(1, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function fetchMachine() {
    setMachineList({ ...machineList, loading: true });

    getMachineList().then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.machineName !== null
        );
        setMachineList({ ...machineList, data: result, loading: false });
      },
      (err) => {
        setMachineList({ ...machineList, data: [], loading: false });
      }
    );
  }

  function fetchSupplier() {
    setSuppList({ ...suppList, data: [], loading: true });

    fetch_Supp_Customer({ partyType: "Supplier" }).then(
      (res) => {
        setSuppList({ ...suppList, data: res.data.data, loading: false });
      },
      (err) => {
        setSuppList({ ...suppList, data: [], loading: false });
      }
    );
  }

  function fetchStatus() {
    setTask_Status({ ...task_status, data: [], loading: true });
    getStatusMaster().then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.model_name === "task"
        );
        setTask_Status({ ...task_status, data: result, loading: false });
      },
      (err) => {
        setTask_Status({ ...task_status, data: [], loading: false });
      }
    );
  }

  function fetchTaskList(pg, epp, search) {
    setDataList({ ...dataList, loading: true, data: [] });

    let payload = {
      pageNo: pg,
      entriesPerPage: epp,
    };

    // if (filterConfig.machine_id.length > 0) {
    //   payload.machine_id = filterConfig.machine_id[0].uuid; // set machine uuid to the search field
    // } else {
    //   delete payload.machine_id;
    // }

    // if (filterConfig.serviced_by.length > 0) {
    //   payload.serviced_by = filterConfig.serviced_by[0].uuid; // set supplier uuid to the search field
    // } else {
    //   delete payload.serviced_by;
    // }

    // if (filterConfig.status.length > 0) {
    //   payload.status = filterConfig.status[0].status_name; // set status name to the search field
    // } else {
    //   delete payload.status;
    // }

    // if (filterConfig.searchVal.length > 0) {
    //   payload.searchVal = filterConfig.searchVal[0]; //Only sent 1st index value for serarch
    // } else {
    //   delete payload.searchVal;
    // }

    if (
      filterConfig.machine_id.length > 0 ||
      filterConfig.serviced_by.length > 0 ||
      filterConfig.status.length > 0 ||
      filterConfig.searchVal !== ""
    ) {
      payload.machine_id = filterConfig.machine_id[0]?.uuid;
      payload.serviced_by = filterConfig.serviced_by[0]?.uuid;
      payload.status = filterConfig.status[0]?.status_name;
      payload.searchVal = filterConfig.searchVal;
      if (
        (filterConfig.machine_id?.length > 0 ||
          filterConfig.serviced_by?.length > 0 ||
          filterConfig.status?.length > 0 ||
          filterConfig.searchVal?.length > 0) &&
        page !== 1 &&
        sea_val == false
      ) {
        payload.pageNo = 1;
        payload.entriesPerPage = entriesPerPage;
        setPage(1);
        setSrNo(0);
      }
    } else {
      delete payload.machine_id;
      delete payload.serviced_by;
      delete payload.status;
      delete payload.searchVal;
    }

    getTask(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });

        if (
          (filterConfig.machine_id?.length > 0) || 
          (filterConfig.serviced_by?.length > 0) ||
          (filterConfig.status?.length > 0) ||
          (filterConfig.searchVal !== "" && filterConfig.searchVal?.length > 0)
        ) {
          setSea_val(true);
        } else {
          setSea_val(false);
        }

        setMaxPage(calMaxPage_new(res.data.total, epp));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({
          ...dataList,
          loading: false,
          data: [],
          totalRecords: "",
        });
      }
    );
  }

  function clearFilter() {
    setFilterConfig({
      ...filterConfig,
      machine_id: [],
      status: [],
      serviced_by: [],
      searchVal: "",
    });
    setSrNo(0);
    setPage(1);
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function delTask(val) {
    SweetAlert.fire({
      title: "Are you sure to delete task ? \n",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = { uuid: val.uuid };
        deleteTask(payload).then(
          (res) => {
            toast.success("Task deleted successfully", {
              autoClose: 2000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchTaskList(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchTaskList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="min-vh-100" id="root_div_main">
        {/* <NavbarComp2 /> */}

        <div id="mySidebar" className="customsidebar">
          <a className="closebtn" onClick={() => toggleNav()}>
            ×
          </a>

          <div className=" content">
            <div>
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selMachine
                  ? constLabel.lbl_selMachine
                  : "Select Machine"}
              </label>

              <Typeahead
                id="basic-typeahead-single"
                labelKey="machineName"
                name="machine"
                multiple={false}
                // size="sm"
                options={machineList.data}
                isLoading={machineList.loading}
                selected={filterConfig.machine_id}
                onChange={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, machine_id: val });
                  } else {
                    setFilterConfig({ ...filterConfig, machine_id: [] });
                  }
                }}
                placeholder="Search by machine"
              />
            </div>

            <div className="mt-2">
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selSupplier
                  ? constLabel.lbl_selSupplier
                  : "Select Supplier"}
              </label>

              <Typeahead
                id="basic-typeahead-single"
                labelKey="name"
                name="supplier"
                multiple={false}
                // size="sm"
                options={suppList.data}
                isLoading={suppList.loading}
                selected={filterConfig.serviced_by}
                onChange={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, serviced_by: val });
                  } else {
                    setFilterConfig({ ...filterConfig, serviced_by: [] });
                  }
                }}
                placeholder="Search serviced by"
              />
            </div>

            <div className="mt-2">
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selStatus
                  ? constLabel.lbl_selStatus
                  : "Select Status"}
              </label>

              <Typeahead
                id="basic-typeahead-single"
                labelKey="status_name"
                name="status"
                multiple={false}
                // size="sm"
                options={task_status.data}
                isLoading={task_status.loading}
                selected={filterConfig.status}
                onChange={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, status: val });
                  } else {
                    setFilterConfig({ ...filterConfig, status: [] });
                  }
                }}
                placeholder="Search by status"
              />
            </div>

            <div className="mt-2">
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_task_name
                  ? constLabel.lbl_task_name
                  : "Task Name"}
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by task name"
                value={filterConfig.searchVal}
                onChange={(val) => {
                  const inputValue = val.target.value;
                  if (!inputValue.trim()) {
                    // Input contains only whitespace, don't trigger the search.
                    if (filterConfig.searchVal.length == 1) {
                      setFilterConfig({ ...filterConfig, searchVal: "" });
                    }

                    if (inputValue.length == 0) {
                      setFilterConfig({ ...filterConfig, searchVal: "" });
                    }
                  } else {
                    setFilterConfig({ ...filterConfig, searchVal: inputValue });
                  }
                }}
              />
            </div>

            <div className="my-3 d-flex justify-content-end">
              <button
                onClick={() => clearFilter()}
                className="btn btn-sm btn-yellow "
              >
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {constLabel?.lbl_clear_filter
                  ? constLabel.lbl_clear_filter
                  : "Clear Filter"}
              </button>
            </div>
          </div>
        </div>

          <div className="content-wrapper">
            <div className="card border-0">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="d-md-flex justify-content-start col-12 col-md-6">
                    <Tooltip id={"open-tooltip"} place="top" />
                    <img
                      src={Slide}
                      height="32"
                      width="32"
                      className="cursor_pointer my-1 me-2"
                      onClick={() => toggleNav()}
                      data-tooltip-id={"open-tooltip"}
                      data-tooltip-content={"Sidebar"}
                    />
                    <h1 className="bold">
                      {constLabel?.lbl_task ? constLabel.lbl_task : "Task"}
                    </h1>
                  </div>

                  <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                    {privilage.write && (
                      <button
                        className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                        onClick={() =>
                          navigate("/pcms/createTask", { state: constLabel })
                        }
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_add_task
                          ? constLabel.lbl_add_task
                          : "Add Task"}
                      </button>
                    )}

                    <Tooltip id={"show-tooltip"} place="top" />
                    <div
                      className="dropdown"
                      data-tooltip-id={"show-tooltip"}
                      data-tooltip-content={"Show / Hide Columns"}
                    >
                      <div
                        className="d-md-flex justify-content-start align-items-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <FontAwesomeIcon icon={faListCheck} size="lg" /> */}
                        {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                        <img
                          src={List}
                          height="32"
                          width="32"
                          className="cursor_pointer"
                        />
                      </div>

                      <ul className="dropdown-menu px-2">
                        {showLabel !== undefined && showLabel.length > 0
                          ? showLabel.map((val, ind, arr) => (
                              <li key={ind}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    style={{ width: "20px", height: "20px" }}
                                    onChange={() => handleClick(ind)}
                                    value=""
                                    checked={val.flag}
                                  />
                                  <label
                                    className=""
                                    style={{ fontSize: "13px" }}
                                    htmlFor="flexCheckDefault"
                                  >
                                    {val.label}
                                  </label>
                                </div>
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  {dataList.loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : dataList && dataList.data?.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead className="table-grey  roboto-bold">
                            <tr className="">
                              <th scope="col">{"Sr.No"}</th>
                              {showLabel.map((v, i) =>
                                v.label === "Task Name" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_task_name
                                      ? constLabel.lbl_task_name
                                      : "Task Name"}
                                  </th>
                                ) : v.label === "Category Name" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_category_name
                                      ? constLabel.lbl_category_name
                                      : "Category Name"}
                                  </th>
                                ) : v.label === "Start Date" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_start_date
                                      ? constLabel.lbl_start_date
                                      : "Start Date"}
                                  </th>
                                ) : v.label === "End Date" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_end_date
                                      ? constLabel.lbl_end_date
                                      : "End Date"}
                                  </th>
                                ) : v.label === "Status" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_status
                                      ? constLabel.lbl_status
                                      : "Status"}
                                  </th>
                                ) : v.label === "Machine Name" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_machine_name
                                      ? constLabel.lbl_machine_name
                                      : "Machine Name"}
                                  </th>
                                ) : v.label === "Assign To Name" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_assignTo_name
                                      ? constLabel.lbl_assignTo_name
                                      : "Assign To Name"}
                                  </th>
                                ) : v.label === "Service By Name" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_serviceBy_name
                                      ? constLabel.lbl_serviceBy_name
                                      : "Service By Name"}
                                  </th>
                                ) : v.label === "Service Cost" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_service_cost
                                      ? constLabel.lbl_service_cost
                                      : "Service Cost"}
                                  </th>
                                ) : v.label === "Comment" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_comment
                                      ? constLabel.lbl_comment
                                      : "Comment"}
                                  </th>
                                ) : null
                              )}
                              <th scope="col" width="10%">
                                {"Action"}
                              </th>
                            </tr>
                          </thead>
                          <tbody className=" roboto">
                            {dataList.data.map((val, i) => (
                              <tr key={i}>
                                <td>{i + parseInt(srNo) + 1}</td>
                                {showLabel.map((item, idx) =>
                                  item.label === "Task Name" && item.flag ? (
                                    <td key={idx}>
                                      {val.task_name != "" &&
                                      val.task_name !== null
                                        ? val.task_name
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Category Name" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.task_category != "" &&
                                      val.task_category !== null
                                        ? val.task_category
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Start Date" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.start_date != "" &&
                                      val.start_date !== null
                                        ? convertDate(val.start_date, "-")
                                        : "NA"}
                                    </td>
                                  ) : item.label === "End Date" && item.flag ? (
                                    <td key={idx}>
                                      {val.end_date != "" &&
                                      val.end_date !== null
                                        ? convertDate(val.end_date, "-")
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Status" && item.flag ? (
                                    <td key={idx}>
                                      {val.status != "" && val.status !== null
                                        ? val.status
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Machine Name" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.machine_id &&
                                      val.machine_id?.machineName != "" &&
                                      val.machine_id?.machineName !== null
                                        ? val.machine_id?.machineName
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Assign To Name" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.assigned_name != "" &&
                                      val.assigned_name !== null
                                        ? val.assigned_name
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Service By Name" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.serviced_by_name != "" &&
                                      val.serviced_by_name !== null
                                        ? val.serviced_by_name
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Service Cost" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.service_cost != "" &&
                                      val.service_cost !== null
                                        ? val.service_cost
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Comment" && item.flag ? (
                                    <td key={idx}>
                                      {val.comments != "" &&
                                      val.comments !== null
                                        ? val.comments
                                        : "NA"}
                                    </td>
                                  ) : null
                                )}
                                <td className="">
                                  {privilage.write && (
                                    <>
                                      <Tooltip
                                        id={"edit-tooltip" + i}
                                        place="top"
                                      />
                                      <Edit
                                        data-tooltip-id={"edit-tooltip" + i}
                                        data-tooltip-content="Edit Task"
                                        className="menu_icon_grey cursor_pointer me-2"
                                        size={20}
                                        onClick={() =>
                                          navigate("/pcms/editTask", {
                                            state: {
                                              locVal: val,
                                              constLabel: constLabel,
                                            },
                                          })
                                        }
                                      />
                                    </>
                                  )}

                                  {privilage.delete && (
                                    <>
                                      <Tooltip
                                        id={"del-tooltip" + i}
                                        place="top"
                                      />
                                      <Trash2
                                        data-tooltip-id={"del-tooltip" + i}
                                        data-tooltip-content={
                                          constLabel?.lbl_delete_task
                                            ? constLabel.lbl_delete_task
                                            : "Delete Task"
                                        }
                                        className="menu_icon_red cursor_pointer"
                                        size={20}
                                        onClick={() => delTask(val)}
                                      />
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="d-flex justify-content-between align-items-center mt-2">
                        <p className="mb-0">
                          Show
                          <select
                            className="mx-1"
                            defaultValue={entriesPerPage}
                            onChange={(e) => {
                              setEntriesPerPage(e.target.value);
                              fetchTaskList(1, e.target.value);
                            }}
                          >
                            <option value={"10"}>10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          Entries
                        </p>
                        <p className="mb-0">{`Showing ${
                          parseInt(srNo) + 1
                        } to ${Math.min(
                          parseInt(entriesPerPage) + parseInt(srNo),
                          dataList.totalRecords
                        )} of ${dataList.totalRecords} entries`}</p>
                        <Pagination className="my-2">
                          <Pagination.Prev
                            disabled={page === 1 ? true : false}
                            onClick={() => {
                              fetchTaskList(
                                page - 1,
                                entriesPerPage,
                                parseInt(srNo) - parseInt(entriesPerPage)
                              );
                              setSrNo((prevC) =>
                                page - 1 == 1
                                  ? 0
                                  : prevC - parseInt(entriesPerPage)
                              );
                              setPage(page - 1);
                            }}
                          >
                            {"Prev"}
                          </Pagination.Prev>

                          <Pagination.Item active>{page}</Pagination.Item>

                          <Pagination.Next
                            disabled={
                              page === maxPage ||
                              maxPage === 0 ||
                              entriesPerPage > dataList.data.length
                                ? true
                                : false
                            }
                            onClick={() => {
                              fetchTaskList(
                                page + 1,
                                entriesPerPage,
                                parseInt(srNo) + parseInt(entriesPerPage)
                              );
                              setSrNo(
                                (prevC) => prevC + parseInt(entriesPerPage)
                              );
                              setPage(page + 1);
                            }}
                          >
                            {"Next"}
                          </Pagination.Next>
                        </Pagination>
                      </div>
                    </>
                  ) : (
                    <div
                      className="alert alert-danger mb-0 text-center my-4"
                      role="alert"
                    >
                      {"Task not found !"}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
}

export default TaskMaster;
