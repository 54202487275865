import React, { useState, useEffect, useReducer } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typeahead } from "react-bootstrap-typeahead";
import { faPlus, faBan, faListCheck } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Pagination } from "react-bootstrap";
import { Download, Edit, Edit2, Eye, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
// import XLSX from "xlsx";
import CreateCustomer from "../Customer/CreateCustomer";
import { getCustomer, deleteCustomer } from "../../../../api/index";
import {
  calMaxPage_new,
  getLabels,
  setAccessPrivilage,
} from "../../../../common/Functions/CommonFunctions";
import axios from "axios";
import { customer_csv } from "../../../../constant";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { Customer_Col_Name } from "../../../../common/Labels/labelConstant";
import { driver } from "driver.js";
import List from "../../../../assets/images/icons/project_menu/checklist.png";
import Slide from "../../../../assets/images/icons/project_menu/collapse.png";

function Customer() {
  let navigate = useNavigate();

  // const ViewCustomer = (val) => {
  //   navigate(`${process.env.PUBLIC_URL}/pcms/customerDetails/`,{state: {val}});

  // };

  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [filterConfig, setFilterConfig] = useState({
    // customer_uuid: [],
    searchVal: "",
    GSTNO: "",
  });

  const [sea_val, setSea_val] = useState(false);
  const [showLabel, setShowLabel] = useState(Customer_Col_Name);
  const [constLabel, setConstLabel] = useState({});

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const [privilage, setPrivilage] = useState(
    setAccessPrivilage(loginData, "Customer")
  );
  const [custList, setCustList] = useState({ data: [], loading: false });

  // useEffect(() => {

  //   if (loginData.role_name.trim() == "admin" ||
  //     loginData.role_name.trim() == "client_admin") {
  //     setPrivilage({
  //       read: true,
  //       write: true,
  //       view: true,
  //       delete: true,
  //     });
  //   } else {
  //     for (let i = 0; i < loginData.module_list.length; i++) {
  //       if (loginData.module_list[i].module == "Customer") {
  //         setPrivilage(loginData.module_list[i]);
  //       } else {
  //         if (loginData.module_list[i].screens !== undefined) {
  //           for (let j = 0; j < loginData.module_list[i].screens.length; j++) {
  //             if (loginData.module_list[i].screens[j].screen_name == "Customer") {
  //               setPrivilage(loginData.module_list[i]);
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }, []);

  useEffect(() => {
    setConstLabel(getLabels("Party Master", "Customer"));
    fetchAllCustomer();
    // const driverObj = driver({
    //   showButtons: ["next", "previous", "close"],
    //   // popoverClass: 'driverjs-theme',
    //   showProgress: true,
    //   steps: [
    //     {
    //       element: "#filterLink",
    //       popover: {
    //         title: "Filter & Search",
    //         description: "Click here to enable option to search records",
    //         side: "left",
    //         align: "start",
    //       },
    //     },
    //     {
    //       element: "#createCustBtn",
    //       popover: {
    //         title: "Create Customer",
    //         description: "Click here to create customer",
    //         side: "left",
    //         align: "start",
    //       },
    //     },
    //     {
    //       element: "#downloadReportBtn",
    //       popover: {
    //         title: "Download Report",
    //         description: "Click here to download excel report",
    //         side: "left",
    //         align: "start",
    //       },
    //     },
    //     {
    //       element: "#prevPageBtn",
    //       popover: {
    //         title: "Previous Button",
    //         description: "Click here to navigate to previous page",
    //         side: "left",
    //         align: "start",
    //       },
    //     },
    //     {
    //       element: "#nextPageBtn",
    //       popover: {
    //         title: "Next Button",
    //         description: "Click here to navigate to next page",
    //         side: "left",
    //         align: "start",
    //       },
    //     },
    //     {
    //       popover: {
    //         title: "Congratulations, Start using app !",
    //       },
    //     },
    //   ],
    // });

    // driverObj.drive();
  }, []);

  function fetchAllCustomer() {
    setCustList({ ...custList, data: [], loading: true });

    getCustomer({ partyType: "Customer" }).then(
      (res) => {
        setCustList({ ...custList, data: res.data.data, loading: false });
      },
      (err) => {
        setCustList({ ...custList, data: [], loading: false });
      }
    );
  }

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchCustomers(1, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function fetchCustomers(pg, epp) {
    setDataList({ ...dataList, loading: true, data: [] });

    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
      partyType: "Customer",
    };

    // if (filterConfig.customer_uuid.length > 0) {
    //   // payload.uuid = filterConfig.customer_uuid.map((item) => item.uuid);
    //   payload.uuid = filterConfig.customer_uuid?.[0]?.uuid;
    //   setSrNo(0);
    //   setPage(1);
    // } else {
    //   delete payload.uuid;
    // }

    if (filterConfig.searchVal !== "" || filterConfig.GSTNO !== "") {
      payload.searchVal = filterConfig.searchVal;
      payload.GSTNO = filterConfig.GSTNO;
      if (
        (filterConfig.searchVal?.length > 0 ||
          filterConfig.GSTNO?.length > 0) &&
        page !== 1 &&
        sea_val == false
      ) {
        payload.pageNo = 1;
        payload.entriesPerPage = entriesPerPage;
        setPage(1);
        setSrNo(0);
      }
    } else {
      delete payload.searchVal;
      delete payload.GSTNO;
    }

    getCustomer(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });

        if (
          (filterConfig.searchVal != "" &&
            filterConfig.searchVal?.length > 0) ||
          (filterConfig.GSTNO != "" && filterConfig.GSTNO?.length > 0)
        ) {
          setSea_val(true);
        } else {
          setSea_val(false);
        }

        setMaxPage(calMaxPage_new(res.data.total, epp));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({
          ...dataList,
          loading: false,
          data: [],
        });
      }
    );
  }

  function clearFilter() {
    setFilterConfig({
      ...filterConfig,
      // customer_uuid: []
      searchVal: "",
      GSTNO: "",
    });
    setSrNo(0);
    setPage(1);
    // fetchAllCustomer();
  }

  // Deleting Customer
  const delCustomer = (val) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          uuid: val.uuid,
          // partyCode: val.partyCode,
        };
        deleteCustomer(payload).then(
          (res) => {
            toast.success("Customer deleted successfully !", {
              autoClose: 3000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchCustomers(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchCustomers(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  const generateAndDownloadCSV = (jsonData) => {
    if (!Array.isArray(jsonData) || jsonData.length === 0) return;

    const csvRows = jsonData.map((data) =>
      Object.values(data)
        .map((value) => `"${value}"`)
        .join(",")
    );

    const csvContent = csvRows.join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "customer.csv";

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  };

  const fetchDataAndDownloadCSV = async () => {
    const token = localStorage.getItem("access");
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + customer_csv,
        {
          responseType: "json", // Set the responseType to ‘arraybuffer’
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the Authorization header
          },
        }
      );

      generateAndDownloadCSV(response.data.customer);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="min-vh-100" id="root_div_main">
        <div id="mySidebar" className="customsidebar ">
          <a className="closebtn" onClick={() => toggleNav()}>
            ×
          </a>

          <div className=" content">
            <div>
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_customer
                  ? constLabel?.lbl_customer
                  : "Customer"}
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by customer (company) name"
                value={filterConfig.searchVal}
                onChange={(val) => {
                  const inputValue = val.target.value;
                  if (!inputValue.trim()) {
                    // Input contains only whitespace, don't trigger the search.
                    if (filterConfig.searchVal.length == 1) {
                      setFilterConfig({ ...filterConfig, searchVal: "" });
                    }

                    if (inputValue.length == 0) {
                      setFilterConfig({ ...filterConfig, searchVal: "" });
                    }
                  } else {
                    setFilterConfig({ ...filterConfig, searchVal: inputValue });
                  }
                }}
              />
            </div>

            <div>
              <label
                className="filterLabel my-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_gst_no ? constLabel.lbl_gst_no : "GST No"}
              </label>
              <input
                className="form-control "
                type="text"
                placeholder="Search by GST number"
                value={filterConfig.GSTNO}
                onChange={(val) => {
                  const inputValue = val.target.value; // Trim leading and trailing whitespaces
                  if (!inputValue.trim()) {
                    // console.log("inputValue2",inputValue)
                    if (filterConfig.GSTNO.length == 1) {
                      // console.log("inputValue3",inputValue)
                      setFilterConfig({ ...filterConfig, GSTNO: "" });
                    }

                    if (inputValue.length == 0) {
                      setFilterConfig({ ...filterConfig, GSTNO: "" });
                    }
                  } else {
                    // console.log("inputValue4",inputValue)
                    setFilterConfig({
                      ...filterConfig,
                      GSTNO: inputValue,
                    });
                  }
                }}
              />
            </div>
            <div className="my-3 d-flex justify-content-end">
              <button
                className="btn btn-sm btn-yellow"
                onClick={() => clearFilter()}
              >
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {constLabel?.lbl_clear_filter
                  ? constLabel.lbl_clear_filter
                  : "Clear Filter"}
              </button>
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="card border-0">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="d-md-flex justify-content-start col-12 col-md-6">
                  {/* <FontAwesomeIcon
                    onClick={() => toggleNav()}
                    className="cursor_pointer mb-2"
                    style={{
                      color: "Black",
                      marginRight: "10px",
                    }}
                    icon={faBars}
                    size="lg"
                  /> */}
                  <Tooltip id={"open-tooltip"} place="top" />
                  <img
                    src={Slide}
                    height="32"
                    width="32"
                    className="cursor_pointer my-1 me-2"
                    onClick={() => toggleNav()}
                    data-tooltip-id={"open-tooltip"}
                    data-tooltip-content={"Sidebar"}
                  />
                  <h1 className="bold">
                    {constLabel?.lbl_customer
                      ? constLabel.lbl_customer
                      : "Customer"}
                  </h1>
                </div>

                <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                  <div className="col-12 col-md-6 me-md-2 me-md-0">
                    {/* <input
                      className="form-control"
                      type="text"
                      placeholder="Search by customer (company) name"
                      value={filterConfig.searchVal}
                      onChange={(val) => {
                        const inputValue = val.target.value;
                        if (!inputValue.trim()) {
                          if (filterConfig.searchVal.length == 1) {
                            setFilterConfig({ ...filterConfig, searchVal: "" });
                          }
                        } else {
                          setFilterConfig({
                            ...filterConfig,
                            searchVal: inputValue,
                          });
                        }
                        if(inputValue.length == 0){
                          setFilterConfig({ ...filterConfig, searchVal: "" });
                        }
                      }}
                    /> */}
                  </div>

                  {privilage.write && (
                    <button
                      className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                      onClick={() => {
                        navigate("/pcms/addCustomer");
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faPlus}
                        size="sm"
                      />{" "}
                      {constLabel?.lbl_add_customer
                        ? constLabel.lbl_add_customer
                        : "Add Customer"}
                    </button>
                  )}

                  <Tooltip id={"show-tooltip"} place="top" />
                  <div
                    className="dropdown"
                    data-tooltip-id={"show-tooltip"}
                    data-tooltip-content={"Show / Hide Columns"}
                  >
                    <div
                      className="d-md-flex justify-content-start align-items-center"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {/* <FontAwesomeIcon icon={faListCheck} size="lg" /> */}
                      {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                      <img
                        src={List}
                        height="32"
                        width="32"
                        className="cursor_pointer"
                      />
                    </div>

                    <ul className="dropdown-menu px-2">
                      {showLabel !== undefined && showLabel.length > 0
                        ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="mt-3">
                {dataList.loading ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : dataList && dataList.data?.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table table-bordered" width={"100%"}>
                        <thead className="table-grey roboto">
                          <tr className="">
                            <th scope="col">{"Sr.No"}</th>
                            {showLabel.map((v, i) =>
                              v.label === "Company Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Company Name"}
                                </th>
                              ) : v.label === "Customer Type" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Customer Type"}
                                </th>
                              ) : v.label === "Contact No" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Contact No"}
                                </th>
                              ) : v.label === "Email ID" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Email ID"}
                                </th>
                              ) : v.label === "Website" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Website"}
                                </th>
                              ) : v.label === "GST Type" && v.flag ? (
                                <th key={i} scope="col">
                                  {"GST Type"}
                                </th>
                              ) : v.label === "GST No" && v.flag ? (
                                <th key={i} scope="col">
                                  {"GST No"}
                                </th>
                              ) : v.label === "PAN No" && v.flag ? (
                                <th key={i} scope="col">
                                  {"PAN No"}
                                </th>
                              ) : v.label === "Vendor Code" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Vendor Code"}
                                </th>
                              ) : v.label === "Cutomer Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Cutomer Name"}
                                </th>
                              ) : v.label === "Contact Person Name" &&
                                v.flag ? (
                                <th key={i} scope="col">
                                  {"Contact Person Name"}
                                </th>
                              ) : v.label === "Country" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Country"}
                                </th>
                              ) : v.label === "State" && v.flag ? (
                                <th key={i} scope="col">
                                  {"State"}
                                </th>
                              ) : v.label === "City" && v.flag ? (
                                <th key={i} scope="col">
                                  {"City"}
                                </th>
                              ) : null
                            )}
                            <th scope="col" width="10%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="roboto">
                          {dataList.data?.map((val, i) => (
                            <tr key={i}>
                              <td>{i + srNo + 1}</td>
                              {showLabel.map((item, idx) =>
                                item.label === "Company Name" && item.flag ? (
                                  <td key={idx}>
                                    {val.organization != "" &&
                                    val.organization != null
                                      ? val.organization
                                      : "NA"}
                                  </td>
                                ) : item.label === "Customer Type" &&
                                  item.flag ? (
                                  <td key={idx}>{val.customerType}</td>
                                ) : item.label === "Contact No" && item.flag ? (
                                  <td key={idx}>
                                    {val.primaryContactNo != "" &&
                                    val.primaryContactNo != null
                                      ? val.primaryContactNo
                                      : "NA"}
                                  </td>
                                ) : item.label === "Email ID" && item.flag ? (
                                  <td key={idx}>
                                    {val.email != "" && val.email != null
                                      ? val.email
                                      : "NA"}
                                  </td>
                                ) : item.label === "Website" && item.flag ? (
                                  <td key={idx}>
                                    {val.website != "" && val.website != null
                                      ? val.website
                                      : "NA"}
                                  </td>
                                ) : item.label === "GST Type" && item.flag ? (
                                  <td key={idx}>{val.gst_treatment}</td>
                                ) : item.label === "GST No" && item.flag ? (
                                  <td key={idx}>
                                    {val.gst != "" && val.gst != null
                                      ? val.gst
                                      : "NA"}
                                  </td>
                                ) : item.label === "PAN No" && item.flag ? (
                                  <td key={idx}>
                                    {val.PANNO != "" && val.PANNO != null
                                      ? val.PANNO
                                      : "NA"}
                                  </td>
                                ) : item.label === "Vendor Code" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.vendor_code != "" &&
                                    val.vendor_code != null
                                      ? val.vendor_code
                                      : "NA"}
                                  </td>
                                ) : item.label === "Cutomer Name" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.name != "" && val.name != null
                                      ? val.name
                                      : "NA"}
                                  </td>
                                ) : item.label === "Contact Person Name" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.contactPersonName != "" &&
                                    val.contactPersonName != null
                                      ? val.contactPersonName
                                      : "NA"}
                                  </td>
                                ) : item.label === "Country" && item.flag ? (
                                  <td key={idx}>
                                    {val.country_name != "" &&
                                    val.country_name != null
                                      ? val.country_name
                                      : "NA"}
                                  </td>
                                ) : item.label === "State" && item.flag ? (
                                  <td key={idx}>
                                    {val.state_name != "" &&
                                    val.state_name != null
                                      ? val.state_name
                                      : "NA"}
                                  </td>
                                ) : item.label === "City" && item.flag ? (
                                  <td key={idx}>
                                    {val.city_name != "" &&
                                    val.city_name != null
                                      ? val.city_name
                                      : "NA"}
                                  </td>
                                ) : null
                              )}

                              <td>
                                {privilage.write && (
                                  <>
                                    <Tooltip id={"edit-tooltip"} place="top" />
                                    <Edit
                                      data-tooltip-id={"edit-tooltip"}
                                      data-tooltip-content={
                                        constLabel?.lbl_edit_customer
                                          ? constLabel.lbl_edit_customer
                                          : "Edit Customer"
                                      }
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20}
                                      onClick={() =>
                                        navigate("/pcms/neweditCustomer", {
                                          state: val,
                                        })
                                      }
                                    />
                                  </>
                                )}

                                {/* {privilage.write && (
                                  <>
                                    <Tooltip id={"edit-tooltip"} place="top" />
                                    <div
                                      data-tooltip-id={"edit-tooltip"}
                                      data-tooltip-content={
                                        constLabel?.lbl_edit_customer
                                          ? constLabel.lbl_edit_customer
                                          : "Edit Customer"
                                      }
                                      className="editIcon me-2"
                                      onClick={() =>
                                        navigate("/pcms/neweditCustomer", {
                                          state: val,
                                        })
                                      }
                                    >
                                      <Edit2 color="#FFFFFF" size={18} />
                                    </div>
                                  </>
                                )} */}

                                {privilage.read && (
                                  <>
                                    <Tooltip id={"view-tooltip"} place="top" />
                                    <Eye
                                      data-tooltip-id={"view-tooltip"}
                                      data-tooltip-content={
                                        constLabel?.lbl_view_customer
                                          ? constLabel.lbl_view_customer
                                          : "View Customer"
                                      }
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20}
                                      onClick={() =>
                                        navigate("/pcms/customerDetails/", {
                                          state: val,
                                        })
                                      }
                                    />
                                  </>
                                )}

                                {/* {privilage.read && (
                                  <>
                                    <Tooltip id={"view-tooltip"} place="top" />
                                    <div
                                      data-tooltip-id={"view-tooltip"}
                                      data-tooltip-content={
                                        constLabel?.lbl_view_customer
                                          ? constLabel.lbl_view_customer
                                          : "View Customer"
                                      }
                                      className="pdrIcon me-2"
                                      // onClick={() => ViewCustomer(val)}
                                      onClick={() =>
                                        navigate("/pcms/customerDetails/", {
                                          state: val,
                                        })
                                      }
                                    >
                                      <Eye color="#FFFFFF" size={18} />
                                    </div>
                                  </>
                                )} */}

                                {privilage.delete && (
                                  <>
                                    <Tooltip
                                      id={"delete-tooltip"}
                                      place="top"
                                    />
                                    <Trash2
                                      data-tooltip-id={"delete-tooltip"}
                                      data-tooltip-content={
                                        constLabel?.lbl_delete_customer
                                          ? constLabel.lbl_delete_customer
                                          : "Delete Customer"
                                      }
                                      className="menu_icon_red cursor_pointer"
                                      size={20}
                                      onClick={() => delCustomer(val)}
                                    />
                                  </>
                                )}

                                {/* {privilage.delete && (
                                  <>
                                    <Tooltip
                                      id={"delete-tooltip"}
                                      place="top"
                                    />
                                    <div
                                      data-tooltip-id={"delete-tooltip"}
                                      data-tooltip-content={
                                        constLabel?.lbl_delete_customer
                                          ? constLabel.lbl_delete_customer
                                          : "Delete Customer"
                                      }
                                      className="deleteIcon me-2"
                                      onClick={() => delCustomer(val)}
                                    >
                                      <Trash2 color="#FFFFFF" size={18} />
                                    </div>
                                  </>
                                )} */}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <div>
                        <p className="mb-0">
                          Show
                          <select
                            className="mx-1"
                            value={entriesPerPage}
                            onChange={(e) => {
                              setEntriesPerPage(e.target.value);
                              fetchCustomers(1, e.target.value);
                            }}
                          >
                            <option value={"10"}>10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          Entries
                        </p>
                      </div>
                      <p className="mb-0">{`Showing ${
                        parseInt(srNo) + 1
                      } to ${Math.min(
                        parseInt(entriesPerPage) + parseInt(srNo),
                        dataList.totalRecords
                      )} of ${dataList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchCustomers(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1
                                ? 0
                                : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                            maxPage === 0 ||
                            entriesPerPage > dataList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            fetchCustomers(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  </>
                ) : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"Customer not found !"}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Customer;
