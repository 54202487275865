import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getProdCategory,
  getCustomer,
  getMarket_Group,
  getProduct_Family,
  getUOM,
  saveProduct,
  getBOM,
  getProdList,
  updateProduct,
  deleteBOM,
  gethsn_master,
} from "../../../../../api";
import {
  calMaxPage,
  getLabels,
} from "../../../../../common/Functions/CommonFunctions";
import { Accordion, Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import SweetAlert from "sweetalert2";

const schema = yup
  .object()
  .shape({
    // category: yup
    //   .array()
    //   .required("Product category is required !")
    //   .min(1, "Select atleast one type !"),
    marketGroup: yup
      .array()
      .required("Please select market group")
      .min(1, "Please select market group"),
    productFamily: yup
      .array()
      .required("Please select product family")
      .min(1, "Please select product family"),
    openingStock: yup
      .string()
      .transform((value, originalValue) => originalValue.trim() === '' ? null : value)
      .matches(/^[0-9]+$/, 'Enter valid opening stock')
      .nullable(),
    OEMnumber: yup
      .string()
      .nullable()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      ),
    // .matches(/^[a-zA-Z0-9\s]*$/, 'Enter a valid OEM part no'),
    productName: yup.string().required("Product name is required !"),
    // hsn: yup
    //   .string()
    //   .matches(/^[a-zA-Z0-9]*$/, "Enter valid hsn")
    //   .nullable(true),
    unit: yup
      .array()
      .required("Unit is required !")
      .min(1, "Please select unit !"),
    machineCost: yup
      .string()
      .required("Cost is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid cost"),
    salesPrice: yup
      .string()
      .required("Sales price is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid sales price"),
    // selling_price: yup
    //   .string()
    //   .required("Selling price is required !")
    //   .matches(/^\d+(\.\d+)?$/, "Enter valid selling price"),
    purchase_price: yup
      .string()
      .required("Purchase price is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid purchase price"),
    can_be_sold: yup.string().required("Required"),
    can_be_purchased: yup.string().required("Required"),
    // can_be_expensed: yup.string().required("Required"),
    // has_bom: yup
    //   .string()
    //   .required("Required"),
  })
  .required();

function ManufacturedGoods(props) {
  let navigate = useNavigate();
  let location = useLocation();

  const [prodCategory, setProdCategory] = useState({
    data: [],
    loading: false,
  });
  const [marketGroup, setMarketGroup] = useState({ data: [], loading: false });
  const [prodFamily, setProdFamily] = useState({ data: [], loading: false });
  const [partyList, setPartyList] = useState({ data: [], loading: false });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [hsnList, setHSNList] = useState({ data: [], loading: false });
  const [createBom, setCreateBom] = useState(
    props.main_obj.obj_bom.flag ? props.main_obj.obj_bom.flag : false
  );
  const [constLabel, setConstLabel] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);
  const [particularBomList, setParticularBomList] = useState({
    data: [],
    loading: false,
  });

  const [filterConfig, setFilterConfig] = useState({ hsn_sac_code: "" });

  const [modal, setModal] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    setConstLabel(getLabels("Master", "Product"));

    fetchProductCategory();
    fetchMarketGroup();
    fetchProductFamily();
    fetchParty();
    fetchUOM();
    // fetchHSN();
  }, []);


  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      fetchHSN();
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function fetchProdList(product_uuid) {

    getProdList({ uuid: product_uuid }).then(
      (res) => {

        let bom_check = false;
        if (createBom == true) {
          bom_check = true;
        }
        props.setMain_obj({
          ...props.main_obj,
          prod_overview: res.data.data[0],
          obj_bom: { ...props.main_obj.obj_bom, flag: bom_check },
        });
        props.nextStep();
      },
      (err) => {

      }
    );
  }

  function fetchHSN() {
    setHSNList({ ...hsnList, data: [], loading: true });
    let payload = {
      pageNo: 1,
      entriesPerPage: 1000,
    };
    if (filterConfig.hsn_sac_code !== "" && filterConfig.hsn_sac_code.length >= 4) {
      payload.hsn_sac_code = filterConfig.hsn_sac_code.trim(); // Search by hsn sac code
      gethsn_master(payload).then(
        (res) => {
          setHSNList({ ...hsnList, data: res.data.data, loading: false });
        },
        (err) => {
          setHSNList({ ...hsnList, data: [], loading: false });
        }
      );
    } else {
      // delete payload.hsn_sac_code;
      setHSNList({ ...hsnList, data: [], loading: false });
    }
  }

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM().then(
      (res) => {

        setUOMList({ ...uomList, data: res.data.data, loading: false });
      },
      (err) => {

        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  function fetchProductCategory() {
    setProdCategory({ ...prodCategory, data: [], loading: true });
    getProdCategory().then(
      (res) => {

        setProdCategory({
          ...prodCategory,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {

        setProdCategory({ ...prodCategory, data: [], loading: false });
      }
    );
  }

  function fetchMarketGroup() {
    setMarketGroup({ ...marketGroup, data: [], loading: true });
    getMarket_Group().then(
      (res) => {

        setMarketGroup({
          ...marketGroup,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {

        setMarketGroup({ ...marketGroup, data: [], loading: false });
      }
    );
  }

  function fetchProductFamily() {
    setProdFamily({ ...prodFamily, data: [], loading: true });
    getProduct_Family().then(
      (res) => {

        setProdFamily({
          ...prodFamily,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {

        setProdFamily({ ...prodFamily, data: [], loading: false });
      }
    );
  }

  function fetchParty() {
    setPartyList({ ...partyList, data: [], loading: true });
    getCustomer({ partyType: "Customer" }).then(
      (res) => {

        const result = res.data.data.filter((item) => item.name !== null);
        setPartyList({ ...partyList, data: result, loading: false });
      },
      (err) => {

        setPartyList({ ...partyList, data: [], loading: false });
      }
    );
  }

  // const onSubmit = (data) => {
  //   props.setCurrentStep(props.currentStep + 1);
  // };

  const onSubmit = (data) => {
    setBtnDisable(true);


    if (data !== "") {
      const reqPayload = {
        productType: props.product_type?.prodTypeId,
        productTypename: props.product_type?.productType,
        product_type_id: props.product_type?.uuid,
        category: data.category.length ? data.category?.[0]?.categoryname : null,
        category_id: data.category.length ? data.category?.[0]?.uuid : null,
        marketgrp: data.marketGroup?.[0]?.grpId,
        market_grp_id: data.marketGroup?.[0]?.uuid,
        prodfamily: data.productFamily?.[0]?.familyId,
        prod_family_id: data.productFamily?.[0]?.uuid,
        // subcategory: data.subCategory,
        productName: data.productName,
        openingstock: data.openingStock,
        hsn: data.hsn.length > 0 ? data.hsn?.[0]?.hsn_sac_code : null,
        hsn_uuid: data.hsn.length > 0 ? data.hsn?.[0]?.uuid : null,
        machineCost: data.machineCost,
        salesPrice: data.salesPrice,
        can_be_sold: data.can_be_sold,
        can_be_purchased: data.can_be_purchased,
        OEMnumber: data.OEMnumber,
        partNo: data.OEMnumber,
        // can_be_expensed: data.can_be_expensed,
        // beacon_id: data.beacon_id,
        // qr_code_id: data.qr_code_id,
        // selling_price: data.selling_price,
        purchase_price: data.purchase_price,
        unit_id: data.unit ? data.unit?.[0]?.uuid : "",
        remark: data.remark,
        // party: [],
        batch_tracking: data.batch_tracking
      };


      // props.nextStep();
      if (props.main_obj.prod_overview?.uuid) {
        reqPayload.uuid = props.main_obj.prod_overview.uuid;

        updateProduct(reqPayload).then(
          (res) => {

            setBtnDisable(false);
            if (res.status === 200 || res.status === 201) {
              toast.success(
                "Manufactured goods product updated successfully !",
                {
                  autoClose: 2000,
                }
              );
              setTimeout(() => {
                // fetchProdList(res.data.data?.[0]?.product_serializer?.uuid);
                fetchProdList(res.data.updatedRows?.[0]?.uuid);
              }, [2300]);
            }
          },
          (err) => {
            setBtnDisable(false);

            if (err.response.status === 400) {

              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
            }
          }
        );
      } else {
        saveProduct(reqPayload).then(
          (res) => {
            console.log("res", res);
            setBtnDisable(false);

            if (res.status === 200 || res.status === 201) {
              toast.success("Manufactured goods product saved successfully !", {
                autoClose: 2000,
              });

              props.setCreatedProd(res.data.result);

              setTimeout(() => {
                // fetchProdList(res.data.product.uuid);
                fetchProdList(res.data.result.uuid);
              }, [2300]);
            }
          },
          (err) => {
            setBtnDisable(false);

            if (err.response.status === 400) {

              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
            }
          }
        );
      }
    } else {
      errors.showMessages();
    }
  };

  function delete_bom(val) {

    SweetAlert.fire({
      title: "Are you sure to delete \n" + val.bom_type + " ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteBOM({ uuid: val.uuid }).then(
          (res) => {

            toast.success("Bill of material deleted successfully !", {
              autoClose: 2000,
            });
            fetchParticularBoms();
          },
          (err) => {

            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  function fetchParticularBoms() {
    setParticularBomList({ ...particularBomList, data: [], loading: true });
    getBOM({ product_id: props.main_obj?.prod_overview?.uuid }).then(
      (res) => {

        setParticularBomList({
          ...particularBomList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {

        setParticularBomList({
          ...particularBomList,
          data: [],
          loading: false,
        });
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="card shadow-sm rounded-0 p-2">
        <div className="card-body">
          <p className="pc_card_title roboto-bold">
            {constLabel?.lbl_product_summary
              ? constLabel?.lbl_product_summary
              : "Product Summary"}
          </p>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_product_category
                    ? constLabel?.lbl_product_category
                    : "Product Category"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <Controller
                  name="category"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.category !== undefined &&
                      props.main_obj.prod_overview.category !== null &&
                      props.main_obj.prod_overview.category_id !== null
                      ? [props.main_obj.prod_overview?.category_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="categoryname"
                      className="mt-2"
                      multiple={false}
                      options={prodCategory.data}
                      isLoading={prodCategory.loading}
                      placeholder="Select product category"
                      onChange={onChange}
                      defaultValue={
                        props.main_obj.prod_overview.category !== undefined &&
                          props.main_obj.prod_overview.category !== null &&
                          props.main_obj.prod_overview.category_id !== null
                          ? [props.main_obj.prod_overview?.category_id]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                    // isInvalid={errors.category ? true : false}
                    />
                  )}
                />

                {/* {errors.category && (
                  <span className="text-danger err-msg">
                    {errors.category.message}
                  </span>
                )} */}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_market_group
                    ? constLabel?.lbl_market_group
                    : "Market group"}
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name="marketGroup"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.market_grp_id !== undefined &&
                      props.main_obj.prod_overview.market_grp_id !== null
                      ? [props.main_obj.prod_overview?.market_grp_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="groupname"
                      className="mt-2"
                      multiple={false}
                      options={marketGroup.data}
                      isLoading={marketGroup.loading}
                      placeholder="Select market group"
                      onChange={onChange}
                      defaultSelected={
                        props.main_obj.prod_overview.market_grp_id !==
                          undefined &&
                          props.main_obj.prod_overview.market_grp_id !== null
                          ? [props.main_obj.prod_overview?.market_grp_id]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.marketGroup ? true : false}
                    />
                  )}
                />

                {errors.marketGroup && (
                  <span className="text-danger err-msg">
                    {errors.marketGroup.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_product_family
                    ? constLabel?.lbl_product_family
                    : "Product Family"}
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name="productFamily"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.prod_family_id !== undefined &&
                      props.main_obj.prod_overview.prod_family_id !== null
                      ? [props.main_obj.prod_overview?.prod_family_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="familyname"
                      className="mt-2"
                      multiple={false}
                      options={prodFamily.data}
                      isLoading={prodFamily.loading}
                      placeholder="Select product family"
                      onChange={onChange}
                      defaultSelected={
                        props.main_obj.prod_overview.prod_family_id !==
                          undefined &&
                          props.main_obj.prod_overview.prod_family_id !== null
                          ? [props.main_obj.prod_overview?.prod_family_id]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.productFamily ? true : false}
                    />
                  )}
                />

                {errors.productFamily && (
                  <span className="text-danger err-msg">
                    {errors.productFamily.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_product_name
                    ? constLabel?.lbl_product_name
                    : "Product Name"}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.productName
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter product name"
                  defaultValue={
                    props.main_obj.prod_overview.productName !== null &&
                      props.main_obj.prod_overview.productName !== ""
                      ? props.main_obj.prod_overview.productName
                      : ""
                  }
                  name="productName"
                  {...register("productName")}
                />
                {errors.productName && (
                  <span className="text-danger err-msg">
                    {errors.productName.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_opening_stock
                    ? constLabel?.lbl_opening_stock
                    : "Opening Stock"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  defaultValue={
                    props.main_obj.prod_overview.openingstock !== null &&
                      props.main_obj.prod_overview.openingstock !== ""
                      ? props.main_obj.prod_overview.openingstock
                      : 0
                  }
                  placeholder="Enter opening stock"
                  name="openingStock"
                  {...register("openingStock")}
                />
                {errors.openingStock && (
                  <span className="text-danger err-msg">
                    {errors.openingStock.message}
                  </span>
                )}
              </div>
            </div>

            {/*<div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_hsn ? constLabel?.lbl_hsn : "HSN"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter hsn"
                  name="hsn"
                  defaultValue={
                    props.main_obj.prod_overview.hsn !== null &&
                      props.main_obj.prod_overview.hsn !== ""
                      ? props.main_obj.prod_overview.hsn
                      : ""
                  }
                  {...register("hsn")}
                />
                {errors.hsn && (
                  <span className="text-danger err-msg">
                    {errors.hsn.message}
                  </span>
                )}
              </div>
            </div>*/}

            {/* HSN select box */}
            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_hsn ? constLabel?.lbl_hsn : "HSN"}
                  {/* <span className="text-danger"> *</span> */}
                </label>
                <Controller
                  name="hsn"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.hsn_uuid !== undefined &&
                      props.main_obj.prod_overview.hsn_uuid !== null
                      ? [props.main_obj.prod_overview?.hsn_uuid]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="hsn_sac_code"
                      className="mt-2"
                      multiple={false}
                      options={hsnList.data}
                      isLoading={hsnList.loading}
                      placeholder="Select hsn"
                      onChange={onChange}
                      // onChange={(val) => {
                      //   if (val.length > 0) {
                      //     setFilterConfig({ ...filterConfig, hsn_sac_code: val[0].hsn_sac_code });
                      //   } else {
                      //     setFilterConfig({ ...filterConfig, hsn_sac_code: "" });
                      //   }
                      //   onChange(val);
                      // }}
                      defaultSelected={
                        props.main_obj.prod_overview.hsn_uuid !== undefined &&
                          props.main_obj.prod_overview.hsn_uuid !== null
                          ? [props.main_obj.prod_overview?.hsn_uuid]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                      // isInvalid={errors.hsn ? true : false}
                      onInputChange={(text) =>
                        setFilterConfig({ ...filterConfig, hsn_sac_code: text })
                      }
                    />
                  )}
                />

                {/* {errors.hsn && (
                  <span className="text-danger err-msg">
                    {errors.hsn.message}
                  </span>
                )} */}
              </div>
            </div>
            {/* HSN select box */}

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_unit ? constLabel?.lbl_unit : "Unit"}
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name="unit"
                  control={control}
                  defaultValue={
                    props.main_obj.prod_overview.unit_id !== undefined &&
                      props.main_obj.prod_overview.unit_id !== null
                      ? [props.main_obj.prod_overview?.unit_id]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="uom"
                      className="mt-2"
                      multiple={false}
                      options={uomList.data}
                      isLoading={uomList.loading}
                      placeholder="Select unit"
                      onChange={onChange}
                      defaultSelected={
                        props.main_obj.prod_overview.unit_id !== undefined &&
                          props.main_obj.prod_overview.unit_id !== null
                          ? [props.main_obj.prod_overview?.unit_id]
                          : []
                      }
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.unit ? true : false}
                    />
                  )}
                />

                {errors.unit && (
                  <span className="text-danger err-msg">
                    {errors.unit.message}
                  </span>
                )}
              </div>
            </div>

            {location.pathname == "/pcms/editProductConfigurator" ? (
              <div className="col-md-3 mt-3">
                <label className="lbl-style roboto-bold invisible">
                  {"Unit"}
                </label>
                <div className="d-flex align-items-center">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      defaultChecked={
                        props.main_obj.obj_bom.flag
                          ? props.main_obj.obj_bom.flag
                          : false
                      }
                      disabled
                      checked={
                        props.main_obj.obj_bom.flag
                          ? props.main_obj.obj_bom.flag
                          : false
                      }
                    // name="can_be_sold"
                    // {...register("can_be_sold")}
                    />
                    <label className="form-check-label lbl-style roboto-bold">
                      {constLabel?.lbl_has_bom
                        ? constLabel?.lbl_has_bom
                        : "Has Bom"}
                    </label>
                  </div>
                  <div className="ml-2">
                    <button
                      onClick={() => {
                        setModal(true);
                        fetchParticularBoms();
                      }}
                      className="btn btn-grey d-block px-3 mt-2"
                    >
                      {constLabel?.lbl_view_bill_of_material
                        ? constLabel?.lbl_view_bill_of_material
                        : "View Bill of Material"}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="col-md-3">
                <label className="lbl-style roboto-bold invisible">
                  {"Unit"}
                  <span className="text-danger">*</span>
                </label>
                <div className="form-check mt-4">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    name="has_bom"
                    onChange={(e) => {
                      setCreateBom(e.target.checked);
                    }}
                    checked={createBom}
                  // {...register("has_bom")}
                  />
                  <label className="form-check-label lbl-style roboto-bold">
                    {constLabel?.create_bom
                      ? constLabel?.create_bom
                      : "Create BOM"}
                  </label>
                </div>
              </div>
            )}

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_oem_part_no
                    ? constLabel?.lbl_oem_part_no
                    : "OEM Part No"}
                </label>
                <input
                  className={
                    errors.OEMnumber
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter OEM part no"
                  name="OEMnumber"
                  defaultValue={
                    props.main_obj.prod_overview.OEMnumber !== null &&
                      props.main_obj.prod_overview.OEMnumber !== ""
                      ? props.main_obj.prod_overview.OEMnumber
                      : ""
                  }
                  {...register("OEMnumber")}
                />
                {errors.OEMnumber && (
                  <span className="text-danger err-msg">
                    {errors.OEMnumber.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">
            {constLabel?.lbl_product_detail
              ? constLabel?.lbl_product_detail
              : "Product Detail"}
          </p>
          <div className="row col-12">
            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {constLabel?.lbl_can_be_sold
                  ? constLabel?.lbl_can_be_sold
                  : "Can be Sold"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={
                    props.main_obj.prod_overview.can_be_sold
                      ? props.main_obj.prod_overview.can_be_sold
                      : false
                  }
                  name="can_be_sold"
                  {...register("can_be_sold")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.lbl_can_be_sold
                    ? constLabel?.lbl_can_be_sold
                    : "Can be Sold"}
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {constLabel?.lbl_can_be_pruchase
                  ? constLabel?.lbl_can_be_pruchase
                  : "Can be Purchase"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={
                    props.main_obj.prod_overview.can_be_purchased
                      ? props.main_obj.prod_overview.can_be_purchased
                      : false
                  }
                  name="can_be_purchased"
                  {...register("can_be_purchased")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.lbl_can_be_pruchase
                    ? constLabel?.lbl_can_be_pruchase
                    : "Can be Purchase"}
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {constLabel?.lbl_batch_tracking
                  ? constLabel?.lbl_batch_tracking
                  : "Batch Tracking"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={
                    props.main_obj.prod_overview.batch_tracking
                      ? props.main_obj.prod_overview.batch_tracking
                      : false
                  }
                  name="batch_tracking"
                  {...register("batch_tracking")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.lbl_batch_tracking
                    ? constLabel?.lbl_batch_tracking
                    : "Batch Tracking"}
                </label>
              </div>
            </div>

            {/* <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_beacon_id
                    ? constLabel?.lbl_beacon_id
                    : "Beacon Id"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  defaultValue={
                    props.main_obj.prod_overview.beacon_id !== null &&
                      props.main_obj.prod_overview.beacon_id !== ""
                      ? props.main_obj.prod_overview.beacon_id
                      : ""
                  }
                  placeholder="Enter BeaconId"
                  name="beacon_id"
                  {...register("beacon_id")}
                />
                {errors.beacon_id && (
                  <span className="text-danger err-msg">
                    {errors.beacon_id.message}
                  </span>
                )}
              </div>
            </div> */}

            {/* <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_qr_code_id
                    ? constLabel?.lbl_qr_code_id
                    : "QR Code Id"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  defaultValue={
                    props.main_obj.prod_overview.qr_code_id !== null &&
                      props.main_obj.prod_overview.qr_code_id !== ""
                      ? props.main_obj.prod_overview.qr_code_id
                      : ""
                  }
                  placeholder="Enter QRCodeId"
                  name="qr_code_id"
                  {...register("qr_code_id")}
                />
                {errors.qr_code_id && (
                  <span className="text-danger err-msg">
                    {errors.qr_code_id.message}
                  </span>
                )}
              </div>
            </div> */}
          </div>

          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">
            {constLabel?.lbl_product_price
              ? constLabel?.lbl_product_price
              : "Product Price"}
          </p>
          <div className="row col-12">
            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_cost ? constLabel?.lbl_cost : "Cost"}
                  <span> &#8377;</span>
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.machineCost
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter cost"
                  name="machineCost"
                  defaultValue={
                    props.main_obj.prod_overview.machineCost !== null &&
                      props.main_obj.prod_overview.machineCost !== ""
                      ? props.main_obj.prod_overview.machineCost
                      : 0.0
                  }
                  {...register("machineCost")}
                />
                {errors.machineCost && (
                  <span className="text-danger err-msg">
                    {errors.machineCost.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_sales_price
                    ? constLabel?.lbl_sales_price
                    : "Sales Price "}
                  <span> &#8377;</span>
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.salesPrice
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter sales price"
                  name="salesPrice"
                  defaultValue={
                    props.main_obj.prod_overview.salesPrice !== null &&
                      props.main_obj.prod_overview.salesPrice !== ""
                      ? props.main_obj.prod_overview.salesPrice
                      : 0.0
                  }
                  {...register("salesPrice")}
                />
                {errors.salesPrice && (
                  <span className="text-danger err-msg">
                    {errors.salesPrice.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_purchase_price
                    ? constLabel?.lbl_purchase_price
                    : "Purchase Price "}
                  <span> &#8377;</span>
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.purchase_price
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter purchase price"
                  name="purchase_price"
                  defaultValue={
                    props.main_obj.prod_overview.purchase_price !== null &&
                      props.main_obj.prod_overview.purchase_price !== ""
                      ? props.main_obj.prod_overview.purchase_price
                      : 0.0
                  }
                  {...register("purchase_price")}
                />
                {errors.purchase_price && (
                  <span className="text-danger err-msg">
                    {errors.purchase_price.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_remark ? constLabel?.lbl_remark : "Remark"}
                </label>
                <textarea
                  className="form-control mt-2"
                  id="remark"
                  type="text"
                  defaultValue={
                    props.main_obj.prod_overview.remark !== null &&
                      props.main_obj.prod_overview.remark !== ""
                      ? props.main_obj.prod_overview.remark
                      : 0.0
                  }
                  placeholder="Enter remark"
                  name="remark"
                  {...register("remark")}
                ></textarea>
              </div>
            </div>
          </div>
          <hr className="my-4" />
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-grey px-4 me-2"
              onClick={() => navigate("/pcms/product")}
            >
              {constLabel?.lbl_cancel ? constLabel?.lbl_cancel : "Cancel"}
            </button>
            <button
              className="btn button-primary px-4 me-2"
              onClick={handleSubmit(onSubmit)}
              disabled={btnDisable}
            // onClick={() => props.setCurrentStep(props.currentStep + 1)}
            >
              {constLabel?.lbl_save_continue
                ? constLabel?.lbl_save_continue
                : "Save & Continue"}
            </button>
            <button
              className="btn btn-grey px-4 me-2"
              disabled={
                Object.keys(props.main_obj.prod_overview).length > 0
                  ? false
                  : true
              }
              onClick={() => props.nextStep()}
            >
              {constLabel?.lbl_skip ? constLabel?.lbl_skip : "Skip"}
            </button>
          </div>
        </div>
      </div>

      <Modal
        show={modal}
        onHide={() => setModal(false)}
        size="xl"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Bill Of Material List"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion>
            <div className="row prod_bom_accor">
              {particularBomList.loading ? (
                <div className="loader-box justify-content-center">
                  <Spinner />
                </div>
              ) : particularBomList.data.length > 0 ? (
                particularBomList.data.map((val, ind) => (
                  <div className="col-12 col-md-6 mb-3" key={ind}>
                    <Accordion.Item eventKey={ind}>
                      <Accordion.Header className="position-relative">
                        <div className="d-flex justify-content-between align-items-center">
                          {val.bom_type}
                        </div>

                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip">
                              {constLabel?.lbl_delete_bom
                                ? constLabel?.lbl_delete_bom
                                : "Delete BOM"}
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            onClick={() => delete_bom(val)}
                            className="icon ms-3"
                            style={{
                              color: "#fff",
                              position: "absolute",
                              top: "17px",
                              right: "60px",
                            }}
                            icon={faTrashAlt}
                          // size={"lg"}
                          />
                        </OverlayTrigger>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-12 col-md-8">
                            <p className="mb-1">
                              <span className="roboto-bold ">
                                {constLabel?.lbl_remark
                                  ? constLabel?.lbl_remark
                                  : "Remark"}{" "}
                                :{" "}
                              </span>
                              {`${val.remarks}`}
                            </p>
                          </div>
                          <div className="col-12 col-md-4">
                            <p>
                              <span className="roboto-bold">
                                {constLabel?.lbl_quantity
                                  ? constLabel?.lbl_quantity
                                  : "Quantity"}{" "}
                                :{" "}
                              </span>
                              {`${val.qty}`}
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-4 roboto-bold">
                            {constLabel?.lbl_product_family
                              ? constLabel?.lbl_product_family
                              : "Product Family"}
                          </div>
                          <div className="col-12 col-md-4 roboto-bold">
                            {constLabel?.lbl_product_name
                              ? constLabel?.lbl_product_name
                              : "Product Name"}
                          </div>
                          <div className="col-12 col-md-2 roboto-bold">
                            {constLabel?.lbl_quantity
                              ? constLabel?.lbl_quantity
                              : "Quantity"}
                          </div>
                          <div className="col-12 col-md-2 roboto-bold">
                            {constLabel?.lbl_unit
                              ? constLabel?.lbl_unit
                              : "Unit"}
                          </div>
                          {val.bom_line_item.map((item, ind) => (
                            <>
                              <div className="col-12 col-md-4">
                                {item.product_family_id !== null
                                  ? item.product_family_id?.familyname
                                  : "NA"}
                              </div>
                              <div className="col-12 col-md-4">
                                {item.productname !== null
                                  ? item.productname
                                  : "NA"}
                              </div>
                              <div className="col-12 col-md-2">{item.qty}</div>
                              <div className="col-12 col-md-2">
                                {item.unitname}
                              </div>
                            </>
                          ))}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                ))
              ) : (
                <div>
                  <div
                    className="alert alert-danger text-center p-4"
                    role="alert"
                  >
                    {"No Bill Of Material Found"}
                  </div>
                </div>
              )}
            </div>
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-grey px-4" onClick={() => setModal(false)}>
            {constLabel?.lbl_close ? constLabel?.lbl_close : "Close"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ManufacturedGoods;
