import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert2";
import DatePicker from "react-datepicker";
import {
  deleteMaterialRequest,
  getMaterialRequest, import_po
} from "../../../api/index";
import {
  calMaxPage_new,
  convertDateYYMMDD,
  getLabels,
  isExcelFile,
  setAccessPrivilage
} from "../../../common/Functions/CommonFunctions";
import { Pagination, Spinner } from "react-bootstrap";
import { Trash2, FilePlus } from "react-feather";
import { useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { Material_Request_Col_name } from "../../../common/Labels/labelConstant";
import List from "../../../assets/images/icons/project_menu/checklist.png"


function MaterialRequest() {





  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [visible, setVisible] = useState(false);
  const [search, setSearch] = useState({ value: "", status: false });
  const [reset, setReset] = useState(false);
  const [filterConfig, setFilterConfig] = useState({
    suppliername: [], //array
    client_poid: [], //array
    po_status: [], //string
    startPrice: "",
    endPrice: "",
    voucher_no: "",
    quote_ref: "",
    gst: [], //array
    productName: [], //array
    stateName: [],
    productType: [],
  });
  const [constLabel, setConstLabel] = useState({});
  const [startDate, setStartDate] = useState(getPrvMonthDateFromToday);
  const [endDate, setEndDate] = useState(new Date());
  const [poStatusList, setPoStatusList] = useState({
    data: [],
    loading: false,
  });
  const [priceRange, setPriceRange] = useState({ from: 1, to: 1000 });
  const [isChecked, setIsChecked] = useState(false);

  const [showLabel, setShowLabel] = useState(Material_Request_Col_name);
  const [errorMessage, setErrorMessage] = useState("");

  const [downloadTemplate, setDownloadTemplate] = useState(false);
  const [importData, setImportData] = useState(false);

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const [privilage, setPrivilage] = useState(
    setAccessPrivilage(loginData, "Purchase")
  );

  const handleCheckboxChange = () => {
    // Toggle the isChecked state when the checkbox is changed
    let val = !isChecked;
    if (val) {
      setFilterConfig({ ...filterConfig, po_inwarded: "True" });
    } else {
      let obj = { ...filterConfig };
      delete obj.po_inwarded;
      setFilterConfig({ ...obj });
    }
    setIsChecked(!isChecked);
  };

  const handleSliderChange = (event, sliderName) => {
    const value = parseInt(event.target.value, 10);
    let obj = {};
    // Reset error message
    setErrorMessage("");

    if (sliderName == "from") {
      obj.startPrice = value;
      obj.endPrice = priceRange.to;

      // Check if startPrice is greater than or equal to endPrice, then set error message
      if (value >= priceRange.to) {
        setErrorMessage("From price must be less than To price");
        return;
      }
    } else {
      obj.endPrice = value;
      obj.startPrice = priceRange.from;

      // Check if endPrice is less than or equal to startPrice, then set error message
      if (value <= priceRange.from) {
        setErrorMessage("To price must be greater than From price");
        return;
      }
    }
    setPriceRange({ ...priceRange, [sliderName]: value });
    setFilterConfig({ ...filterConfig, ...obj });
  };
  const { control } = useForm({ mode: "onChange" }); // initialise the hook

  let navigate = useNavigate();


  useEffect(() => {
    setConstLabel(getLabels("Purchase", "Material Request"));
    const debounceTimeout = setTimeout(() => {
      fetchMaterialRequestList(1, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig, startDate, endDate]);




  function getPrvMonthDateFromToday() {
    const today = new Date();
    const endDate = new Date(today);
    endDate.setMonth(today.getMonth() - 1);
    return endDate;
  }

  function fetchMaterialRequestList(pg, epp) {
    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
      startDate: convertDateYYMMDD(new Date(startDate), "-"),
      endDate: convertDateYYMMDD(new Date(endDate), "-"),
    };
    setDataList({ ...dataList, data: [], loading: true });

    getMaterialRequest(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });
        setMaxPage(calMaxPage_new(parseInt(res.data.total), epp));
        //console.log('ma',calMaxPage_new(parseInt(res.data.total),epp))
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, data: [], loading: false });
      }
    );
  }
  //console.log("max",maxPage);



  const delRecord = (val) => {
    SweetAlert.fire({
      title: "Are you sure to delete ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteMaterialRequest({ uuid: val.uuid }).then(
          (res) => {
            toast.success("Material request deleted successfully", {
              autoClose: 2000,
            });

            if (dataList.data.length == 1 && page > 1) {
              fetchMaterialRequestList(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchMaterialRequestList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Get the selected file

    // setSelectedFile(file);

    if (isExcelFile(file.name)) {
      try {
        if (file) {
          const formData = new FormData();
          formData.append("excel_file", file);

          import_po(formData).then(
            (res) => {
              if (res.status === 200 || res.status === 201) {
                toast.success("PO imported successfully !", {
                  autoClose: 3000,
                });
                fetchMaterialRequestList(1, entriesPerPage);
              }
            },
            (err) => {
              if (err.response.status === 400) {
                toast.error(err.response, {
                  autoClose: 2000,
                });
              }

              if (err.response.status === 409) {
                SweetAlert.fire({
                  title: err.response.data.message,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonText: "Ok",
                  cancelButtonText: "cancel",
                  reverseButtons: true,
                }).then((result) => {
                  if (result.value) {
                    formData.append("allow_duplicate", "yes");
                    import_po(formData).then(
                      (res) => {
                        if (res.status === 200 || res.status === 201) {
                          toast.success("PO imported successfully !", {
                            autoClose: 3000,
                          });
                          fetchMaterialRequestList(1, entriesPerPage);
                        }
                      },
                      (err) => {
                        if (err.response.status === 400) {
                          toast.error(err.response.data?.error?.po_date[0], {
                            autoClose: 2000,
                          });
                          fetchMaterialRequestList(1, entriesPerPage);
                        }
                      }
                    );
                  }
                });
              }
            }
          );
        }
      } catch (err) {
        toast.error("Something went wrong !", {
          autoClose: 2000,
        });
      }
    } else {
      toast.error("Import valid excel file !", {
        autoClose: 2000,
      });
    }
  };



  function base64ToExcel(response) {
    const { statusCode, headers, body, isBase64Encoded } = response;

    // Assume base64String contains the base64 representation of the Excel file
    const base64String = body; // Replace this with your base64 string

    // Convert the base64 string into a Uint8Array
    const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));

    // Create a Blob object from the Uint8Array
    const blob = new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a link element with download attribute
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toDateString().split(" ").join("_");
    link.download = `Purchase_Order_${currentDate}.xlsx`;

    // Programmatically click on the link to trigger the download
    setDownloadTemplate(false);
    document.body.appendChild(link);
    link.click();

    // Cleanup: remove the link and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }


  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }



  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div id="mySidebar" className="customsidebar ">
        <a className="closebtn" onClick={() => toggleNav()}>
          ×
        </a>

        <div className=" content">















        </div>
      </div>
      <div className="min-vh-100" id="root_div_main">
        <div className="content-wrapper">
          <div className="card border-0">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="d-md-flex justify-content-start col-12 col-md-6">
                  {/* <Tooltip id={"open-tooltip"} place="top" />
                    <img
                      src={Slide}
                      height="32"
                      width="32"
                      className="cursor_pointer my-1 me-2"
                      onClick={() => toggleNav()}
                      data-tooltip-id={"open-tooltip"}
                      data-tooltip-content={"Sidebar"}
                    /> */}
                  <h1 className="bold">
                    {constLabel?.lbl_material_request
                      ? constLabel.lbl_material_request
                      : "Material Request"}
                  </h1>
                </div>

                <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                  {/* {privilage.write && (
                      <button
                        className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                        onClick={() =>
                          navigate(
                            `/pcms/create/purchase-order/${btoa("null")}/${btoa(
                              "null"
                            )}`,
                            { state: dataList.data[0] }
                          )
                        }
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_create_purchase_order
                          ? constLabel.lbl_create_purchase_order
                          : "Create Purchase Order"}
                      </button>
                    )} */}

                  {/* {privilage.report &&
                      <>
                        <Tooltip id={"download-tooltip"} place="top" />
                        <button 
                          className="btn btn-light-grey me-0 me-md-2 mt-2 mt-md-0"
                          data-tooltip-id={"download-tooltip"}
                          data-tooltip-content={
                            constLabel?.lbl_download_template
                            ? constLabel.lbl_download_template
                            : "Download Template"}
                          onClick={() => {
                            setDownloadTemplate(true);
                            get_po_template().then((res) => {
                              // console.log("res", res);
                              base64ToExcel(res.data);
                            }, (err) => {
                              console.log("err", err);
                              toast.error("Something went wrong", {
                                autoClose: 2000,
                              });
                              setDownloadTemplate(false);
                            })
                          }}
                          disabled={downloadTemplate}
                        >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faDownload}
                          size="sm"
                        />{" "}{downloadTemplate && <Spinner animation="border" size="sm" />}
                        </button>
                      </>
                    } */}

                  {/* {privilage.report &&
                    <>
                      <Tooltip id={"upload-tooltip"} place="top" />
                      <input
                        type="file"
                        accept=".pdf, .jpg, .jpeg, .png" // Define allowed file types
                        onChange={handleFileChange}
                        style={{ display: "none" }} // Hide the input element
                        id="fileInput"
                      />
                      <label htmlFor="fileInput">
                        <button
                          className="btn btn-grey me-0 me-md-2 mt-2 mt-md-0"
                          data-tooltip-id={"upload-tooltip"}
                          data-tooltip-content={
                            constLabel?.lbl_import_po
                              ? constLabel.lbl_import_po
                              : "Import PO"}
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          }
                          onChange={handleFileChange}
                        >
                          <FontAwesomeIcon
                            style={{ color: "#fff"}}
                            icon={faUpload}
                            size="sm"
                          />
                        </button>
                      </label>
                    </>
                    } */}

                  <Tooltip id={"show-tooltip"} place="top" />
                  <div
                    className="dropdown"
                    data-tooltip-id={"show-tooltip"}
                    data-tooltip-content={"Show / Hide Columns"}
                  >
                    <div
                      className="d-md-flex justify-content-start align-items-center"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {/* <FontAwesomeIcon icon={faListCheck} size="lg" /> */}
                      {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                      <img
                        src={List}
                        height="32"
                        width="32"
                        className="cursor_pointer"
                      />
                    </div>

                    <ul className="dropdown-menu px-2">
                      {showLabel !== undefined && showLabel.length > 0
                        ? showLabel.map((val, ind, arr) => (
                          <li key={ind}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                style={{ width: "20px", height: "20px" }}
                                onChange={() => handleClick(ind)}
                                value=""
                                checked={val.flag}
                              />
                              <label
                                className=""
                                style={{ fontSize: "13px" }}
                                htmlFor="flexCheckDefault"
                              >
                                {val.label}
                              </label>
                            </div>
                          </li>
                        ))
                        : null}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                {dataList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : dataList.data.length > 0 ? (
                  <div
                    className="table-responsive"
                  >
                    <table className="table table-bordered">
                      <thead className="table-grey roboto-bold">
                        <tr className="">
                          <th scope="col">{"Sr.No"}</th>

                          {showLabel.map((v, i) =>
                            v.label === "MR Number" && v.flag ? (
                              <th key={i} scope="col">
                                {constLabel?.lbl_po_id
                                  ? constLabel.lbl_po_id
                                  : "MR Number"}
                              </th>
                            ) : v.label === "Material Request Date" && v.flag ? (
                              <th key={i} scope="col">
                                {constLabel?.lbl_date
                                  ? constLabel.lbl_date
                                  : "Material Request Date"}
                              </th>
                            ) : v.label === "Status" && v.flag ? (
                              <th key={i} scope="col">
                                {constLabel?.lbl_status
                                  ? constLabel.lbl_status
                                  : "Status"}
                              </th>
                            ) : v.label === "Remark" && v.flag ? (
                              <th key={i} scope="col">
                                {constLabel?.lbl_remark
                                  ? constLabel.lbl_remark
                                  : "Remark"}
                              </th>
                            )
                              : null
                          )}

                          <th scope="col" width="15%">
                            {"Action"}
                          </th>
                        </tr>
                      </thead>
                      <tbody className=" roboto">
                        {dataList.data.map((val, ind) => (
                          <tr key={ind}>
                            <td>{parseInt(ind) + 1 + parseInt(srNo)}</td>
                            {showLabel.map((item, idx) =>
                              item.label === "MR Number" && item.flag ? (
                                <td key={idx}>
                                  {" "}
                                  {val.material_req_id != "" && val.material_req_id !== null
                                    ? val.material_req_id
                                    : "NA"}
                                </td>
                              ) : item.label === "Material Request Date" &&
                                item.flag ? (
                                <td key={idx}>
                                  {val.material_req_date
                                    ? convertDateYYMMDD(new Date(val.material_req_date), "-")
                                    : "NA"}
                                </td>
                              ) : item.label === "Status" && item.flag ? (
                                <td key={idx}>
                                  {val.status ? val.status : "NA"}
                                </td>
                              ) : item.label === "Remark" && item.flag ? (
                                <td key={idx}>
                                  {val.remarks ? val.remarks : "NA"}
                                </td>
                              ) : null
                            )}

                            <td>






                              {privilage.write &&
                                <>
                                  <Tooltip
                                    id={"po-tooltip" + ind}
                                    place="top"
                                  />
                                  <FilePlus
                                    data-tooltip-id={"delete-tooltip" + ind}
                                    data-tooltip-content={
                                      constLabel?.lbl_create_po
                                        ? constLabel.lbl_create_po
                                        : "Create Purchase Order"
                                    }                                    
                                    className="menu_icon_grey cursor_pointer me-1"
                                    size={20}
                                    onClick={() => {
                                      navigate(
                                        `/pcms/create/purchase-order/${btoa("null")}/${btoa(
                                          "null"
                                        )}`,
                                        { state: val }
                                      )
                                    }
                                    }
                                  />
                                </>
                              }

                              {privilage.delete &&
                                <>
                                  <Tooltip
                                    id={"delete-tooltip" + ind}
                                    place="top"
                                  />
                                  <Trash2
                                    data-tooltip-id={"delete-tooltip" + ind}
                                    data-tooltip-content={
                                      constLabel?.lbl_delete_material_request
                                        ? constLabel.lbl_delete_material_request
                                        : "Delete Material Request"
                                    }
                                    className="menu_icon_red cursor_pointer"
                                    size={20}
                                    onClick={() => {
                                      delRecord(val);
                                      console.log("asdflk")
                                    }
                                    }
                                  />
                                </>
                              }
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div
                    className="alert alert-danger mb-0 text-center my-4"
                    role="alert"
                  >
                    {"No Material Request Found"}
                  </div>
                )}

                {dataList.loading ? null : (
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <p className="mb-0">
                      Show
                      <select
                        className="mx-1"
                        defaultValue={entriesPerPage}
                        onChange={(e) => {
                          setEntriesPerPage(e.target.value);
                          fetchMaterialRequestList(1, e.target.value);
                        }}
                      >
                        <option value={"10"}>10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      Entries
                    </p>
                    <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                      } to ${Math.min(
                        parseInt(entriesPerPage) + parseInt(srNo),
                        dataList.totalRecords
                      )} of ${dataList.totalRecords} entries`}</p>
                    <Pagination className="my-2">
                      <Pagination.Prev
                        disabled={page === 1 ? true : false}
                        onClick={() => {
                          fetchMaterialRequestList(page - 1, entriesPerPage);
                          setSrNo((prevC) =>
                            page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                          );
                          setPage(page - 1);
                        }}
                      >
                        {"Prev"}
                      </Pagination.Prev>

                      <Pagination.Item active>{page}</Pagination.Item>

                      <Pagination.Next
                        disabled={
                          page === maxPage || maxPage === 0 ? true : false
                        }
                        onClick={() => {
                          fetchMaterialRequestList(page + 1, entriesPerPage);
                          setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                          setPage(page + 1);
                        }}
                      >
                        {"Next"}
                      </Pagination.Next>
                    </Pagination>
                  </div>
                )}
              </div>

            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MaterialRequest;
