import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faFilter, faCog ,  faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { Pagination ,Spinner } from "react-bootstrap";
import { Eye } from "react-feather";

import { Tooltip } from "react-tooltip";
import { useSelector } from "react-redux";
import { Goods_Challan_COL_Name } from "../../common/Labels/labelConstant";
import {
  calMaxPage_new,
  convertDateYYMMDD,
  formatDate5,
  getLabels,
  setAccessPrivilage,
} from "../../common/Functions/CommonFunctions";


import { Challan_Excel, getOperation,  get_challan } from "../../api";
import { useLocation, useNavigate } from "react-router-dom";
import List from "../../assets/images/icons/project_menu/checklist.png"
import Slide from "../../assets/images/icons/project_menu/collapse.png"
import Back from "../../assets/images/icons/project_menu/back-button.png"

function GoodsChallanWorkOrderView() {
  let navigate = useNavigate();
  const location = useLocation();

  // console.log("location",location);
  // console.log("location",location);
  const [showLabel, setShowLabel] = useState(Goods_Challan_COL_Name);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);

  const [work_order_no, setWork_order_no] = useState();
  const [startDate, setStartDate] = useState();
  const [endate, setEndDate] = useState();

  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [opsList, setopsList] = useState({
    data: [],
    loading: true,
  });

  const [filterConfig, setFilterConfig] = useState({
    operation: [],
  });

  const [constLabel, setConstLabel] = useState({});

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage] = useState(setAccessPrivilage(loginData, "Goods Challan"));
  const [lockBtn, setLockBtn] = useState(false);

  useEffect(() => {
    setConstLabel(getLabels("Inventory", "Goods Challan"));

  

    if (location.state !== null) {
      setPage(1);
      fetchchallan(page, entriesPerPage);
      setWork_order_no(location.state.work_order_no);
      setStartDate(location.state.startDate);
      setEndDate(location.state.endDate);
      fetchOperation();
      setWork_order_no(location.state.work_order_no);
      // setStartDate(location.state.startDate);
      setEndDate(location.state.endDate);
      fetchOperation();
    }

    if (location.state == null && work_order_no) {
      fetchchallan(page, entriesPerPage, work_order_no, startDate, endate);
    }
    if (location.state == null && work_order_no) {
      fetchchallan(page, entriesPerPage, work_order_no, startDate, endate);
    }

    // console.log("useffect")
    // console.log("work_orfer_number",work_order_no)
  }, [filterConfig, location.state]);

  function clearFilter() {
    setFilterConfig({
      ...filterConfig,
      operation: [],
    });
    setSrNo(0);
    setPage(1);
  }

  
  function fetchchallan(pg, epp, wono = null, SD = null, ED = null) {
    setDataList({ ...dataList, loading: true, data: [] });

    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
      work_order_no:
        wono == null ? location.state.work_order_no : work_order_no,
      startDate: convertDateYYMMDD(
        new Date(SD == null ? location.state.startDate : SD),
        "-"
      ),
      endDate: convertDateYYMMDD(
        new Date(ED == null ? location.state.endDate : ED),
        "-"
      ),
      work_order_no:
        wono == null ? location.state.work_order_no : work_order_no,
      startDate: convertDateYYMMDD(
        new Date(SD == null ? location.state.startDate : SD),
        "-"
      ),
      endDate: convertDateYYMMDD(
        new Date(ED == null ? location.state.endDate : ED),
        "-"
      ),
    };

    if (filterConfig.operation.length > 0) {
      payload.operation_name = filterConfig.operation?.[0]?.operationName;
    } else {
      delete payload.operation_name;
    }

    
    // console.log("payload",payload)
    get_challan(payload).then(
      (res) => {
        // console.log("res", res.data.data);
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });
        setMaxPage(calMaxPage_new(res.data.total, epp));
        // console.log("calMaxPage_new(res.data.total, epp)",calMaxPage_new(res.data.total, epp))
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, loading: false, data: [] });
      }
    );
  }


  function fetchOperation() {
    setopsList({ loading: true, data: [] });
    setopsList({ loading: true, data: [] });

    getOperation().then(
      (res) => {
        setopsList({
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        setopsList({ ...dataList, loading: false, data: [] });
      }
    );
  }


  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function toggleDiv(ind) {
    const selRow = document.getElementById("collapse_row" + ind);

    selRow.classList.toggle("expanded");
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");
  
    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  function base64ToExcel(response) {
    const { statusCode, headers, body, isBase64Encoded } = response;

    // Assume base64String contains the base64 representation of the Excel file
    const base64String = body; // Replace this with your base64 string

    // Convert the base64 string into a Uint8Array
    const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));

    // Create a Blob object from the Uint8Array
    const blob = new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a link element with download attribute
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toDateString().split(" ").join("_");
    link.download = `ChallanReport_${currentDate}.xlsx`;

    // Programmatically click on the link to trigger the download
    document.body.appendChild(link);
    link.click();

    // Cleanup: remove the link and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    setLockBtn(false);
  }

  async function goToDownload() {
    setLockBtn(true);

    const payload = {};
     
    payload.startDate= convertDateYYMMDD(new Date(startDate), "-");
    payload.endDate=convertDateYYMMDD(new Date(endate), "-");

    
  
    if (work_order_no !=="") {
      payload.work_order_no =work_order_no.trim();

    } else {
      delete payload.work_order_no;
    }

   

    await Challan_Excel(payload).then(
      (res) => {
        //
        if (res) {
          // excel_download(res.data.data);
          base64ToExcel(res.data);

        }
      },
      (err) => {
        toast.error(err.message, {
          autoClose: 2000,
        });
      }
    );
  }
  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}
      <div className="min-vh-100 " id="root_div_main">
        {privilage.read && (
          <div id="mySidebar" className="customsidebar ">
            <a className="closebtn" onClick={() => toggleNav()}>
              ×
            </a>

            <div className=" content">
              <div>
                <label
                  className="filterLabel my-2 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {constLabel?.operation ? constLabel.operation : "Operation"}
                </label>
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="operationName"
                  multiple={false}
                  // size="sm"
                  options={opsList.data}
                  isLoading={opsList.loading}
                  selected={filterConfig.operation}
                  onChange={(val) => {
                    if (val.length > 0) {
                      // console.log(val);
                      setFilterConfig({ ...filterConfig, operation: val });
                    } else {
                      setFilterConfig({ ...filterConfig, operation: [] });
                    }
                  }}
                  placeholder="Select operation"
                />
              </div>

              <div className="my-3 d-flex justify-content-end">
                <button
                  onClick={() => clearFilter()}
                  className="btn btn-sm btn-yellow "
                >
                  <FontAwesomeIcon
                    style={{ color: "#344454" }}
                    icon={faBan}
                    size="sm"
                  />{" "}
                  {constLabel?.lbl_clear_filter
                    ? constLabel.lbl_clear_filter
                    : "Clear Filter"}
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="content-wrapper">
          <div className="card border-0">
            <div className="card-body">
              <div className="row align-items-center">
                  <div className="d-md-flex justify-content-start col-12 col-md-6">
                    <Tooltip id={"open-tooltip"} place="top" />
                    <img
                      src={Slide}
                      height="32"
                      width="32"
                      className="cursor_pointer my-1 me-2"
                      onClick={() => toggleNav()}
                      data-tooltip-id={"open-tooltip"}
                      data-tooltip-content={"Sidebar"}
                    />

                    <Tooltip id={"back-tooltip"} place="top" />
                    <img
                      src={Back}
                      height="32"
                      width="32"
                      className="cursor_pointer my-1 me-2"
                      onClick={() => navigate("/pcms/goodsMovementChallan")}
                      data-tooltip-id={"back-tooltip"}
                      data-tooltip-content={"Back"}
                    />

                    <h1 className="bold">
                      {constLabel?.lbl_goods_challan
                        ? constLabel?.lbl_goods_challan
                        : "Goods Challan"}{" - "}{`${work_order_no}`}
                    </h1>
                  </div>

                  <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">

                  {privilage.report && (
                      <button
                        className="btn btn-light-grey me-0 me-md-2 mt-2 mt-md-0"
                        disabled={lockBtn}
                        onClick={() => goToDownload()}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faDownload}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_challan_report
                            ? constLabel.lbl_challan_report
                            : "Challan Report"}{" "}
                          {lockBtn ? (
                            <Spinner animation="border" size="sm" />
                          ) : null}{" "}
                      </button>
                    )}
                    <Tooltip id={"show-tooltip"} place="top" />
                    <div
                      className="dropdown"
                      data-tooltip-id={"show-tooltip"}
                      data-tooltip-content={"Show / Hide Columns"}
                    >
                      <div
                        className="d-md-flex justify-content-start align-items-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <FontAwesomeIcon icon={faListCheck} size="lg" /> */}
                        {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                        <img
                          src={List}
                          height="32"
                          width="32"
                          className="cursor_pointer"
                        />
                      </div>

                      <ul className="dropdown-menu px-2">
                        {showLabel !== undefined && showLabel.length > 0
                          ? showLabel.map((val, ind, arr) => (
                              <li key={ind}>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    style={{ width: "20px", height: "20px" }}
                                    onChange={() => handleClick(ind)}
                                    value=""
                                    checked={val.flag}
                                  />
                                  <label
                                    className=""
                                    style={{ fontSize: "13px" }}
                                    htmlFor="flexCheckDefault"
                                  >
                                    {val.label}
                                  </label>
                                </div>
                              </li>
                            ))
                          : null}
                      </ul>
                    </div>
                  </div>
              </div>
              <div className="mt-3 ">
                {dataList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : dataList && dataList.data?.length > 0 ? (
                  <>
                    <div className="table-responsive pc">
                      <table
                        className="table table-bordered"
                      >
                        <thead className="table-grey roboto">
                          <tr className="" key={Date.now()}>
                            <th scope="col">{"Sr.No"}</th>
                            {showLabel.map((v, i) =>
                              v.label === "Good Challan Id" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_goods_challan_id
                                    ? constLabel?.lbl_goods_challan_id
                                    : "Good Challan Id"}
                                </th>
                              ) : null
                            )}
                            {showLabel.map((v, i) =>
                              v.label === "Challan Type" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_challan_type
                                    ? constLabel?.lbl_challan_type
                                    : "Challan Type"}
                                </th>
                              ) : null
                            )}
                            {showLabel.map((v, i) =>
                              v.label === "Challan Reference" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_out_chllan_ref
                                    ? constLabel?.lbl_out_chllan_ref
                                    : "Challan Reference"}
                                </th>
                              ) : null
                            )}
                             <th  scope="col">
                                  {constLabel?.lbl_productname
                                    ? constLabel?.lbl_productname
                                    : "Product Name"}
                                </th>


                                <th  scope="col">
                                  {constLabel?.lbl_quantity
                                    ? constLabel?.lbl_quantity
                                    : "Quantity"}
                                </th> 
                                <th  scope="col">
                                  {constLabel?.lbl_uom
                                    ? constLabel?.lbl_uom
                                    : "Quantity"}
                                </th>    
                            {showLabel.map((v, i) =>
                              v.label === "Quantity" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_quantity
                                    ? constLabel?.lbl_quantity
                                    : "Quantity"}
                                </th>
                              ) : null
                            )}
                            {showLabel.map((v, i) =>
                              v.label === "From Location" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_from_location
                                    ? constLabel?.lbl_from_location
                                    : "From Location"}
                                </th>
                              ) : null
                            )}
                            {showLabel.map((v, i) =>
                              v.label === "To Location" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_to_location
                                    ? constLabel?.lbl_to_location
                                    : "To Location"}
                                </th>
                              ) : null
                            )}
                            {showLabel.map((v, i) =>
                              v.label === "Date" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_date
                                    ? constLabel?.lbl_date
                                    : "Date"}
                                </th>
                              ) : null
                            )}
                            {showLabel.map((v, i) =>
                              v.label === "Lot No" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_lot_no
                                    ? constLabel?.lbl_lot_no
                                    : "Lot No"}
                                </th>
                              ) : null
                            )}
                            {showLabel.map((v, i) =>
                              v.label === "Product Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_productname
                                    ? constLabel?.lbl_productname
                                    : "Product Name"}
                                </th>
                              ) : null
                            )}
                            {showLabel.map((v, i) =>
                              v.label === "Status" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_status
                                    ? constLabel?.lbl_status
                                    : "Status"}
                                </th>
                              ) : null
                            )}
                            {showLabel.map((v, i) =>
                              v.label === "Work Order" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_work_order
                                    ? constLabel?.lbl_work_order
                                    : "Work Order"}
                                </th>
                              ) : null
                            )}
                            {showLabel.map((v, i) =>
                              v.label === "Job Card" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_job_card
                                    ? constLabel?.lbl_job_card
                                    : "Job Card"}
                                </th>
                              ) : null
                            )}
                            <th scope="col" width="15%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="roboto">
                          {dataList.data?.map((val, i) => (
                            <>
                              <tr key={i} className="">
                                {/* <td>{val.NCReasonId}</td> */}
                                <td>{i + parseInt(srNo) + 1}</td>
                                {showLabel.map((item, idx) =>
                                  item.label === "Good Challan Id" &&
                                  item.flag ? (
                                    <td key={idx}>
                                      {val.gmchallanid != "" &&
                                      val.gmchallanid !== null
                                        ? val.gmchallanid
                                        : "NA"}
                                    </td>
                                  ) : null
                                )}

                                {showLabel.map((item, idx) =>
                                  item.label === "Challan Type" && item.flag ? (
                                    <td key={idx}>
                                      {val.challantype != "" &&
                                      val.challantype !== null
                                        ? val.challantype
                                        : "NA"}
                                    </td>
                                  ) : null
                                )}
                                {showLabel.map((item, idx) =>
                                  item.label === "Challan Reference" &&
                                  item.flag ? (
                                    <td key={idx}>
                                      {val.outwardchallanid != "" &&
                                      val.outwardchallanid !== null
                                        ? val.outwardchallanid[0].challanid
                                        : "NA"}
                                    </td>
                                  ) : null
                                )}

                               
                                    <td scope="col">
                                      {val.challan_line_items[0].product_name?val.challan_line_items[0].product_name:"NA"}
                                    </td>
                                    <td scope="col">
                                      {val.challan_line_items[0].qty?val.challan_line_items[0].qty:"NA"}
                                    </td>
                                    <td scope="col">
                                      {val.challan_line_items[0].uom?val.challan_line_items[0].uom:"NA"}
                                    </td>
                                {showLabel.map((item, idx) =>
                                  item.label === "Quantity" && item.flag ? (
                                    <td key={idx}>
                                      {val.qty != "" && val.qty !== null
                                        ? val.qty
                                        : "NA"}
                                    </td>
                                  ) : null
                                )}
                                {showLabel.map((item, idx) =>
                                  item.label === "From Location" &&
                                  item.flag ? (
                                    <td key={idx}>
                                      {val.fromDetails !== null
                                        ? val.fromDetails.locationname
                                        : "NA"}
                                    </td>
                                  ) : null
                                )}
                                {showLabel.map((item, idx) =>
                                  item.label === "To Location" && item.flag ? (
                                    <td key={idx}>
                                      {val.toDetails !== null
                                        ? val.toDetails.locationname
                                        : "NA"}
                                    </td>
                                  ) : null
                                )}
                                {showLabel.map((item, idx) =>
                                  item.label === "Date" && item.flag ? (
                                    <td key={idx}>
                                      {val.challandate != "" &&
                                      val.challandate !== null
                                        ? formatDate5(val.challandate)
                                        : "NA"}
                                    </td>
                                  ) : null
                                )}
                                {showLabel.map((item, idx) =>
                                  item.label === "Lot No" && item.flag ? (
                                    <td key={idx}>
                                      {val.lotnum != "" && val.lotnum !== null
                                        ? val.lotnum
                                        : "NA"}
                                    </td>
                                  ) : null
                                )}
                                {showLabel.map((item, idx) =>
                                  item.label === "Product Name" && item.flag ? (
                                    <td key={idx}>
                                      {val.product_details != "" &&
                                      val.product_details !== null
                                        ? val.product_details.productName
                                        : "NA"}
                                    </td>
                                  ) : null
                                )}
                                {showLabel.map((item, idx) =>
                                  item.label === "Status" && item.flag ? (
                                    <td key={idx}>
                                      {val.challanstate != "" &&
                                      val.challanstate !== null
                                        ? val.challanstate
                                        : "NA"}
                                    </td>
                                  ) : null
                                )}
                                {showLabel.map((item, idx) =>
                                  item.label === "Work Order" && item.flag ? (
                                    <td key={idx}>
                                      {val.work_order_id != "" &&
                                      val.work_order_id !== null
                                        ? val.work_order_id
                                        : "NA"}
                                    </td>
                                  ) : null
                                )}
                                {showLabel.map((item, idx) =>
                                  item.label === "Job Card" && item.flag ? (
                                    <td key={idx}>
                                      {val.job_card_id != "" &&
                                      val.job_card_id !== null
                                        ? val.job_card_id
                                        : "NA"}
                                    </td>
                                  ) : null
                                )}
                                <td>
                                  {privilage.read ? (
                                    <>
                                      <Tooltip id={"view-tooltip" + i} place="top" />
                                      <Eye
                                        data-tooltip-id={"view-tooltip" + i}
                                        data-tooltip-content={
                                          constLabel?.lbl_view_challan
                                            ? constLabel.lbl_view_challan
                                            : "View Challan"
                                        }
                                        onClick={() => {
                                          toggleDiv(i, val);
                                        }}
                                        className="menu_icon_grey cursor_pointer"
                                        size={20}
                                      />
                                    </>
                                  ) : null}
                                </td>
                              </tr>
                              <tr
                                className={"collapsible-row"}
                                id={"collapse_row" + i}
                              >
                                <td colSpan="12">
                                  <div className="collapse-content">
                                    <div className="row mb-3">
                                      <div className="col-12 col-md-1">
                                        <label className="roboto-bold">
                                          {"Sr.No"}
                                        </label>
                                      </div>
                                      <div className="col-12 col-md-2">
                                        <label className="roboto-bold">
                                          {constLabel?.lbl_work_order
                                            ? constLabel.lbl_work_order
                                            : "Work Order"}
                                        </label>
                                      </div>
                                      <div className="col-12 col-md-2">
                                        <label className="roboto-bold">
                                          {constLabel?.lbl_job_card
                                            ? constLabel.lbl_job_card
                                            : "Job Card"}
                                        </label>
                                      </div>
                                      <div className="col-12 col-md-2">
                                        <label className="roboto-bold">
                                          {constLabel?.lbl_part_number
                                            ? constLabel.lbl_part_number
                                            : "Part Number"}
                                        </label>
                                      </div>
                                      <div className="col-12 col-md-2">
                                        <label className="roboto-bold">
                                          {constLabel?.lbl_operation
                                            ? constLabel.lbl_operation
                                            : "Operation"}
                                        </label>
                                      </div>
                                      <div className="col-12 col-md-1">
                                        <label className="roboto-bold">
                                          {constLabel?.lbl_WO_quantity
                                            ? constLabel.lbl_WO_quantity
                                            : "WO Quantity"}
                                        </label>
                                      </div>
                                      <div className="col-12 col-md-2">
                                        <label className="roboto-bold">
                                          {constLabel?.lbl_hsn
                                            ? constLabel.lbl_hsn
                                            : "HSN"}
                                        </label>
                                      </div>
                                    </div>
                                    {val.challan_line_items &&
                                      val.challan_line_items.length > 0 &&
                                      val.challan_line_items.map(
                                        (vv, index) => (
                                          <div className="row " key={index}>
                                            <div className="col-12 col-md-1">
                                              <label className="roboto">
                                                {index + 1}
                                              </label>
                                            </div>

                                            <div className="col-12 col-md-2">
                                              <label className="roboto">
                                                {vv.work_order_no
                                                  ? vv.work_order_no
                                                  : "NA"}
                                              </label>
                                            </div>
                                            <div className="col-12 col-md-2">
                                              <label className="roboto">
                                                {vv.job_card_no
                                                  ? vv.job_card_no
                                                  : "NA"}
                                              </label>
                                            </div>

                                            <div className="col-12 col-md-2">
                                              <label className="roboto">
                                                {vv.partNo?vv.partNo:"NA"}
                                              </label>
                                            </div>

                                            <div className="col-12 col-md-2">
                                              <label className="roboto">
                                                {vv.operation_name
                                                  ? vv.operation_name
                                                  : "NA"}
                                              </label>
                                            </div>
                                            <div className="col-12 col-md-1">
                                              <label className="roboto">
                                                {vv.work_order_quantity?vv.work_order_quantity:"NA"}
                                              </label>
                                            </div>
                                            <div className="col-12 col-md-2">
                                              <label className="roboto">
                                                {vv.hsn?vv.hsn:"NA"}
                                              </label>
                                            </div>
                                          </div>
                                        )
                                      )}
                                  </div>
                                </td>
                              </tr>
                            </>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                    <p className="mb-0">
                        Show
                        <select
                          className="mx-1"
                          defaultValue={entriesPerPage}
                          onChange={(e) => {
                            setEntriesPerPage(e.target.value);
                            fetchchallan(
                              1,
                              e.target.value,
                              work_order_no,
                              startDate,
                              endate
                            );
                          }}
                        >
                          <option value={"10"}>10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        Entries
                      </p>
                      <p className="mb-0">{`Showing ${
                        parseInt(srNo) + 1
                      } to ${Math.min(
                        parseInt(entriesPerPage) + parseInt(srNo),
                        dataList.totalRecords
                      )} of ${dataList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchchallan(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1
                                ? 0
                                : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                            maxPage === 0 ||
                            entriesPerPage > dataList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            fetchchallan(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  </>
                ) : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"Challan not found !"}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
     
    </React.Fragment>
  );
}

export default GoodsChallanWorkOrderView;
