import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlus,
  faBan,
  faFilter,
  faPercentage,
  faPercent,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Modal, Spinner } from "react-bootstrap";
import { Download, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { get_gst_slab, save_gst_slab, delete_gst_slab } from "../../../api";
import {
  calMaxPage,
  getLabels,
  setAccessPrivilage,
} from "../../../common/Functions/CommonFunctions";
import EditGSTSlab from "./EditGSTSlab";
import { Tooltip } from "react-tooltip";
import { GST_Slab_Col_Name } from "../../../common/Labels/labelConstant";
import { useSelector } from "react-redux";
import List from "../../../assets/images/icons/project_menu/checklist.png";

const schema = yup
  .object()
  .shape({
    tax_name: yup.string().required("Tax name is required!"),
    tax_type: yup.string().required("Tax type is required!"),
    rate: yup
      .string()
      .required("Rate is required!")
      .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid rate")
      .test(
        "is-between-1-and-100",
        "Rate must be between 1 and 100",
        (value) => {
          if (!value) return true; // Let Yup.string().required() handle the required validation
          const numericValue = parseFloat(value);
          return numericValue >= 1 && numericValue <= 100;
        }
      ),
  })
  .required();

function GSTSlab() {
  const [showLabel, setShowLabel] = useState(GST_Slab_Col_Name);
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [sea_val, setSea_val] = useState(false);

  const [filterConfig, setFilterConfig] = useState({ searchVal: "" });

  const [constLabel, setConstLabel] = useState({});

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage] = useState(setAccessPrivilage(loginData, "GST Slab"));

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    setConstLabel(getLabels("Master", "GST Slab"));
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchGST_SlabList(page, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function clearFilter() {
    setFilterConfig({ ...filterConfig, searchVal: "" });
  }

  function fetchGST_SlabList(pg, epp) {
    setDataList({ ...dataList, loading: true, data: [] });

    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
    };

    if (filterConfig.searchVal != "") {
      payload.searchVal = filterConfig.searchVal;
      if (
        filterConfig.searchVal?.length > 0 &&
        page !== 1 &&
        sea_val == false
      ) {
        payload.pageNo = 1;
        payload.entriesPerPage = entriesPerPage;

        setPage(1);
        setSrNo(0);
      }
    } else {
      delete payload.searchVal;
    }

    get_gst_slab(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });

        if (
          filterConfig.searchVal != "" &&
          filterConfig.searchVal?.length > 0
        ) {
          setSea_val(true);
        } else {
          setSea_val(false);
        }

        setMaxPage(calMaxPage(res.data.total));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, loading: false, data: [] });
      }
    );
  }

  const onSubmit = (data) => {
    if (data !== "") {
      const reqPayload = {
        tax_name: data.tax_name.trim(),
        tax_type: data.tax_type.trim().toUpperCase(),
        rate: parseInt(data.rate),
      };

      save_gst_slab(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("GST slab created successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            fetchGST_SlabList(page, entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  // Deleting GST slab
  const delGST_Slab = (val) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          uuid: val.uuid,
        };
        delete_gst_slab(payload).then(
          (res) => {
            toast.success("GST slab deleted successfully !", {
              autoClose: 3000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchGST_SlabList(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchGST_SlabList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", { autoClose: 3000 });
          }
        );
      }
    });
  };

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}
      <div className="min-vh-100" id="root_div_main">
        <Modal
          show={visible}
          onHide={() => {
            setVisible(false);
            reset();
          }}
          size={"md"}
        >
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>
              {constLabel?.lbl_add_gst_slab
                ? constLabel.lbl_add_gst_slab
                : "Add GST Slab"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form action="">
              <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 roboto-bold"
                >
                  {constLabel?.lbl_tax_name
                    ? constLabel.lbl_tax_name
                    : "Tax Name"}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text"
                    className={
                      errors.tax_name
                        ? "form-control  is-invalid"
                        : "form-control "
                    }
                    id="tax_name"
                    name="tax_name"
                    placeholder="Enter tax name"
                    {...register("tax_name")}
                  />
                  <span className="text-danger err-msg">
                    {errors.tax_name?.message}
                  </span>
                </div>
              </div>

              <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 roboto-bold"
                >
                  {constLabel?.lbl_tax_type
                    ? constLabel.lbl_tax_type
                    : "Tax Type"}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text"
                    className={
                      errors.tax_type
                        ? "form-control  is-invalid"
                        : "form-control "
                    }
                    id="tax_type"
                    name="tax_type"
                    placeholder="Enter tax type"
                    {...register("tax_type")}
                  />
                  <span className="text-danger err-msg">
                    {errors.tax_type?.message}
                  </span>
                </div>
              </div>

              <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 roboto-bold"
                >
                  {constLabel?.lbl_rate ? constLabel.lbl_rate : "Rate"} (%)
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-12">
                  <div className="input-group">
                    <input
                      type="text"
                      className={
                        errors.rate
                          ? "form-control  is-invalid"
                          : "form-control "
                      }
                      id="rate"
                      name="rate"
                      min={1}
                      max={100}
                      placeholder="Enter rate"
                      {...register("rate")}
                    />
                    <span className="input-group-text">
                      <FontAwesomeIcon
                        style={{ color: "#212529", cursor: "pointer" }}
                        icon={faPercent}
                        size="sm"
                      />
                    </span>
                  </div>
                  <span className="text-danger err-msg">
                    {errors.rate?.message}
                  </span>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleSubmit(onSubmit)}
              className="f-16 btn btn-yellow "
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
            </button>
          </Modal.Footer>
        </Modal>

        {privilage.read && (
          <div id="mySidebar" className="customsidebar ">
            <a className="closebtn" onClick={() => closeNav()}>
              ×
            </a>

            <div className=" content">
              <div>
                <label
                  className="filterLabel mb-1 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {constLabel?.lbl_search ? constLabel.lbl_search : "Search"}
                </label>
                <input
                  className="form-control form-control-sm"
                  type="text"
                  placeholder="Search by name / type"
                  value={
                    filterConfig.searchVal.length > 0
                      ? filterConfig.searchVal
                      : ""
                  }
                  onChange={(val) => {
                    const inputValue = val.target.value; // Trim leading and trailing whitespaces

                    if (inputValue.trim() !== "") {
                      setFilterConfig({
                        ...filterConfig,
                        searchVal: [inputValue],
                      });
                    } else {
                      // setFilterConfig({ ...filterConfig, searchVal: [] });
                    }
                  }}
                />
              </div>
              <div className="my-3 d-flex justify-content-end">
                <button
                  onClick={() => clearFilter()}
                  className="btn btn-sm btn-yellow "
                >
                  <FontAwesomeIcon
                    style={{ color: "#344454" }}
                    icon={faBan}
                    size="sm"
                  />{" "}
                  {constLabel?.lbl_clear_filter
                    ? constLabel.lbl_clear_filter
                    : "Clear Filter"}
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="content-wrapper">
          <div className="card border-0">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="d-md-flex justify-content-start align-items-center col-12 col-md-6">
                  {/* <FontAwesomeIcon
                      onClick={() => toggleNav()}
                      className="cursor_pointer mb-2"
                      style={{
                        color: "Black",
                        marginRight: "10px",
                      }}
                      icon={Slide}
                      size="lg"
                    /> */}
                  <h1 className="bold">
                    {constLabel?.lbl_gst_slab ? constLabel.lbl_gst_slab : "GST Slab"}
                  </h1>
                </div>
                <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                  <div className="col-12 col-md-6 me-md-2 me-md-0">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search by name / type"
                      value={filterConfig.searchVal}
                      onChange={(val) => {
                        const inputValue = val.target.value;
                        if (!inputValue.trim()) {
                          // Input contains only whitespace, don't trigger the search.
                          if (filterConfig.searchVal.length == 1) {
                            setFilterConfig({ ...filterConfig, searchVal: "" });
                          }
                        } else {
                          setFilterConfig({
                            ...filterConfig,
                            searchVal: inputValue,
                          });
                        }
                        if(inputValue.length == 0){
                          setFilterConfig({ ...filterConfig, searchVal: "" });
                        }
                      }}
                    />
                  </div>
                  {/* {privilage.write && (
                    <button
                      className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                      onClick={() => {
                        setVisible(true);
                        reset();
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faPlus}
                        size="sm"
                      />{" "}
                      {constLabel?.lbl_add_gst_slab
                        ? constLabel?.lbl_add_gst_slab
                        : "Add GST Slab"}
                    </button>
                  )} */}

                  <Tooltip id={"show-tooltip"} place="top" />
                  <div
                    className="dropdown"
                    data-tooltip-id={"show-tooltip"}
                    data-tooltip-content={"Show / Hide Columns"}
                  >
                    <div
                      className="d-md-flex justify-content-start align-items-center"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {/* <FontAwesomeIcon icon={faCog} size="lg" /> */}
                      {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                      <img src={List} height='32' width='32' className="cursor_pointer" />
                    </div>

                    <ul className="dropdown-menu px-2">
                      {showLabel !== undefined && showLabel.length > 0
                        ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                {dataList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : dataList && dataList.data?.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                      >
                        <thead className="table-grey roboto">
                          <tr className="">
                            <th scope="col" width="15%">
                              {"Sr.No"}
                            </th>
                            {showLabel.map((v, i) =>
                              v.label === "Tax Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_tax_name
                                    ? constLabel.lbl_tax_name
                                    : "Tax Name"}
                                </th>
                              ) : v.label === "Tax Type" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_tax_type
                                    ? constLabel.lbl_tax_type
                                    : "Tax Type"}
                                </th>
                              ) : v.label === "Rate" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_rate
                                    ? constLabel.lbl_rate
                                    : "Rate"}{" "}
                                  %
                                </th>
                              ) : null
                            )}
                            {/* <th scope="col" width="15%">
                            {"Action"}
                          </th> */}
                          </tr>
                        </thead>
                        <tbody className="roboto">
                          {dataList.data?.map((val, i) => (
                            <tr key={i} className="">
                              {/* <td>{val.NCReasonId}</td> */}
                              <td>{i + parseInt(srNo) + 1}</td>
                              {showLabel.map((item, idx) =>
                                item.label === "Tax Name" && item.flag ? (
                                  <td key={idx}>
                                    {val.tax_name != "" && val.tax_name !== null
                                      ? val.tax_name
                                      : "NA"}
                                  </td>
                                ) : item.label === "Tax Type" && item.flag ? (
                                  <td key={idx}>
                                    {val.tax_type != "" && val.tax_type !== null
                                      ? val.tax_type
                                      : "NA"}
                                  </td>
                                ) : item.label === "Rate" && item.flag ? (
                                  <td key={idx}>
                                    {val.rate != "" && val.rate !== null
                                      ? val.rate
                                      : "NA"}
                                  </td>
                                ) : null
                              )}
                              {/* <td>
                              {privilage.write &&
                                <EditGSTSlab
                                  data={val}
                                  constLabel={constLabel}
                                  fetchGST_SlabList={fetchGST_SlabList}
                                  page={page}
                                  entriesPerPage={entriesPerPage}
                                />
                              }

                              {privilage.delete &&
                                <>
                                  <Tooltip id={"delete-tooltip" + i} place="top" />
                                  <div
                                    data-tooltip-id={"delete-tooltip" + i}
                                    data-tooltip-content={constLabel?.lbl_delete_gst_slab ? constLabel.lbl_delete_gst_slab : "Delete GST Slab"}
                                    className="deleteIcon me-2"
                                    onClick={() => delGST_Slab(val)}
                                  >
                                    <Trash2 color="#FFFFFF" size={18} />
                                  </div>
                                </>}
                            </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-2">
                    <p className="mb-0">
                      Show
                      <select
                        className="mx-1"
                        defaultValue={entriesPerPage}
                        onChange={(e) => {
                          setEntriesPerPage(e.target.value);
                          fetchGST_SlabList(1, e.target.value);
                        }}
                      >
                        <option value={"10"}>10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                      Entries
                    </p>
                      <p className="mb-0">{`Showing ${
                        parseInt(srNo) + 1
                      } to ${Math.min(
                        parseInt(entriesPerPage) + parseInt(srNo),
                        dataList.totalRecords
                      )} of ${dataList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchGST_SlabList(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1
                                ? 0
                                : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                            maxPage === 0 ||
                            entriesPerPage > dataList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            fetchGST_SlabList(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  </>
                ) : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"GST slab not found !"}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default GSTSlab;
