import { parse, format } from 'date-fns'
// import nameConstant from "../Labels/nameConstant.json"
import routes from "../../Routes/routes";
import moment from 'moment';

export const convertDateYYMMDD = (d, divider) => {


  const isYYYYMMDDFormat = /^\d{2}-\d{2}-\d{4}$/.test(d) ||
    /^\d{1}-\d{2}-\d{4}$/.test(d) ||
    /^\d{2}-\d{1}-\d{4}$/.test(d) ||
    /^\d{1}-\d{1}-\d{4}$/.test(d);

  if (isYYYYMMDDFormat) {


    const [day, month, year] = d.split('-');
    const originalDate = new Date(`${year}-${month}-${day}`);
    return originalDate.toISOString().split('T')[0];
  }

  var weekDate = new Date(d)
  let date = weekDate.getDate()
  let month = weekDate.getMonth() + 1
  const year = weekDate.getFullYear()

  if (month < 10) {
    month = '0' + month
  }
  if (date < 10) {
    date = '0' + date
  }

  return year + divider + month + divider + date
}

export function newDateTimeForm(date, time) {
  // console.log("date", date);
  // console.log("time", time);
  let year = date.getFullYear();
  let month = (date.getMonth() + 1).toString().padStart(2, "0");
  let day = date.getDate().toString().padStart(2, "0");

  // Extracting time components
  let hours = time.getHours().toString().padStart(2, "0");
  let minutes = time.getMinutes().toString().padStart(2, "0");
  let seconds = time.getSeconds().toString().padStart(2, "0");

  // Creating start_time in the desired format (assuming UTC time for the example)
  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
}

export const convertDateYYMMDDUsingLib = (d) => {
  // Parse the date string into a Date object
  const parsedDate = parse(d, 'dd-MM-yyyy', new Date())

  // Format the Date object into the desired format
  const formattedDate = format(parsedDate, 'yyyy-MM-dd')


  return formattedDate
}

export const convertDate = (d, divider) => {

  const isYYYYMMDDFormat = /^\d{4}-\d{2}-\d{2}$/.test(d);
  if (!isYYYYMMDDFormat) {
    return d;
  }
  var weekDate = new Date(d);
  const date = weekDate.getDate();
  const month = weekDate.getMonth() + 1;
  const year = weekDate.getFullYear();

  const formattedDate = (date < 10 ? '0' : '') + date;
  const formattedMonth = (month < 10 ? '0' : '') + month;

  return formattedDate + divider + formattedMonth + divider + year;
}

export function convertDateFormat(inputDate) {
  if (inputDate !== undefined) {
    const parts = inputDate.split('-'); // Split the input date
    const year = parts[2];
    const month = parts[1].length === 1 ? '0' + parts[1] : parts[1]; // Add leading zero if needed
    const day = parts[0].length === 1 ? '0' + parts[0] : parts[0]; // Add leading zero if needed

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
}

export function convertDateYYMMDDHHMM(date) {
  if (date !== undefined) {
    // Extract the year, month, day, hour, and minute
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hour = date.getHours().toString().padStart(2, '0');
    const minute = date.getMinutes().toString().padStart(2, '0');

    // Create the formatted date string
    const formattedDate = `${year}-${month}-${day} ${hour}:${minute}`;
    return formattedDate;
  }
}

export const calMaxPage = (counts) => {
  const maxPage = Math.floor(counts / 10)
  if (counts % 10 === 0) {
    return maxPage
  } else {
    return maxPage + 1
  }
}

export const calMaxPage_new = (counts, entriesPerPage) => {

  const maxPage = Math.ceil(counts / entriesPerPage)

  return maxPage;
}

export function timeDuration(startTime, endTime) {
  const start = startTime.split(':').map(Number)
  const end = endTime.split(':').map(Number)
  const startInMinutes = start[0] * 60 + start[1]
  const endInMinutes = end[0] * 60 + end[1]
  const duration = endInMinutes - startInMinutes
  const hours = Math.floor(duration / 60)
  const minutes = duration % 60
  return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`
}

export function timeDuration2(start, end) {
  const date1 = start
  const date2 = end

  const durationInMs = date2 - date1
  const durationInMinutes = durationInMs / (1000 * 60)
  const hours = Math.floor(durationInMinutes / 60)
  const minutes = durationInMinutes % 60
  const durationFormatted = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`


  return durationFormatted
}

export async function downloadAsJSON(val) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))


  val.customerId = userInfo.user_id

  if (Object.keys(val).length > 0) {
    const json = await JSON.stringify(val)

    const blob = new Blob([json], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const fileName = val.name + '_' + val.vesselname + '.json'
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.click()
    URL.revokeObjectURL(url)
  } else {
    return null
  }
}


export function splitStringFromQuestionMark(inputString) {
  const questionMarkIndex = inputString.indexOf('?');
  if (questionMarkIndex !== -1) {
    const substringAfterQuestionMark = inputString.substring(questionMarkIndex);
    return substringAfterQuestionMark;
  } else {
    return inputString;
  }
}


export const generateAndDownloadCSV = (jsonData) => {
  if (!Array.isArray(jsonData) || jsonData.length === 0) return;

  const csvRows = jsonData.map((data) =>
    Object.values(data)
      .map((value) => `"${value}"`)
      .join(",")
  );

  const csvContent = csvRows.join("\n");

  const blob = new Blob([csvContent], { type: "text/csv" });

  const url = URL.createObjectURL(blob);

  const a = document.createElement("a");
  a.href = url;
  a.download = "Operation_category.csv";

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
};


export function convertImageTobase64(file) {
  const reader = new FileReader();

  reader.onload = function (e) {
    const base64String = e.target.result;

    return base64String
  };

}


export function generateRandomString(length) {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    randomString += charset.charAt(randomIndex);
  }

  return randomString;
}



export function convert_date_and_time(date, time) {
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  const hours = time.getHours();
  const minutes = time.getMinutes();
  const seconds = time.getSeconds();
  const milliseconds = time.getMilliseconds();

  return new Date(year, month, day, hours, minutes, seconds, milliseconds)
}




export var settings = {
  className: "center",
  centerMode: true,
  infinite: true,
  centerPadding: "60px",
  slidesToShow: 4,
  slidesToScroll: 1,
  speed: 500,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }
  ]
};


export function convertUrl(url) {

  var result;

  if (url !== null || url !== undefined) {
    result = url.split("?");
  }

  return result[0];
}


export function amountInWords(amount) {
  const units = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
  const teens = ["", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
  const tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
  const thousands = ["", "Thousand", "Million", "Billion"];

  function toWords(num) {
    if (num === 0) return "Zero";
    if (num < 10) return units[num];
    if (num < 20) return teens[num - 10];
    if (num < 100) return tens[Math.floor(num / 10)] + " " + toWords(num % 10);
    return units[Math.floor(num / 100)] + " Hundred " + toWords(num % 100);
  }

  function groupToWords(num, idx) {
    if (num === 0) return "";
    if (num < 1000) return toWords(num) + " " + thousands[idx];
    return toWords(Math.floor(num / 1000)) + " " + thousands[idx] + " " + groupToWords(num % 1000, idx + 1);
  }

  return groupToWords(amount, 0);
}


export function isExcelFile(fileName) {
  // Define an array of valid Excel extensions
  const excelExtensions = ['.xlsx', '.xls'];

  // Get the file extension from the file name
  const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);

  // Check if the file extension is in the array of valid Excel extensions
  return excelExtensions.includes(`.${fileExtension.toLowerCase()}`);
}

/* Function is used for the filter module wise screens then screen wise const label list */
export function getLabels(moduleName, screenName) {
  if (moduleName !== "" && screenName !== "") {
    /*
    # Niranjan 04-12-2023
    # Get Module Name and Screen List by compare Module Name
    # Get Screen Name and Label List by compare Screen Name
 */
    const nameConstant = JSON.parse(localStorage.getItem("lablelist"))
    if (nameConstant?.length > 0) {
      const result = nameConstant.find(
        (module) => module.module_name === moduleName
      );

      if (result !== undefined && Object.keys(result).length > 0) {
        const res_list = result.screen_list.find(
          (item) => item.screen_name === screenName
        );

        return res_list !== undefined ? res_list.labelList : {};
      } else {
        return {};
      }
    } else {
      return {};
    }

  } else {
    return {};
  }
}


// para2 here is actually the name of module or screen from where this function is getting called.
export function setAccessPrivilage(loginData, para2) {
  if (loginData.role_name.trim() === "admin" ||
    loginData.role_name.trim() === "client_admin") {
    return {
      read: true,
      write: true,
      view: true,
      delete: true,
      report: true,
    };
  } else {
    for (let i = 0; i < loginData.module_list.length; i++) {
      if (loginData.module_list[i].module === para2) {
        return loginData.module_list[i];
      } else {
        if (loginData.module_list[i].screens !== undefined) {
          for (let j = 0; j < loginData.module_list[i].screens.length; j++) {
            if (loginData.module_list[i].screens[j].screen_name === para2) {
              return loginData.module_list[i];
            }
          }
        } else {
          return {
            read: false,
            write: false,
            view: false,
            delete: false,
            report: false,
          };
        }
      }
    }
  }
}





export function findRoute(scrval) {


  let result = [];
  for (let i = 0; i < routes.length; i++) {
    if (routes[i].screenForMaster !== undefined) {

      if (scrval.screen_name.toLowerCase() === "nc") {
        if (routes[i].screenForMaster?.toLowerCase() === scrval.screen_name.toLowerCase()
          || routes[i].screenForMaster?.toLowerCase() === "quality") {
          result.push(routes[i]);
        }
      } else if (scrval.screen_name.toLowerCase() === "nc dashboard" || scrval.screen_name.toLowerCase() === "quality dashboard") {

        if (routes[i].screenForMaster?.toLowerCase() === "quality dashboard") {
          result.push(routes[i]);
        }

      } else {
        if (routes[i].screenForMaster?.toLowerCase() === scrval.screen_name.toLowerCase()) {
          result.push(routes[i]);
        }
      }
    }
  }



  // if the we dont find the route required to navigate then give slash
  if (result.length > 0) {

    return result[0].path
  } else {
    return "/"
  }
}

// date convert into yyyy-mm-dd h:mm aa 2024-01-22 5:02 PM
export function formatHrs_Min(date) {
  if (date !== undefined) {
    // Extract the year, month, day, hour, and minute
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    let hour = date.getHours();
    const ampm = hour >= 12 ? 'PM' : 'AM';
    hour = hour % 12 || 12; // Convert 0 to 12 for midnight

    const formattedDate = `${year}-${month}-${day} ${hour.toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')} ${ampm}`;
    return formattedDate;
  }
}

export function formatUTCDate(utcString) {
  // Create a Date object from the UTC string
  const date = new Date(utcString);

  // Extract date components
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear();


  // Extract time components
  const hours = date.getUTCHours() % 12 || 12; // Handle 12-hour format
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const ampm = date.getUTCHours() >= 12 ? 'PM' : 'AM';

  // Format the date and time string
  return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
}

export function formatDateString(inputDate) {
  // Create a Date object from the input string
  const date = new Date(inputDate);

  // Check if the input is a valid date
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  // Format the date components
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  // const hours = date.getHours().toString().padStart(2, '0');
  // const minutes = date.getMinutes().toString().padStart(2, '0');
  // const ampm = date.getHours() >= 12 ? 'PM' : 'AM';

  // Construct the formatted date string
  // const formattedDate = `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
}



export function formatDateString2(input) {
  // Parse the input date string

  let ourDate = input.trim();

  const pad = (num) => String(num).padStart(2, '0');

  const dateFormatRegex = /^\d{1,2}-\d{1,2}-\d{4}$/;

  if (dateFormatRegex.test(ourDate)) {
    // Split the date components
    const [day, month, year] = ourDate.split('-');

    // Pad the day and month with leading zeros if needed
    const paddedDay = String(day).padStart(2, '0');
    const paddedMonth = String(month).padStart(2, '0');

    // Return the formatted date
    ourDate = `${paddedDay}-${paddedMonth}-${year}`;
  }

  // Function to format date as "dd-mm-yyyy"
  const formatDate = (date) => {
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Try parsing the input date in different formats
  const dateFormats = [
    'YYYY-MM-DD hh:mm A',
    'YYYY-MM-DD',
    'DD-MM-YYYY',
    'D-MM-YYYY',
    'DD-M-YYYY',
    'D-M-YYYY'
  ];

  let formattedDate = 'Invalid Date';

  for (const format of dateFormats) {
    const parsedDate = moment(ourDate, format, true);
    if (parsedDate.isValid()) {
      formattedDate = formatDate(parsedDate.toDate());
      break;
    }
  }



  return formattedDate;
}

export function formatDate5(inputDate) {
  // Check if inputDate is not present or empty
  if (!inputDate) {
    return "NA";
  }

  // Create a new Date object from the given string
  const date = new Date(inputDate);

  // Get the day, month, and year components
  const day = date.getDate().toString().padStart(2, '0'); // Add leading zero if needed
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();

  // Construct the formatted date string in "dd mm yyyy" format
  const formattedDate = `${day}-${month}-${year}`;

  // Return the formatted date
  return formattedDate;
}


export function formatDateString3(input) {
  // Function to pad single digits with leading zeros
  const pad = (num) => String(num).padStart(2, '0');

  // Function to format date as "dd-mm-yyyy"
  const formatDate = (date) => {
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are zero-based
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };

  // Try parsing the input date in different formats
  const dateFormats = [
    'YYYY-MM-DD hh:mm A',
    'YYYY-MM-DD',
    'DD-MM-YYYY'
  ];

  let formattedDate = 'Invalid Date';

  for (const format of dateFormats) {
    const parsedDate = moment(input, format, true);
    if (parsedDate.isValid()) {
      // Check if the parsed format is 'YYYY-MM-DD hh:mm A'
      if (format === 'YYYY-MM-DD hh:mm A') {
        return input; // Return as it is
      } else {
        formattedDate = formatDate(parsedDate.toDate());
        break;
      }
    }
  }

  return formattedDate + " 00:00:00";
}




export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}


export function formatDateString4(input) {
  // Function to pad single digits with leading zeros

  let ourDate = input.trim();

  const pad = (num) => String(num).padStart(2, '0');

  const dateFormatRegex = /^\d{1,2}-\d{1,2}-\d{4}$/;

  if (dateFormatRegex.test(ourDate)) {
    // Split the date components
    const [day, month, year] = ourDate.split('-');

    // Pad the day and month with leading zeros if needed
    const paddedDay = String(day).padStart(2, '0');
    const paddedMonth = String(month).padStart(2, '0');

    // Return the formatted date
    ourDate = `${paddedDay}-${paddedMonth}-${year}`;
  }

  // Function to format date as "dd-mm-yyyy"
  const formatDate = (date) => {
    const day = pad(date.getDate());
    const month = pad(date.getMonth() + 1); // Months are zero-based
    const year = date.getFullYear();
    const hours = pad(date.getHours() % 12 || 12); // Convert to 12-hour format
    const minutes = pad(date.getMinutes());
    const ampm = date.getHours() < 12 ? 'AM' : 'PM';
    return `${day}-${month}-${year} ${hours}:${minutes} ${ampm}`;
  };

  // Try parsing the input date in different formats
  const dateFormats = [
    'YYYY-MM-DD hh:mm A',
    'YYYY-MM-DD',
    'DD-MM-YYYY',
    'D-MM-YYYY',
    'DD-M-YYYY',
    'D-M-YYYY'
  ];

  let formattedDate = 'Invalid Date';

  for (const format of dateFormats) {
    const parsedDate = moment(ourDate, format, true);
    if (parsedDate.isValid()) {
      formattedDate = formatDate(parsedDate.toDate());
      break;
    }
  }



  return formattedDate;
}


// RMI material code increment logic is here
export function incrementMaterialCode(materialCode, currIndex) {

  let [prefix, suffixStr, fix] = materialCode.split('-');
  // let [suffix, fix] = suffixStr.split('-');
  let suffix = parseInt(suffixStr, 10);

  // Increment the suffix and handle overflow
  if (suffix < 9999) {
    suffix += currIndex;
  } else {
    suffix = currIndex;
    // Increment the prefix (handle transition from single-letter to double-letter)
    if (!prefix) {
      prefix = 'A';
    } else {
      const lastChar = prefix.slice(-1);
      if (lastChar === 'Z') {
        prefix += 'A';
      } else {
        const prefixChars = prefix.split('');
        const lastIndex = prefixChars.length - 1;
        const newChar = String.fromCharCode(prefixChars[lastIndex].charCodeAt(0) + 1);
        prefixChars[lastIndex] = newChar;
        prefix = prefixChars.join('');
      }
    }
  }

  // Format the new material code
  const newMaterialCode = `${prefix}-${String(suffix).padStart(4, '0')}-${fix}`;
  return newMaterialCode;
}


export function removePrefix(url) {
  const prefix = process.env.REACT_APP_IMAGE_URL;
  console.log(url.replace(prefix, ''));
  return url.replace(prefix, '');
}

export function splitString(inputString) {
  let arr = inputString.split("-"); // split client id from the string Ex. RMI-1234-001
  let str = `${arr[0]}-${arr[1]}`; // RMI-1234
  return str;
}

export function convertBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = reject;
    if (file) {
      reader.readAsDataURL(file);
    } else {
      reject(new Error("File is empty"));
    }
  });
}

// For inventory date conversion date format function
export function formatDateString5(dateStr) {
  // Create a new Date object from the input string
  const date = new Date(dateStr);

  // Define a function to pad single digit numbers with leading zeros
  const pad = (num) => num.toString().padStart(2, '0');

  // Extract date components
  const day = pad(date.getUTCDate());
  const month = pad(date.getUTCMonth() + 1); // Months are zero-indexed
  const year = date.getUTCFullYear();

  const hours = date.getUTCHours();
  const minutes = pad(date.getUTCMinutes());
  const seconds = pad(date.getUTCSeconds());

  // Determine AM/PM
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert hours from 24-hour to 12-hour format
  const hours12 = pad(hours % 12 || 12); // Convert 0 (midnight) to 12

  // Format the date string
  return `${day}-${month}-${year} ${hours12}:${minutes}:${seconds} ${ampm}`;
}


export function adjustDateTime(database_time) {
  // Parse the input time string to a Date object
  let date = new Date(database_time);
  // Subtract 5 hours and 30 minutes (330 minutes) from the date
  date.setMinutes(date.getMinutes() - 330);
  // Format the date without milliseconds and the "Z" indicating UTC
  return date;
}

export function adjustDateTime2(database_time) {
  // Parse the input time string to a Date object
  let date = new Date(database_time);
  // Subtract 5 hours and 30 minutes (330 minutes) from the date
  date.setMinutes(date.getMinutes());
  // Format the date without milliseconds and the "Z" indicating UTC
  return date;
}


// Split readable id's (Ex. "WO-123-2024-001" => "WO-123")
export function getShortReadableId(readableId) {
  if (!readableId) return 'None';
  const workOrderNoParts = readableId.split('-');
  return workOrderNoParts.length > 2 ? `${workOrderNoParts[0]}-${workOrderNoParts[1]}` : readableId;
}

export function get_dispatch_quantity(arr) {

  if (arr.length == 0) {
    return 0
  }
    let sum = 0;
    sum = arr.reduce((ac, dc) => {
      return ac + Number(dc.dispatch_qty)
    }, 0);
  return sum;
}

export function calculateWorkHours(startDateTime, endDateTime, officeStartMillis, officeEndMillis) {
  const start = new Date(startDateTime);
  const end = new Date(endDateTime);

  // Edge case: if startDateTime is after endDateTime
  if (start >= end) return 0;

  // Constants for full working day in hours
  const fullDayHours = (officeEndMillis - officeStartMillis) / 1000 / 60 / 60;

  let totalHours = 0;

  // Case 1: If start and end date are on the same day
  if (start.toDateString() === end.toDateString()) {
    // Calculate the working hours within office hours for the same day
    const startWorkMillis = Math.max(
      start.getHours() * 60 * 60 * 1000 + start.getMinutes() * 60 * 1000,
      officeStartMillis
    );
    const endWorkMillis = Math.min(
      end.getHours() * 60 * 60 * 1000 + end.getMinutes() * 60 * 1000,
      officeEndMillis
    );
    totalHours = Math.max(0, (endWorkMillis - startWorkMillis) / 1000 / 60 / 60); // Convert ms to hours
    return totalHours;
  }

  // Case 2: If start and end dates are different
  let currentDate = new Date(start);

  // First day: calculate hours worked after the start time or from office start time
  const startWorkMillis = Math.max(
    start.getHours() * 60 * 60 * 1000 + start.getMinutes() * 60 * 1000,
    officeStartMillis
  );
  totalHours += (officeEndMillis - startWorkMillis) / 1000 / 60 / 60; // Convert ms to hours

  // Loop through full days between start and end dates
  currentDate.setDate(currentDate.getDate() + 1);
  currentDate.setHours(0, 0, 0, 0);
  while (currentDate.toDateString() !== end.toDateString()) {
    // Add full working hours for full days between
    totalHours += fullDayHours;
    currentDate.setDate(currentDate.getDate() + 1);
  }

  // Last day: calculate hours worked up to the end time or until office end time
  const endWorkMillis = Math.min(
    end.getHours() * 60 * 60 * 1000 + end.getMinutes() * 60 * 1000,
    officeEndMillis
  );
  const officeStartTimeOnEndDay = officeStartMillis;
  totalHours += Math.max(0, (endWorkMillis - officeStartTimeOnEndDay) / 1000 / 60 / 60); // Convert ms to hours

  return totalHours;
}

export function excelReportDate(date) {
  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });
  const year = date.getFullYear();
  
  const suffix = (day) => {
    if (day > 3 && day < 21) return 'th'; // covers 11th to 19th
    switch (day % 10) {
      case 1: return 'st';
      case 2: return 'nd';
      case 3: return 'rd';
      default: return 'th';
    }
  };
  
  return `${day}${suffix(day)} ${month} ${year}`;
}

export function getFinancialYearStartDate(today) {
  const year =
    today.getMonth() >= 3 ? today.getFullYear() : today.getFullYear() - 1;
  const financialYearStartDate = new Date(year, 3, 1); // April is month 3 in JavaScript Date
  return financialYearStartDate;
}


