import React, { Component } from 'react';
import Lottie from 'react-lottie';
import animationData from '../../../assets/lottie_json/Animation - 1726662800576.json';

class NullHandleErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state to show fallback UI
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can log the error to an error reporting service here
        console.error("Error caught in NullHandleErrorBoundary:", error, errorInfo);
    }



    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData, // Your Lottie JSON data
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };

        if (this.state.hasError) {
            // You can customize the fallback UI
            return (
                <div id="fallback_404">
                    <Lottie
                        options={defaultOptions}
                        height={600}
                        width={600}
                    />
                </div>
            )

        }

        // If no error, render the children components normally
        return this.props.children;
    }
}

export default NullHandleErrorBoundary;
