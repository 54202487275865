import React, { useState, useEffect, useReducer } from "react";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlus,
  faBan,
  faFilter,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Modal, Spinner } from "react-bootstrap";
import { Download, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import axios from "axios";
import { deletTransactionMaster, getTransactionMaster, saveTransactionMaster } from "../../../api";
import { calMaxPage, getLabels, setAccessPrivilage } from "../../../common/Functions/CommonFunctions";
import EditTransactionMaster from "./EditTransactionMaster";
import { Typeahead } from "react-bootstrap-typeahead";
import { Tooltip } from "react-tooltip";
import { Transaction_Col_Name } from "../../../common/Labels/labelConstant";
import { useSelector } from "react-redux";

const schema = yup
  .object()
  .shape({
    transaction_name: yup
      .string()
      .required("Transaction name is required !"),
    transaction_code: yup
      .string()
      .required("Transaction code is required !")
      .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric characters are allowed'),
    action: yup
      .array()
      .min(1, "Please select any one action !")
      .required("Please select any one action !"),
  })
  .required();

function TransactionMaster() {

  const actiondata = [
    {
      id: 1,
      name: "I"
    },
    {
      id: 2,
      name: "R"
    },
    // {
    //   id: 3,
    //   name: "I,R"
    // }
  ]

  const [showLabel, setShowLabel] = useState(Transaction_Col_Name);
  const [visible, setVisible] = useState(false);

  const [apiBySearch, setApiBySearch] = useState(false);
  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");

  const [constLabel, setConstLabel] = useState({});

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage] = useState(setAccessPrivilage(loginData, "Transaction Master"));


  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const initialState = {
    loading: true,
    error: "",
    transactionlist: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "HANDLE_ACTION":
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          transactionlist: action.transactionlist,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    
    setConstLabel(getLabels('Master', 'Transaction'));
    dispatch({
      type: "HANDLE_ACTION",
      page: state.page,
      loading: true,
      error: state.error,
      transactionlist: state.transactionlist,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetchTransactions();
  }, [state.page, state.itemsPerPage]);

  function fetchTransactions(para1, para2, para3) {
    dispatch({
      type: "HANDLE_ACTION",
      page: para1 === "refresh" ? 1 : state.page,
      loading: true,
      error: state.error,
      transactionlist: state.transactionlist,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      pageNo: para3 == "change" ? state.page - 1 : state.page,
      entriesPerPage: state.itemsPerPage,
    };

    if (para3 == "change") {
      if (state.page == 2) {

        setSrNo(0);
      }
      else {
        let srno = srNo - state.itemsPerPage;
        setSrNo(srno)
      }
    }

    //

    if (para1 === "search") {
      reqPayload = {
        pageNo: state.page,
        entriesPerPage: state.itemsPerPage,
        searchVal: para2,
      };
    }

    getTransactionMaster(reqPayload).then(
      (res) => {
       
        setTotalRecords(res.data.total);

        dispatch({
          type: "HANDLE_ACTION",
          page: para3 == "change" ? state.page - 1 : state.page,
          loading: false,
          error: state.error,
          transactionlist: res.data.data,
          itemsPerPage: state.itemsPerPage,
          maxPage:
            para1 === "search"
              ? calMaxPage(res.data.data.length)
              : calMaxPage(res.data.total),
        });
      },
      (err) => {
        

        if (err.response.status === 500) {
          toast.error("Something went wrong !", { autoClose: 5000 });
        }
      }
    );
  }

  const onSubmit = (data) => {
    

    if (data !== "") {
      const reqPayload = {
        transaction_name: data.transaction_name,
        transaction_code: data.transaction_code,
        action: data.action[0].name,
        description: data.description
      };

      


      saveTransactionMaster(reqPayload)
        .then(
          (res) => {
            
            if (res.status === 200 || res.status === 201) {
              toast.success("Transaction created Successfully !", {
                autoClose: 3000,
              });
              setVisible(false);
              fetchTransactions();
              reset();
            }
          },
          (err) => {
            
            if (err.response.status === 400) {
              
              toast.error(err.response.data.non_field_errors[0], {
                autoClose: 2000,
              });

            }
          }
        )

    } else {
      errors.showMessages();
    }
  };

  // Deleting Trasaction master 
  const delTransactionMaster = (val) => {
    
    SweetAlert.fire({
      title: "Are you sure ?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          uuid: val.uuid,
        };
        deletTransactionMaster(payload).then(
          (res) => {
            
            toast.success("Transaction deleted successfully !", {
              autoClose: 3000,
            });

            if (state.page > 1 && state.transactionlist.length == 1) {
              
              fetchTransactions(null, null, "change");
            }
            else {
              fetchTransactions();
            }

          },
          (err) => {
            
            toast.error("Something went wrong !", { autoClose: 3000 });
          }
        );
      }
    });
  };

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function handleClick(ind) {
    

    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }


  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}
      <div className="min-vh-100" id="root_div_main">
        <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>{constLabel?.lbl_add_transaction ? constLabel.lbl_add_transaction : "Add Transaction"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form action="">
              <div className="form-group row mt-2">
                <label style={{ fontSize: "14px" }} className="col-form-label col-md-12 roboto-bold">
                  {constLabel?.lbl_transaction_name ? constLabel.lbl_transaction_name : "Transaction Name"} <span>
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>

                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control "
                    id="transaction_name"
                    name="transaction_name"
                    placeholder="Enter transaction name"
                    {...register("transaction_name")}
                  />
                  <span className="text-danger">
                    {errors.transaction_name?.message}
                  </span>
                </div>
              </div>
              <div className="form-group row mt-2">
                <label style={{ fontSize: "14px" }} className="col-form-label col-md-12 roboto-bold">
                  {constLabel?.lbl_transaction_code ? constLabel.lbl_transaction_code : "Transaction code"}<span>
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control "
                    id="transaction_code"
                    name="transaction_code"
                    placeholder="Enter transaction code"
                    {...register("transaction_code")}
                  />
                  <span className="text-danger">
                    {errors.transaction_code?.message}
                  </span>
                </div>
              </div>
              <div className="form-group row mt-2">
                <label style={{ fontSize: "14px" }} className="col-form-label col-md-12 roboto-bold">
                  {constLabel?.lbl_transaction_action ? constLabel.lbl_transaction_action : "Transaction Action"}
                  <span>
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-12">

                  <Controller
                    name={`action`}
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"

                        multiple={false}
                        options={actiondata}
                        // isLoading={orgtype.loading}
                        placeholder="Select action"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.action ? true : false}
                      />
                    )}
                  />
                  {errors.action && (
                    <span className="text-danger err-msg">
                      {errors.action.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group row mt-2">
                <label style={{ fontSize: "14px" }} className="col-form-label col-md-12 roboto-bold">
                  {constLabel?.lbl_description ? constLabel.lbl_description : "Description"}
                </label>
                <div className="col-md-12">
                  <textarea
                    type="text"
                    className="form-control "
                    id="description"
                    name="description"
                    placeholder="Enter description"
                    rows={2}
                    {...register("description")}
                  />

                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleSubmit(onSubmit)}
              className="f-16 btn btn-yellow "
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
            </button>
          </Modal.Footer>
        </Modal>
        <div id="mySidebar" className="customsidebar ">
          <a className="closebtn" onClick={() => closeNav()}>
            ×
          </a>

          <div className=" content">
            <div className="filter row">
              <label
                className="filterLabel mb-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_search ? constLabel.lbl_search : "Search"}
              </label>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search by name"
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      fetchTransactions("search", e.target.value);
                      setApiBySearch(true);
                    } else {
                      fetchTransactions();
                    }
                  }}
                />
              </div>
            </div>
            <div className="my-3 d-flex justify-content-end">
              <button className="btn btn-sm btn-yellow">
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {constLabel?.lbl_clear_filter ? constLabel.lbl_clear_filter : "Clear Filter"}
              </button>
              {/* <button
                          className="btn btn-sm btn-grey ms-0 ms-3 px-4"
                      >
                          <FontAwesomeIcon style={{ color: "#fff" }} icon={faSearch} size="sm" />{" "}
                          Search
                      </button> */}
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="bold">{"Transaction Master"}</h1>
            <div>
              {/* <button
                className="btn btn-grey px-3 me-0 me-md-2"
                onClick={() => {
                  openNav();
                }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "5px" }}
                  icon={faFilter}
                  size="sm"
                />
                Filter Menu
              </button> */}

              {privilage.write &&
                <button
                  className="btn button-primary px-4"
                  onClick={() => {
                    setVisible(true);
                    reset();
                  }}
                >
                  <FontAwesomeIcon
                    style={{ color: "#fff" }}
                    icon={faPlus}
                    size="sm"
                  />{" "}
                  {constLabel?.lbl_add_transaction ? constLabel.lbl_add_transaction : "Add Transaction Master"}
                </button>
              }
            </div>
          </div>
          <div className="card border-0 mt-3">
            <div className="card-body">
              <div className="d-flex justify-content-between mb-3 me-3">

                <p className="mb-0">
                  Show
                  <select
                    className="mx-1"
                    defaultValue={"10"}
                    // onChange={(e) => {
                    //   setEntriesPerPage(e.target.value);
                    //   fetchProdConfig(page, e.target.value);
                    // }}
                    onChange={(e) => {
                      dispatch({
                        type: "HANDLE_ACTION",
                        page: 1,
                        loading: state.loading,
                        error: state.error,
                        transactionlist: state.transactionlist,
                        itemsPerPage: e.target.value,
                        maxPage: state.maxPage,
                      })
                      setSrNo(0);
                    }

                    }
                  >
                    <option value={"10"}>10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                  Entries
                </p>
                <div className="dropdown">


                  <div
                    className="settignIcon_custom_nc d-flex justify-content-center align-items-center"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <FontAwesomeIcon
                      style={{ color: "#fff" }}
                      icon={faCog}
                      size="sm"
                    />
                  </div>

                  <ul className="dropdown-menu px-2">
                    {showLabel !== undefined && showLabel.length > 0
                      ? showLabel.map((val, ind, arr) => (
                        <li key={ind}>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              style={{ width: "20px", height: "20px" }}
                              onChange={() => handleClick(ind)}
                              value=""
                              checked={val.flag}
                            />
                            <label
                              className=""
                              style={{ fontSize: "13px" }}
                              htmlFor="flexCheckDefault"
                            >
                              {val.label}
                            </label>
                          </div>
                        </li>
                      ))
                      : null}
                  </ul>
                </div>

              </div>
              <div className="">
                {state.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : state.transactionlist && state.transactionlist.length > 0 ? (
                  <div className="table-responsive">
                    <table
                      className="table table-bordered table-sm"
                      width={"100%"}
                    >
                      <thead className="table-grey roboto">
                        <tr className="">
                          <th scope="col" width="15%">
                            {"Sr. No"}
                          </th>
                          {showLabel.map((v, i) =>
                            v.label === "Transaction Name" && v.flag ? (<th scope="col">{constLabel?.lbl_transaction_name ? constLabel.lbl_transaction_name : "Transaction Name"}</th>) :
                              v.label === "Transaction Code" && v.flag ? (<th scope="col">{constLabel?.lbl_transaction_code ? constLabel.lbl_transaction_code : "Transaction Code"}</th>) :
                                v.label === "Transaction Action" && v.flag ? (<th scope="col">{constLabel?.lbl_transaction_action ? constLabel.lbl_transaction_action : "Transaction Action"}</th>) :
                                  v.label === "Description" && v.flag ? (<th scope="col">{constLabel?.lbl_description ? constLabel.lbl_description : "Description"}</th>) : null
                          )}
                          <th scope="col" width="15%">
                            {"Action"}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="roboto">
                        {state.transactionlist.map((val, i) => (
                          <tr key={i} className="">
                            <td>{i + parseInt(srNo) + 1}</td>
                            {showLabel.map((item, idx) =>
                              item.label === "Transaction Name" && item.flag ? (<td key={idx}>{val.transaction_name != "" && val.transaction_name !== null ? val.transaction_name : "NA"}</td>) :
                                item.label === "Transaction Code" && item.flag ? (<td key={idx}>{val.transaction_code != "" && val.transaction_code !== null ? val.transaction_code : "NA"}</td>) :
                                  item.label === "Transaction Action" && item.flag ? (<td key={idx}>{val.action != "" && val.action !== null ? val.action : "NA"}</td>) :
                                    item.label === "Description" && item.flag ? (<td key={idx}>{val.description != "" && val.description !== null ? val.description : "NA"}</td>) : null
                            )}
                            <td>

                              {privilage.write &&
                                <EditTransactionMaster
                                  data={val}
                                  constLabel={constLabel}
                                  fetchTransactions={fetchTransactions}
                                />
                              }

                              {privilage.delete &&
                                <>
                                  <Tooltip id={"delete-tooltip" + i} place="top" />
                                  <div
                                    data-tooltip-id={"delete-tooltip" + i}
                                    data-tooltip-content={constLabel?.lbl_delete_transaction ? constLabel.lbl_delete_transaction : "Delete Transaction"}
                                    className="deleteIcon me-2"
                                    onClick={() => delTransactionMaster(val)}
                                  >
                                    <Trash2 color="#FFFFFF" size={18} />
                                  </div>
                                </>
                              }
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : apiBySearch ? (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"No Transaction Master found !"}
                  </p>
                ) : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"Transaction master  is empty"}
                  </p>
                )}

                {state.loading ? null : (
                  <div className="mt-4 d-flex justify-content-between">
                    <p className='mb-0'>{`Showing ${parseInt(srNo) + 1} to ${Math.min(parseInt(state.itemsPerPage) + parseInt(srNo), totalRecords)} of ${totalRecords} entries`}</p>
                    <Pagination className="my-2">
                      <Pagination.Prev
                        disabled={state.page === 1 ? true : false}
                        onClick={() => {
                          dispatch({
                            type: "HANDLE_ACTION",
                            page: state.page - 1,
                            loading: state.loading,
                            error: state.error,
                            transactionlist: state.transactionlist,
                            itemsPerPage: state.itemsPerPage,
                            maxPage: state.maxPage,
                          });
                          setSrNo((prevC) => prevC - parseInt(state.itemsPerPage));
                        }}
                      >
                        {"Prev"}
                      </Pagination.Prev>

                      <Pagination.Item active>{state.page}</Pagination.Item>

                      <Pagination.Next
                        disabled={
                          state.page === state.maxPage || state.maxPage === 0
                            ? true
                            : false
                        }
                        onClick={() => {
                          dispatch({
                            type: "HANDLE_ACTION",
                            page: state.page + 1,
                            loading: state.loading,
                            error: state.error,
                            transactionlist: state.transactionlist,
                            itemsPerPage: state.itemsPerPage,
                            maxPage: state.maxPage,
                          });
                          setSrNo((prevC) => prevC + parseInt(state.itemsPerPage));
                        }}
                      >
                        {"Next"}
                      </Pagination.Next>
                    </Pagination>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default TransactionMaster