import React, { useState } from "react";
import logo from "../../../assets/images/proditm.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

function NameSetting(props) {
    // console.log("props", props);

  let navigate = useNavigate();
  const [lockbtn, setLockBtn] = useState(false);

  const { register, handleSubmit, formState } = useForm({
    // resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { errors } = formState;

  const [choice, setChoice] = useState(props.main_obj?.name_choice && props.main_obj?.name_choice !== "" ? props.main_obj?.name_choice : "GstLegalName");

  function handleChoice(e) {
    setChoice(e.target.value);
    props.setMain_obj({...props.main_obj, name_choice : e.target.value});
  }


  return (
    <>
      <ToastContainer />
          <div className="col-12 col-md-6 pt-5 align-items-center d-flex flex-column pb-2">
            <div className="text-center">
              <img
                src={logo}
                className="img-fluid mx-auto"
                alt="Logo"
                style={{ height: "70px", width: "150px" }} // Adjust image size
              />
              <h3 className="bold w-100 mt-3">Welcome to PRODi !</h3>
              <h4 className="w-100 my-0 mt-2">Experience the seamless</h4>
              <h4 className="w-100">onboarding process</h4>

            { props.main_obj?.gst_detail?.tradeNam !== props.main_obj?.gst_detail?.lgnm ?
              <>
                <p className="bold w-100 my-0 mt-3">The registered organization name and</p>
                <p className="bold w-100">the GST portal name differ.</p>
              </>
              : <p className="bold w-100 my-0 mt-3 invisible"></p>
            }
            </div>
            <div className="w-75 d-flex justify-content-center cursor_pointer">
                <div className="form-control form-check ps-5">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="org_name_choice"
                    id="gst_legal_name"
                    value="GstLegalName"
                    checked={choice == "GstLegalName"}
                    onChange={handleChoice}
                  />
                  <label className="form-check-label body3" htmlFor="gst_legal_name">
                    {"Use GST Legal Name (" + props.main_obj?.gst_detail?.lgnm + ") as your organization name"}
                  </label>
                </div>
            </div>
          { props.main_obj?.gst_detail?.tradeNam !== props.main_obj?.gst_detail?.lgnm ?
            <div className="w-75 d-flex justify-content-center cursor_pointer mt-2">
                <div className="form-control form-check ps-5">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="org_name_choice"
                    id="gst_trade_name"
                    value="GstTradeName"
                    checked={choice == "GstTradeName"}
                    onChange={handleChoice}
                  />
                  <label className="form-check-label body3" htmlFor="gst_trade_name">
                    {"Use GST Trade Name (" + props.main_obj?.gst_detail?.tradeNam + ") as your organization name"}
                  </label>
                </div>
            </div>
          : null }
              <div className="w-50 d-flex justify-content-center">
                <button
                  className="btn button-primary w-100 mt-4 roboto-bold"
                  type="submit"
                  onClick={() => props.nextStep()}
                >
                  Confirm{" "}
                </button>
              </div>

          </div>
    </>
  );
}

export default NameSetting;
