import React, { useState, useEffect, useReducer } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlus,
  faBan,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination } from "react-bootstrap";
import { Edit, Edit2, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import axios from "axios";
import {
  deleteMachine,
  getMachineList,
  getTask,
  get_master_files,
} from "../../../../api/index";
import {
  convertDate,
  formatDateString,
  setAccessPrivilage,
} from "../../../../common/Functions/CommonFunctions";
import { machine_asset_csv } from "../../../../constant";
import EditMachineAsset from "./EditMachineAsset";
import ViewMachineAsset from "./ViewMachineAsset";
import CreateMachine from "./CreateMachine";
import EditMachine from "./EditMachine";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import nameConstant from "../../../../common/Labels/nameConstant.json";
import { screenList } from "../../../../common/Labels/screenList";
import { useSelector } from "react-redux";
import Slide from "../../../../assets/images/icons/project_menu/collapse.png";

function MachineAsset() {
  let navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const [selStatus, setSelStatus] = useState({
    view: true,
    add: false,
    edit: false,
  });

  const [machineList, setMachineList] = useState({ data: [], loading: false });
  const [selMachine, setSelMachine] = useState({});
  const [machineName, setMachineName] = useState("");
  const [machineImgs, setMachineImgs] = useState({ loading: false, data: [] });

  const [taskList, setTaskList] = useState({
    data: [],
    loading: false,
    totalRecords: "",
  });
  const [constLabel, setConstLabel] = useState({});

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage, setPrivilage] = useState(
    setAccessPrivilage(loginData, "Machine")
  );

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    mode: "onChange",
  }); // initialise the hook

  /*
    # Niranjan 04-12-2023
    # Get Module Name and Screen List by compare Module Name
    # Get Screen Name and Label List by compare Screen Name
 */
  useEffect(() => {
    if (Object.keys(nameConstant.data).length > 0) {
      const result = nameConstant.data.find(
        (module) => module.module_name === "Machine Master"
      );

      if (Object.keys(result).length > 0) {
        const res_list = result.screen_list.find(
          (item) => item.screen_name === screenList.machine_screen
        );

        setConstLabel(res_list.labelList);
      } else {
        setConstLabel({});
      }
    } else {
      setConstLabel({});
    }
  }, []);

  let { errors } = formState;

  useEffect(() => {
    openNav();
    // fetchMachine();
  }, []);

  useEffect(() => {
    const getData = setTimeout(() => {
      fetchMachine();
    }, 1000);

    return () => clearTimeout(getData);
  }, [machineName]);

  function fetchMachine(para1) {
    setMachineList({ ...machineList, loading: true });
    let payload = {
      machine_name: machineName.length > 0 ? machineName : null,
    };

    if (para1 == "deleting") {
      payload = null;
    }

    getMachineList(payload).then(
      (res) => {
        // const result = res.data.data.filter((item) => item.machineName !== null);
        setMachineList({ ...machineList, data: res.data.data, loading: false });
        if (Object.keys(selMachine).length > 0) {
          const result = res.data.data.filter((val) => {
            return selMachine.uuid == val.uuid;
          });
          if (result.length > 0) {
            setSelMachine(result[0]);
          } else {
            setSelMachine({});
          }
        }
      },
      (err) => {
        setSelMachine({});

        setMachineList({ ...machineList, data: [], loading: false });
      }
    );
  }

  // Deleting machine asset
  const delMachine = (val) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          uuid: val.uuid,
        };
        deleteMachine(payload).then(
          (res) => {
            toast.success("Machine details deleted successfully !", {
              autoClose: 3000,
            });

            setMachineName("");
            setSelMachine({});
            fetchMachine("deleting");
            setTaskList({ ...taskList, data: [], loading: false });
          },
          (err) => {
            toast.error(err.response.data.message, { autoClose: 3000 });
          }
        );
      }
    });
  };

  function fetchMaintenanceTask(pg, epp, machine) {
    setTaskList({ ...taskList, loading: true });

    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
      machine_id: machine && machine.uuid,
    };

    getTask(payload).then(
      (res) => {
        // const result = res.data.data.filter((item) => item.machineName !== null);
        setTaskList({
          ...taskList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });
      },
      (err) => {
        setTaskList({ ...taskList, data: [], loading: false });
      }
    );
  }

  const generateAndDownloadCSV = (jsonData) => {
    if (!Array.isArray(jsonData) || jsonData.length === 0) return;

    const csvRows = jsonData.map((data) =>
      Object.values(data)
        .map((value) => `"${value}"`)
        .join(",")
    );

    const csvContent = csvRows.join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "Machine_asset.csv";

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  };

  const fetchDataAndDownloadCSV = async () => {
    const token = localStorage.getItem("access");
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + machine_asset_csv,
        {
          responseType: "json", // Set the responseType to ‘arraybuffer’
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the Authorization header
          },
        }
      );

      generateAndDownloadCSV(response.data.MachineDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function fetchImage(val) {
    setMachineImgs({ ...machineImgs, loading: true, data: [] });
    get_master_files({
      ref_model_id: val.machineId,
      ref_model_uuid: val.uuid,
      model_name: "Machine",
      document_type: "image",
    }).then(
      (res) => {
        setMachineImgs({ ...machineImgs, loading: false, data: res.data.data });
      },
      (err) => {
        setMachineImgs({ ...machineImgs, loading: false, data: [] });
      }
    );
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="min-vh-100" id="root_div_main">
        <div id="mySidebar" className="supplier_customsidebar">
          <div className="d-flex justify-content-between m-2">
            <p className="roboto-bold pt-2" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_machine ? constLabel.lbl_machine : "Machine"}
            </p>
            <div>
              {/* {privilage.write && (
                <button
                  className="btn button-primary btn-sm me-4"
                  // onClick={() => navigate("/pcms/createMachine")}
                  onClick={() =>
                    setSelStatus({ view: false, add: true, edit: false })
                  }
                >
                  {constLabel?.lbl_add_machine
                    ? constLabel.lbl_add_machine
                    : "Add Machine"}
                </button>
              )} */}
              <a className="custom-close-btn" onClick={() => toggleNav()}>
                ×
              </a>
            </div>
          </div>

          <div className="col-12 pb-2 px-2">
            <input
              type="text"
              className="form-control form-control-sm"
              placeholder="Search by name"
              value={machineName}
              onChange={(e) => {
                setMachineName(e.target.value);
                if (e.target.value.length === 0) {
                  setSelMachine({});
                }
              }}
            />
          </div>

          <div className="content" style={{ marginTop: "0px" }}>
            {privilage.read && machineList.loading ? (
              <div className="d-flex justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              machineList.data?.map((val, ind) => (
                <div className="supplier_box" key={ind}>
                  <div className="form-check">
                    <input
                      onChange={() => {
                        setSelMachine(val);
                        fetchMaintenanceTask(page, entriesPerPage, val);
                        fetchImage(val);
                        setSelStatus({
                          view: true,
                          add: false,
                          edit: false,
                        });
                      }}
                      checked={selMachine && selMachine?.uuid === val.uuid}
                      className="form-check-input cursor_pointer"
                      type="checkbox"
                      value=""
                      id={"flexCheckDefault" + ind}
                    />
                    <label
                      className="form-check-label cursor_pointer"
                      htmlFor={"flexCheckDefault" + ind}
                    >
                      {val.machineName + " - " + val.serialNo}
                    </label>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="machine_view">
          <div className="row">
            <div className="d-md-flex justify-content-between align-items-center col-12 m-0">
              <Tooltip id={"open-tooltip"} place="left" />
              <img
                src={Slide}
                height="32"
                width="32"
                className="cursor_pointer my-1 me-2"
                onClick={() => toggleNav()}
                data-tooltip-id={"open-tooltip"}
                data-tooltip-content={"Sidebar"}
              />

              {privilage.write && (
                <button
                  className="btn button-primary btn-sm"
                  // onClick={() => navigate("/pcms/createMachine")}
                  onClick={() =>
                    setSelStatus({ view: false, add: true, edit: false })
                  }
                >
                  {constLabel?.lbl_add_machine
                    ? constLabel.lbl_add_machine
                    : "Add Machine"}
                </button>
              )}
            </div>

            {selStatus.add == true ? (
              <CreateMachine
                setSelStatus={setSelStatus}
                fetchMachine={fetchMachine}
                constLabel={constLabel}
              />
            ) : null}

            {selStatus.edit == true ? (
              selMachine !== undefined ? (
                <EditMachine
                  setSelStatus={setSelStatus}
                  selMachine={selMachine}
                  setSelMachine={setSelMachine}
                  fetchMachine={fetchMachine}
                  machineImgs={machineImgs}
                  fetchImage={fetchImage}
                  constLabel={constLabel}
                />
              ) : null
            ) : null}

            {selStatus.view == true ? (
              Object.keys(selMachine)?.length > 0 ? (
                <div className="col-12 mt-3">
                  <div className="card border-0">
                    <div className="card-body p-4">
                      <div className="d-flex justify-content-between pb-3 position-relative">
                        {/* <ArrowRightCircle onClick={navigate('')} /> */}
                        <p className="roboto-bold" style={{ fontSize: "24px" }}>
                          {/* {constLabel?.lbl_machine_detail
                            ? constLabel?.lbl_machine_detail
                            : "Machine Detail"} */}
                          {selMachine?.machineName && selMachine?.serialNo
                            ? `${selMachine.machineName} - ${selMachine.serialNo}`
                            : null}
                        </p>

                        {selMachine.status && (
                          <div className="col-12 col-md-6">
                            <span className="badge rounded-pill bg-success no-badge-weight">
                              {selMachine.status == "active" && "Active"}
                            </span>
                          </div>
                        )}

                        {/* {privilage.write && (
                          <>
                            <Tooltip id={"edit-tooltip"} place="top" />
                            <div
                              data-tooltip-id={"edit-tooltip"}
                              data-tooltip-content={
                                constLabel?.lbl_edit_machine
                                  ? constLabel?.lbl_edit_machine
                                  : "Edit Machine"
                              }
                              className="editIcon-sm me-2 "
                              style={{ position: "absolute", right: "35px" }}
                            >
                              <Edit2
                                color="#FFFFFF"
                                size={16}
                                className="cursor-pointer"
                                onClick={() => {
                                  // navigate('/pcms/editMachine');
                                  setSelStatus({
                                    view: false,
                                    add: false,
                                    edit: true,
                                  });
                                }}
                              />
                            </div>
                          </>
                        )} */}

                        {privilage.write && (
                          <>
                            <Tooltip id={"edit-tooltip"} place="top" />
                              <Edit
                                  data-tooltip-id={"edit-tooltip"}
                                  data-tooltip-content={
                                    constLabel?.lbl_edit_machine
                                      ? constLabel?.lbl_edit_machine
                                      : "Edit Machine"
                                  }
                                className="menu_icon_grey cursor_pointer"
                                style={{ position: "absolute", right: "3%" }}
                                size={20} 
                                onClick={() => {
                                  // navigate('/pcms/editMachine');
                                  setSelStatus({
                                    view: false,
                                    add: false,
                                    edit: true,
                                  });
                                }}
                              />
                          </>
                        )}

                        {privilage.delete && (
                          <>
                            <Tooltip id={"delete-tooltip"} place="top" />
                              <Trash2
                                  data-tooltip-id={"delete-tooltip"}
                                  data-tooltip-content={
                                    constLabel?.lbl_delete_machine
                                      ? constLabel?.lbl_delete_machine
                                      : "Delete Machine"
                                  }
                                className="menu_icon_red cursor_pointer"
                                style={{ position: "absolute", right: "0%" }}
                                size={20} 
                                onClick={() => delMachine(selMachine)}
                              />
                          </>
                        )}

                        {/* {privilage.delete && (
                          <>
                            <Tooltip id={"delete-tooltip"} place="top" />
                            <div
                              data-tooltip-id={"delete-tooltip"}
                              data-tooltip-content={
                                constLabel?.lbl_delete_machine
                                  ? constLabel?.lbl_delete_machine
                                  : "Delete Machine"
                              }
                              className="deleteIcon-sm me-2 "
                              style={{ position: "absolute", right: "0" }}
                            >
                              <Trash2
                                color="#FFFFFF"
                                size={16}
                                className="cursor-pointer"
                                onClick={() => {
                                  delMachine(selMachine);
                                }}
                              />
                            </div>
                          </>
                        )} */}
                      </div>

                      {selMachine?.machineName !== "" ? (
                        <div className="row ">
                          <div className="col-12 col-md-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_location_name
                                ? constLabel?.lbl_location_name
                                : "Location Name"}
                            </label>
                            <p className="mb-0">
                              {selMachine?.locationName !== undefined &&
                              selMachine?.locationName !== null &&
                              selMachine?.locationName !== ""
                                ? selMachine?.locationName
                                : "N/A"}
                            </p>
                          </div>
                          <div className=" col-12 col-md-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_model
                                ? constLabel?.lbl_model
                                : "Model"}
                            </label>
                            <p className="mb-0">
                              {selMachine?.machineModel !== undefined &&
                              selMachine?.machineModel !== null &&
                              selMachine?.machineModel !== ""
                                ? selMachine?.machineModel
                                : "N/A"}
                            </p>
                          </div>
                          <div className="col-12 col-md-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_serial_number
                                ? constLabel?.lbl_serial_number
                                : "Serial Number"}
                            </label>
                            <p className="mb-0">
                              {selMachine?.serialNo !== undefined &&
                              selMachine?.serialNo !== null &&
                              selMachine?.serialNo !== ""
                                ? selMachine?.serialNo
                                : "N/A"}
                            </p>
                          </div>
                          <div className="col-12 col-md-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_vendor_name
                                ? constLabel?.lbl_vendor_name
                                : "Vendor Name"}
                            </label>
                            <p className="mb-0">
                              {selMachine?.vendorName !== undefined &&
                              selMachine?.vendorName !== null &&
                              selMachine?.vendorName !== ""
                                ? selMachine?.vendorName
                                : "N/A"}
                            </p>
                          </div>
                          <div className="col-12 col-md-3 mt-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_machine_cost
                                ? constLabel?.lbl_machine_cost
                                : "Machine Cost"}
                            </label>
                            <p className="mb-0">
                              {selMachine?.cost !== undefined &&
                              selMachine?.cost !== null &&
                              selMachine?.cost !== ""
                                ? selMachine?.cost
                                : "N/A"}
                            </p>
                          </div>
                          <div className="col-12 col-md-3 mt-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_service_interval
                                ? constLabel?.lbl_service_interval
                                : "Service Interval"}
                            </label>
                            <p className="mb-0">
                              {selMachine?.service_schedule_duration !==
                                undefined &&
                              selMachine?.service_schedule_duration !== null
                                ? selMachine?.service_schedule_duration +
                                  " " +
                                  selMachine?.service_schedule_type
                                : "N/A"}
                            </p>
                          </div>
                          <div className="col-12 col-md-3 mt-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_lastService_date
                                ? constLabel?.lbl_lastService_date
                                : "Last Service Date"}
                            </label>
                            <p className="mb-0">
                              {selMachine?.lastServiceDate !== undefined &&
                              selMachine?.lastServiceDate !== null
                                ? formatDateString(selMachine?.lastServiceDate)
                                : "N/A"}
                            </p>
                          </div>
                          <div className="col-12 col-md-3 mt-3">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_nextService_date
                                ? constLabel?.lbl_nextService_date
                                : "Next Service Date"}
                            </label>
                            <p className="mb-0">
                              {selMachine?.nextServiceDate !== undefined &&
                              selMachine?.nextServiceDate !== null
                                ? formatDateString(
                                    selMachine?.nextServiceDate,
                                    "-"
                                  )
                                : "N/A"}
                            </p>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="alert alert-danger mb-0 text-center my-4"
                          role="alert"
                        >
                          {"Please select machine !"}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="alert alert-danger mb-0 text-center my-4"
                  role="alert"
                >
                  {"No machine  found !"}
                </div>
              )
            ) : null}

            {selStatus.view == true
              ? privilage.read && (
                  <div className="col-12 mt-4">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="roboto-bold" style={{ fontSize: "24px" }}>
                        {constLabel?.lbl_maintenance_data
                          ? constLabel.lbl_maintenance_data
                          : "Maintenance Data"}
                      </p>
                    </div>

                    <div className="col-12">
                      {taskList.loading ? (
                        <div className="text-center">
                          <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </div>
                        </div>
                      ) : taskList.data !== undefined &&
                        taskList.data.length > 0 ? (
                        <>
                          <div
                            className="table-responsive"
                            style={{ minHeight: "60vh" }}
                          >
                            <table className="table table-bordered">
                              <thead className="table-grey roboto">
                                <tr className="">
                                  <th>{"Sr.No"}</th>
                                  <th>
                                    {constLabel?.lbl_task_name
                                      ? constLabel.lbl_task_name
                                      : "Task Name"}
                                  </th>
                                  <th>
                                    {constLabel?.lbl_start_name
                                      ? constLabel.lbl_start_name
                                      : "Start Date"}
                                  </th>
                                  <th>
                                    {constLabel?.lbl_end_start
                                      ? constLabel.lbl_end_start
                                      : "End Date"}
                                  </th>
                                  <th>
                                    {constLabel?.lbl_service_cost
                                      ? constLabel.lbl_service_cost
                                      : "Service Cost"}
                                  </th>
                                  <th>
                                    {constLabel?.lbl_status
                                      ? constLabel.lbl_status
                                      : "Status"}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {taskList.data.map((val, index) => (
                                  <tr key={index}>
                                    <td width="5%">{index + 1}</td>
                                    <td>{val.task_name}</td>
                                    <td>{convertDate(val.start_date, "-")}</td>
                                    <td>{convertDate(val.end_date, "-")}</td>
                                    <td>{val.service_cost}</td>
                                    <td>{val.status}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>

                          <div className="d-flex justify-content-between align-items-center mt-2">
                            <p className="mb-0">
                              Show
                              <select
                                className="mx-1"
                                defaultValue={entriesPerPage}
                                onChange={(e) => {
                                  setEntriesPerPage(e.target.value);
                                  fetchMaintenanceTask(
                                    page,
                                    e.target.value,
                                    selMachine
                                  );
                                }}
                              >
                                <option value={"10"}>10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                              </select>
                              Entries
                            </p>
                            <p className="mb-0">{`Showing ${
                              parseInt(srNo) + 1
                            } to ${
                              taskList.data.length +
                              entriesPerPage * page -
                              entriesPerPage
                            } of ${taskList.totalRecords} entries`}</p>
                            <Pagination className="my-2">
                              <Pagination.Prev
                                disabled={page === 1 ? true : false}
                                onClick={() => {
                                  fetchMaintenanceTask(
                                    page - 1,
                                    entriesPerPage,
                                    parseInt(srNo) - parseInt(entriesPerPage)
                                  );
                                  setSrNo((prevC) =>
                                    page - 1 == 1 ? 0 : prevC - entriesPerPage
                                  );
                                  setPage(page - 1);
                                }}
                              >
                                {"Prev"}
                              </Pagination.Prev>

                              <Pagination.Item active>{page}</Pagination.Item>

                              <Pagination.Next
                                disabled={
                                  page === maxPage ||
                                  maxPage === 0 ||
                                  entriesPerPage > taskList.data.length
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  fetchMaintenanceTask(
                                    page + 1,
                                    entriesPerPage,
                                    parseInt(srNo) + parseInt(entriesPerPage)
                                  );
                                  setSrNo(
                                    (prevC) => prevC + parseInt(entriesPerPage)
                                  );
                                  setPage(page + 1);
                                }}
                              >
                                {"Next"}
                              </Pagination.Next>
                            </Pagination>
                          </div>
                        </>
                      ) : (
                        <div
                          className="alert alert-danger mb-0 text-center my-2"
                          role="alert"
                        >
                          {"No machine task found !"}
                        </div>
                      )}
                    </div>
                  </div>
                )
              : null}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MachineAsset;
