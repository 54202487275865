import React, { Suspense } from "react";
import "./App.css";
import "@trendmicro/react-sidenav/dist/react-sidenav.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import "react-datepicker/dist/react-datepicker.css";
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import DefaultLayout from "./DefaultLayout/DefaultLayout";
import LandingPage from "./Components/LandingPage/LandingPage";
import POWebView from "./Components/Common/POWebView";
import NewLogin from "./Authentication/NewLogin";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-tooltip/dist/react-tooltip.css'
import "driver.js/dist/driver.css";
import Common from "./Components/Masters/OnBoarding/Common";
import LedgerList from "./Components/Tally/LedgerList";


function App() {
  
  return (
    <React.Fragment>
      <BrowserRouter>
        <Suspense fallback={<p>loading</p>}>
          <Routes>
          <Route path={`${process.env.PUBLIC_URL}/onBoarding`} element={<Common />} />
            <Route path={`${process.env.PUBLIC_URL}/login`} element={<NewLogin />} />
            <Route path={`${process.env.PUBLIC_URL}/tally`} element={<LedgerList />} />
            <Route path={`${process.env.PUBLIC_URL}/`} element={<LandingPage />} />
            <Route path={`${process.env.PUBLIC_URL}/PO-report/:id/:token`} element={<POWebView />} />
            <Route path="*" element={<DefaultLayout />} />

          </Routes>
        </Suspense>
      </BrowserRouter>
    </React.Fragment>
  );
}

export default App;
