import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from '../slices/usermanagement/user';
import moduleReducer from '../slices/modules/modules';
import authSlice from '../slices/authentication/authSlice';
import stepReducer from '../slices/production_planning/production_plan_slice';

const persistConfig = {
    key: 'root', // Key for storage
    storage,    // Choose local or session storage
};

const persistedReducer = persistReducer(persistConfig, authSlice);
const persistedModReducer = persistReducer({ key: 'mod', storage, }, moduleReducer);


export const store = configureStore({
    reducer: {
        users: userReducer,
        // auth: authSlice,
        auth: persistedReducer,
        module: persistedModReducer,
        steps: stepReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export const persistor = persistStore(store);