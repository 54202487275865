import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert2";
import {
  faBan,
  faCog,
  faFilter,
  faPlus,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { getSupplier, getQuotation, deleteQuotation } from "../../../api/index";
import {
  calMaxPage,
  convertDate,
  getLabels,
  setAccessPrivilage,
} from "../../../common/Functions/CommonFunctions";
import { Pagination, OverlayTrigger } from "react-bootstrap";
import { PlusSquare, Trash2, Copy, FileText } from "react-feather";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { Supplier_Quotation_Col_Name } from "../../../common/Labels/labelConstant";
import { useSelector } from "react-redux";
import List from "../../../assets/images/icons/project_menu/checklist.png"
import Slide from "../../../assets/images/icons/project_menu/collapse.png"

function Quatation() {
  const [showLabel, setShowLabel] = useState(Supplier_Quotation_Col_Name);
  const [supplierList, setSupplierList] = useState({
    data: [],
    loading: false,
  });
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });

  const [filterConfig, setFilterConfig] = useState({
    supplier_id: [],
    status_name: "",
  });

  const [constLabel, setConstLabel] = useState({});

  const { setValue, control, watch } = useForm({ mode: "onChange" }); // initialise the hook

  let navigate = useNavigate();

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const [privilage, setPrivilage] = useState(
    setAccessPrivilage(loginData, "Purchase")
  );

  useEffect(() => {
    setConstLabel(getLabels("Purchase", "Supplier Quotation"));
    fetchSuppliers();
  }, []);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      fetchQuatationList(1, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function fetchSuppliers() {
    setSupplierList({ ...supplierList, state: true, data: [] });
    getSupplier({partyType: "Supplier"}).then(
      (res) => {
        const result = res.data.data.filter((item) => item.name !== null);
        setSupplierList({
          ...supplierList,
          state: false,
          data: result,
        });
      },
      (err) => {
        setSupplierList({ ...supplierList, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }

  function fetchQuatationList(pg, epp) {
    setDataList({ ...dataList, loading: true, data: [] });

    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
    };

    if (filterConfig.supplier_id.length > 0) {
      payload.supplierid_id = filterConfig.supplier_id[0].uuid; // set supplier uuid to the search field
    } else {
      delete payload.supplierid_id;
    }

    if (filterConfig.status_name.length > 0) {
      payload.status_name = filterConfig.status_name;          // set supplier status to the search field
    } else {
      delete payload.status_name;
    }

    getQuotation(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });
        setMaxPage(calMaxPage(res.data.total));
        if(pg == 1){
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({
          ...dataList,
          loading: false,
          data: [],
          totalRecords: "",
        });
      }
    );
  }

  function clearFilter() {
    setFilterConfig({
      ...filterConfig,
      supplier_id: [],
      status_name: ""
    });
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function delQuotation(val) {
    SweetAlert.fire({
      title: "Are you sure to delete quotation ? \n",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = { uuid: val.uuid };
        deleteQuotation(payload).then(
          (res) => {
            toast.success("Quotation deleted successfully", {
              autoClose: 2000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchQuatationList(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page-1);
            } else {
              fetchQuatationList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="min-vh-100" id="root_div_main">
        {/* <NavbarComp2 /> */}

        <div id="mySidebar" className="customsidebar">
          <a className="closebtn" onClick={() => toggleNav()}>
            ×
          </a>

          <div className=" content">
            <div>
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_selSupplier
                  ? constLabel.lbl_selSupplier
                  : "Select Supplier"}
              </label>

              <Typeahead
                id="basic-typeahead-single"
                labelKey="name"
                name="supplier"
                multiple={false}
                // size="sm"
                options={supplierList.data}
                isLoading={supplierList.loading}
                selected={filterConfig.supplier_id}
                onChange={(val) => {
                  if (val.length > 0) {
                    setFilterConfig({ ...filterConfig, supplier_id: val });
                  } else {
                    setFilterConfig({ ...filterConfig, supplier_id: [] });
                  }
                }}
                placeholder="Search by supplier"
              />
            </div>

            <div className="mt-2">
              <label
                className="filterLabel mb-1 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_status_name
                  ? constLabel?.lbl_status_name
                  : "Status Name"}
              </label>
              <input
                className="form-control"
                type="text"
                placeholder="Search by status name"
                // value={filterConfig.status_name != ""  ? filterConfig.status_name : ""}
                value={filterConfig.status_name}
                onChange={(val) => {
                  const inputValue = val.target.value;
                  if (!inputValue.trim()) {
                    // Input contains only whitespace, don't trigger the search.
                    if (filterConfig.status_name.length == 1) {
                      setFilterConfig({ ...filterConfig, status_name: "" });
                    }

                    if (inputValue.length == 0) {
                      setFilterConfig({ ...filterConfig, status_name: "" });
                    }

                  } else {
                    setFilterConfig({
                      ...filterConfig,
                      status_name: inputValue,
                    });
                  }
                }}
              />
            </div>
            <div className="my-3 d-flex justify-content-end">
              <button
                className="btn btn-sm btn-yellow"
                onClick={() => clearFilter()}
              >
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {constLabel?.lbl_clear_filter
                  ? constLabel.lbl_clear_filter
                  : "Clear Filter"}
              </button>
            </div>
          </div>
        </div>

          <div className="content-wrapper">
            <div className="card border-0">
              <div className="card-body">
                <div className="row align-items-center">
                <div className="d-md-flex justify-content-start col-12 col-md-6">
                  <Tooltip id={"open-tooltip"} place="top" />
                  <img
                    src={Slide}
                    height="32"
                    width="32"
                    className="cursor_pointer my-1 me-2"
                    onClick={() => toggleNav()}
                    data-tooltip-id={"open-tooltip"}
                    data-tooltip-content={"Sidebar"}
                  />
                  <h1 className="bold">
                    {constLabel?.lbl_suh1plier_quotation
                      ? constLabel.lbl_supplier_quotation
                      : "Supplier Quotation"}
                  </h1>
                </div>
                <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                  {privilage.write && (
                    <button
                      className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                      onClick={() => navigate("/pcms/createQuotation")}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff", marginRight: "6px" }}
                        icon={faPlus}
                        size="sm"
                      />
                      {constLabel?.lbl_create_quotation
                        ? constLabel.lbl_create_quotation
                        : "Create Quotation"}
                    </button>
                  )}

                  <Tooltip id={"show-tooltip"} place="top" />
                  <div
                    className="dropdown"
                    data-tooltip-id={"show-tooltip"}
                    data-tooltip-content={"Show / Hide Columns"}
                  >
                    <div
                      className="d-md-flex justify-content-start align-items-center"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {/* <FontAwesomeIcon icon={faCog} size="lg" /> */}
                      {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                      <img
                        src={List}
                        height="32"
                        width="32"
                        className="cursor_pointer"
                      />
                    </div>

                    <ul className="dropdown-menu px-2">
                      {showLabel !== undefined && showLabel.length > 0
                        ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                </div>
              </div>
                <div className="mt-3">
                {dataList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : dataList.data.length > 0 ? (
                  <>
                    <div className="table-responsive">
                      <table className="table mt-2 table-bordered">
                        <thead className="table-grey roboto-bold">
                          <tr className="">
                            <th scope="col">{"Sr.No"}</th>
                            {showLabel.map((v, i) =>
                              v.label === "Quote Id" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_quote_id
                                    ? constLabel.lbl_quote_id
                                    : "Quote Id"}
                                </th>
                              ) : v.label === "Date" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_date
                                    ? constLabel.lbl_date
                                    : "Date"}
                                </th>
                              ) : v.label === "Supplier Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_supplier_name
                                    ? constLabel.lbl_supplier_name
                                    : "Supplier Name"}
                                </th>
                              ) : v.label === "Quotation For" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_quotation_for
                                    ? constLabel.lbl_quotation_for
                                    : "Quotation For"}
                                </th>
                              ) : v.label === "Value" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_value
                                    ? constLabel.lbl_value
                                    : "Value"}
                                </th>
                              ) : v.label === "Status" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_status
                                    ? constLabel.lbl_status
                                    : "Status"}
                                </th>
                              ) : v.label === "Remark" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_remark
                                    ? constLabel.lbl_remark
                                    : "Remark"}
                                </th>
                              ) : null
                            )}
                            <th scope="col" width="15%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className=" roboto">
                          {dataList.data.map((val, i) => (
                            <tr key={i}>
                              <td>{i + parseInt(srNo) + 1}</td>
                              {showLabel.map((item, idx) =>
                                item.label === "Quote Id" && item.flag ? (
                                  <td key={idx}>
                                    {val.quotesid != "" && val.quotesid !== null
                                      ? val.quotesid
                                      : "NA"}
                                  </td>
                                ) : item.label === "Date" && item.flag ? (
                                  <td key={idx}>
                                    {val.quote_date != "" &&
                                    val.quote_date !== null
                                      ? convertDate(val.quote_date, "-")
                                      : "NA"}
                                  </td>
                                ) : item.label === "Supplier Name" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.supplier_name != "" &&
                                    val.supplier_name !== null
                                      ? val.supplier_name
                                      : "NA"}
                                  </td>
                                ) : item.label === "Quotation For" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.quotationForDetails?.comName != "" &&
                                    val.quotationForDetails?.comName !== null
                                      ? val.quotationForDetails?.comName
                                      : "NA"}
                                  </td>
                                ) : item.label === "Value" && item.flag ? (
                                  <td key={idx}>
                                    {val.final_total != "" &&
                                    val.final_total !== null
                                      ? val.final_total
                                      : "NA"}
                                  </td>
                                ) : item.label === "Status" && item.flag ? (
                                  <td key={idx}>
                                    {val.status_name != "" &&
                                    val.status_name !== null
                                      ? val.status_name
                                      : "NA"}
                                  </td>
                                ) : item.label === "Remark" && item.flag ? (
                                  <td key={idx}>
                                    {val.remark != "" && val.remark !== null
                                      ? val.remark
                                      : "NA"}
                                  </td>
                                ) : null
                              )}

                              <td className="">
                              {privilage.write &&
                              <>
                                <Tooltip id={"revise-tooltip" + i} place="top" />
                                  <Copy
                                      data-tooltip-id={"revise-tooltip" + i}
                                      data-tooltip-content={
                                        constLabel?.lbl_revise_quotation
                                          ? constLabel.lbl_revise_quotation
                                          : "Revise Quotation"
                                      }
                                    className="menu_icon_grey cursor_pointer me-2"
                                    size={20} 
                                    onClick={() =>
                                      navigate("/pcms/editQuotation", {
                                        state: val,
                                      })
                                    }
                                  />
                              </>
                              }

                              {privilage.read &&
                              <>
                                <Tooltip id={"view-tooltip" + i} place="top" />
                                  <FileText
                                      data-tooltip-id={"view-tooltip" + i}
                                      data-tooltip-content={
                                        constLabel?.lbl_view_quotation
                                          ? constLabel.lbl_view_quotation
                                          : "View Quotation"
                                      }
                                    className="menu_icon_grey cursor_pointer me-2"
                                    size={20} 
                                    onClick={() =>
                                      navigate("/pcms/viewQuotation2", {
                                        state: val,
                                      })
                                    }
                                  />
                                </>
                              }

                              {privilage.write &&
                              <>
                                <Tooltip id={"c-po-tooltip" + i} place="top" />
                                  {val.status_name && val.status_name.toLowerCase() == "approved" ? (
                                      <PlusSquare
                                        data-tooltip-id={"c-po-tooltip" + i}
                                        data-tooltip-content={
                                          constLabel?.lbl_create_purchase_order
                                            ? constLabel.lbl_create_purchase_order
                                            : "Create Purchase Order"
                                        }
                                        className="menu_icon_grey cursor_pointer me-2"
                                        size={20} 
                                        onClick={() =>
                                          navigate(
                                            `/pcms/create/purchase-order/${btoa(
                                              val.uuid
                                            )}/${btoa(val.supplierid_id)}`
                                          )
                                        }
                                      />
                                  ) : null}
                                </>
                              }

                              {privilage.delete &&
                                <>
                                  <Tooltip id={"delete-tooltip" + i} place="top" />
                                    <Trash2
                                        data-tooltip-id={"delete-tooltip" + i}
                                        data-tooltip-content={
                                        constLabel?.lbl_delete_quotation
                                          ? constLabel.lbl_delete_quotation
                                          : "Delete Quotation"
                                      }
                                      className="menu_icon_red cursor_pointer"
                                      size={20} 
                                      onClick={() => delQuotation(val)}
                                    />
                                </>
                              }
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>

                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <p className="mb-0">
                        Show
                        <select
                          className="mx-1"
                          defaultValue={entriesPerPage}
                          onChange={(e) => {
                            setEntriesPerPage(e.target.value);
                            fetchQuatationList(1, e.target.value);
                          }}
                        >
                          <option value={"10"}>10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        Entries
                      </p>
                      <p className="mb-0">{`Showing ${
                        parseInt(srNo) + 1
                      } to ${Math.min(
                        parseInt(entriesPerPage) + parseInt(srNo),
                        dataList.totalRecords
                      )} of ${dataList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchQuatationList(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );

                            setSrNo(
                              (prevC) => prevC - parseInt(entriesPerPage)
                            );

                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                            maxPage === 0 ||
                            entriesPerPage > dataList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            fetchQuatationList(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );

                            let number = Number(srNo) + Number(entriesPerPage);

                            // setSrNo(number)
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  </>
                ) : (
                  <div
                    className="alert alert-danger mb-0 text-center my-4"
                    role="alert"
                  >
                    {"No quotation data found !"}
                  </div>
                )}
                </div>
              </div>
            </div>
          </div>
      </div>
    </React.Fragment>
  );
}

export default Quatation;
