import React, { useState, useEffect} from 'react'
import { useForm, Controller } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tooltip } from 'react-tooltip';
import { Edit2 } from 'react-feather';
import { Modal } from 'react-bootstrap';
import { updateSupplierType } from '../../../../api';

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required("Supplier type name is required !"),
  })
  .required();

function EditSupplierType(props) {
    const [visible, setVisible] = useState(false);

    const { register, handleSubmit, formState, control, reset, watch } = useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook
    
    let { errors } = formState;
    
    const onSubmit = (data) => {
      
    
      if (data !== "") {
        const reqPayload = {
          uuid: props.data.uuid,
          name: data.name,
        };
    
        
    
        updateSupplierType(reqPayload)
          .then(
            (res) => {
              
              if (res.status === 200 || res.status === 201) {
                toast.success("Supplier type update Successfully !", {
                  autoClose: 3000,
                });
                setVisible(false);
                props.fetchSupplierType(props.page, props.entriesPerPage);
              }
            },
            (err) => {
              
              if (err.response.status === 400) {
                
                toast.error(err.response.data.message, {
                  autoClose: 2000,
                });
                
              }
            }
          )
          .catch((error) => {
            
            toast.error("Something went wrong, please try again !", {
              autoClose: 2000,
            });
          });
      } else {
        errors.showMessages();
      }
    };

  return (
    <React.Fragment>
    <Tooltip id={"delete-tooltip"} place="top" />
      <div
        data-tooltip-id={"delete-tooltip"}
        data-tooltip-content={props.constLabel?.lbl_edit_type ? props.constLabel.lbl_edit_type : "Edit Type"}
        className="editIcon me-2">
        <Edit2
          color="#FFFFFF"
          size={18}
          className="cursor-pointer"
          onClick={() => {
            // setUpdateForm(val)
            setVisible(true); reset();
          }}
        />
      </div>
    <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{props.constLabel?.lbl_update_supplier_type ? props.constLabel.lbl_update_supplier_type :"Update Supplier Type"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form action="">
          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-md-12 roboto-bold">
            {props.constLabel?.lbl_supplier_type ? props.constLabel.lbl_supplier_type : "Supplier Type"}
              <span className="text-danger">*</span>
            </label>
            <div className="col-md-12">
              <input
                type="text"
                className={errors.name ? "form-control  is-invalid"
                : "form-control   "}
                id="name"
                name="name"
                placeholder="Enter supplier type name"
                defaultValue={props.data.name}
                {...register("name")}
              />
              <span className="text-danger err-msg">
                {errors.name?.message}
              </span>
            </div>
          </div>
        </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel?.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>

  </React.Fragment>
  )
}

export default EditSupplierType