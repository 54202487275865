import React, { useState, useEffect } from 'react';
import { create_production_plan } from "../../../api/index";
import { convertDateYYMMDD, calculateWorkHours } from "../../../common/Functions/CommonFunctions"
import { toast } from "react-toastify";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import Row from 'react-bootstrap/Row';
import {
    get_workorder_product_routes, getProdConfig, getOperatorList_availability,
    getOperatorList, getGlobalRouting
} from "../../../api/index"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DatePicker from "react-datepicker";
import JobCardModal from './JobCardModal';
import { useSelector, useDispatch } from 'react-redux';
import { updateStepThree, updateStepFour } from '../../../slices/production_planning/production_plan_slice';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, } from "@fortawesome/free-solid-svg-icons";
import { Edit } from "react-feather";
import refresh from "../../../assets/images/refresh.png";

const schema = yup
    .object()
    .shape({
        route: yup
            .array()
            // .min(1, "Please select route !")
            .notRequired("Please select route !"),
        global_route: yup
            .array()
            // .min(1, "Please select global route !")
            .notRequired("Please select global route !"),
        startDate: yup
            .date()
            .typeError("Please select date!")
            .required("Please select date!"),
        endDate: yup
            .date()
            .typeError("Please select end date!")
            .required("Please select end date!"),
    })
    .required().test('at-least-one', 'Please select either route or global route!', function (value) {
        const { route, global_route } = value;
        return (route && route.length > 0) || (global_route && global_route.length > 0);
    });

function StepFour(props) {
    const { nextStep, prevStep } = props;
    const dispatch = useDispatch();
    const StepOneSavedData = useSelector((state) => state.steps.StepOne);
    const StepTwoSavedData = useSelector((state) => state.steps.StepTwo);
    const StepFourSavedData = useSelector((state) => state.steps.StepFour);


    const [constLabel, setConstLabel] = useState({});
    const [productRoutes, setProductRoutes] = useState({ data: [], loading: false, isSearched: false });
    const [productGlobalRoutes, setProductGlobalRoutes] = useState({ data: [], loading: false });

    const navigate = useNavigate();
    const [uniProdBom, setUniProdBom] = useState([]);

    const [prodOpers, setProdOpers] = useState({ data: [], loading: false, buttonClick: false });
    const [empListWithAvail, setEmpListWithAvail] = useState({ data: [], loading: false });
    const [selectedopr, setSelectedOpr] = useState({ data: null, index: null });

    const [visible, setVisible] = useState(false);
    const [routeType, setRouteType] = useState("product_routes");
    const [disableDates, setDisableDates] = useState(false);


    const {
        register,
        handleSubmit,
        formState,
        control,
        watch,
        getValues,
        setValue,
        reset,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            global_route: [],
            route: [],
        },
        mode: "onChange",
    });
    let { errors } = formState;

    const startDate = watch('startDate');
    const endDate = watch('endDate');



    useEffect(() => {
        console.log("StepTwoSavedData", StepTwoSavedData);
        let isMounted = true;
        if (isMounted) {

            console.log("StepFourSavedData", StepFourSavedData);
            if (StepFourSavedData?.uniProdBom?.length > 0) {
                const redux_step_four_data = JSON.parse(JSON.stringify(StepFourSavedData));
                redux_step_four_data.uniProdBom.forEach((prod) => {
                    console.log("prod", prod);

                    prod.selected_route.route_operation_list.forEach((opr) => {
                        console.log("opr", opr);

                        opr.selected_startdate = new Date(opr.selected_startdate);
                        opr.selected_enddate = new Date(opr.selected_enddate);
                    })
                })


                setUniProdBom([...redux_step_four_data.uniProdBom]);

                console.log("redux_step_four_data.uniProdBom", redux_step_four_data.uniProdBom);

                const selProdIndex = redux_step_four_data.uniProdBom.findIndex((val) => val.selected == true);

                setProductRoutes({
                    ...productRoutes, data: redux_step_four_data.uniProdBom[selProdIndex].product_routes,
                    loading: false, isSearched: true
                });


                setProdOpers({
                    ...prodOpers,
                    loading: false,
                    buttonClick: false,
                    data: redux_step_four_data.uniProdBom[selProdIndex].selected_route.route_operation_list
                });


                console.log(selProdIndex);
                console.log(redux_step_four_data.uniProdBom[selProdIndex]);


                if (redux_step_four_data.uniProdBom[selProdIndex].routeType == "global_routes") {

                    setRouteType("global_routes");


                    productGlobalRoutes.data = redux_step_four_data.uniProdBom[selProdIndex].global_routes;
                    productGlobalRoutes.loading = false;
                    setProductGlobalRoutes(productGlobalRoutes);

                    setValue('global_route', [redux_step_four_data.uniProdBom[selProdIndex].selected_route], { shouldValidate: true });
                } else if (redux_step_four_data.uniProdBom[selProdIndex].routeType == "product_routes") {

                    setRouteType("product_routes");

                    productRoutes.data = redux_step_four_data.uniProdBom[selProdIndex].product_routes;
                    productRoutes.loading = false;
                    productRoutes.isSearched = false;
                    setProductRoutes(productRoutes);
                    setValue('route', [redux_step_four_data.uniProdBom[selProdIndex].selected_route], { shouldValidate: true });
                }
                setValue('startDate', new Date(redux_step_four_data.uniProdBom[selProdIndex].startDate), { shouldValidate: true });
                setValue('endDate', new Date(redux_step_four_data.uniProdBom[selProdIndex].endDate), { shouldValidate: true });




                fetchEmp(redux_step_four_data.uniProdBom[selProdIndex].selected_route.route_operation_list, selProdIndex);

            } else {

                let uniqueProducts = [];
                const uuidSet = new Set();

                let i = 0;

                // for (const prod of StepTwoSavedData.prodListWithBom.data) {
                for (const prod of StepTwoSavedData.unConsolidated_prodListWithBom.data) {

                    // Even if the user selects consolidate we going to create workorder for each product
                    // to achieve this below if condition is crucial
                    // we have handled two arrays 1.consildate products 2.unconsolidated products
                    let combination;
                    let foundedProd;

                    if (StepTwoSavedData.consolidate) {
                        foundedProd = StepTwoSavedData.prodListWithBom.data
                            .find((item) => item.uuid == prod.uuid);

                        console.log("foundedProd", foundedProd);
                        if (!foundedProd) {
                            toast.error("Something went wrong finding selected bom in consolidated array",
                                { autoClose: 3000, });
                            return
                        }



                        combination = `${prod.uuid}-${foundedProd.selected_bom[0].uuid}`;

                    } else {
                        combination = `${prod.uuid}-${prod.selected_bom[0].uuid}`;
                    }



                    // if (!uuidSet.has(combination)) {
                    uuidSet.add(combination);
                    // prod.completed = false;
                    // prod.selected = false;
                    // prod.selected = i == 0 ? true : false;

                    const productWithLessKeys = {
                        OEMnumber: prod.OEMnumber,
                        bom: prod.bom,
                        client_id: prod.client_id,
                        completed: false,
                        selected: false,
                        isActive: prod.isActive,
                        material: prod.material,
                        order_date: prod.order_date,
                        order_due_date: prod.order_due_date,
                        partNo: prod.partNo,
                        prod_family_id: prod.prod_family_id,
                        prodfamily: prod.prodfamily,
                        productId: prod.productId,
                        productName: prod.productName,
                        productType: prod.productType,
                        productTypename: prod.productTypename,
                        product_type_id: prod.product_type_id,
                        selling_price: prod.selling_price,
                        so_item_quantity: prod.so_item_quantity,
                        so_customer_id: prod.so_customer_id,
                        so_final_total: prod.so_final_total,

                        // this needs to be changed later when bar handling will be done
                        length: 0,
                        unit_id: prod.unit_id,
                        so_uuid: prod.so_uuid,
                        so_item_uuid: prod.so_item_uuid,
                        uuid: prod.uuid,
                        product_familyId: prod.prodfamily
                    };

                    if (StepTwoSavedData.consolidate) {
                        productWithLessKeys.selected_bom = foundedProd.selected_bom;
                    } else {
                        productWithLessKeys.selected_bom = prod.selected_bom;
                    }

                    uniqueProducts.push(productWithLessKeys);
                    // }
                    i++;
                }
                console.log("uniqueProducts", uniqueProducts);


                setUniProdBom([...uniqueProducts]);

                const currentDate2 = new Date();
                const nextMonthDate = currentDate2.setMonth(currentDate2.getMonth() + 1);
                const currentDate = new Date();

                setValue('startDate', currentDate, { shouldValidate: true });

                setValue('endDate', new Date(nextMonthDate), { shouldValidate: true });
            }
        }


        return () => {
            isMounted = false;
        };
    }, []);



    function fetchProdRoutes(product_details, ind) {
        console.log("product_details", product_details);
        setProductRoutes({ ...productRoutes, data: [], loading: true, isSearched: false });

        return new Promise(async (resolve, reject) => {
            try {
                uniProdBom.forEach((item, i) => {
                    if (i == ind) {
                        item.selected = true;
                    } else {
                        item.selected = false;
                    }
                })
                console.log("uniProdBom", uniProdBom);
                setUniProdBom(uniProdBom);



                try {
                    const proutes = await get_workorder_product_routes({ product_id: product_details.uuid })
                    console.log("proutes", proutes);

                    productRoutes.data = proutes.data.data;
                    productRoutes.loading = false;
                    productRoutes.isSearched = true;
                    setProductRoutes(productRoutes);


                    if (proutes.data.data.length == 1) {
                        console.log('setting route', proutes.data.data[0]);
                        setValue("route", [proutes.data.data[0]], { shouldValidate: true });
                        setValue("global_route", []);
                    } else {
                        setValue("global_route", []);

                    }

                    uniProdBom[ind].product_routes = proutes.data.data;



                    if (proutes.data.data.length == 0) {
                        console.log('___running');
                        setProductGlobalRoutes({ ...productGlobalRoutes, data: [], loading: true });
                        const groutes = await getGlobalRouting();
                        console.log("groutes", groutes);

                        uniProdBom[ind].global_routes = groutes.data.data;
                        productGlobalRoutes.data = groutes.data.data;
                        productGlobalRoutes.loading = false;

                        if (groutes.data.data.length == 1) {
                            console.log('setting global_route', groutes.data.data[0]);
                            setValue("route", []);
                            setValue("global_route", [groutes.data.data[0]], { shouldValidate: true });
                        }
                        setProductGlobalRoutes(productGlobalRoutes);
                        resolve();
                    } else {
                        console.log('running');
                        setUniProdBom(uniProdBom);

                        productGlobalRoutes.data = [];
                        productGlobalRoutes.loading = false;
                        setProductGlobalRoutes(productGlobalRoutes);
                        resolve();
                    }

                } catch (err) {
                    console.log("err", err);
                    reject(err);
                }

            } catch (error) {
                console.error("Error during API calls", error);
                reject(error);
            }
        });


    }


    const onSubmit = (data) => {
        console.log("data", data);
        let tempUniProdBom = [...uniProdBom];

        const selProdIndex = uniProdBom.findIndex((val) => val.selected == true);
        console.log("selProdIndex", selProdIndex);

        // calculate_total_days();

        // if global route is present whole logic change here
        if (data.global_route.length > 0) {
            setRouteType("global_routes");

            const icon = document.getElementById('refresh-icon-img');
            icon.classList.add('spin');

            const result = data.global_route[0].routeData.map((val) => {
                val.selected_emp = [];
                val.selected_startdate = null;
                val.selected_enddate = null;
                return val
            });
            setProdOpers({ ...prodOpers, loading: false, buttonClick: true, data: result });



            tempUniProdBom[selProdIndex].selected_route = data.global_route[0];
            tempUniProdBom[selProdIndex].startDate = data.startDate;
            tempUniProdBom[selProdIndex].endDate = data.endDate;
            tempUniProdBom[selProdIndex].routeType = "global_routes";
            console.log("result", result);
            tempUniProdBom[selProdIndex].selected_route.route_operation_list = result;

            // uniProdBom = tempUniProdBom;
            setUniProdBom(tempUniProdBom);


            icon.classList.remove('spin');
            if (result.length > 0) {
                const selectedProdIndex = uniProdBom.findIndex((val) => val.selected == true);
                fetchEmp(result, selectedProdIndex);

            }


            icon.classList.remove('spin');

        } else if (data.route.length > 0) {
            setRouteType("product_routes");


            const payload = {
                product_id: tempUniProdBom[selProdIndex].uuid,
                routes_uuid: data.route[0].routes_id
            }

            const icon = document.getElementById('refresh-icon-img');
            icon.classList.add('spin');

            setProdOpers({ ...prodOpers, loading: true, buttonClick: false });
            getProdConfig(payload).then((res) => {
                console.log("res", res);


                const result = res.data.data.map((val) => {
                    val.selected_emp = [];
                    val.selected_startdate = null;
                    val.selected_enddate = null;
                    return val
                });
                console.log("result", result);

                setProdOpers({ ...prodOpers, loading: false, buttonClick: true, data: result, });

                tempUniProdBom[selProdIndex].selected_route = data.route[0];
                tempUniProdBom[selProdIndex].startDate = data.startDate;
                tempUniProdBom[selProdIndex].endDate = data.endDate;
                tempUniProdBom[selProdIndex].routeType = "product_routes";
                console.log("result", result);
                tempUniProdBom[selProdIndex].selected_route.route_operation_list = result;

                // uniProdBom = tempUniProdBom;
                setUniProdBom(tempUniProdBom);


                icon.classList.remove('spin');
                if (result.length > 0) {
                    const selectedProdIndex = uniProdBom.findIndex((val) => val.selected == true);
                    fetchEmp(result, selectedProdIndex);

                }


            }, (err) => {
                icon.classList.remove('spin');
                console.log("err", err);
                setProdOpers({ ...prodOpers, loading: false, buttonClick: true, data: [], });
            })
        }

    }


    function fetchEmp(opr_list, prod_ind) {
        console.log("opr_list", opr_list);
        console.log("productRoutes", productRoutes);
        console.log("productGlobalRoutes", productGlobalRoutes);


        const oper_category_id_list = [];

        for (const opr of opr_list) {
            // here we need to check if operations are of product operations or coming from global routes
            if (productRoutes.data.length > 0) {
                if (!opr.operation_details || !opr.operation_details?.operation_category_id) {
                    toast.error("selected operation doesnt have operation category", {
                        autoClose: 3000,
                    });
                    return
                } else {
                    oper_category_id_list.push(opr.operation_details.operation_category_id);
                }

            } else if (productGlobalRoutes.data.length > 0) {
                if (!opr.operation_category_id) {
                    toast.error("selected operation doesnt have operation category", {
                        autoClose: 3000,
                    });
                    return
                } else {
                    oper_category_id_list.push(opr.operation_category_id);
                }
            }
        }


        setEmpListWithAvail({ ...empListWithAvail, data: [], loading: true });

        const weeklyConfig = {
            monday: StepOneSavedData.formData.monday,
            tuesday: StepOneSavedData.formData.tuesday,
            wednesday: StepOneSavedData.formData.wednesday,
            thursday: StepOneSavedData.formData.thursday,
            friday: StepOneSavedData.formData.friday,
            saturday: StepOneSavedData.formData.saturday,
            sunday: StepOneSavedData.formData.sunday
        }
        console.log("startDate", startDate);
        console.log("endDate", endDate);
        console.log("weeklyConfig", weeklyConfig);

        const params = {
            startDate: convertDateYYMMDD(startDate, "-"),
            endDate: convertDateYYMMDD(endDate, "-"),
            oper_category: JSON.stringify(oper_category_id_list),
            weeklyConfig: JSON.stringify(weeklyConfig)
        }


        if (StepFourSavedData && StepFourSavedData.uniProdBom && StepFourSavedData.uniProdBom.length > 0) {
            const redux_step_four_data = JSON.parse(JSON.stringify(StepFourSavedData));
            const selProdIndex = redux_step_four_data.uniProdBom.findIndex((val) => val.selected == true);

            const start_date = redux_step_four_data.uniProdBom[selProdIndex].startDate;
            const end_date = redux_step_four_data.uniProdBom[selProdIndex].endDate;

            console.log("start_date", start_date);
            console.log("end_date", end_date);

            params.startDate = convertDateYYMMDD(start_date, "-");
            params.endDate = convertDateYYMMDD(end_date, "-");
        }


        console.log("params", params);

        getOperatorList_availability(params).then((res) => {
            console.log("res", res);

            console.log("prod_ind", prod_ind);


            res.data.data.map((val) => {
                val.FullName = val.FirstName + ' ' + val.LastName;
                val.selected_emp = [];
                val.selected_startdate = null;
                val.selected_enddate = null;
            })

            setEmpListWithAvail({ ...empListWithAvail, data: res.data.data, loading: false });
        }, (err) => {
            console.log("err", err);
            setEmpListWithAvail({ ...empListWithAvail, data: [], loading: false });

            if (err.response.status !== 404) {
                toast.error(err.response.data.message, {
                    autoClose: 3000,
                });
            }
        });

    }



    function submitStep() {
        console.log("uniProdBom", uniProdBom);
        const isValidated = validateStepFourData();
        if (isValidated) {
            const allValues = getValues();

            dispatch(updateStepFour({ uniProdBom: uniProdBom, formData: allValues }));
            nextStep();
            console.log("go to next step");
        }
    }

    function validateStepFourData() {

        for (const prod of uniProdBom) {
            if (!prod.selected_route) {
                toast.error("Every prod must have a selected_route.", {
                    autoClose: 3000,
                });
                return
            }

            if (!Array.isArray(prod.selected_route.route_operation_list) ||
                prod.selected_route.route_operation_list.length === 0) {
                toast.error("Every selected_route must have a route_operation_list", {
                    autoClose: 3000,
                });
                return
            }

            const isJobCardPresent = prod.selected_route.route_operation_list.find(
                (val) => val.create_job_card !== null && val.create_job_card !== undefined);

            if (!isJobCardPresent) {
                toast.error("Each product should have atleast one job card", {
                    autoClose: 3000,
                });
                return
            }

        }

        return true; // If validation passes
    }

    function handleDateChange(fn_caller, date, opr_ind, opr) {
        let tempProdOpers = [...prodOpers.data];
        const emp_available_hrs = opr.selected_emp[0].total_hours - opr.selected_emp[0].total_consumed_hrs_in_jc;


        if (fn_caller == "startdate") {
            tempProdOpers[opr_ind].selected_startdate = date;


            const officeStartMillis = 9 * 60 * 60 * 1000; // 9:30 AM in milliseconds
            const officeEndMillis = 18 * 60 * 60 * 1000; // 6:30 PM in milliseconds


            // Calculate hours worked
            if (opr.selected_enddate) {

                const hoursOfWork = calculateWorkHours(date, opr.selected_enddate, officeStartMillis, officeEndMillis);
                console.log(hoursOfWork); // Output: 19

                if (hoursOfWork > opr.selected_emp[0].total_hours -
                    opr.selected_emp[0].total_consumed_hrs_in_jc) {
                    tempProdOpers[opr_ind].hrs_exceeding_err = true;
                } else {
                    tempProdOpers[opr_ind].hrs_exceeding_err = false;
                }
            }
            setProdOpers({ loading: false, data: tempProdOpers });

        } else if (fn_caller == "enddate") {


            tempProdOpers[opr_ind].selected_enddate = date;


            const officeStartMillis = 9 * 60 * 60 * 1000; // 9:30 AM in milliseconds
            const officeEndMillis = 18 * 60 * 60 * 1000; // 6:30 PM in milliseconds

            if (opr.selected_startdate) {

                // Calculate hours worked
                const hoursOfWork = calculateWorkHours(opr.selected_startdate, date, officeStartMillis, officeEndMillis);
                console.log(hoursOfWork); // Output: 19
                if (hoursOfWork > opr.selected_emp[0].total_hours -
                    opr.selected_emp[0].total_consumed_hrs_in_jc) {
                    tempProdOpers[opr_ind].hrs_exceeding_err = true;
                } else {
                    tempProdOpers[opr_ind].hrs_exceeding_err = false;
                }
            }
            setProdOpers({ loading: false, data: tempProdOpers });
        }
    }

    return (
        <React.Fragment>
            <div className='stepFour'>

                {visible &&
                    <JobCardModal
                        uniProdBom={uniProdBom}
                        setUniProdBom={setUniProdBom}
                        // selRoute={selRoute}
                        visible={visible}
                        setVisible={setVisible}
                        fetchEmp={fetchEmp}
                        empListWithAvail={empListWithAvail}
                        setEmpListWithAvail={setEmpListWithAvail}
                        selectedopr={selectedopr}
                        routeType={routeType}
                    />
                }

                <div className='row' id='row1'>
                    <div className='col-12'>

                        <div className="product_container">
                            {uniProdBom.map((val, ind) => (
                                <div className={val.selected ? "card item active" : "card item shadow-sm"} key={ind}
                                    onClick={async () => {

                                        setDisableDates(true);

                                        await fetchProdRoutes(val, ind);

                                        let tempUniProdBom = [...uniProdBom];
                                        console.log("tempUniProdBom", tempUniProdBom);

                                        // tempUniProdBom[ind].route_operation_list = [...prodOpers.data];
                                        // setUniProdBom(tempUniProdBom);
                                        console.log("ind", ind);
                                        console.log("tempUniProdBom[ind].selected_route", tempUniProdBom[ind].selected_route);
                                        if (tempUniProdBom[ind].selected_route) {
                                            console.log("1");

                                            if (productRoutes.isSearched && productRoutes.data.length == 0) {
                                                setValue("global_route", [tempUniProdBom[ind].selected_route]);
                                            } else {
                                                const tempRoute = { ...tempUniProdBom[ind].selected_route };
                                                delete tempRoute.route_operation_list;
                                                console.log("tempRoute", tempRoute);
                                                setValue("route", [tempUniProdBom[ind].selected_route]);

                                            }

                                        } else {
                                            // console.log("2");
                                            // setValue("route", []);
                                            // setValue("global_route", []);
                                        }

                                        if (tempUniProdBom[ind].startDate) {
                                            setValue("startDate", new Date(tempUniProdBom[ind].startDate));
                                        }

                                        if (tempUniProdBom[ind].endDate) {
                                            setValue("endDate", new Date(tempUniProdBom[ind].endDate));
                                        }


                                        if (tempUniProdBom[ind].selected_route &&
                                            tempUniProdBom[ind].selected_route.route_operation_list) {

                                            setProdOpers({
                                                ...prodOpers,
                                                loading: false,
                                                buttonClick: false,
                                                data: tempUniProdBom[ind].selected_route.route_operation_list
                                            });

                                            fetchEmp(tempUniProdBom[ind].selected_route.route_operation_list, ind);
                                        } else {

                                            setEmpListWithAvail({ ...empListWithAvail, data: [], loading: false });

                                            setProdOpers({
                                                ...prodOpers,
                                                loading: false,
                                                buttonClick: false,
                                                data: []
                                            });
                                        }



                                    }}>
                                    <p className='mb-0 product_name'>
                                        {`${val.productName}`}
                                    </p>
                                    <p className='mb-0 bom_id'>
                                        {`${val.selected_bom[0]?.bomId}`}
                                    </p>
                                </div>
                            ))}
                        </div>



                        <div className='card shadow-sm mt-4'>
                            <div className='card-body'>
                                <div className='row'>

                                    {productRoutes.isSearched && productRoutes.data.length == 0 ? null :

                                        <div className='col-12 col-md-3'>

                                            <div className="form-group mt-md-0 mt-3">
                                                <label
                                                    style={{ fontSize: "14px" }}
                                                    className="col-form-label roboto-bold"
                                                >
                                                    {constLabel?.lbl_routes
                                                        ? constLabel.lbl_routes
                                                        : "Select Route"}
                                                    <span className="text-danger"> *</span>
                                                </label>


                                                <Controller
                                                    name="route"
                                                    control={control}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Typeahead
                                                            id="basic-typeahead-single"
                                                            labelKey="routesname"
                                                            multiple={false}
                                                            // size="sm"
                                                            options={productRoutes.data}
                                                            isLoading={productRoutes.loading}
                                                            placeholder="Select route"
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            selected={value}
                                                            isInvalid={errors.route ? true : false}
                                                        />
                                                    )}
                                                />
                                                <span className="text-danger err-msg">
                                                    {errors.route?.message}
                                                </span>


                                            </div>

                                        </div>
                                    }



                                    {productRoutes.isSearched && productRoutes.data.length == 0 &&

                                        <div className='col-12 col-md-3'>

                                            <div className="form-group mt-md-0 mt-3">
                                                <label
                                                    style={{ fontSize: "14px" }}
                                                    className="col-form-label roboto-bold"
                                                >
                                                    {constLabel?.lbl_routes
                                                        ? constLabel.lbl_routes
                                                        : "Select Route"}
                                                    <span className="text-danger"> *</span>
                                                </label>


                                                <Controller
                                                    name="global_route"
                                                    control={control}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Typeahead
                                                            id="basic-typeahead-single"
                                                            labelKey="routeName"
                                                            multiple={false}
                                                            // size="sm"
                                                            options={productGlobalRoutes.data}
                                                            isLoading={productGlobalRoutes.loading}
                                                            placeholder="Select route"
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            selected={value}
                                                            isInvalid={errors.global_route ? true : false}
                                                        />
                                                    )}
                                                />
                                                <span className="text-danger err-msg">
                                                    {errors.global_route?.message}
                                                </span>


                                            </div>

                                        </div>
                                    }


                                    <div className='col-12 col-md-2'>

                                        <div className='form-group mt-md-0 mt-3'>
                                            <label
                                                style={{ fontSize: "14px" }}
                                                className="col-form-label roboto-bold"
                                            >
                                                {constLabel?.lbl_start_date
                                                    ? constLabel.lbl_start_date
                                                    : "Start Date"}
                                                <span className="text-danger"> *</span>
                                            </label>

                                            <Controller
                                                control={control}
                                                name="startDate"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        className={
                                                            errors.startDate
                                                                ? "form-control  is-invalid"
                                                                : "form-control  "
                                                        }
                                                        placeholderText="Select date"
                                                        selected={field.value}
                                                        onChange={(date) => {
                                                            field.onChange(date);
                                                        }}
                                                        dateFormat="dd/MM/yyyy"
                                                        autoComplete="false"
                                                        minDate={
                                                            new Date(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate()).getTime()
                                                        }
                                                        disabled={disableDates}
                                                    />
                                                )}
                                            />

                                            {errors.startDate && (
                                                <span className="text-danger err-msg">
                                                    {errors.startDate.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className='col-12 col-md-2'>

                                        <div className='form-group mt-md-0 mt-3'>
                                            <label
                                                style={{ fontSize: "14px" }}
                                                className="col-form-label roboto-bold"
                                            >
                                                {constLabel?.lbl_end_date
                                                    ? constLabel.lbl_end_date
                                                    : "End Date"}
                                                <span className="text-danger"> *</span>
                                            </label>

                                            <Controller
                                                control={control}
                                                name="endDate"
                                                render={({ field }) => (
                                                    <DatePicker
                                                        className={
                                                            errors.endDate
                                                                ? "form-control  is-invalid"
                                                                : "form-control  "
                                                        }
                                                        placeholderText="Select date"
                                                        selected={field.value}
                                                        onChange={(date) => {
                                                            field.onChange(date);

                                                        }}
                                                        dateFormat="dd/MM/yyyy"
                                                        autoComplete="false"
                                                        minDate={
                                                            new Date(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate()).getTime()
                                                        }
                                                        disabled={disableDates}
                                                    />
                                                )}
                                            />

                                            {errors.endDate && (
                                                <span className="text-danger err-msg">
                                                    {errors.endDate.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>

                                    <div className='col-12 col-md-3'>

                                        <div className='form-group mt-md-0 mt-3'>
                                            <div>
                                                <label
                                                    style={{ fontSize: "14px" }}
                                                    className="col-form-label roboto-bold invisible"
                                                >
                                                    label
                                                </label>
                                            </div>

                                            <div>

                                                <button
                                                    disabled={prodOpers.loading}
                                                    className='btn button-primary px-4'
                                                    onClick={handleSubmit(onSubmit)}
                                                >Get Worker Availability
                                                    <img className='ms-2' src={refresh}
                                                        alt="refresh" id='refresh-icon-img' />
                                                </button>
                                            </div>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/* table view */}
                        {!prodOpers.loading && prodOpers.data.length == 0 && prodOpers.buttonClick ?
                            < div
                                className="alert alert-danger mb-0 text-center my-4"
                                role="alert"
                            >
                                No Operations Found
                            </div>

                            :
                            !prodOpers.loading && prodOpers.data.length == 0 && !prodOpers.buttonClick ?
                                < div
                                    className="alert alert-danger mb-0 text-center my-4"
                                    role="alert"
                                >
                                    Select product and search
                                </div>

                                :

                                // prodOpers.loading ?
                                //     <div className="text-center">
                                //         <div className="spinner-border" role="status">
                                //             <span className="visually-hidden">Loading...</span>
                                //         </div>
                                //     </div>
                                // :

                                <table className="table table-bordered mt-4">
                                    <thead className="table-grey roboto-bold">
                                        <tr className="">

                                            <th scope="col" style={{ width: "25%" }}>
                                                {constLabel?.lbl_emp
                                                    ? constLabel.lbl_emp
                                                    : "Operation"}
                                            </th>

                                            <th scope="col" style={{ width: "15%" }}>
                                                {constLabel?.lbl_emp
                                                    ? constLabel.lbl_emp
                                                    : "Employee"}
                                            </th>

                                            <th scope="col" style={{ width: "15%" }}>
                                                {constLabel?.lbl_emp
                                                    ? constLabel.lbl_emp
                                                    : "Employee Available Hrs"}
                                            </th>

                                            <th scope="col" style={{ width: "15%" }}>
                                                {constLabel?.lbl_start_date
                                                    ? constLabel.lbl_start_date
                                                    : "Start Date"}
                                            </th>
                                            <th scope="col" style={{ width: "15%" }}>
                                                {constLabel?.lbl_end_date
                                                    ? constLabel.lbl_end_date
                                                    : "End Date"}
                                            </th>


                                            <th scope="col" style={{ width: "15%" }}>
                                                {constLabel?.lbl_action
                                                    ? constLabel.lbl_action
                                                    : "Action"}
                                            </th>


                                        </tr>
                                    </thead>
                                    <tbody className="roboto">

                                        {prodOpers.data.map((value, opr_ind) => (
                                            <tr key={opr_ind}>

                                                <td>
                                                    {`${value.operationname} `}
                                                </td>

                                                <td>
                                                    <Typeahead
                                                        id="basic-typeahead-single"
                                                        labelKey="FullName"
                                                        multiple={false}
                                                        options={empListWithAvail.data}
                                                        isLoading={empListWithAvail.loading}
                                                        placeholder="Select Employee"
                                                        onChange={(emp) => {
                                                            console.log("emp", emp);


                                                            let tempProdOpers = [...prodOpers.data];
                                                            tempProdOpers[opr_ind].selected_emp = emp;
                                                            tempProdOpers[opr_ind].selected_startdate = null;
                                                            tempProdOpers[opr_ind].selected_enddate = null;
                                                            tempProdOpers[opr_ind].create_job_card = null;
                                                            setProdOpers({ ...prodOpers, loading: false, data: tempProdOpers });


                                                        }}
                                                        selected={value.selected_emp}
                                                    />

                                                </td>

                                                <td>

                                                    {value.selected_emp && value.selected_emp[0] &&
                                                        value.selected_emp[0].total_hours -
                                                        value.selected_emp[0].total_consumed_hrs_in_jc
                                                    }
                                                </td>

                                                <td>
                                                    <DatePicker
                                                        className={"form-control"}
                                                        placeholderText="Select start date"
                                                        onChange={(date) => {
                                                            console.log(date);
                                                            handleDateChange("startdate", date, opr_ind, value);
                                                            // let tempProdOpers = [...prodOpers.data];
                                                            // tempProdOpers[opr_ind].selected_startdate = date;
                                                            // setProdOpers({ loading: false, data: tempProdOpers });
                                                        }}
                                                        dateFormat="dd/MM/yyyy HH:mm"
                                                        showTimeSelect
                                                        autoComplete="false"
                                                        minDate={startDate}
                                                        maxDate={endDate}
                                                        selected={value.selected_startdate}
                                                        disabled={value.selected_emp == null
                                                            || value.selected_emp == undefined
                                                            || value?.selected_emp?.length == 0}
                                                    />
                                                </td>

                                                <td>
                                                    <DatePicker
                                                        className={
                                                            value.hrs_exceeding_err
                                                                ? "form-control is-invalid"
                                                                : "form-control"
                                                        }
                                                        placeholderText="Select end date"
                                                        onChange={(date) => {
                                                            console.log(date);
                                                            handleDateChange("enddate", date, opr_ind, value);
                                                            // let tempProdOpers = [...prodOpers.data];
                                                            // tempProdOpers[opr_ind].selected_enddate = date;
                                                            // setProdOpers({ loading: false, data: tempProdOpers });
                                                        }}
                                                        dateFormat="dd/MM/yyyy HH:mm"
                                                        showTimeSelect
                                                        autoComplete="false"
                                                        minDate={startDate}
                                                        maxDate={endDate}
                                                        selected={value.selected_enddate}
                                                        disabled={value.selected_emp == null
                                                            || value.selected_emp == undefined
                                                            || value?.selected_emp?.length == 0}
                                                    />

                                                    {value.hrs_exceeding_err &&
                                                        <span className='text-danger'>Total hours of job card are exceeding total available hours</span>
                                                    }
                                                </td>

                                                <td>
                                                    <button
                                                        disabled={value.selected_emp == null
                                                            || value.selected_emp == undefined
                                                            || value?.selected_emp?.length == 0 || value.hrs_exceeding_err}
                                                        onClick={() => {
                                                            setSelectedOpr({ data: value, index: opr_ind });

                                                            const selProdIndex = uniProdBom.findIndex((val) => val.selected == true);
                                                            let tempUniProdBom = [...uniProdBom];

                                                            tempUniProdBom[selProdIndex].selected_route
                                                                .route_operation_list[opr_ind] = value;
                                                            setUniProdBom(tempUniProdBom);

                                                            setVisible(true);
                                                        }}
                                                        className='btn button-primary px-4'>
                                                        {value.create_job_card ? "Update Job Card" :
                                                            "Create Job Card"}
                                                        {/* <Edit
                                                                className="cursor_pointer me-2"
                                                                size={18}
                                                            /> */}

                                                    </button>
                                                </td>


                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                        }


                    </div>

                    <div className='d-flex justify-content-end mt-4'>
                        <button className='btn btn-grey px-4'
                            onClick={() => {
                                prevStep();
                            }}
                        >Previous</button>
                        <button
                            onClick={() => submitStep()}
                            className='btn button-primary px-4 ms-0 ms-md-2'
                        >Next</button>


                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default StepFour