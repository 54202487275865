import React, { useEffect, useState } from "react";
import {
  getOperatorList,
  createEmpApi,
  deleteEmpApi,
  get_master_files,
  del_master_file,
  getDesignationApi,
} from "../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  calMaxPage,
  getLabels,
  setAccessPrivilage,
  convertUrl,
} from "../../../common/Functions/CommonFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faCog,
  faPlus,
  faClose,
  faFileAlt,
  faBan,
} from "@fortawesome/free-solid-svg-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";

import { Tooltip } from "react-tooltip";
import { Eye, Trash2, Edit2, File, Edit } from "react-feather";
import { useSelector } from "react-redux";
import SweetAlert from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Pagination } from "react-bootstrap";
import { Emp_Col_Name } from "../../../common/Labels/labelConstant";
import List from "../../../assets/images/icons/project_menu/checklist.png";
import Slide from "../../../assets/images/icons/project_menu/collapse.png";

const schema = yup
  .object()
  .shape({
    machineCatName: yup
      .string()
      .required("Machine category name is required !"),
  })
  .required();

function EmployeeMaster() {
  let navigate = useNavigate();
  const [empList, setEmpList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [show2, setShow2] = useState(false);
  const [docList, setDocList] = useState({ data: [], loading: true });
  const [selEmp, setSelEmp] = useState({});
  const [desigList, setDesignList] = useState({ data: [], loading: false });

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage, setPrivilage] = useState(
    setAccessPrivilage(loginData, "Employee Master")
  );

  const [showLabel, setShowLabel] = useState(Emp_Col_Name);
  const [constLabel, setConstLabel] = useState({});
  const [visible, setVisible] = useState(false);
  const [filterConfig, setFilterConfig] = useState({
    card_id: "",
    searchVal: "",
    designation: [],
  });

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  useEffect(() => {
    setConstLabel(getLabels("Master", "Employee Master"));

    // storing modules access got in login in state
  }, []);

  useEffect(() => {
    if (desigList.data.length == 0) {
      fetchDesignationList();
    }
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchEmpList(1, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function fetchDesignationList() {
    setDesignList({ ...desigList, data: [], loading: true });

    getDesignationApi().then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.desi_name !== "" && item.desi_name !== null
        );
        setDesignList({ ...desigList, data: result, loading: false });
      },
      (err) => {
        setDesignList({ ...desigList, data: [], loading: false });
      }
    );
  }

  function fetchEmpList(pg, epp) {
    setEmpList({ ...empList, loading: true });
    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
    };

    if (filterConfig.designation.length > 0) {
      payload.designation = filterConfig.designation?.[0]?.desi_name;
    } else {
      delete payload.designation;
    }

    if (filterConfig.card_id != "") {
      // payload.searchVal = filterConfig.search_val;
      payload.card_id = filterConfig.card_id.trim(); //Search by emp card id

      // setSrNo(0)
    } else {
      delete payload.card_id;
    }

    if (filterConfig.searchVal != "") {
      // payload.searchVal = filterConfig.search_val;
      payload.searchVal = filterConfig.searchVal.trim(); //Search by First Name, Middle Name, Last Name

      // setSrNo(0)
    } else {
      delete payload.searchVal;
    }

    getOperatorList(payload).then(
      (res) => {
        setEmpList({
          ...empList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });
        setMaxPage(res.data.total);
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setEmpList({ ...empList, data: [], loading: false });
        toast.error(err.response.data.message, {
          autoClose: 2000,
        });
      }
    );
  }

  function handleClick(ind) {
    // let tempLabels = { ...constLabel };

    // tempLabels.tbl_column = tempLabels.tbl_column.map((val, i) => {
    //   if (ind === i) {
    //     // Toggle the flag for the clicked checkbox
    //     return { ...val, flag: !val.flag };
    //   }
    //   return val;
    // });
    // setConstLabel(tempLabels);
    // setConstLabel((val) => {
    //     const updatedCardShow = val.tbl_column.map((val, i) => {
    //         if (ind === i) {
    //             // Toggle the flag for the clicked checkbox
    //             return { ...val, flag: !val.flag };
    //         }
    //         return val;
    //     });

    //     return { tbl_column: updatedCardShow };
    // });

    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  function delEmp(val) {
    SweetAlert.fire({
      title: "Are you sure to delete employee ? \n",
      text: val.FirstName + " " + val.LastName,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = { uuid: val.uuid };
        deleteEmpApi(payload).then(
          (res) => {
            toast.success("Employee deleted successfully", {
              autoClose: 2000,
            });

            if (empList.data.length == 1 && page > 1) {
              fetchEmpList(page - 1, entriesPerPage);
            } else {
              fetchEmpList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  function fetchEmpDocs(emp) {
    get_master_files({ ref_model_uuid: emp.uuid }).then(
      (res) => {
        setDocList({ ...docList, data: res.data.data, loading: false });
      },
      (err) => {
        setDocList({ ...docList, data: [], loading: false });
        toast.error(err.response.data.message, {
          autoClose: 2000,
        });
      }
    );
  }

  function clearFilter() {
    let obj = {
      card_id: "",
      searchVal: "",
      designation: [],
    };
    setFilterConfig({ ...obj });
    setSrNo(0);
    setPage(1);
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div id="mySidebar" className="customsidebar ">
        <a className="closebtn" onClick={() => toggleNav()}>
          ×
        </a>

        <div className=" content">
          <div>
            <label
              className="filterLabel my-2 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {"Select Designation"}
            </label>

            <Typeahead
              id="basic-typeahead-single"
              labelKey="desi_name"
              name="designation"
              multiple={false}
              options={desigList.data}
              isLoading={desigList.loading}
              placeholder="Select designation"
              selected={filterConfig.designation}
              onChange={(val) => {
                if (val.length > 0) {
                  setFilterConfig({ ...filterConfig, designation: val });
                } else {
                  setFilterConfig({ ...filterConfig, designation: [] });
                }
              }}
            />
          </div>

          <div className="mt-2">
            <label
              className="filterLabel mb-1 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {constLabel?.lbl_emp_card_id
                ? constLabel?.lbl_emp_card_id
                : "Emp Card Id"}
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Search by employee card id"
              value={filterConfig.card_id}
              onChange={(val) => {
                const inputValue = val.target.value;
                if (!inputValue.trim()) {
                  // Input contains only whitespace, don't trigger the search.
                  if (filterConfig.card_id.length == 1) {
                    setFilterConfig({ ...filterConfig, card_id: "" });
                  }
                } else {
                  setFilterConfig({
                    ...filterConfig,
                    card_id: inputValue,
                  });
                }
                if (inputValue.length == 0) {
                  setFilterConfig({ ...filterConfig, card_id: "" });
                }
              }}
            />
          </div>

          <div className="mt-2">
            <label
              className="filterLabel mb-1 roboto-bold"
              style={{ fontSize: "14px" }}
            >
              {constLabel?.lbl_name ? constLabel?.lbl_name : "Name"}
            </label>
            <input
              className="form-control"
              type="text"
              placeholder="Search by name"
              value={filterConfig.searchVal}
              onChange={(val) => {
                const inputValue = val.target.value;
                if (!inputValue.trim()) {
                  // Input contains only whitespace, don't trigger the search.
                  if (filterConfig.searchVal.length == 1) {
                    setFilterConfig({ ...filterConfig, searchVal: "" });
                  }
                } else {
                  setFilterConfig({
                    ...filterConfig,
                    searchVal: inputValue,
                  });
                }
                if (inputValue.length == 0) {
                  setFilterConfig({ ...filterConfig, searchVal: "" });
                }
              }}
            />
          </div>

          <div className="my-3 d-flex justify-content-end">
            <button
              onClick={() => clearFilter()}
              className="btn btn-sm btn-yellow "
            >
              <FontAwesomeIcon
                style={{ color: "#344454" }}
                icon={faBan}
                size="sm"
              />{" "}
              {constLabel?.lbl_clear_filter
                ? constLabel?.lbl_clear_filter
                : "Clear Filter"}
            </button>
          </div>
        </div>
      </div>
      <div className="min-vh-100" id="root_div_main">
        <div className="content-wrapper">
          <div className="card border-0">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="d-md-flex justify-content-start col-12 col-md-6">
                  {/* <FontAwesomeIcon
                    onClick={() => toggleNav()}
                    className="cursor_pointer mb-2"
                    style={{
                      color: "Black",
                      marginRight: "10px",
                    }}
                    icon={faBars}
                    size="lg"
                  /> */}
                  <Tooltip id={"open-tooltip"} place="top" />
                  <img
                    src={Slide}
                    height="32"
                    width="32"
                    className="cursor_pointer my-1 me-2"
                    onClick={() => toggleNav()}
                    data-tooltip-id={"open-tooltip"}
                    data-tooltip-content={"Sidebar"}
                  />

                  <h1 className="bold">
                    {constLabel?.lbl_emp_master
                      ? constLabel.lbl_emp_master
                      : "Employee Master"}
                  </h1>
                </div>
                <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                  <button
                    className="btn button-primary me-0 me-2 ms-0 ms-md-2"
                    onClick={() => {
                      navigate("/pcms/employee");
                    }}
                  >
                    <FontAwesomeIcon
                      style={{ color: "#fff" }}
                      icon={faPlus}
                      size="sm"
                    />{" "}
                    {constLabel?.lbl_create_emp
                      ? constLabel.lbl_create_emp
                      : "Create Employee"}
                  </button>

                  <Tooltip id={"show-tooltip"} place="top" />
                  <div
                    className="dropdown"
                    data-tooltip-id={"show-tooltip"}
                    data-tooltip-content={"Show / Hide Columns"}
                  >
                    <div
                      className="d-md-flex justify-content-start align-items-center"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {/* <FontAwesomeIcon icon={faCog} size="lg" /> */}
                      {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                      <img
                        src={List}
                        height="32"
                        width="32"
                        className="cursor_pointer"
                      />
                    </div>

                    <ul className="dropdown-menu px-2">
                      {showLabel !== undefined && showLabel.length > 0
                        ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                {empList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : empList.data && empList.data.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead className="table-grey roboto">
                        <tr className="f-14">
                          <th scope="col">{"Sr.No"}</th>
                          {showLabel.map((v, i) =>
                            v.label === "Emp Id" && v.flag ? (
                              <th key={i} scope="col">
                                {"Emp Id"}
                              </th>
                            ) : v.label === "Emp Card Id" && v.flag ? (
                              <th key={i} scope="col">
                                {"Emp Card Id"}
                              </th>
                            ) : v.label === "Name" && v.flag ? (
                              <th key={i} scope="col">
                                {"Name"}
                              </th>
                            ) : v.label === "Designation" && v.flag ? (
                              <th key={i} scope="col">
                                {"Designation"}
                              </th>
                            ) : v.label === "Department" && v.flag ? (
                              <th key={i} scope="col">
                                {"Department"}
                              </th>
                            ) : v.label === "Sub Department" && v.flag ? (
                              <th key={i} scope="col">
                                {"Sub Department"}
                              </th>
                            ) : v.label === "Emp Type" && v.flag ? (
                              <th key={i} scope="col">
                                {"Emp Type"}
                              </th>
                            ) : v.label === "Contact No" && v.flag ? (
                              <th key={i} scope="col">
                                {"Contact No"}
                              </th>
                            ) : v.label === "Gender" && v.flag ? (
                              <th key={i} scope="col">
                                {"Gender"}
                              </th>
                            ) : v.label === "Location" && v.flag ? (
                              <th key={i} scope="col">
                                {"Location"}
                              </th>
                            ) : null
                          )}
                          <th scope="col">{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody className="roboto">
                        {empList.data.map((val, i) => (
                          <tr key={i}>
                            <td>{i + parseInt(srNo) + 1}</td>
                            {showLabel.map((item, idx) =>
                              item.label === "Emp Id" && item.flag ? (
                                <td key={idx}>
                                  {val.empid != "" && val.empid != null
                                    ? val.empid
                                    : "NA"}
                                </td>
                              ) : item.label === "Emp Card Id" && item.flag ? (
                                <td key={idx}>
                                  {val.card_id != "" && val.card_id != null
                                    ? val.card_id
                                    : "NA"}
                                </td>
                              ) : item.label === "Name" && item.flag ? (
                                <td key={idx}>
                                  {val.FirstName != "" &&
                                  val.FirstName != null &&
                                  val.LastName != "" &&
                                  val.LastName != null
                                    ? val.FirstName + " " + val.LastName
                                    : "NA"}
                                </td>
                              ) : item.label === "Designation" && item.flag ? (
                                <td key={idx}>
                                  {val.Designation != "" &&
                                  val.Designation != null
                                    ? val.Designation
                                    : "NA"}
                                </td>
                              ) : item.label === "Department" && item.flag ? (
                                <td key={idx}>
                                  {val.Department != "" &&
                                  val.Department != null
                                    ? val.Department
                                    : "NA"}
                                </td>
                              ) : item.label === "Sub Department" &&
                                item.flag ? (
                                <td key={idx}>
                                  {val.SubDepartment != "" &&
                                  val.SubDepartment != null
                                    ? val.SubDepartment
                                    : "NA"}
                                </td>
                              ) : item.label === "Emp Type" && item.flag ? (
                                <td key={idx}>
                                  {val.emp_type != "" && val.emp_type != null
                                    ? val.emp_type
                                    : "NA"}
                                </td>
                              ) : item.label === "Contact No" && item.flag ? (
                                <td key={idx}>
                                  {val.ContactNo != "" && val.ContactNo != null
                                    ? val.ContactNo
                                    : "NA"}
                                </td>
                              ) : item.label === "Gender" && item.flag ? (
                                <td key={idx}>
                                  {val.gender != "" && val.gender != null
                                    ? val.gender
                                    : "NA"}
                                </td>
                              ) : item.label === "Location" && item.flag ? (
                                <td key={idx}>
                                  {val.Location != "" && val.Location != null
                                    ? val.Location
                                    : "NA"}
                                </td>
                              ) : null
                            )}

                            <td>
                              {/* {privilage.read && <ViewMachineCategory data={val} constLabel={constLabel} /> } */}

                              {privilage.write && (
                                <>
                                  <Tooltip
                                    id={"edit-tooltip" + i}
                                    place="top"
                                  />
                                  <Edit
                                    data-tooltip-id={"edit-tooltip" + i}
                                    data-tooltip-content={
                                      constLabel?.lbl_edit_bom
                                        ? constLabel.lbl_edit_bom
                                        : "Edit Employee"
                                    }
                                    className="menu_icon_grey cursor_pointer me-2"
                                    size={20}
                                    onClick={() => {
                                      navigate("/pcms/employee", {
                                        state: {
                                          action: "update",
                                          data: val,
                                        },
                                      });
                                    }}
                                  />
                                </>
                              )}

                              {privilage.delete && (
                                <>
                                  <Tooltip
                                    id={"delete-tooltip" + i}
                                    place="top"
                                  />
                                  <Trash2
                                    data-tooltip-id={"delete-tooltip" + i}
                                    data-tooltip-content={
                                      constLabel?.lbl_delete_category
                                        ? constLabel.lbl_delete_category
                                        : "Delete Employee"
                                    }
                                    className="menu_icon_red cursor_pointer"
                                    size={20}
                                    onClick={() => delEmp(val)}
                                  />
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"Employee list is empty"}
                  </p>
                )}

                {empList.loading
                  ? null
                  : Object.keys(constLabel).length > 0 && (
                      <div className="d-flex justify-content-between align-items-center mt-2">
                        <p className="mb-0">
                          Show
                          <select
                            className="mx-1"
                            defaultValue={entriesPerPage}
                            onChange={(e) => {
                              setEntriesPerPage(e.target.value);
                              fetchEmpList(1, e.target.value);
                              setPage(1);
                              setSrNo(0);
                            }}
                          >
                            <option value={"10"}>10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          Entries
                        </p>
                        <p className="mb-0">{`Showing ${
                          parseInt(srNo) + 1
                        } to ${Math.min(
                          parseInt(entriesPerPage) + parseInt(srNo),
                          empList.totalRecords
                        )} of ${empList.totalRecords} entries`}</p>
                        <Pagination className="my-2">
                          <Pagination.Prev
                            disabled={page === 1 ? true : false}
                            onClick={() => {
                              fetchEmpList(
                                page - 1,
                                entriesPerPage,
                                parseInt(srNo) - parseInt(entriesPerPage)
                              );
                              setSrNo((prevC) =>
                                page - 1 == 1
                                  ? 0
                                  : prevC - parseInt(entriesPerPage)
                              );
                              setPage(page - 1);
                            }}
                          >
                            {"Prev"}
                          </Pagination.Prev>

                          <Pagination.Item active>{page}</Pagination.Item>

                          <Pagination.Next
                            disabled={
                              page === maxPage ||
                              maxPage === 0 ||
                              entriesPerPage > empList.data.length
                                ? true
                                : false
                            }
                            onClick={() => {
                              fetchEmpList(
                                page + 1,
                                entriesPerPage,
                                parseInt(srNo) + parseInt(entriesPerPage)
                              );
                              setSrNo(
                                (prevC) => prevC + parseInt(entriesPerPage)
                              );
                              setPage(page + 1);
                            }}
                          >
                            {"Next"}
                          </Pagination.Next>
                        </Pagination>
                      </div>
                    )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default EmployeeMaster;
